import React from "react";
import { Table, Input } from "elements";
import { useAuthDataContext } from "context/Context";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { useMutation } from "@apollo/client";
import { RISKS } from "graphql/queries";
import { useSnackbar } from "notistack";
import { CREATE_RISK, UPDATE_TESTSC } from "graphql/mutations";
import { map, filter } from "lodash";
import { getName } from "tools";
import AddBoxIcon from "@material-ui/icons/AddBox";

export default ({ data, testc, setRightbarData, refetch }) => {
  // const { user } = useAuthDataContext();
  // const [ open, setOpen ] = React.useState()
  const [createRisk] = useMutation(CREATE_RISK);
  const [updateTestc] = useMutation(UPDATE_TESTSC);
  const { enqueueSnackbar } = useSnackbar();

  const addRisk = () => {
    // console.log(data);
    setRightbarData({
      __typename: "Risk",
      risks: data,
      id: testc,
    });
  };
  const updateRisksInTestc = async (risks) => {
    await updateTestc({
      variables: { id: testc, input: { risks } },
      refetchQueries: [{ query: RISKS }],
    });

    enqueueSnackbar(`${getName("Testc")} zostało zaktualizowane`, {
      variant: "success",
    });
    refetch();
  };

  const onAdd = async ({ value, description }) => {
    const r = await createRisk({
      variables: {
        input: { value, description, testcs: data.id },
      },
      refetchQueries: [{ query: RISKS }],
    });

    await updateRisksInTestc([...map(data, "id"), r.data.createRisk.id]);
  };

  const onRemove = async (selected) => {
    let s = map(selected, "id"),
      u = filter(data, (d) => s.indexOf(d.id) < 0);

    await updateRisksInTestc(map(u, "id"));
  };

  return (
    <>
      <Table
        typename="Risk"
        columns={[
          {
            Header: "Nazwa",
            accessor: "value",
            type: "label",
            width: "38%",
          },
        ]}
        onAdd={onAdd}
        onDelete={onRemove}
        toolbarButtons={[
          // {
          //   label: "Nowe ryzyko",
          //   icon: <AddCircleOutlineIcon />,
          //   onClick: addNew,
          // },

          {
            label: "Istniejące ryzyko",
            icon: <AddBoxIcon />,
            onClick: addRisk,
          },
        ]}
        // {...props}
        data={data}
      />
      <>
        {/* <Dialog open={open} onClose={handleClose}>
          <DialogTitle id="form-dialog-title">
           
          </DialogTitle>
          <DialogContent style={{ width: 600 }}>
             <Input type="select" inputProps={{
                 fullWidth: true,
                 data: [{id: 1, value: "Plik 3"},{id: 2, value: "Plik 4"}]
             }}/>
          </DialogContent>
          <DialogActions>
            
            <Button onClick={handleClose} color="primary">
              Anuluj
            </Button>
            <Button type="submit" color="primary">
              Dodaj
            </Button>
          </DialogActions>
        
      </Dialog> */}
      </>
    </>
  );
};
