import React from 'react'
import {Select} from 'elements'
import { useQuery } from '@apollo/client';
import { PERSPECTIVES } from 'graphql/queries'


export default (props) => {
    const {data, loading} = useQuery(PERSPECTIVES)
    
    if(loading) return false
    // const setIt=(value)=>{
    //     console.log("aaa", value)
    //     onChange && onChange(value)
    // }

    return <Select {...props} data={data.perspectives.perspectiveFeed} />
}