import React from "react";

import NewRow from "./newRow";
import clsx from "clsx";
// import DeleteIcon from '@material-ui/icons/Delete'

import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import GlobalFilter from "./globalFilter";
import { lighten, makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Toolbar from "@material-ui/core/Toolbar";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight: {
    color: theme.palette.secondary.main,
    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
  },

  title: {
    flex: "1 1 100%",
    fontFamily: "bold",
    color: "#222",
  },
  selectedInfo: {
    fontFamily: "bold",
    marginRight: 10,
  },
}));

const TableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    addRow,
    deleteRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    globalFilter,
    typename,
  } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <>
            <span className={classes.selectedInfo}>
              Wybranych: {numSelected}
            </span>
            {props.toolbarActions &&
              props.toolbarActions.map((b, i) =>
                !b.typename ? (
                  <Button
                    startIcon={b.icon}
                    onClick={() => b.onClick(props.selected)}
                  >
                    {b.label}
                  </Button>
                ) : (
                  <NewRow
                    key={"toolbarAction" + i}
                    label={b.label}
                    addRow={b.onSubmit}
                    typename={b.typename}
                    buttonStyle={{ width: b.width }}
                    selected={props.selected}
                  />
                )
              )}
            {deleteRow && (
              <Button startIcon={<HighlightOffIcon />} onClick={deleteRow}>
                Usuń
              </Button>
            )}
            {/* { props.additionalButtons.map((b,i)=><Button  key={'additionalButton'+i} className={classes.button} style={{ width: b.width}} startIcon={b.icon} onClick={()=>additionalButtonClicked(b)}>{b.label}</Button>) } */}
          </>
        ) : (
          <>
            {props.handleShowAll && (
              <FormControlLabel
                control={
                  <Checkbox
                    // checked={showAll}
                    onChange={props.handleShowAll}
                    color="primary"
                  />
                }
                label="Pokaż wszystkie"
              />
            )}
            {typename && addRow && (
              <NewRow addRow={addRow} typename={typename} />
            )}
            {props.toolbarButtons &&
              props.toolbarButtons.map((b, i) => (
                <Button
                  key={"additionalButton" + i}
                  className={classes.button}
                  style={{ width: b.width }}
                  startIcon={b.icon}
                  onClick={b.onClick}
                >
                  {b.label}
                </Button>
              ))}
          </>
        )}
      </div>

      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
    </Toolbar>
  );
};

TableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default TableToolbar;
