import React from "react";
import { Button, Grid } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "@apollo/client";
import { useAuthDataContext } from "context/Context";
import { makeStyles } from "@material-ui/core/styles";
import { TESTS, TEST, REVIEW_NOTES } from "graphql/queries";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { Input } from "elements";
import { getName } from "tools";
import { UPDATE_TEST } from "graphql/mutations";

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: 12,
  },
  field: {
    marginBottom: 20,
  },
  list: {
    marginLeft: 30,
  },
  first: {
    "& .MuiInput-underline:before": {
      borderBottom: 0,
    },
  },
}));

export default ({ test }) => {
  const classes = useStyles();
  const { user } = useAuthDataContext();
  const [updateTest] = useMutation(UPDATE_TEST);
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  let rnCount = {
    num: 0,
    all: 0,
  };
  const rn = useQuery(REVIEW_NOTES, { variables: { tests: [test.id] } });
  if (rn.loading) return false;
  else {
    rn.data.reviewNotes.map((r) => {
      if (r.status) {
        rnCount.num += 1;
      }
      rnCount.all += 1;
    });
  }
  console.log(test.audit, 11111);

  const onCloseTest = async (value) => {
    // if(value) setOpen(true);

    const refetchQueries = [
      { query: TESTS },
      { query: TEST, variables: { id: test.id } },
    ];
    const na = await updateTest({
      variables: { id: test.id, input: { status: value ? 1 : 0 } },
      refetchQueries,
    });

    enqueueSnackbar(
      `${getName("Test")} ${na.data.updateTest.value} został ${
        value ? "zamknięty" : "otwarty"
      } `,
      { variant: "success" }
    );
  };

  const onChange = (name) => async (value) => {
    const refetchQueries = [
      { query: TESTS },
      { query: TEST, variables: { id: test.id } },
    ];

    const na = await updateTest({
      variables: { id: test.id, input: { [name]: value } },
      refetchQueries,
    });

    enqueueSnackbar(
      `${getName("Test")} ${na.data.updateTest.value} został zaktualizowany`,
      { variant: "success" }
    );
  };

  return (
    <Grid container className={classes.first}>
      <Grid item xs={5}>
        <div className={classes.label}>Audytor odpowiedzialny</div>
        <div className={classes.field}>
          <Input
            type="user"
            type="user"
            inputProps={{
              onChange: onChange("auditor"),
              value: test.auditor.id,
              withName: true,
            }}
          />
        </div>

        {/* <div className={classes.label}>Data testu</div><div className={classes.field}>
                    <Input inputProps={{ onChange: onChange('start'), value: test.start }} type="date" />
                </div> */}
        <div className={classes.label}>Czas</div>
        <div className={classes.field}>
          <Input
            inputProps={{ onChange: onChange("hours"), value: test.hours }}
            type="number"
          />
        </div>
      </Grid>
      <Grid item xs={7}>
        {test.from && (
          <span>
            <FormControl component="fieldset" style={{ width: "100%" }}>
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item> {test.from}</Grid>
              </Grid>
            </FormControl>
            <br />
            <br />
          </span>
        )}
        <FormControl component="fieldset" style={{ width: "100%" }}>
          <FormLabel component="legend">Statystyki</FormLabel>
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>Otwarte Review Notes:</Grid>
            <Grid item>
              {" "}
              <Input type="count" inputProps={{ value: rnCount }} />
            </Grid>
          </Grid>
        </FormControl>
        <br />
        <br />
        <FormControl component="fieldset" style={{ width: "100%" }}>
          <FormLabel component="legend">Status testu</FormLabel>
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>Otwarty</Grid>
            <Grid item>
              <Input
                type="switch"
                inputProps={{
                  onChange: onCloseTest,
                  value: test.status === 1,
                  disabled:
                    test.audit.status === 19 ||
                    [test.auditor.id, test.audit.leader.id].indexOf(user.id) >=
                      0,
                }}
              />
            </Grid>
            <Grid item>Zamknięty</Grid>
          </Grid>
        </FormControl>
      </Grid>
    </Grid>
  );
};
