import React from 'react'
import { Typography, Slider , Divider} from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/styles';
import {getSurveyData} from 'tools'
import { useQuery, useMutation } from '@apollo/client';
import { SURVEY } from 'graphql/queries'
import { useSnackbar } from 'notistack';
import {
    UPDATE_SURVEY
} from 'graphql/mutations'
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

const useStyles = makeStyles(theme => ({
    sliderContainer:{
      marginTop: 20,
      marginLeft: 80,
      marginBottom: 20,
      padding: 20
    },
    tButton:{
        marginLeft: 10,
        marginBottom: 10
    },
    left:{
        display: 'inline-block',
        width: 60,
        textAlign: 'right',
        marginRight: 10
    },
    right:{
        display: 'inline-block',
        width: 80,
        marginLeft: 10
    }
  }));

const StyledSlider = withStyles({
        root: {
          color: '#3880ff',
          height: 2,
          padding: '15px 0',
        },
        
        active: {},
        valueLabel: {
          left: 'calc(-50% - 3px)',
          top: -22,
          width: 100,
          '& *': {
            background: 'transparent',
            color: '#000',
            width: 100,
          },
        },
        track: {
          height: 2,
        },
        rail: {
          height: 2,
          opacity: 0.5,
          backgroundColor: '#bfbfbf',
        },
        mark: {
          backgroundColor: '#bfbfbf',
          height: 8,
          width: 1,
          marginTop: -3,
        },
        markActive: {
          opacity: 1,
          backgroundColor: 'currentColor',
        },
      })(Slider);
      
function DiscreteSlider(props) {
    const classes = useStyles();
    const {q, left, width, onChange,...rest} = props

    function valueText(value) {
        let v = ""+value
        return (v.length===4) ? `(${parseInt(v[0]+v[1]),parseInt(v[2]+v[3])})`: `(${parseInt(v[0]),parseInt(v[1]+v[2])})`;
    }
          
    function valueLabelFormat(value, q) {
        let isOdd = value%2
        let v = isOdd ? (value-1)+"": value+""
        let label = ""
        // if(v.length===4) label = isOdd ? `(${parseInt(v[0]+v[1])},${parseInt(v[2]+v[3])})<`:`(${parseInt(v[0]+v[1])},${parseInt(v[2]+v[3])})=`
        // else label = isOdd ? `(${parseInt(v[0])},${parseInt(v[1]+v[2])})<`:`(${parseInt(v[0])},${parseInt(v[1]+v[2])})=`
        // return label+props.q
        if(v.length===4) label = isOdd ? `(${parseInt(v[0]+v[1])},${parseInt(v[2]+v[3])})<`:`(${parseInt(v[0]+v[1])},${parseInt(v[2]+v[3])})=`
        else label = isOdd ? `(${parseInt(v[0])},${parseInt(v[1]+v[2])})<`:`(${parseInt(v[0])},${parseInt(v[1]+v[2])})=`
        // return label+props.q
        return props.q
    }
      
    return (
        <div className={classes.sliderContainer} style={{marginLeft: left, width }}>
            <StyledSlider
              onChangeCommitted={onChange}
              valueLabelFormat={valueLabelFormat}
              getAriaValueText={valueText}
              step={1}
              valueLabelDisplay="on"
              {...rest}
            />
        </div>
    );
}

function Question({idx, text, sliders, q, onChange, getMarks, values, condition}){
    // eslint-disable-next-line
    let display = true
    if(condition){
        try{ 
            display = eval(condition)
        }catch(e) { display = false }
        if(!display && values[idx][0]!==0) onChange(idx,0)(null,0)
    }

    return display ?  <>
        <Typography variant="div" style={{padding: 20}}>
            {text}          
        </Typography>
        { sliders.map(
            (s,j)=><DiscreteSlider key={"q"+idx+j} q={q} 
                min={s.min}
                max={s.max}
                left={s.left} 
                width={s.width}
                defaultValue={values[idx][j]}
                onChange={onChange(idx,j)}
                marks={getMarks( s.markSlice, s.markSlice+((s.max-s.min)/2)+1 )}
            />)
        }
        <Divider /><br />
    </>: null
}

function TButton({left, right, onChange, key, ...rest}){
    const [value, setValue] = React.useState(rest.value);

    const handleChange = (_, v) => {
        setValue(v);
        onChange(_,v)
    };
    const classes = useStyles();
    return (<div key={key} className={classes.tButton}>
        <span className={classes.left}>{left}</span>
        <ToggleButtonGroup
            value={value}
            exclusive
            onChange={handleChange}
            aria-label="wybierz"
        >
            <ToggleButton value={1} aria-label="left aligned">
            {"<"}
            </ToggleButton>
            <ToggleButton value={2} aria-label="centered">
            =
            </ToggleButton>
            <ToggleButton value={3} aria-label="right aligned">
            {">"}
            </ToggleButton>
        </ToggleButtonGroup>
        <span className={classes.right}>{right}</span>
    </div>)
}

export default () => {
    const {data,loading} = useQuery(SURVEY)
    const [updateSurvey] = useMutation(UPDATE_SURVEY)
    const [values, setValues] = React.useState(null)
    const { enqueueSnackbar } = useSnackbar();
    React.useEffect(() => {
        if(data) setValues(JSON.parse(data.survey)) 
    }, [data])

    if(loading || !values) return null
   
    const {questionnaire, marks, lastQuestion} = getSurveyData()
    const getMarks = (start, end) =>  marks.slice( start, end )

    const onChange = (i,j) => async (_, v) => {
        let vs = [...values]
        vs[i][j]=v
        setValues(vs)   
        
        const {data} = await updateSurvey({ 
            variables: { survey: JSON.stringify(vs) },
            refetchQueries: [{query: SURVEY }]
        })
        enqueueSnackbar(`Ankieta został zaktualizowana`, { variant: 'success' });
      
    }
   
    return (
        <div style={{marginTop: 20}}>
            {questionnaire.map((question,i)=><Question key={i} getMarks={getMarks} idx={i} values={values} onChange={onChange} {...question}/>)}
                <Typography variant="div" style={{padding: 20}}>
                   {"Proszę zdecydować czy podane pary są jednakowo ryzykowne czy nie wybierając jeden ze znaków =, < lub >"}          
                </Typography><br/><br/>
                {lastQuestion.map((q,i)=><TButton value={values[5][i]} key={i} onChange={onChange(5,i)} {...q}/>)} 
                <br/><br/>
        </div>
    )
}
