import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import {getColor} from 'tools'


const StyledTab = withStyles((theme) => ({
  root: props=>({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginLeft: 5,
    minWidth: 0,
    '&.Mui-selected':{
        color: getColor(props.color)[700],
    },
    '&:hover': {
        color: getColor(props.color)[400],
        opacity: 1,
    },
    
    '&:focus': {
        color: getColor(props.color)[700],
    },
  }),
}))((props) => <Tab disableRipple {...props}  />);

export default StyledTab