import React from "react";
import DrawList from "./drawlist";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery, useMutation } from "@apollo/client";
import { RECEIVERS } from "graphql/queries";
import { Loading } from "elements";
import { map } from "lodash";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1, 1, 0, 0),
  },
}));

export default ({ data, onUpdate, onUpdateEx }) => {
  // const [value, setValue] = React.useState("1");
  const m = React.useRef({});

  const a = useQuery(RECEIVERS);
  if (a.loading) return <Loading />;

  const onChange = (values) => {
    onUpdate(values, data.id);
  };

  const onChangeEx = (d, value) => {
    onUpdateEx(d, value, data.id);

    ex[d] = value;
  };

  let ex = {},
    selected = [];
  map(data.messages, (m) => {
    selected.push(m.receiver.id);
    ex[m.receiver.id] = m.value;
  });
  // map(data.messages, 'receiver.id')
  return (
    <DrawList
      data={a.data.receivers.receiverFeed}
      selected={selected}
      ex={ex}
      onChange={onChange}
      onChangeEx={onChangeEx}
      // onChangeLevels={onChangeLevels}
    />
  );
};
