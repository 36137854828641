import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Button } from "@material-ui/core";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import Fade from "@material-ui/core/Fade";
import NotesIcon from "@material-ui/icons/Notes";
import teal from "@material-ui/core/colors/teal";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  avatar: {
    width: 28,
    height: 28,
    fontSize: 14,
    fontFamily: "bold",
    cursor: "s-resize",
  },
  withText: {
    cursor: "s-resize",
  },
  empty: {
    width: 28,
    height: 28,
    fontSize: 14,
    fontFamily: "bold",
    opacity: ".1",
  },
  table: {
    maxWidth: 300,
  },
  text: {
    padding: 20,
  },
  ul: {
    listStyleType: "none",
    textAlign: "center",
  },
  num1: {
    fontSize: 13,
    color: "#fff",
    display: "block",
  },
  icon: {
    fontSize: 13,
  },
}));

export default ({ value, columns, all }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverToogle = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const getTableHead = () => {
    return (
      <TableHead>
        <TableRow>
          {columns.map((column) => (
            <TableCell style={{ width: column.width }}>{column.name}</TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  const getTableRows = () => {
    return value.map((v, i) => (
      <TableRow key={i}>
        {columns.map((column) => (
          <TableCell scope="row">{v && v[column.value]}</TableCell>
        ))}
      </TableRow>
    ));
  };
  // 23.227.38.65
  // console.log("aaa", value, all, parseInt(parseFloat(value.length/all)*10)*100)
  let n = all ? parseFloat(value.num / all) : 0;
  let cls = all ? parseInt(n * 10) * 100 : 500;
  if (cls > 900) cls = 900;
  if (n > 0 && n < 0.1) cls = 100;

  return (
    <div>
      {value && value.length ? (
        <Avatar
          onClick={handlePopoverToogle}
          className={classes.avatar}
          style={{ backgroundColor: teal[cls] }}
          variant="square"
        >
          <ul className={classes.ul}>
            <li className={classes.num1}>
              {columns ? value.length : <NotesIcon className={classes.icon} />}
            </li>
          </ul>
        </Avatar>
      ) : (
        <Avatar className={classes.avatar} variant="square">
          <ul className={classes.ul}>
            <li className={classes.num1}>
              {columns ? (
                0
              ) : (
                <div className={classes.empty}>
                  <NotesIcon className={classes.icon} />
                </div>
              )}
            </li>
          </ul>
        </Avatar>
      )}
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        transition
        style={{ maxWidth: 300, fontSize: 11 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              {columns ? (
                <Table className={classes.table} aria-label="info table">
                  {getTableHead()}
                  <TableBody>{getTableRows()}</TableBody>
                </Table>
              ) : (
                <div className={classes.text}>{value}</div>
              )}
              <Button onClick={handlePopoverClose} style={{ width: "100%" }}>
                Zamknij
              </Button>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
};

{
  /* <Avatar
        className={classes.avatar}
        onClick={handlePopoverToogle}
        // onMouseLeave={handlePopoverClose}
      >{
        columns ? <span>{value.length}</span>: <NotesIcon />}
      </Avatar>:
      <Avatar className={classes.avatarEmpty}><div></div></Avatar>
        
    } */
}
