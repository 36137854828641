import React, { useState, useRef } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { TESTS, USERS, AUDIT } from "graphql/queries";
import { MOVETESTS_AUDIT, UPDATE_AUDIT } from "graphql/mutations";
import { Table, Loading, RightBar } from "elements";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import Button from "@material-ui/core/Button";

// import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import moment from "moment-business-days";
import { useAuthDataContext } from "context/Context";
import _ from "lodash";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Input from "elements/input";

export default ({ audit }) => {
  const t = useQuery(TESTS, { variables: { audit: audit.id } });
  const u = useQuery(USERS);
  const [moveTestsInTeam] = useMutation(MOVETESTS_AUDIT);
  const [updateAudit] = useMutation(UPDATE_AUDIT);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const usersFrom = useRef([]);
  const userTo = useRef();

  const [rightBar, setRightBar] = React.useState(false);
  const { user, settings } = useAuthDataContext();
  const { enqueueSnackbar } = useSnackbar();

  if (t.loading || u.loading) return <Loading />;

  const columns = [
    {
      Header: "Audytor",
      accessor: "value",
      type: "label",
      width: "50%",
    },
    {
      Header: "Il. zadań",
      accessor: "count",
      type: "count",
      width: "15%",
      inputProps: {
        columns: [{ name: "Nazwa", value: "value", width: "100%" }],
      },
    },
    {
      Header: "Czas",
      accessor: "hours",
      type: "count",
      width: "15%",
      inputProps: {
        columns: [
          { name: "Nazwa", value: "value", width: "80%" },
          { name: "Godziny", value: "hours", width: "20%" },
        ],
      },
    },
    {
      Header: "Urlopy",
      accessor: "holidays",
      type: "count",
      width: "16%",
      inputProps: {
        columns: [{ name: "Data", value: "date", width: "100%" }],
      },
    },
  ];

  let holidays = {};

  u.data.users.map((uu) => {
    holidays[uu.id] = uu.holidays ? uu.holidays.split(",") : [];
  });

  let data = [audit.leader, ...audit.team];
  let usr = { 0: { count: 0, hours: 0, data: [] } },
    all = { count: 0, hours: 0 };

  data.map((d) => {
    if (d) usr[d.id] = { data: [], count: 0, hours: 0 };
  });

  t.data.tests.testFeed.map((t) => {
    if (!t.auditor) {
      usr[0].data.push(t);
      usr[0].count += 1;
      usr[0].hours += t.hours || 0;
    } else if (usr[t.auditor.id]) {
      usr[t.auditor.id].data.push(t);
      usr[t.auditor.id].count += 1;
      usr[t.auditor.id].hours += t.hours;
    } else usr[t.auditor.id] = { data: [t], count: 1, hours: t.hours || 0 };

    all.count += 1;
    // all.hours += t.hours;
  });

  const dates = JSON.parse(audit.dates);

  if (dates && dates.date3 && dates.date4) {
    let d1 = moment.unix(dates.date3 / 1000),
      d2 = moment.unix(dates.date4 / 1000);

    all.hours = d2.businessDiff(d1, true) * 8;
  } else {
    enqueueSnackbar(`Daty audytu nie zostay określone`, { variant: "error" });
    all.hours = 0;
  }

  let res = 0;

  Object.keys(usr).map((k) => {
    if (holidays[k] && dates) {
      res = holidays[k].filter((h) => h >= dates.date3 && h <= dates.date4);
      usr[k].all = all.hours - res.length * 8;
      usr[k].holidays = res.length;
      usr[k].holidaysData = [];
      res.length &&
        res.map((rr) =>
          usr[k].holidaysData.push({
            date: moment.unix(rr).format(settings.dateFormat || "YYYY-MM-DD"),
          })
        );
    }
  });

  // let hours=null
  data = data.map((d) => {
    if (d) {
      return {
        ...d,
        count: {
          num: usr[d.id] ? usr[d.id].count : 0,
          data: usr[d.id] ? usr[d.id].data : [],

          all: all.count,
        },
        hours: {
          num: usr[d.id] ? usr[d.id].hours : 0,
          data: usr[d.id] ? usr[d.id].data : [],
          all: usr[d.id] ? usr[d.id].all : 0,
        },
        holidays: {
          num: usr[d.id] ? usr[d.id].holidays : 0,
          data: usr[d.id] ? usr[d.id].holidaysData : [],
        },
      };
    }
  });
  if (usr[0].count) {
    data.push({
      id: 0,
      value: "Nieprzydzielone",
      count: {
        num: usr[0] ? usr[0].count : 0,
        data: usr[0] ? usr[0].data : [],
        all: all.count,
      },
      hours: {
        num: usr[0] ? usr[0].hours : 0,
        data: usr[0] ? usr[0].data : [],
        all: all.hours,
      },
    });
  }

  const goTo = (i) => {
    setRightBar(true);
  };

  const onDeleteFromTeam = (td) => {
    usersFrom.current = _.map(td, "id");
    setOpen(true);
  };

  const onDeleteFromTeamSubmit = async () => {
    if (audit.leader.id !== user.id) {
      enqueueSnackbar(`Nie jesteś liderem zespołu`, { variant: "error" });
      return false;
    }
    let team = _.map(audit.team, "id");
    const idx = usersFrom.current.indexOf(audit.leader.id);

    let refetchQueries = [
      { query: TESTS },
      { query: TESTS, variables: { audit: audit.id } },
    ];

    await moveTestsInTeam({
      variables: {
        input: {
          audit: audit.id,
          usersFrom: usersFrom.current,
          userTo: userTo.current,
        },
      },
      refetchQueries,
    });

    if (idx >= 0) {
      usersFrom.current.splice(idx, 1);
      await updateAudit({
        variables: {
          id: audit.id,
          input: { leader: userTo.current },
        },
        refetchQueries: [
          { query: USERS },
          { query: AUDIT, variables: { id: audit.id } },
        ],
      });

      _.remove(team, (n) => userTo.current === n);
    } else if (team.indexOf(userTo.current) < 0) team.push(userTo.current);

    _.remove(team, (n) => usersFrom.current.indexOf(n) >= 0);

    await updateTeam(team);

    usersFrom.current = null;
    userTo.current = null;
    setOpen(false);
  };

  const updateTeam = async (team) => {
    if (audit.leader.id !== user.id) {
      enqueueSnackbar(`Nie jesteś liderem zespołu`, { variant: "error" });
      return false;
    }

    await updateAudit({
      variables: {
        id: audit.id,
        input: { team },
      },
      refetchQueries: [
        { query: USERS },
        { query: AUDIT, variables: { id: audit.id } },
      ],
    });
  };

  const onChange = (usr) => {
    userTo.current = usr;
  };

  const handleClose = () => {
    setOpen(false);
  };

  const addToTeam = async ({ user }) => {
    if (audit.leader.id !== user.id) {
      enqueueSnackbar(`Nie jesteś liderem zespołu`, { variant: "error" });
      return false;
    }

    const refetchQueries = [
      { query: USERS },
      { query: AUDIT, variables: { id: audit.id } },
    ];
    let team = _.map(audit.team, "id");
    if (team.indexOf(user) < 0) {
      team.push(user);
      await updateAudit({
        variables: {
          id: audit.id,
          input: { team },
        },
        refetchQueries,
      });
    }
  };

  const onMoveTestsForm = async ({ user, users }) => {
    let team = _.map(audit.team, "id");
    let refetchQueries = [
      { query: TESTS },
      { query: TESTS, variables: { audit: audit.id } },
    ];

    if (team.indexOf(user) < 0) await addToTeam({ user });

    await moveTestsInTeam({
      variables: {
        input: {
          audit: audit.id,
          usersFrom: users.split(","),
          userTo: user,
        },
      },
      refetchQueries,
    });
  };

  const onAddSubmit = () => {};

  return (
    <>
      <Table
        typename="Team"
        columns={columns}
        skipPageReset={true}
        data={data}
        rowActions={[{ icon: <KeyboardArrowRightIcon />, onClick: goTo }]}
        customConfirmDelete={true}
        onAdd={addToTeam}
        onDelete={onDeleteFromTeam}
        toolbarActions={[
          {
            typename: "TeamMove",
            onSubmit: onMoveTestsForm,
          },
        ]}
      />
      {/* <RightBar open={rightBar} onClose={() => setRightBar(false)}>
        abc
      </RightBar> */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Usuwanie członka zespółu
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Wybierz członka zespoły do którego mają być przepisane testy
            audytowe usuwanego użytkownika
          </DialogContentText>
          <Input
            type="user"
            inputProps={{ onChange: onChange, withName: true }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Anuluj
          </Button>
          <Button onClick={onDeleteFromTeamSubmit} color="primary">
            Usuń
          </Button>
        </DialogActions>
      </Dialog>
      {/* <Dialog
        open={open2}
        onClose={handleClose2}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Usuwanie członka zespółu
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Wybierz użytkonika którego chcesz dodać do zespółu
          </DialogContentText>
          <Input
            type="user"
            inputProps={{ onChange: onChange, withName: true }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2} color="primary">
            Anuluj
          </Button>
          <Button onClick={onAddSubmit} color="primary">
            Usuń
          </Button>
        </DialogActions>
      </Dialog> */}
    </>
  );
};
