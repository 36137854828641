import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuthDataContext } from 'context/Context'
import {
  SignIn as SignInView,
} from 'views';

const RouteWithLayout = props => {
  const { layout: Layout, component: Component, ...rest } = props;
  const { user } = useAuthDataContext();
  // if(!user) return false

  return (user || props.path==='sign-in' ? 
      <Route
        {...rest}
        render={matchProps => (
          <Layout>
            <Component {...matchProps} />
          </Layout>
        )}
      />:
      <SignInView />
  );
 


 
  
};


RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
};

export default RouteWithLayout;
