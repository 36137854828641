import React, { useState, useRef } from "react";
import { Table, Loading } from "elements";
import { useQuery, useMutation } from "@apollo/client";
import { TASKS } from "graphql/queries";
import { FILE_URL } from "vars";
import {
  UPDATE_TASK,
  DELETE_TASK,
  CREATE_TASK,
  EXPORT_TASKS,
} from "graphql/mutations";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/styles";
import { getName } from "tools";
import { useAuthDataContext } from "context/Context";
import GridOnIcon from "@material-ui/icons/GridOn";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import _ from "lodash";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  tab: {
    paddingRight: 45,
  },
}));

const TaskListClose = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [updateTask] = useMutation(UPDATE_TASK);
  const [createTask] = useMutation(CREATE_TASK);
  const [deleteTask] = useMutation(DELETE_TASK);
  const [exportTasks] = useMutation(EXPORT_TASKS);
  const { systemSettings, settings, setSettings } = useAuthDataContext();
  const history = useHistory();

  let data = [];
  props.data.map((d, i) => {
    data.push({
      ...d,
      //link: [d.audit ? d.audit : null, d.test ? d.test : null],
    });
  });

  const setData = () => {
    console.log("setData");
  };
  const _updateTask = async (idx, name, value) => {
    let row = props.data[idx];
    // console.log(idx,name,value, row)
    const refetchQueries = [{ query: TASKS }];

    const { data } = await updateTask({
      variables: { id: row.id, input: { [name]: value } },
      refetchQueries,
    });

    enqueueSnackbar(
      `${getName("Task")} ${data.updateTask.value} zostało zaktualizowane`,
      { variant: "success" }
    );
  };
  const _createTask = async (values) => {
    let input = { ...values };
    // input.test=props.test.id
    // input.audit=props.test.audit.id
    if (input.riskSelect) input.riskSelect = parseInt(input.riskSelect);
    // if (input.financialImpactA)
    //   input.financialImpactA = parseInt(input.financialImpactA);
    // if (input.financialImpactP)
    //   input.financialImpactP = parseInt(input.financialImpactP);
    const { data } = await createTask({
      variables: { input },
      refetchQueries: [{ query: TASKS }],
    });
    enqueueSnackbar(
      `${getName("Task")} ${data.createTask.value} zostało dodane`,
      { variant: "success" }
    );
  };
  const _deleteTask = async (fs) => {
    if (fs.length === 0) return false;
    let snackText = [
      fs.length > 1 ? getName("Task", 1) : getName("Task"),
      "",
      fs.length > 1 ? "zostały usunięte" : "został usunięty",
    ];
    let names = [];
    for (let i = fs.length - 1; i > 0; i--) {
      await deleteTask({ variables: { id: fs[i].id } });
      names.push(fs[i].value);
    }
    await deleteTask({
      variables: { id: fs[0].id },
      refetchQueries: [{ query: TASKS }],
    });
    names.push(fs[0].value);
    snackText[1] = names.join(", ");
    enqueueSnackbar(snackText.join(" "), { variant: "success" });
  };

  const exportToFile = async () => {
    const result = await exportTasks();

    download(result.data.exportTasks);
    enqueueSnackbar(getName("Task", 1) + " poprawie wyeksportowane ", {
      variant: "success",
    });
  };

  const download = (name) => {
    const link = document.createElement("a");
    // link.targe="_blank"
    link.href = `${FILE_URL}/${name}/tasks.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getLabel = (num) => {
    if (
      systemSettings &&
      systemSettings.riskLabel &&
      systemSettings.riskLabel[num]
    )
      return systemSettings.riskLabel[num];
    else return num;
  };

  let columns = [
    // {
    //   Header: "Otw./zamk.",
    //   accessor: "status",
    //   type: "switch",
    //   width: "10%",
    // },

    {
      Header: "Nazwa",
      accessor: "value",
      type: "label",
      width: "25%",
      // inputProps: {
      //   subtext: "info",
      // },
    },
    {
      Header: "Status",
      accessor: "status",
      type: "status",
      inputProps: {
        labels: {
          0: { label: "W trakcie" },
          1: { label: "Prośba o przedłużenie" },
          2: { label: "Prośba odrzucona" },
          4: { label: "Wdrożona" },
          5: { label: "Zamknięty" },
        },
      },
      width: "10%",
    },
  ];

  function goToTask(i) {
    const task = props.data[i];
    const tabs = settings.tabs ? [...settings.tabs] : [];

    if (_.findIndex(tabs, { id: task.id }) < 0) {
      setSettings({
        tabs: [
          {
            id: task.id,
            title:
              task.value.length > 20
                ? task.value.slice(0, 20) + "..."
                : task.value,
            full: task.value,
            parent: null,
            href: "/task/" + task.id,
          },
          ...tabs,
        ],
      });
    }

    history.push("/task/" + task.id);
  }

  return (
    <Table
      typename="Task"
      columns={columns}
      setData={setData}
      updateMyData={_updateTask}
      skipPageReset={true}
      // onAdd={_createTask}
      // onDelete={_deleteTask}
      rowActions={[{ icon: <KeyboardArrowRightIcon />, onClick: goToTask }]}
      // toolbarButtons={[
      //   {
      //     label: "Eksportuj do Excel'a",
      //     icon: <GridOnIcon />,
      //     onClick: exportToFile,
      //   },
      // ]}
      {...props}
      data={data}
    />
  );
};

export default () => {
  const { loading, data } = useQuery(TASKS, { variables: { status: "5" } });

  if (loading) return <Loading />;

  return <TaskListClose data={data.tasks.taskFeed} />;
};
