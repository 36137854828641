import React from "react";
import { Table, Form } from "elements";
import { useForm } from "react-hook-form";
import { useAuthDataContext } from "context/Context";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { map, filter } from "lodash";

export default ({ testc, onUpdate }) => {
  const { user } = useAuthDataContext();
  const [inputs, setInputs] = React.useState(JSON.parse(testc.inputs || "[]"));

  const [open, setOpen] = React.useState();
  const form = useForm();

  const onAdd = (_1, _2, _3) => {
    console.log("upd", _1, _2, _3);
  };
  const onDelete = async (_1) => {
    let s = [...inputs];

    for (let i = 0; i < _1.length; i++) {
      s = filter(s, function (o) {
        return o.id != _1[i].id;
      });
    }
    await onUpdate(testc.id, { inputs: JSON.stringify(s) });
    setInputs(s);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdate = async (idx, name, value) => {
    let s = [...inputs];

    // console.log([...s, values.source])
    console.log(inputs);
    console.log(idx, name, value);

    s[idx][name] = value;

    await onUpdate(testc.id, { inputs: JSON.stringify(s) });
    setInputs(s);
    // handleClose();
  };

  return (
    <>
      <Table
        typename="Vars"
        columns={[
          {
            Header: "Nazwa",
            accessor: "name",
            type: "label",
          },
          {
            Header: "Wskazówka",
            accessor: "hint",
            type: "info",
          },
          {
            Header: "Wartość",
            accessor: "value",
            type: "text",
          },
        ]}
        // toolbarButtons={[

        //   {
        //     label: "Dodaj zmienną",
        //     icon: <AddCircleOutlineIcon />,
        //     onClick: ()=>setOpen(true),
        //   },
        // ]}

        // setData={setData}
        updateMyData={handleUpdate}
        onDelete={onDelete}
        // {...props}
        data={inputs || []}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="form-dialog-title">Zdefiniuj zmienne</DialogTitle>
        <DialogContent style={{ width: 600 }}>
          <form>
            <Form
              fields={[
                {
                  label: "Nazwa",
                  type: "text",
                  fullWidth: true,
                  name: "name",
                },
                {
                  label: "Wartość",
                  type: "text",
                  fullWidth: true,
                  name: "value",
                },
              ]}
              form={form}
            />
          </form>
          {/* <Input type="select" inputProps={{
                 fullWidth: true,
                 data: data.sources.sourceFeed
             }}/> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Anuluj
          </Button>
          <Button
            onClick={form.handleSubmit(handleUpdate)}
            type="submit"
            color="primary"
          >
            Dodaj
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
