import React, { useState, useRef } from "react";
import { Table } from "elements";
import { useQuery, useMutation } from "@apollo/client";
import { REVIEW_NOTES } from "graphql/queries";
import {
  UPDATE_REVIEWNOTE,
  DELETE_REVIEWNOTE,
  CREATE_REVIEWNOTE,
} from "graphql/mutations";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/styles";
import { getName } from "tools";
// import { useAuthDataContext } from 'context/Context'
import { ReviewNotes } from "components/SharedComponents";

const useStyles = makeStyles((theme) => ({
  tab: {
    paddingRight: 45,
  },
}));

export default ({ test }) => {
  return (
    <ReviewNotes
      disabled={test.status === 1 || test.audit.status === 19}
      testId={test.id}
      auditId={test.audit.id}
    />
  );
};
