import React from "react";
import { getError } from "tools";
import { makeStyles } from "@material-ui/core/styles";
import { useAuthDataContext } from "context/Context";
import _ from "lodash";
import { useHistory } from "react-router";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

const useStyles = makeStyles((theme) => ({
  link: {
    fontSize: 12,
    color: "#666",
    marginRight: 5,
    cursor: "pointer",
    "&:hover": {
      color: "#222",
    },
  },
  slink: {
    cursor: "pointer",
    "&:hover": {
      color: "#222",
    },
  },
}));

export default (props) => {
  const { systemSettings, settings, setSettings } = useAuthDataContext();
  const classes = useStyles();
  const history = useHistory();
  const v = Array.isArray(props.value) ? props.value : [props.value];

  function goToUrl(p) {
    const { value, id, __typename } = p;
    const tabs = settings.tabs ? [...settings.tabs] : [];
    const href = `/${__typename}/${id}`;

    if (_.findIndex(tabs, { id }) < 0) {
      setSettings({
        tabs: [
          {
            id: id,
            title: value.length > 20 ? value.slice(0, 20) + "..." : value,
            full: value,
            parent: null,
            href,
          },
          ...tabs,
        ],
      });
    }

    history.push(href);
  }

  return v ? (
    v.length === 1 && v[0] ? (
      <>
        <div className={classes.slink} onClick={() => goToUrl(v[0])}>
          {v[0].value}
        </div>
      </>
    ) : (
      <>
        {v[0] && (
          <div className={classes.link} onClick={() => goToUrl(v[0])}>
            {v[0].value}
          </div>
        )}

        {v[1] && (
          <div className={classes.link} onClick={() => goToUrl(v[1])}>
            {v[1].value}
          </div>
        )}
      </>
    )
  ) : (
    <>-</>
  );
};
