import React from "react";
import {
  AUDITS,
  AUDITGROUPS,
  TESTS,
  USERS,
  SELECTEDAUDITGROUPS,
} from "graphql/queries";
import { useAuthDataContext } from "context/Context";
import { RightBar, MultiSelect, Panel, Loading, Input } from "elements";
import { useQuery } from "@apollo/client";
import { isEmpty } from "underscore";
import { makeStyles } from "@material-ui/styles";
import { getName } from "tools";
import _ from "lodash";
import {
  Card,
  CardHeader,
  CardContent,
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  Divider,
} from "@material-ui/core";
import { useParams, useHistory} from "react-router-dom";
import { Scheduler, SimpleScheduler } from "./components";
import moment from "moment-business-days";

const useStyles = makeStyles((theme) => ({}));

const SchedulerContainer = ({
  auditIds,
  auditGroups,
  audits,
  users,
  simple,
  filter, t
}) => {
  const [tasks, setTasks] = React.useState([])

  let ath = {},
    data = [],
    dates = null,
    dataById = {},
    duration = 0,
    hours = 0;
  let format = "DD-MM-YYYY";

  moment.updateLocale("pl", {
    holidays: ["07-07-2020", "08-07-2020"],
    holidayFormat: "DD-MM-YYYY",
  });

  // let a = moment('07-07-2020', "DD-MM-YYYY").businessDiff(moment('10-07-2020', "DD-MM-YYYY"))
  // alert(a)

  React.useEffect(()=>{
    console.log("IN!!!")
      auditGroups.map((ag) => {
        dataById[ag.id] = {
          name: ag.value,
          start_date: 0,
          end_date: 0,
          duration: 0,
        };
        ag.audits.map((a) => {
          dates = JSON.parse(a.dates);

          if (dates && dates.date1 && dates.date4) {
            if (
              !dataById[ag.id].start_date ||
              dataById[ag.id].start_date > parseInt(dates.date1) / 1000
            )
              dataById[ag.id].start_date = parseInt(dates.date1) / 1000;

            if (
              !dataById[ag.id].end_date ||
              dataById[ag.id].end_date > parseInt(dates.date4) / 1000
            )
              dataById[ag.id].end_date = parseInt(dates.date4) / 1000;

            dataById[ag.id].duration =
              moment
                .unix(parseInt(dataById[ag.id].end_date) / 1000)
                .businessDiff(
                  moment.unix(parseInt(dataById[ag.id].start_date) / 1000)
                ) * 8;
            dataById[a.id] = {
              team: {},
              name: a.value,
              start_date: parseInt(dates.date1) / 1000,
              duration:
                moment
                  .unix(parseInt(dates.date4) / 1000)
                  .businessDiff(moment.unix(parseInt(dates.date1) / 1000)) * 8,
            };

            dataById[a.id].team[a.leader.id] = {
              name: a.leader.value,
              start_date: parseInt(dates.date1) / 1000,
              duration: 1,
            };
            a.team.map((at) => {
              dataById[a.id].team[at.id] = {
                name: at.value,
                start_date: parseInt(dates.date1) / 1000,

                duration: 1,
              };
            });
          }
        });
      });

      t.data.tests.testFeed.map((tt) => {
        if (tt.audit) {
          if (
            tt.auditor &&
            dataById[tt.audit.id] &&
            dataById[tt.audit.id].team &&
            dataById[tt.audit.id].team[tt.auditor.id]
          )
            dataById[tt.audit.id].team[tt.auditor.id].duration === 1
              ? (dataById[tt.audit.id].team[tt.auditor.id].duration = parseInt(
                  tt.hours
                ))
              : (dataById[tt.audit.id].team[tt.auditor.id].duration += parseInt(
                  tt.hours
                ));
        }
      });
      let au = null,
        usr = null,
        days = 0;
      let h = [1594080000];

      Object.keys(dataById).map((di) => {
        au = dataById[di];
        if (au.team) {
          Object.keys(au.team).map((dii) => {
            usr = au.team[dii];

            // console.log(usr.name,usr.start_date, moment.unix(usr.start_date).businessAdd(Math.ceil(usr.duration/8)).format("YYYY-MM-DD") )
          });
        }
      });

      auditGroups.map((ag) => {
        data.push({
          class: "group",
          id: ag.id,
          value: ag.value,
          text: ag.value,
          duration: parseInt(dataById[ag.id].duration),
          start_date: moment.unix(dataById[ag.id].start_date).format(format),
        });
        ag.audits.map((a) => {
          if (!dataById[a.id]) return false;
          dates = JSON.parse(a.dates);
          if (dates && dates.date1) {
            data.push({
              id: a.id,
              class: "audit",
              value: a.value,
              text: a.value,
              duration: parseInt(dataById[a.id].duration),
              start_date: moment.unix(dataById[a.id].start_date).format(format),
              parent: ag.id,
            });
            data.push({
              id: a.id + a.leader.id,
              class:
                parseInt(dataById[a.id].team[a.leader.id].duration) >
                parseInt(dataById[a.id].duration)
                  ? "error"
                  : parseInt(dataById[a.id].team[a.leader.id].duration) === 1
                  ? "empty"
                  : "user",

              value: users[a.leader.id]?.value,
              text: users[a.leader.id]?.value,
              parent: a.id,
              owner_id: a.leader.id,
              duration: dataById[a.id].team[a.leader.id].duration
                ? parseInt(dataById[a.id].team[a.leader.id].duration)
                : parseInt(dataById[a.id].duration),
              start_date: moment
                .unix(dataById[a.id].team[a.leader.id].start_date)
                .format(format),
            });
            let d = null;
            a.team.map((at) => {
              d = parseInt(dataById[a.id].team[at.id].duration);
              data.push({
                id: a.id + at.id,
                class:
                  d > parseInt(dataById[a.id].duration)
                    ? "error"
                    : d === 1
                    ? "empty"
                    : "user",
                value: users[at.id]?.value,
                text: users[at.id]?.value,
                duration: d,
                start_date: moment
                  .unix(dataById[a.id].team[at.id].start_date)
                  .format(format),
                owner_id: at.id,
                parent: a.id,
              });
            });
          }
        });
      });

      
      if(filter.length>0){
        data = data.filter(d => d.owner_id===undefined || (d.owner_id && filter.indexOf(d.owner_id)>=0) )

      
        data = data.filter((d,i) => {
          if(d.class==='group'){
              try{
      
                return data[i+2].owner_id===undefined || i+1===data.length-1 || i===data.length-1  ? false: true 
              }catch(err){ return false } 
          } if(d.class==='audit'){
              try{
                return data[i+1].owner_id===undefined || i===data.length-1  ? false: true 
              }catch(err){ return false } 
          } else return true
      
        }) 
      }
      console.log(data, tasks)
      setTasks([...data])

  
  }, [filter, auditGroups])

  if(!tasks.length) return null

  return (
    <div style={{ height: "100%" }}>
      {simple ? (
        <SimpleScheduler tasks={tasks} />
      ) : (
        <Scheduler tasks={{ data:tasks }} style={{ height: "100%" }} users={users} />
      )}
    </div>
  );
};

function TabPanel(props) {
  const { children, value, index, style, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={style}
      {...other}
    >
      {value === index && <Box style={style}>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default () => {
  let { num } = useParams();
  
  const [pids, setPids] = React.useState([]);
  const {
    settings: { auditGroupFilter, auditTeamFilter },
    setSettings,
  } = useAuthDataContext();
  const [teamFilter, setTeamFilter] = React.useState(auditTeamFilter || []);
  const [ids, setIds] = React.useState(auditGroupFilter || []);

  const ag = useQuery(SELECTEDAUDITGROUPS, {
    variables: { ids },
    fetchPolicy: "no-cache",
  });
  const u = useQuery(USERS);
  const aag = useQuery(AUDITGROUPS);
  const classes = useStyles();
  const [value, setValue] = React.useState(parseInt(num) || 0);
  const [rightbarData, setRightbarData] = React.useState(false);
  const history = useHistory()
  const t = useQuery(TESTS, { variables: { audits: [] } });
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const changeAuditGroup = (_ids) => {
    setIds(_ids);
    setSettings({ auditGroupFilter: _ids });
    // window.location.reload(false);

    ag.refetch({ variables: { ids: _ids } });
    t.refetch({ variables: { audits: auditIds } } )
  };

  if (ag.loading || u.loading || aag.loading || t.loading) return <Loading />;

  let auditIds = [],
    audits = {};
  ag.data.auditGroups.map((aa) => {
    aa.audits.map((a) => {
      auditIds.push(a.id);
      audits[a.id] = a;
    });
  });

  let users = {};
  u.data.users.map((uu) =>  { if(teamFilter.length === 0 || teamFilter.indexOf(uu.id)>=0) users[uu.id] = uu } );

  // users = users.filter( u=>teamFilter.indexOf(u.id)>=0 )

  const onTeamChange = (tf) => {
    
    setSettings({ auditTeamFilter: tf });
   
    setTeamFilter([...tf])
    history.go(0)
    // window.location.reload(false);
  }

  return (
    <div style={{ height: "100%" }}>
      <AppBar position="static" color="transparent">
        <Tabs value={value} onChange={handleChange} aria-label="">
          <Tab label="Terminarz" {...a11yProps(0)} />
          {/* <Tab label="Terminarz2" {...a11yProps(1)} />  */}
        </Tabs>
      </AppBar>
      <TabPanel
        value={value}
        index={0}
        className={classes.tab}
        style={{ height: "100%" }}
      >
        <Panel
          subheader={
            <>
            <input type="hidden" value={teamFilter} />
            <Input type="team" value={teamFilter} inputProps={{ onChange: onTeamChange, value: teamFilter }}/>
            <MultiSelect
               label={getName("AuditGroup", 1)}
               value={ids}
              onChange={changeAuditGroup}
               data={aag.data.auditGroups}
               noBorder={true}
             />
            </>
          }
          title="Harmonogram"
          style={{ height: "100%" }}
        >
          <SchedulerContainer
            auditGroups={ag.data.auditGroups}
            auditIds={auditIds}
            users={users}
            audits={audits}
            filter={ teamFilter }
            t={t}
          />
        </Panel>
      </TabPanel>
      {/* <TabPanel value={value} index={1} className={classes.tab} style={{height: '100%'}}>
        <Panel
          subheader={<MultiSelect  label={getName("AuditGroup",1)} value={ids} onChange={changeAuditGroup} data={aag.data.auditGroups} noBorder={true}/>}
          title="Harmonogram" style={{height: '100%'}}
        >
          <SchedulerContainer  simple={true} auditGroups={ag.data.auditGroups} auditIds={auditIds} users={users} audits={audits}/>
          
        </Panel>
         </TabPanel> */}

      <RightBar
        open={rightbarData}
        onClose={() => setRightbarData(false)}
      ></RightBar>
    </div>
  );
};
