import React, {useEffect, useState, useRef} from 'react'
import {Text} from 'elements'

export default (props) => {
   
    return <Text 
      {...props}
     
      type='number'
    />
  
}
