import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import TagFacesIcon from '@material-ui/icons/TagFaces';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {Input} from 'elements'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  input:{
      border: 0, borderBottom: '1px solid #222'
  },
  label:{
    fontSize: 14, marginBottom: 3, display: 'block'
  }
}));

export default ({value, onChange, setValue, register, name, label, validation})  => {
  const classes = useStyles();
//   console.log(value,98765)
  const [chipData, setChipData] = React.useState(value && value.length>0 ? value: []);
  const [showAdd, setShowAdd] = React.useState("");
  const newItem = React.useRef()

  React.useEffect(() => {
    
    register && register({name}, validation);
    value && setValue(name, value)
  }, [])
 
  const handleDelete = (chipToDelete) => () => {
    let cd = chipData.filter((chip) => chip !== chipToDelete)
    setChipData(cd);
    setValue && setValue(name, cd)
    onChange()
  };


  const handleInputChange = ({target}) => {
    setShowAdd(target.value && target.value.length>0 ? true:false)
  }

  const onClick = () => {
    chipData.push(newItem.current.value)
    setShowAdd(false)
    newItem.current.value=""
    setValue && setValue(name, chipData)
    onChange && onChange(chipData)
  }

  return (
    <div>
        {label &&  <label className={classes.label}>{label}</label>}
        {chipData.map((data, i) => {
            return (<Chip
                    key={i}
                    label={data}
                    onDelete={handleDelete(data)}
                    className={classes.chip}
                    />);
            })}
        <span><TextField onChange={handleInputChange} inputRef={newItem} placeholder="Nowy..." className={classes.input}/>{showAdd && <Button onClick={onClick}>Dodaj</Button>}</span>
        {/* <input type="hidden" value={chipData} ref={register} name={name}/> */}
    </div>
  );
}
