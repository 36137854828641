import React, {useRef, useContext, useEffect, useState} from 'react'
import { useAuthDataContext } from 'context/Context'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';
import { VARIANT, PERSPECTIVE} from 'graphql/queries'
import {BarChart} from 'elements'
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{padding: '0 20px'}}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '5%'
    }
  }));


    

function PerspectiveDetils(props){
    const { settings } = useAuthDataContext();
    const classes = useStyles();
    const dateFromat = settings.dateFormat || "YYYY-MM-DD"

    const getPrev = (data) => {
        let j=0, i=0, pre=[]
        data.prevWeight.map(p => {
            
            pre[i] ? pre[i].push({
                weight: p.weight,
                time: moment.unix(p.time).format(dateFromat)
            }): pre[i]=[{
                weight: p.weight,
                time: moment.unix(p.time).format(dateFromat)
            }]
            
            j+=1
            if(j===10){ i+=1; j=0 }
             
        })
        return pre
    }

    const [idx, setIdx] = useState(0)
    const [prev, setPrev] = useState()
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        setPrev(getPrev(props.data))
        return () => {}
    }, [props.data])

    if(!prev) return false

    return (
        <div className={classes.root}>
        <AppBar position="static" color='default'>
            <Tabs value={value} onChange={handleChange} aria-label="">
            <Tab label="Szczególy" {...a11yProps(0)} />
            <Tab label="Log" {...a11yProps(1)} />
            </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
            <div className={classes.container}>
                <div>Nazwa: {props.data.value}</div> <br/>
                <div>Ocena ryzyka: {props.data.weight}</div> <br/>
                <div>Ostatnia zmiana wagi: {props.data.prevWeight.length ? moment.unix(props.data.prevWeight[0].time).format(dateFromat): "-" }</div> 
                <br/>
                <div>Historia zmian wagi</div><br/>
                { prev.length>0 && <BarChart
                        width={500}
                        height={300}
                        data={prev[0].reverse()}
                        barSize={20}
                        dataKey="weight"
                    />  
                }
                { prev.length===0 && <div> Brak historii</div>}
            </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
      in progress
      </TabPanel>
    </div>
    )
}


export default (props) => {
    const {data, loading} = useQuery(PERSPECTIVE,{
        variables: {id: props.id}
    })

    if(loading) return false

    return <PerspectiveDetils data={data.perspective} />
}