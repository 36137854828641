import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { LineChart, Input, Panel } from "elements";

const useStyles = makeStyles((theme) => ({
  table: {
    width: "70%",
    margin: "0px 10%",
    border: "1px solid #aaa",
    borderCollapse: "collapse",
  },
  td1: {
    borderRight: "1px solid #aaa",
    borderTop: "1px solid #aaa",
    padding: 5,
  },
  td2: {
    borderTop: "1px solid #aaa",
    padding: 5,
  },
}));

export default ({ data, title, hideDiagram }) => {
  const classes = useStyles();

  const getApiRow = (i) => (
    <tr>
      <td className={classes.td1}>
        {new Date(data.dates[i]).toISOString().slice(0, 10)}
      </td>
      <td className={classes.td2}>{data.values[i]}</td>
    </tr>
  );

  const getDataChart = () => {
    let dataChart = [];
    data.values.map((v, i) => {
      dataChart.push({
        time: new Date(data.dates[i]).toISOString().slice(0, 10),
        value: v ? ("" + v).replace(",", ".") : 0,
      });
    });
    return dataChart;
  };

  let dataReversed = {
    dates: data.dates.reverse(),
    values: data.values.reverse(),
    type: data.type,
  };

  return (
    <Panel title={title}>
      {/* {data} */}
      <br />
      <br />
      {!hideDiagram && (
        <LineChart
          width={500}
          height={300}
          data={getDataChart()}
          dataKey="value"
        />
      )}
      <br />
      <br />
      <table className={classes.table}>
        {
          (dataReversed.type =
            "api" &&
            dataReversed.values &&
            dataReversed.values.map((v, i) => getApiRow(i)))
        }
      </table>
    </Panel>
  );
};
