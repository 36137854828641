import React from "react";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import { useAuthDataContext } from "context/Context";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { Empty } from ".";
import { useQuery } from "@apollo/client";
import { LOGS } from "graphql/queries";
import { Loading } from "elements";
import { FILE_URL } from "vars";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 20,
  },
  description: {
    color: "#555",
    a: {
      color: "#555",
    },
  },
  leftTab: {
    width: 200,
    maxWidth: 200,
  },
}));
export default ({ variables }) => {
  const classes = useStyles();
  const { systemSettings } = useAuthDataContext();
  const l = useQuery(LOGS, { fetchPolicy: "no-cache", variables });

  if (l.loading) return <Loading />;

  const dateFromat = systemSettings.dateFormat || "YYYY-MM-DD";

  const { length } = l.data.logs.logFeed;
  if (length === 0) return <Empty />;

  const getDescLink = (log) => {
    const type = parseInt(log.type);
    let desc = null,
      attr = {};
    desc = log.description.split(";");

    if (variables[desc[0]]) {
      return "";
    }

    if (desc[0] !== "file") {
      return <RouterLink to={`/${desc[0]}/${desc[1]}`}>{desc[2]}</RouterLink>;
    } else {
      return (
        <a href={`${FILE_URL}/${desc[1]}/${desc[2]}`} download>
          {desc[2]}
        </a>
      );
    }
  };

  return (
    <Timeline className={classes.root}>
      {l.data.logs.logFeed.map((l, i) => (
        <TimelineItem key={"tm" + i}>
          <TimelineOppositeContent className={classes.leftTab}>
            <Typography variant="body2" color="textSecondary">
              <span>{moment(l.createdAt).format(dateFromat + " HH:mm")}</span>
              <br />
              { l.user && <span>{l.user.value}</span> } 
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            {i === length - 1 ? "" : <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="h6" component="h4">
              {l.title}
            </Typography>
            <Typography className={classes.description}>
              {l.type ? getDescLink(l) : l.description}
            </Typography>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};
