import React, { useState } from "react";
import { getError } from "tools";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  container: {},
  subtext: {
    fontSize: 11,
    color: "#898989",
  },
}));

export default (props) => {
  const {
    register,
    type,
    helperText,
    validation,
    name,
    onChange,
    value,
    error,
    key,
    subtext,
    inputProps,
    ...rest
  } = props;
  // const [error, setError] = useState(null)
  const classes = useStyles();
  const [v, setV] = useState(value || undefined);

  let multiline = false,
    rows = 0;

  if (inputProps && inputProps.multiline) {
    multiline = inputProps.multiline;
    rows = inputProps.rows ? inputProps.rows : 3;
  }

  // console.log(props,88776655)

  const setIt = (e) => {
    let _v;
    props.type === "number"
      ? (_v = parseInt(e.target.value))
      : (_v = e.target.value);
    onChange && onChange(_v);
    setV(_v);
  };
  // useEffect(() => { setV(value); onChange && onChange(name, value) }, [value])
  // useEffect(() => { setError(error) }, [props.error])
  // console.log(props);

  return (
    <div className={classes.container}>
      {register ? (
        <TextField
          key={key}
          error={error && true}
          name={name}
          multiline={multiline}
          rows={rows}
          defaultValue={v}
          inputRef={register(validation)}
          helperText={
            error ? getError(error.type) || error.message : helperText
          }
          type={type || "text"}
          onChange={onChange}
          fullWidth
          size="small"
          disabled={
            props.data &&
            props.data.status &&
            (props.data.status === 1 || props.data.status === 19)
          }
          {...rest}
          sx={{
            marginTop: 15,
          }}
        />
      ) : (
        <TextField
          {...props}
          onChange={setIt}
          size="small"
          disabled={
            props.data &&
            props.data.status &&
            (props.data.status === 1 || props.data.status === 19)
          }
          multiline={multiline}
          rows={rows}
          fullWidth
          sx={{
            marginTop: 5,
          }}
        />
      )}
      {subtext && <div className={classes.subtext}>{props.data[subtext]}</div>}
    </div>
  );
};
