import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from "moment"
import {Alert} from "."

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default ({result, date}) => {
    const classes = useStyles();
 
  return  <Accordion>
  <AccordionSummary
    expandIcon={<ExpandMoreIcon />}
    aria-controls="panel1a-content"
    id="panel1a-header"
  >
    <Typography className={classes.heading}>{moment.unix(date/1000).format("YYYY-MM-DD hh:mm")}</Typography>
  </AccordionSummary>
  <AccordionDetails>
    <Typography>
    {result.alerts && <div>Podniesione alerty</div> }
      {result.alerts && result.alerts.map(alert => <Alert alert={alert}/>)}

      <br/>
      {result.predicted_alerts &&  <div>Mozliwe alerty</div>}
      {result.predicted_alerts && result.predicted_alerts.map(alert => <Alert alert={alert} pre={true}/>)}
     
      {result.error && <div>{result.error}</div>}
     
    </Typography>
  </AccordionDetails>
</Accordion>
};
