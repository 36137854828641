import React from "react";
import { Input } from "elements";
import { getName } from "tools";
import { RECEIVERS } from "graphql/queries";
import { useMutation } from "@apollo/client";
// import { useSnackbar } from "notistack";
import {
  UPDATE_RECEIVER
} from "graphql/mutations";
import { useSnackbar } from "notistack";

export default ({data}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [updateReceiver ] = useMutation(UPDATE_RECEIVER);

  const changeIt = async (name, value) => {
    if(data[name] === value) return false

    await updateReceiver({
      variables: { id: data.id, input: { [name]: value } },
      refetchQueries: [{ query: RECEIVERS }],
    });

    enqueueSnackbar(
      `${getName("Receiver")} został zaktualizowany`,
      { variant: "success" }
    );
  };
  

  return <div style={{padding: 20}}>
    <Input
        type="text"
        inputProps={{
          label: "Nazwa",
          onBlur: (event)=>changeIt('value', event.target.value) ,
          fullWidth: true,
          defaultValue: data.value,
           // helperText: "podaj adresy email oddzielone średnikiem (;)"s
        }}
        
      /><br/><br/><br/>
      <Input
        type="text"
        inputProps={{
          label: "Opis",
          onBlur: (event)=>changeIt('description', event.target.value) ,
          fullWidth: true,
          defaultValue: data.description,
         // helperText: "podaj adresy email oddzielone średnikiem (;)"
        }}
        
      /><br/><br/><br/>

      <Input
        type="text"
        inputProps={{
          label: "Adresy email",
          onBlur: (event)=>changeIt('emails', event.target.value) ,
          fullWidth: true,
          defaultValue: data.emails,
          helperText: "podaj adresy email oddzielone średnikiem (;)"
        }}
        
      /><br/><br/><br/>
      

  </div>
};
