import React, { useContext } from "react";
import moment from "moment";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useQuery } from "@apollo/client";
import { RECEIVER } from "graphql/queries";

import Button from "@material-ui/core/Button";
import { Info, Messages } from "./components";
import { useSnackbar } from "notistack";
import {
  // BrowserRouter as Router,
  // Switch,
  // Route,
  useLocation,
  useParams,
} from "react-router-dom";
import { getName } from "tools";
import { ErrorPage, Panel } from "elements";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    height: "100%",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    width: "20%",
  },
  tabsContent: {
    width: "80%",
  },
}));

function ReceiverDetails({ data, num, refetch }) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(num);

  let location = useLocation();
  React.useEffect(() => {
    try {
      setValue(
        parseInt(location.pathname.match(/\/\d+\/?$/)[0].replace(/\//, ""))
      );
    } catch (err) {
      setValue(0);
    }
  }, [location]);

  const handleChange = (_, newValue) => {
    let p = window.location.pathname.replace(/\/\d+\/?$/, "") + "/" + newValue;
    window.history.pushState({}, null, p);
    // setPath(p);
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        orientation="vertical"
        variant="scrollable"
        className={classes.tabs}
      >
        <Tab label="Informacje" {...a11yProps(0)} />
        <Tab color="receiver" label={getName("Action", 1)} {...a11yProps(1)} />
      </Tabs>

      <TabPanel value={value} index={0} className={classes.tabsContent}>
        <Panel title="Informacje podstawowe">
          <div className={classes.container}>
            <Info data={data} />
          </div>
        </Panel>
      </TabPanel>
      <TabPanel value={value} index={1} className={classes.tabsContent}>
        <Panel title={getName("Action")}>
          <Messages receiver={data.id} />
        </Panel>
      </TabPanel>
    </div>
  );
}

export default (props) => {
  let { id, num } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { data, loading, refetch } = useQuery(RECEIVER, {
    variables: { id },
    pollInterval: 20000,
    fetchPolicy: "no-cache",
  });

  if (loading) return false;
  if (!data.receiver) {
    enqueueSnackbar(`${getName("Receiver")} został usunięty`, {
      variant: "error",
    });
    return <ErrorPage />;
  }

  return (
    <ReceiverDetails
      refetch={refetch}
      data={data.receiver}
      num={num ? parseInt(num) : 0}
    />
  );
};
