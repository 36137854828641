import React, { useContext } from "react";
import moment from "moment";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useQuery } from "@apollo/client";
import { AUDIT } from "graphql/queries";
import { Alerts, Outputs, Vars } from "./components";
import { useSnackbar } from "notistack";
import { useLocation, Link, useParams } from "react-router-dom";
import { getName } from "tools";
import { ErrorPage, Panel, Tabs, Tab } from "elements";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    padding: 0,
  },

  container: {
    padding: "2%",
  },
}));

function ContinuousAudit({ data, num, refetch }) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(num);

  let location = useLocation();
  React.useEffect(() => {
    try {
      setValue(
        parseInt(location.pathname.match(/\/\d+\/?$/)[0].replace(/\//, ""))
      );
    } catch (err) {
      setValue(0);
    }
  }, [location]);

  const handleChange = (_, newValue) => {
    let p = window.location.pathname.replace(/\/\d+\/?$/, "") + "/" + newValue;
    window.history.pushState({}, null, p);
    // setPath(p);
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="transparent">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label=""
          color="auditc"
        >
          <Tab color="auditc" label="Informacje" {...a11yProps(0)} />
          <Tab color="auditc" label={getName("Test", 1)} {...a11yProps(1)} />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0} dir={theme.direction}>
        <Panel title="Informacje podstawowe">
          <Alerts data={data} refetch={refetch} />
        </Panel>
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        <Panel title={getName("Test")}>
          <Outputs data={data} />
        </Panel>
      </TabPanel>
      <TabPanel value={value} index={2} dir={theme.direction}>
        <Panel title="Pliki audytu">
          <Vars data={data} />
        </Panel>
      </TabPanel>
    </div>
  );
}

export default (props) => {
  let { id, num } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { data, loading, refetch } = useQuery(AUDIT, {
    variables: { id },
    pollInterval: 20000,
    fetchPolicy: "no-cache",
  });

  if (loading) return false;
  if (!data.audit) {
    enqueueSnackbar(`${getName("Audit")} został usunięty`, {
      variant: "error",
    });
    return <ErrorPage />;
  }

  return <ContinuousAudit />;

  // return <ContinuousAudit refetch={refetch} data={data.audit} num={num ? parseInt(num) : 0} />;
};
