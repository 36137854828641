import React from "react";
import { useQuery } from "@apollo/client";
import { REVIEW_NOTES } from "graphql/queries";
import { makeStyles } from "@material-ui/styles";
import { ReviewNotes } from "components/SharedComponents";
import { useAuthDataContext } from "context/Context";

const useStyles = makeStyles((theme) => ({
  tab: {
    paddingRight: 45,
  },
}));

export default ({ audit }) => {
  const { user } = useAuthDataContext();
  return (
    <ReviewNotes
      all={false}
      open={true}
      columns={[
        {
          Header: "Otwarta/zamknięta",
          accessor: "status",
          type: "switch",
          width: "10%",
          inputProps: { ownerOnly: true, owner: user.id },
        },
        {
          Header: "Nazwa",
          accessor: "value",
          type: "label",
          width: "20%",
        },
        {
          Header: "Audyt",
          accessor: "audit",
          type: "link",
          width: "10%",
          inputProps: { readOnly: true },
        },
        {
          Header: "Test",
          accessor: "test",
          type: "link",
          width: "12%",
          inputProps: { readOnly: true },
        },
        {
          Header: "Audytorzy",
          accessor: "auditors",
          type: "team",
          width: "15%",
          inputProps: { readOnly: true },
        },

        {
          Header: "Aktualizacja",
          accessor: "updatedAt",
          type: "label",
          width: "10%",
          inputProps: { type: "updatedAt" },
        },
        {
          Header: "",
          accessor: "lastAuditor.id",
          type: "user",
          width: "10%",
          inputProps: { readOnly: true },
        },
      ]}
    />
  );
};
