import React, { useContext } from "react";
import moment from "moment";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useQuery } from "@apollo/client";
import { AUDIT } from "graphql/queries";
import Button from "@material-ui/core/Button";
import { Outer, Inner, Source } from "./components";
import { useSnackbar } from "notistack";
import {
  // BrowserRouter as Router,
  // Switch,
  // Route,
  useLocation,
  Link,
  useParams,
} from "react-router-dom";
import { getName } from "tools";
import { Panel, RightBar } from "elements";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import { map } from "lodash";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    height: "100%",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    width: "20%",
  },
  tabsContent: {
    width: "80%",
  },
}));

function Dbs({ data, num, setData }) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(num);
  const [rightbarData, setRightbarData] = React.useState(false);

  let location = useLocation();
  React.useEffect(() => {
    try {
      setValue(
        parseInt(location.pathname.match(/\/\d+\/?$/)[0].replace(/\//, ""))
      );
    } catch (err) {
      setValue(0);
    }
  }, [location]);

  const handleChange = (_, newValue) => {
    let p = window.location.pathname.replace(/\/\d+\/?$/, "") + "/" + newValue;
    window.history.pushState({}, null, p);
    // setPath(p);
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const showData = async (source) => {
    let data = {};

    if (!source.inout) {
      // external

      let response = await fetch(source.path);
      const { dates, values } = await response.json();

      data = { dates, values, type: "api" };
    } else {
      data = {
        dates: map(source.data, "Date"),
        values: map(source.data, source.column),
        type: "api",
      };
    }

    setRightbarData({ __typename: "Data", data, title: source.value });
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        orientation="vertical"
        variant="scrollable"
        className={classes.tabs}
      >
        <Tab label="Źródła wewnętrzne" {...a11yProps(0)} />
        <Tab label="Źródła zewnętrzne" {...a11yProps(1)} />
      </Tabs>

      <TabPanel
        value={value}
        index={0}
        dir={theme.direction}
        className={classes.tabsContent}
      >
        <Panel
          subheader={"Lista źródeł danych wewnętrznych"}
          title={"Źródła wewnętrzne"}
        >
          <Inner data={data} showDetails={showData} />
        </Panel>
      </TabPanel>

      <TabPanel
        value={value}
        index={1}
        dir={theme.direction}
        className={classes.tabsContent}
      >
        <Panel
          subheader={"Lista źródeł danych zewnątrznych"}
          title={"Źródła zewnątrznych"}
        >
          <Outer showDetails={showData} />
        </Panel>
      </TabPanel>

      <RightBar
        open={rightbarData !== false}
        onClose={() => setRightbarData(false)}
      >
        {rightbarData.__typename === "Data" && (
          <Source
            data={rightbarData.data}
            title={rightbarData.title}
            // hideDiagram={rightbarData.hideDiagram}
          />
        )}
      </RightBar>
    </div>
  );
}

export default (props) => {
  let { id, num } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = React.useState({
    id: "i123dsdfsfsdfsd",
    value: "Źródła danych",
    vars: [
      {
        name: "dane",
        type: "period",
        displayName: "Okres",
        hint: "Od kiedy mają zostać pobrane dane do audytu.",
        default: "1m",
      },
      {
        name: "start",
        type: "date",
        displayName: "Początek audytu",
        hint: "W jakim okresie dane liczbowe mają zostać zagregowane.",
        default: "-1y",
      },
    ],
    outputs: [
      {
        name: "slope",
        type: "normalized_double",
        displayName: "Współczynnik nachylenia krzywej",
        hint: " Wartość ta wskazuje na trend wzrostowy (>0) lub spadkowy (<0) w badanym okresie. Wartości bliskie 0 świadczą o braku trendu. Wartościami skrajnymi są -1 oraz 1.",
        example: "0.4",
      },
      {
        name: "mean",
        type: "normalized_unsigned_double",
        displayName: "Średnia wartość",
        hint: " Średnia wartość współczynnika testu Benforda. Jeśli wartość ta jest większa od przyjętego progu istotności to należy odrzucić hipotezę o zachodzeniu testu  Benforda. Przykładowo jeśli wartość 'mean'=0.1 to oznacza że przy progu istotności 0.05, należy odrzucić hipotezę testu Benforda i przyjąć, że dane wejściowe mają dopuszczalny rozkład.",
        example: "0.024",
      },
    ],
    alerts: [
      {
        value: "Alert 1",
        uid: 1,
        __typename: "Alert",
        ifs: {
          cond: "and",
          list: [
            ["slope", ">", "0.5"],
            ["mean", ">", "0"],
          ],
        },
        thens: { cond: "or", list: [["UUID_1", "bardzo_prawdopodobny"]] },
      },

      {
        value: "Alert 2",
        uid: 1,
        __typename: "Alert",
        ifs: { cond: "and", list: [["slope", ">", "0"]] },
        thens: { cond: "or", list: [["UUID_1", "prawdopodobne"]] },
      },

      {
        value: "Alert 3",
        uid: 1,
        __typename: "Alert",
        ifs: {
          cond: "and",
          list: [
            ["slope", ">", "0"],
            ["mean", "<", "0"],
          ],
        },
        thens: {
          cond: "and",
          list: [
            ["UUID_1", "bardzo_prawdopodobny"],
            ["UUID_2", "prawdopodobne"],
          ],
        },
      },

      {
        value: "Alert 4",
        uid: 1,
        __typename: "Alert",
        ifs: {
          cond: "and",
          list: [
            ["slope", "<", "0.5"],
            ["mean", "<", "0"],
          ],
        },
        thens: { cond: "or", list: [["UUID_1", "nieprawdopodobny"]] },
      },
    ],
  });

  return <Dbs data={data} setData={setData} />;

  // return <ContinuousTest refetch={refetch} data={data.audit} num={num ? parseInt(num) : 0} />;
};
