import React from "react";
import { Table, Loading, Input, FileButton } from "elements";
import { FILE_URL } from "vars";
import GetAppIcon from "@material-ui/icons/GetApp";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import _ from "lodash";
import { useHistory } from "react-router";
import CachedIcon from "@material-ui/icons/Cached";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import LinkIcon from "@material-ui/icons/Link";
import { useQuery, useMutation } from "@apollo/client";
import { SOURCES } from "graphql/queries";
import {
  UPDATE_SOURCE,
  DELETE_SOURCE,
  CREATE_SOURCE,
  TEST_SOURCE,
  SOURCE_DATA,
} from "graphql/mutations";
import { UPLOAD_FILE } from "graphql/mutations";
import { getName } from "tools";
import { Form } from "elements";
import { useForm } from "react-hook-form";

import { useSnackbar } from "notistack";
import AddBoxIcon from "@material-ui/icons/AddBox";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

import { v4 as uuidv4 } from "uuid";

function SourcesDialog({ handleClose, onAdd }) {
  const [upload] = useMutation(UPLOAD_FILE);
  const [type, setType] = React.useState(false);
  const [fl, setFl] = React.useState(false);
  const form = useForm();

  const handleUpdate = (values) => {
    // console.log(values)
    onAdd(values);
    handleClose();
  };

  // const onChange = (url) => {
  //   values.current={
  //     ...values.current,
  //     value: out.dict[url],
  //     url
  //   }
  // }

  const onChangeF = (type) => {
    setType(type);
  };

  const onFileAdd = async (files) => {
    const file = files[0];
    const source = uuidv4();

    setFl({
      name: file.name,
      source,
    });

    setType("upload");

    const res = await upload({ variables: { input: { file, source } } });
  };

  return (
    <>
      <DialogTitle id="form-dialog-title">Żródla danych</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Zdefiniuj źródło danych wewnętrznych
        </DialogContentText>
        <form>
          <Form
            fields={[
              {
                label: "Nazwa",
                name: "value",
                type: "text",
              },
              {
                label: "Rodzaj",
                type: "select",
                fullWidth: true,
                name: "type",
                onChange: onChangeF,
                data: [
                  { id: "file", value: "plik" },
                  { id: "link", value: "link" },
                ],
              },
            ]}
            form={form}
          />

          {/* { type === "link" && <Input
          
          type='select'
          inputProps={{
            label:'Odświeźanie',
            fullWidth:true,
            name:'frequency',
            data:[
              { id: "0", value: "ręcznie" },
              { id: "30", value: "co 30 min." },
              { id: "60", value: "co godzinę" },
              { id: "1440", value: "raz dziennie" },
              { id: "43800", value: "raz w mies." }
            ]
            
          }}
          />
          } */}

          {type === "link" && (
            <>
              <Input
                type="select"
                inputProps={{
                  label: "Protokół",
                  fullWidth: true,
                  name: "protocol",
                  register: form.register,
                  data: [{ id: "sftp", value: "sftp" }],
                }}
              />
              <Input
                type="text"
                inputProps={{
                  label: "Adres",
                  name: "host",
                  register: form.register,
                }}
              />
              <Input
                type="text"
                inputProps={{
                  label: "Port",
                  name: "port",
                  register: form.register,
                }}
              />
              <Input
                type="text"
                inputProps={{
                  label: "Użytkownik",
                  name: "username",
                  register: form.register,
                }}
              />
              <Input
                type="text"
                inputProps={{
                  label: "Hasło",
                  name: "password",
                  register: form.register,
                }}
              />
              <Input
                type="text"
                inputProps={{
                  label: "Nazwa pliku",
                  name: "file",
                  register: form.register,
                }}
              />
            </>
          )}

          {type === "file" && <FileButton onChange={onFileAdd} />}
          {type === "upload" && <div>{fl.name}</div>}
          <input
            type="hidden"
            ref={form.register}
            name="frequency"
            value={fl.frequency}
          />
          <input
            type="hidden"
            ref={form.register}
            name="path"
            value={fl.source}
          />
          <input
            type="hidden"
            ref={form.register}
            name="file"
            value={fl.name}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Anuluj
        </Button>
        <Button onClick={form.handleSubmit(handleUpdate)} color="primary">
          Dodaj
        </Button>
      </DialogActions>
    </>
  );
}

function Sources({ showDetails, data }) {
  const [updateSource] = useMutation(UPDATE_SOURCE);
  const [createSource] = useMutation(CREATE_SOURCE);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [deleteSource] = useMutation(DELETE_SOURCE);
  const [testSource] = useMutation(TEST_SOURCE);
  const [sourceData] = useMutation(SOURCE_DATA);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onAdd = async (input) => {
    // console.log(input)

    const { data } = await createSource({
      variables: {
        input: { ...input, inout: true },
      },
      refetchQueries: [{ query: SOURCES, variables: { inner: true } }],
    });
    enqueueSnackbar(`${getName("Source")} zostało dodane`, {
      variant: "success",
    });
  };

  // const refreshSource = (idx) => {
  //   let row = data[idx];

  // }

  const downloadFile = async (idx) => {
    let row = data[idx];

    if (row.type === "file") {
      const link = document.createElement("a");
      link.href = `${FILE_URL}/${row.path}/${row.file}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else if (row.type === "link") {
      const { data } = await testSource({
        variables: { id: row.id },
      });
      if (data.testSource) {
        enqueueSnackbar(`${getName("Source")} działa poprawnie`, {
          variant: "success",
        });
      } else {
        enqueueSnackbar(`${getName("Source")} nie działa poprawnie`, {
          variant: "error",
        });
      }
    }
  };

  const getSourceData = async (idx) => {
    let row = data[idx];
    const result = await sourceData({
      variables: { id: row.id },
    });

    showDetails({
      inout: row.inout,
      column: row.column,
      value: row.value,
      data: JSON.parse(result.data.sourceData),
    });
  };

  const onDelete = async (ps) => {
    if (ps.length === 0) return false;

    let snackText = [
      ps.length > 1 ? getName("Source", 1) : getName("Source"),
      "",
      ps.length > 1 ? "zostały usunięte" : "zostało usunięte",
    ];
    let names = [];
    for (let i = ps.length - 1; i > 0; i--) {
      await deleteSource({ variables: { id: ps[i].id } });
      // names.push(ps[i].value);
    }
    const { data } = await deleteSource({
      variables: { id: ps[0].id },
      refetchQueries: [{ query: SOURCES, variables: { inner: true } }],
    });

    if (data.deleteSource) {
      enqueueSnackbar(snackText.join(" "), { variant: "success" });
    } else
      enqueueSnackbar(
        "Nie można usunąć źródła. W pierwszej kolejności należy usunąć źródło z testów.",
        { variant: "error" }
      );
  };

  const onUpdate = async (idx, name, value) => {
    let row = data[idx];

    await updateSource({
      variables: { id: row.id, input: { [name]: value } },
      refetchQueries: [{ query: SOURCES, variables: { inner: true } }],
    });

    enqueueSnackbar(`${getName("Source")} zostało zaktualizowane`, {
      variant: "success",
    });
  };

  return (
    <>
      <Table
        typename="Source"
        columns={[
          {
            Header: "Nazwa",
            accessor: "value",
            type: "text",
            width: "45%",
          },
          // {
          //   Header: "Odświeźanie",
          //   accessor: "frequency",
          //   type: "select",
          //   width: "10%",
          //   inputProps: {
          //     data: [
          //       { id: "0", value: "ręcznie" },
          //       { id: "30", value: "co 30 min." },
          //       { id: "60", value: "co godzinę" },
          //       { id: "1440", value: "raz dziennie" },
          //       { id: "43800", value: "raz w mies." },              ],
          //   },
          // },
          {
            Header: "Typ",
            accessor: "type",
            type: "select",
            width: "40%",
            inputProps: {
              readOnly: true,
              // disabled: true,
              data: [
                {
                  id: "file",
                  value: (
                    <span style={{ marginTop: 3 }}>
                      <InsertDriveFileIcon fontSize="small" /> Plik{" "}
                    </span>
                  ),
                },
                {
                  id: "link",
                  value: (
                    <span style={{ marginTop: 3 }}>
                      <LinkIcon fontSize="small" /> Scieżka sieciowa{" "}
                    </span>
                  ),
                },
              ],
            },
          },
        ]}
        // onAdd={onAdd}
        onDelete={onDelete}
        updateMyData={onUpdate}
        toolbarButtons={[
          {
            label: getName("Source", 2) + " wew.",
            icon: <AddBoxIcon />,
            onClick: handleClickOpen,
          },
        ]}
        rowActions={[{ icon: <CloudDownloadIcon />, onClick: downloadFile }]}
        // {...props}
        data={data}
      />
      <Dialog open={open} onClose={handleClose}>
        <SourcesDialog handleClose={handleClose} onAdd={onAdd} />
      </Dialog>
    </>
  );
}

export default (props) => {
  const { data, loading } = useQuery(SOURCES, { variables: { inner: true } });
  if (loading) return <Loading />;

  return (
    <Sources data={data.sources.sourceFeed} showDetails={props.showDetails} />
  );
};
