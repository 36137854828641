import React from "react";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  root: {
    textAlign: "center",
  },
  logo1: {
    fontFamily: "logo",

    opacity: 0.95,
    margin: "0px!important",
    // marginLeft: '20px!important'
  },
  logo2: {
    opacity: 0.5,
    fontFamily: "logo",
    color: 'white!important',
    letterSpacing: "-1px",
    marginLeft: "0px!important",
    background: "#009688",
    paddingRight: 3,
    paddingLeft: 2,
    marginLeft: 2
  },
  default: {
    color: "#222",
  },
  white: {
    color: "white",
  },
  normal: {
    fontSize: "20px",
    letterSpacing: "-1px",
  },
  big: {
    fontSize: "30px",
    letterSpacing: "-3px",
  },
}));
export default ({ type, size }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <span
        className={clsx(
          classes.logo1,
          classes[type || "default"],
          classes[size || "normal"]
        )}
      >
        RISK
      </span>
      <span
        className={clsx(
          classes.logo2,
          classes[type || "default"],
          classes[size || "normal"]
        )}
      >
        SHIELD
      </span>
    </div>
  );
};
