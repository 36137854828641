import React from "react";
import { MultiSelect } from "elements";
import { useQuery } from "@apollo/client";
import { INSTANCES } from "graphql/queries";

export default (props) => {
  const { data, loading } = useQuery(INSTANCES);
  if (loading) return false;

  let instances = [],
    name = [];
  try {
    console.log(data.instances);
    data.instances.instanceFeed.map((i) => {
      try {
        name = [];
        JSON.parse(i.value).map((ii) => name.push(ii.variant));
        instances.push({ ...i, value: name.join("→") });
      } catch (err) {
        instances.push({ ...i });
      }
    });
  } catch (e) {}

  return <MultiSelect {...props} data={instances} />;
};
