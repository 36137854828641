import React from "react";
import { useQuery } from "@apollo/client";
import { ACTION } from "graphql/queries";
import { makeStyles, useTheme } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    
  }));


const Alert = ({data}) => {

  const classes = useStyles();

  return <div>
      {data.value}
    </div>
  
};


export default ({alert, pre}) => {
    const { data, loading, refetch } = useQuery(ACTION, {
      variables: { id:alert.id }
    });
  
    if (loading) return false;

    if (!data.action) {
      return <></>;
    }
  
    return <Alert data={data.action} alert={alert} pre={pre} />;
  };
  
