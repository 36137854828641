import React from 'react'
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default (props) =>  {
    const [checked, setChecked] = React.useState(props.value || false);

    const handleChange = (event) => {
        setChecked(event.target.checked);
        props.onChange && props.onChange(event.target.checked)
    };

    React.useEffect(() => {
        props.disabled && setChecked(false)
    },[props.disabled])

    
    return (
        <FormControlLabel
        control={
            <Checkbox
            defaultChecked
            color="primary"
            inputProps={{ ref: props.register, 'aria-label': 'checkbox' }}
            checked={checked}
            onChange={handleChange}
            disabled={props.disabled}
        />
        }
        label={props.label}
      />
       
    )
}
