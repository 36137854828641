import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import moment from "moment";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Typography,
  Divider,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
  details: {
    display: "flex",
  },
  avatar: {
    marginLeft: "auto",
    height: 60,
    width: 60,
    flexShrink: 0,
    flexGrow: 0,
  },
  progress: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    marginRight: theme.spacing(2),
  },
}));

const AccountProfile = ({ user, settings }) => {
  // const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <div className={classes.details}>
          <div>
            <Typography gutterBottom variant="h2">
              {user.value}
            </Typography>
            <Typography
              className={classes.locationText}
              color="textSecondary"
              variant="body1"
            >
              Data utworzenie:{" "}
              {moment(user.createdAt).format(
                settings?.dateFormat || "YYYY-MM-DD"
              )}
            </Typography>
            <Typography
              className={classes.dateText}
              color="textSecondary"
              variant="body1"
            >
              Data utworzenie:{" "}
              {moment(user.updatedAt).format(
                settings?.dateFormat || "YYYY-MM-DD"
              )}
            </Typography>
          </div>
          <Avatar
            className={classes.avatar}
            style={{ backgroundColor: user.color }}
            variant="square"
          >
            {user.avatar}
          </Avatar>
        </div>
      </CardContent>
      <Divider />
    </Card>
  );
};

AccountProfile.propTypes = {
  className: PropTypes.string,
};

export default AccountProfile;
