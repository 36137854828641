import React from 'react'
import {
    Card,
    CardHeader,
    CardContent,
    Divider
  } from '@material-ui/core';

export default (props) => {
    return (
        <Card style={props.style} elevation={0}>
            { props.title && <CardHeader
            subheader={ props.subheader }
            title={ props.title || null }
            
            /> }
            <Divider />
            { props.children }
        </Card>
    )
}
