import React, {useState, useEffect} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
    drawer: {
        width:600,
        flexShrink: 0
      },
      drawerPaper: {
        width: 600,
      },
      drawerContainer: {
        overflow: 'auto',
        // padding: '64px 0px 20px'
      },
  }));

export default (props) => {
    const { title,children } = props
    const [open, setOpen] = useState(props.open)
    let width = props.width || 650
    const classes = useStyles();

    const close = () => {
        setOpen(!open)
        props.onClose && props.onClose()
    }

    useEffect(() => {
        setOpen(props.open)
       
    }, [props.open])
    
    return (
        <Drawer 
            open={open} 
            onClose={close} 
            className={classes.drawer}
            anchor="right"
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <div className={classes.drawerContainer}>{children}</div>
            
        </Drawer>
    )
}
