import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

import {getColor} from 'tools'

const StyledTabs = withStyles({
  indicator: props => ({
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: getColor(props.color)[700],
    },
  }),
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

export default StyledTabs