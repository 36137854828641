import React from "react";
import { useAuthDataContext } from "context/Context";
import moment from "moment";

export default ({ value, type, labels }) => {
  const { systemSettings } = useAuthDataContext();

  const getValue = () => {
    if (!value && value !== 0) return "-";

    if (type === "date") {
      return moment.unix(value / 1000).format(systemSettings.dateFormat);
    } else if (type === "updatedAt") {
      return moment(value).format(systemSettings.dateFormat + " HH:mm");
    } else if (labels) {
      return labels[value];
    } else return value;
  };

  return <div>{getValue()}</div>;
};
