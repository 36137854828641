import React, { useState } from "react";
import moment from "moment";
import { useAuthDataContext } from "context/Context";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useQuery, useMutation } from "@apollo/client";
import { RECEIVERS } from "graphql/queries";
// import { useSnackbar } from "notistack";
import {
  UPDATE_RECEIVER,
  DELETE_RECEIVER,
  CREATE_RECEIVER,
} from "graphql/mutations";
import { getName } from "tools";
// import {
//   Receiver
// } from "./components";
import { useSnackbar } from "notistack";
import { useLocation, useParams } from "react-router-dom";

import { Panel, Tabs, Tab, RightBar, Loading, Table } from "elements";
import AppBar from "@material-ui/core/AppBar";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import _ from "lodash";
import { useHistory } from "react-router";

// import FileCopyIcon from '@material-ui/icons/FileCopy';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    padding: 0,
  },

  container: {
    padding: "2%",
  },
}));

function Receivers({ data, num, setData }) {
  const classes = useStyles();
  const history = useHistory();
  const { user, settings, setSettings } = useAuthDataContext();
  const theme = useTheme();
  const [value, setValue] = React.useState(num);
  const [rightbarData, setRightbarData] = React.useState(false);
  const emData = React.useRef({});

  const [updateReceiver] = useMutation(UPDATE_RECEIVER);
  const [createReceiver] = useMutation(CREATE_RECEIVER);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [deleteReceiver] = useMutation(DELETE_RECEIVER);

  const showData = (idx) => {
    setRightbarData({ __typename: "Data" });
  };

  const goToReceiver = (idx) => {
    const receiver = data[idx];
    const tabs = settings.tabs ? [...settings.tabs] : [];

    if (_.findIndex(tabs, { id: receiver.id }) < 0) {
      setSettings({
        tabs: [
          {
            id: receiver.id,
            title:
              receiver.value.length > 20
                ? receiver.value.slice(0, 20) + "..."
                : receiver.value,
            full: receiver.value,
            parent: null,
            href: "/receiver/" + receiver.id,
          },
          ...tabs,
        ],
      });
    }

    history.push("/receiver/" + receiver.id);
  };

  let location = useLocation();
  React.useEffect(() => {
    try {
      setValue(
        parseInt(location.pathname.match(/\/\d+\/?$/)[0].replace(/\//, ""))
      );
    } catch (err) {
      setValue(0);
    }
  }, [location]);

  const handleChange = (_, newValue) => {
    let p = window.location.pathname.replace(/\/\d+\/?$/, "") + "/" + newValue;
    window.history.pushState({}, null, p);
    // setPath(p);
    setValue(newValue);
  };

  const onAdd = async ({ value, description, emails }) => {
    await createReceiver({
      variables: {
        input: { value, description, emails },
      },
      refetchQueries: [{ query: RECEIVERS }],
    });
    enqueueSnackbar(`${getName("Receiver")} został dodane`, {
      variant: "success",
    });
  };

  const onDelete = async (ps) => {
    if (ps.length === 0) return false;

    let snackText = [
      ps.length > 1 ? getName("Receiver", 1) : getName("Receiver"),
      "",
      ps.length > 1 ? "został usunięty" : "zostali usunięci",
    ];
    let names = [];
    for (let i = ps.length - 1; i > 0; i--) {
      await deleteReceiver({ variables: { id: ps[i].id } });
      names.push(ps[i].value);
    }
    await deleteReceiver({
      variables: { id: ps[0].id },
      refetchQueries: [{ query: RECEIVERS }],
    });

    enqueueSnackbar(snackText.join(" "), { variant: "success" });
  };

  const onUpdate = async (idx, name, value) => {
    let row = data[idx];

    await updateReceiver({
      variables: { id: row.id, input: { [name]: value } },
      refetchQueries: [{ query: RECEIVERS }],
    });

    enqueueSnackbar(`${getName("Receiver")} został zaktualizowany`, {
      variant: "success",
    });
  };

  const changeInEmails = (what, value) => {
    emData.current[what] = value;
  };

  return (
    <div className={classes.root}>
      <TabPanel value={value} index={0} dir={theme.direction}>
        <Panel subheader={"Lista adresatów"} title={"Adresaci"}>
          <Table
            typename="Receiver"
            columns={[
              {
                Header: "Nazwa",
                accessor: "value",
                type: "text",
                width: "30%",
              },
              // {
              //   Header: "Ryzyko",
              //   accessor: "risk",
              //   type: "select",
              //   inputProps: {
              //     data: [
              //       { id: 1, value: "Ryzyko 1" },
              //       { id: 2, value: "Ryzyko 2" },
              //       { id: 3, value: "Ryzyko 3" }
              //     ],
              //   },
              //   width: "18%",
              // },
              {
                Header: "Opis",
                accessor: "description",
                type: "longText",
                width: "10%",
              },
              {
                Header: "Adresy",
                accessor: "emails",
                type: "longText",
                width: "16%",
              },
            ]}
            // toolbarButtons={[
            //   {
            //     label: "Skopiuj działanie",
            //     icon: <FileCopyIcon />,
            //     onClick: ()=>{},
            //   },

            // ]}
            // setData={setData}
            updateMyData={onUpdate}
            // skipPageReset={true}
            onAdd={onAdd}
            onDelete={onDelete}
            rowActions={[{ icon: <DoubleArrowIcon />, onClick: goToReceiver }]}
            // {...props}
            data={data}
          />
        </Panel>
      </TabPanel>

      {/* <RightBar
        open={rightbarData !== false}
        onClose={() => closeReceiver(false)}
      >
        {rightbarData.__typename === "Receiver" && (
          <Receiver data={rightbarData} changeIt={changeInEmails}/>
        )}
      </RightBar> */}
    </div>
  );
}

export default (props) => {
  let { num } = useParams();
  const a = useQuery(RECEIVERS);

  if (a.loading) return <Loading />;

  return <Receivers num={num} data={a.data.receivers.receiverFeed} />;

  // return <ContinuousAudit refetch={refetch} data={data.audit} num={num ? parseInt(num) : 0} />;
};

// const [ data, setData ] = useState({
//   // tests: [
//   //   {id: "i123dsdfsfsdfsd0", value: "Test 1", source: 1, status: <FiberManualRecordIcon fontSize="small" style={{color: "green"}}/>, freq:"1h", last:"2021-05-07 12:23",  __typename:"Testc"},
//   //   {id: "i123dsdfsfsdfsd1", value: "Test 2", source: 2,  status: <FiberManualRecordIcon fontSize="small" color="error"/>, freq:"1m", last:"2021-04-15 12:23",  __typename:"Testc"},
//   //   {id: "i123dsdfsfsdfsd2", value: "Test 3", source: 3,  status: <FiberManualRecordIcon fontSize="small" color="action"/>, freq:"1d", last:"2021-05-06 10:03",  __typename:"Testc"}
//   // ],
//   // templates:[
//   //   {id: "i123dsdfsfsdfsd", __typename:"Templatec", value: "Schemat 1",  vars: [{ name: "Okres", type: "date"}] , outputs: [{ name: "Wynik", type: "double"}], alerts: [{ uid: "UUID1" }] },
//   //   {id: "i123dsdfsfqewqeqw" , __typename:"Templatec", value: "Schemat 2",   vars: [{ name: "Okres", type: "date"}], outputs: [{ name: "Wynik", type: "double"}], alerts: [{ uid: "UUID1" }] },

//   // ],
//   // alerts:[
//   //   { value: "UUID 1",  uid: 1,
//   //     __typename:"Alert",
//   //     ifs: { cond: 'and', list: [["slope", "<", "123"],["slope2", ">", "123"]]} ,
//   //     thens: { cond: 'or', list: [["slope", "<", "123"],["slope2", ">", "123"]]} },

//   //   {
//   //     value: "UUID 2",  uid: 2,
//   //     __typename:"Alert",
//   //     ifs: { cond: 'or',  list: [["slope11", "<", "123"],["slope22", ">", "123"]]},
//   //     thens: { cond: 'and', list: [["alert1", "prawdopodobny"],["alert2", ">", "mało prawdopodobny"]]} ,
//   //   }
//   // ],
//   actions:[
//     { value: "Działanie 1",  uid: 1, risk:"1", activation: '0.95',
//       __typename:"Receiver", desc: "To jest alert o wzroscie", text: "Alert o wzroscie", to: "ksiegowa@firma.pl;ceo@firma.pl"
//     },

//     {
//       value: "Działanie 2",  uid: 2, risk:"3", activation: '0.5',
//       __typename:"Receiver", desc: "To jest alert o wzroscie", text: "Alert o wzroscie", to: "alert@firma.pl"
//     }
//   ]
// })
