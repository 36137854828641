import React from "react";
import { Table, Loading } from "elements";
// import { useAuthDataContext } from "context/Context";
import { useQuery, useMutation } from "@apollo/client";
import { ACTIONS } from "graphql/queries";
import { useSnackbar } from "notistack";
import { getName } from "tools";
import { UPDATE_ACTION, DELETE_ACTION, CREATE_ACTION } from "graphql/mutations";

const ActionForTestc = ({ data, showDetails, testc, refetch }) => {
  // const { user } = useAuthDataContext();
  const { enqueueSnackbar } = useSnackbar();
  const [updateAction] = useMutation(UPDATE_ACTION);
  const [createAction] = useMutation(CREATE_ACTION);
  const [deleteAction] = useMutation(DELETE_ACTION);
  const [actions, setActions] = React.useState(data);

  let data2 = JSON.parse(JSON.stringify(data));

  const onDrawSelectClick = (obj) => {
    showDetails(obj);
  };

  React.useEffect(() => {
    data2.map((d) => {
      d.onClick = onDrawSelectClick;
      // d.onClickCustom = onDrawSelectClickCustom
      d.rules = d.rules ? JSON.parse(d.rules) : { list: [] };
      // { cond: 'and', list: [["Współczynnik nachylenia krzywej", ">", "0.5"],["Średnia wartość", ">", "0"]] }
    });

    // console.log(data2)
  });

  const onCreate = async (values) => {
    let input = { ...values };
    input.testc = testc;

    const { data } = await createAction({
      variables: { input },
      refetchQueries: [{ query: ACTIONS, variables: { testc } }],
    });
    enqueueSnackbar(`${getName("Action")} zostało dodane`, {
      variant: "success",
    });
  };

  const onDelete = async (ps) => {
    if (ps.length === 0) return false;

    let snackText = [
      ps.length > 1 ? getName("Receiver", 1) : getName("Receiver"),
      "",
      ps.length > 1 ? "zostało usunięte" : "zostały usunięte",
    ];
    let names = [];
    for (let i = ps.length - 1; i > 0; i--) {
      await deleteAction({ variables: { id: ps[i].id } });
      names.push(ps[i].value);
    }
    await deleteAction({
      variables: { id: ps[0].id },
      refetchQueries: [{ query: ACTIONS, variables: { testc } }],
    });

    enqueueSnackbar(snackText.join(" "), { variant: "success" });
  };

  const onUpdate = async (idx, name, value) => {
    let row = data[idx];

    await updateAction({
      variables: { id: row.id, input: { [name]: value } },
      refetchQueries: [{ query: ACTIONS, variables: { testc } }],
    });

    enqueueSnackbar(`${getName("Action")} zostało zaktualizowane`, {
      variant: "success",
    });
  };

  return (
    <Table
      typename="Action"
      columns={[
        {
          Header: "Nazwa",
          accessor: "value",
          type: "label",
          width: "60%",
        },

        {
          Header: "Warunki",
          accessor: "rules",
          type: "drawSelect",
          width: "15%",
          inputProps: {
            __typename: "Rule",
          },
        },

        {
          Header: "Odbiorcy",
          accessor: "messages",
          type: "drawSelect",
          width: "15%",
          inputProps: {
            __typename: "Receiver",
            columns: [{ value: "messages", width: "100%" }],
          },
        },
      ]}
      // setData={setData}
      updateMyData={onUpdate}
      // skipPageReset={true}
      onAdd={onCreate}
      onDelete={onDelete}
      // rowActions={[{ icon: <MoreHorizIcon />, onClick: showDetails }]}

      // {...props}
      data={data2}
    />
  );
};

export default ({ testc, showDetails }) => {
  const { data, loading } = useQuery(ACTIONS, {
    variables: { testc },
  });

  if (loading) return <Loading />;

  return (
    <ActionForTestc
      testc={testc}
      data={data.actions.actionFeed}
      showDetails={showDetails}
    />
  );
  // return <ContinuousTest refetch={refetch} data={data.audit} num={num ? parseInt(num) : 0} />;
};
