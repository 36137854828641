import gql from "graphql-tag";

export const UPDATE_USER = gql`
  mutation update($input: updateUserInput!) {
    updateUser(input: $input) {
      settings
    }
  }
`;

export const UPDATE_USERS = gql`
  mutation UpdateUsers($id: ID!, $input: updateUserInput!) {
    updateUsers(id: $id, input: $input) {
      id
      email
      value
      permissions
    }
  }
`;

export const CREATE_USER = gql`
  mutation CreateUser($input: createUserInput!) {
    createUser(input: $input) {
      id
      email
      value
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id) {
      id
    }
  }
`;

export const CREATE_PERSPECTIVE = gql`
  mutation CreatePerspective($input: createPerspectiveInput!) {
    createPerspective(input: $input) {
      id
      value
      weight
    }
  }
`;

export const DELETE_PERSPECTIVE = gql`
  mutation DeletePerspective($id: ID!) {
    deletePerspective(id: $id) {
      id
    }
  }
`;

export const UPDATE_PERSPECTIVE = gql`
  mutation UpdatePerspective($id: ID!, $input: updatePerspectiveInput!) {
    updatePerspective(id: $id, input: $input) {
      id
      value
      weight
    }
  }
`;

export const CREATE_VARIANT = gql`
  mutation CreateVariant($input: createVariantInput!) {
    createVariant(input: $input) {
      id
      value
      risk
      perspective {
        id
      }
    }
  }
`;

export const DELETE_VARIANT = gql`
  mutation DeleteVariant($id: ID!) {
    deleteVariant(id: $id) {
      id
    }
  }
`;

export const UPDATE_VARIANT = gql`
  mutation UpdateVariant($id: ID!, $input: updateVariantInput!) {
    updateVariant(id: $id, input: $input) {
      id
      value
      risk
      perspective {
        id
      }
    }
  }
`;

export const CREATE_INSTANCE = gql`
  mutation CreateInstance($input: createInstanceInput!) {
    createInstance(input: $input) {
      id
      value
      risk
    }
  }
`;

export const RANK_INSTANCES = gql`
  mutation rankInstances {
    rankInstances
  }
`;

export const DELETE_INSTANCE = gql`
  mutation DeleteInstance($id: ID!) {
    deleteInstance(id: $id) {
      id
    }
  }
`;

export const UPDATE_INSTANCE = gql`
  mutation UpdateInstance($id: ID!, $input: updateInstanceInput!) {
    updateInstance(id: $id, input: $input) {
      id
      value
      risk
    }
  }
`;

export const CREATE_AUDIT = gql`
  mutation CreateAudit($input: createAuditInput!) {
    createAudit(input: $input) {
      id
      value
      fields
      leader {
        id
        value
        avatar
        color
        position
      }
      team {
        id
        email
        value
        avatar
        color
        position
      }
      dates
    }
  }
`;
export const UPDATE_AUDIT = gql`
  mutation UpdateAudit($id: ID!, $input: updateAuditInput!) {
    updateAudit(id: $id, input: $input) {
      id
      value
    }
  }
`;
export const DELETE_AUDIT = gql`
  mutation DeleteAudit($id: ID!) {
    deleteAudit(id: $id) {
      id
    }
  }
`;

export const MOVETESTS_AUDIT = gql`
  mutation MoveTestsInTeam($input: moveTestsInTeamInput!) {
    moveTestsInTeam(input: $input) {
      id
    }
  }
`;

export const CREATE_TEST = gql`
  mutation CreateTest($input: createTestInput!) {
    createTest(input: $input) {
      id
      value
      desc
      todo
      hours
    }
  }
`;
export const UPDATE_TEST = gql`
  mutation UpdateTest($id: ID!, $input: updateTestInput!) {
    updateTest(id: $id, input: $input) {
      id
      value
    }
  }
`;
export const DELETE_TEST = gql`
  mutation DeleteTest($id: ID!) {
    deleteTest(id: $id) {
      id
    }
  }
`;

export const CREATE_INCIDENT = gql`
  mutation CreateIncident($input: createIncidentInput!) {
    createIncident(input: $input) {
      id
      value
      desc
      employeeName
      employeeEmail
      status
    }
  }
`;
export const UPDATE_INCIDENT = gql`
  mutation UpdateIncident($id: ID!, $input: updateIncidentInput!) {
    updateIncident(id: $id, input: $input) {
      id
      value
    }
  }
`;
export const DELETE_INCIDENT = gql`
  mutation DeleteIncident($id: ID!) {
    deleteIncident(id: $id) {
      id
    }
  }
`;

export const UPDATE_AUDIT_TEMPLATE = gql`
  mutation UpdateAuditTemplate($id: ID!, $input: updateAuditTemplateInput!) {
    updateAuditTemplate(id: $id, input: $input) {
      id
      value
      fields
    }
  }
`;

export const UPDATE_FINDING_TEMPLATE = gql`
  mutation UpdateFindingTemplate(
    $id: ID!
    $input: updateFindingTemplateInput!
  ) {
    updateFindingTemplate(id: $id, input: $input) {
      id
      value
      fields
    }
  }
`;

export const CREATE_AUDIT_TEMPLATE = gql`
  mutation CreateAuditTemplate($input: createAuditTemplateInput!) {
    createAuditTemplate(input: $input) {
      id
      value
      fields
    }
  }
`;

export const CREATE_FINDING_TEMPLATE = gql`
  mutation CreateFindingTemplate($input: createFindingTemplateInput!) {
    createFindingTemplate(input: $input) {
      id
      value
      fields
    }
  }
`;

export const DELETE_AUDIT_TEMPLATE = gql`
  mutation DeleteAuditTemplate($id: ID!) {
    deleteAuditTemplate(id: $id) {
      id
    }
  }
`;

export const DELETE_FINDING_TEMPLATE = gql`
  mutation DeleteFindingTemplate($id: ID!) {
    deleteFindingTemplate(id: $id) {
      id
    }
  }
`;

export const CREATE_TEST_TEMPLATE = gql`
  mutation CreateTestTemplate($input: createTestTemplateInput!) {
    createTestTemplate(input: $input) {
      id
      value
      fields
    }
  }
`;

export const DELETE_TEST_TEMPLATE = gql`
  mutation DeleteTestTemplate($id: ID!) {
    deleteTestTemplate(id: $id) {
      id
    }
  }
`;

export const UPDATE_TEST_TEMPLATE = gql`
  mutation UpdateTestTemplate($id: ID!, $input: updateTestTemplateInput!) {
    updateTestTemplate(id: $id, input: $input) {
      id
      value
      fields
    }
  }
`;
export const CREATE_AUDITGROUP = gql`
  mutation CreateAuditGroup($input: createAuditGroupInput!) {
    createAuditGroup(input: $input) {
      id
      value
    }
  }
`;

export const UPDATE_AUDITGROUP = gql`
  mutation UpdateAuditGroup($id: ID!, $input: updateAuditGroupInput!) {
    updateAuditGroup(id: $id, input: $input) {
      id
      value
    }
  }
`;
export const DELETE_AUDITGROUP = gql`
  mutation DeleteAuditGroup($id: ID!) {
    deleteAuditGroup(id: $id) {
      value
    }
  }
`;

export const DELETE_FILE = gql`
  mutation DeleteFile($id: ID!) {
    deleteFile(id: $id) {
      id
    }
  }
`;

export const UPLOAD_FILE = gql`
  mutation ($input: createFileInput!) {
    singleUpload(input: $input) {
      id
      filename
      versions
    }
  }
`;

export const CREATE_AUDITPLAN = gql`
  mutation CreateAuditPlan($input: createAuditPlanInput!) {
    createAuditPlan(input: $input) {
      value
      tree
    }
  }
`;

export const UPDATE_AUDITPLAN = gql`
  mutation UpdateAuditPlan($id: ID!, $input: updateAuditPlanInput!) {
    updateAuditPlan(id: $id, input: $input) {
      id
      value
      tree
    }
  }
`;

export const UPDATE_SURVEY = gql`
  mutation UpdateSurvey($survey: String!) {
    updateSurvey(survey: $survey)
  }
`;

export const CREATE_FINDING = gql`
  mutation CreateFinding($input: createFindingInput!) {
    createFinding(input: $input) {
      id
      value
    }
  }
`;

export const ADD_FINDING_MESSAGE = gql`
  mutation AddFindingMessage($id: ID!, $input: updateFindingInput!) {
    addFindingMessage(id: $id, input: $input) {
      id
      value
    }
  }
`;

export const UPDATE_FINDING = gql`
  mutation UpdateFinding($id: ID!, $input: updateFindingInput!) {
    updateFinding(id: $id, input: $input) {
      id
      value
    }
  }
`;
export const DELETE_FINDING = gql`
  mutation DeleteFinding($id: ID!) {
    deleteFinding(id: $id) {
      id
    }
  }
`;

export const EXPORT_FINDINGS = gql`
  mutation ExportFindings {
    exportFindings
  }
`;

export const EXPORT_AUDITPLAN = gql`
  mutation ExportAuditPlan {
    exportAuditPlan
  }
`;

export const EXPORT_INCIDENTS = gql`
  mutation ExportIncidents {
    exportIncidents
  }
`;

export const EXPORT_INSTANCES = gql`
  mutation ExportInstances {
    exportInstances
  }
`;

export const CREATE_REVIEWNOTE = gql`
  mutation CreateReviewNote($input: createReviewNoteInput!) {
    createReviewNote(input: $input) {
      id
      value
    }
  }
`;

export const UPDATE_REVIEWNOTE = gql`
  mutation UpdateReviewNote($id: ID!, $input: updateReviewNoteInput!) {
    updateReviewNote(id: $id, input: $input) {
      id
      value
    }
  }
`;

export const ADD_MESSAGE = gql`
  mutation AddMessage($id: ID!, $message: String!) {
    addMessage(id: $id, message: $message) {
      id
      value
      messages
    }
  }
`;
export const DELETE_REVIEWNOTE = gql`
  mutation DeleteReviewNote($id: ID!) {
    deleteReviewNote(id: $id) {
      id
    }
  }
`;

export const CREATE_REPORT = gql`
  mutation CreateReport($input: createReportInput!) {
    createReport(input: $input) {
      id
      value
    }
  }
`;

export const UPDATE_REPORT = gql`
  mutation UpdateReport($id: ID!, $input: updateReportInput!) {
    updateReport(id: $id, input: $input) {
      id
      value
    }
  }
`;

export const DELETE_REPORT = gql`
  mutation DeleteReport($id: ID!) {
    deleteReport(id: $id) {
      id
    }
  }
`;
export const GENERATE_REPORT = gql`
  mutation GenerateReport($id: ID!) {
    generateReport(id: $id) {
      id
    }
  }
`;

export const CREATE_RISK = gql`
  mutation CreateRisk($input: createRiskInput!) {
    createRisk(input: $input) {
      id
      value
    }
  }
`;
export const UPDATE_RISK = gql`
  mutation UpdateRisk($id: ID!, $input: updateRiskInput!) {
    updateRisk(id: $id, input: $input) {
      id
    }
  }
`;
export const DELETE_RISK = gql`
  mutation DeleteRisk($id: ID!) {
    deleteRisk(id: $id) {
      id
    }
  }
`;

export const CREATE_PROCES = gql`
  mutation CreateProces($input: createProcesInput!) {
    createProces(input: $input) {
      id
      value
    }
  }
`;
export const UPDATE_PROCES = gql`
  mutation UpdateProces($id: ID!, $input: updateProcesInput!) {
    updateProces(id: $id, input: $input) {
      id
    }
  }
`;
export const DELETE_PROCES = gql`
  mutation DeleteProces($id: ID!) {
    deleteProces(id: $id) {
      id
    }
  }
`;

export const CREATE_CONTROL = gql`
  mutation CreateControl($input: createControlInput!) {
    createControl(input: $input) {
      id
      value
    }
  }
`;
export const UPDATE_CONTROL = gql`
  mutation UpdateControl($id: ID!, $input: updateControlInput!) {
    updateControl(id: $id, input: $input) {
      id
    }
  }
`;
export const DELETE_CONTROL = gql`
  mutation DeleteControl($id: ID!) {
    deleteControl(id: $id) {
      id
    }
  }
`;

export const CREATE_ACTION = gql`
  mutation CreateAction($input: createActionInput!) {
    createAction(input: $input) {
      id
    }
  }
`;
export const UPDATE_ACTION = gql`
  mutation UpdateAction($id: ID!, $input: updateActionInput!) {
    updateAction(id: $id, input: $input) {
      id
    }
  }
`;
export const DELETE_ACTION = gql`
  mutation DeleteAction($id: ID!) {
    deleteAction(id: $id) {
      id
    }
  }
`;

export const CREATE_MESSAGE = gql`
  mutation CreateMessage($input: createMessageInput!) {
    createMessage(input: $input) {
      id
    }
  }
`;
export const UPDATE_MESSAGE = gql`
  mutation UpdateMessage($id: ID!, $input: updateMessageInput!) {
    updateMessage(id: $id, input: $input) {
      id
    }
  }
`;
export const DELETE_MESSAGE = gql`
  mutation DeleteMessage($id: ID!) {
    deleteMessage(id: $id) {
      id
    }
  }
`;

export const DELETE_MESSAGES = gql`
  mutation DeleteMessages($input: deleteMessagesInput!) {
    deleteMessages(input: $input)
  }
`;

export const CREATE_RECEIVER = gql`
  mutation CreateReceiver($input: createReceiverInput!) {
    createReceiver(input: $input) {
      id
    }
  }
`;
export const UPDATE_RECEIVER = gql`
  mutation UpdateReceiver($id: ID!, $input: updateReceiverInput!) {
    updateReceiver(id: $id, input: $input) {
      id
    }
  }
`;
export const DELETE_RECEIVER = gql`
  mutation DeleteReceiver($id: ID!) {
    deleteReceiver(id: $id) {
      id
    }
  }
`;

export const CREATE_SOURCE = gql`
  mutation CreateSource($input: createSourceInput!) {
    createSource(input: $input) {
      id
    }
  }
`;
export const UPDATE_SOURCE = gql`
  mutation UpdateSource($id: ID!, $input: updateSourceInput!) {
    updateSource(id: $id, input: $input) {
      id
    }
  }
`;
export const DELETE_SOURCE = gql`
  mutation DeleteSource($id: ID!) {
    deleteSource(id: $id)
  }
`;

export const TEST_SOURCE = gql`
  mutation TestSource($id: ID!) {
    testSource(id: $id)
  }
`;

export const SOURCE_DATA = gql`
  mutation sourceData($id: ID!) {
    sourceData(id: $id)
  }
`;

export const CREATE_TESTSC = gql`
  mutation CreateTestc($input: createTestcInput!) {
    createTestc(input: $input) {
      id
    }
  }
`;
export const UPDATE_TESTSC = gql`
  mutation UpdateTestc($id: ID!, $input: updateTestcInput!) {
    updateTestc(id: $id, input: $input) {
      id
    }
  }
`;
export const DELETE_TESTSC = gql`
  mutation DeleteTestc($id: ID!) {
    deleteTestc(id: $id) {
      id
    }
  }
`;
export const PERFORM_TESTC = gql`
  mutation PerformTestc($id: ID!) {
    performTestc(id: $id)
  }
`;

export const CREATE_TESTCTEMPLATE = gql`
  mutation CreateTestcTemplate($input: createTestcTemplateInput!) {
    createTestcTemplate(input: $input) {
      id
    }
  }
`;
export const UPDATE_TESTCTEMPLATE = gql`
  mutation UpdateTestcTemplate($id: ID!, $input: updateTestcTemplateInput!) {
    updateTestcTemplate(id: $id, input: $input) {
      id
    }
  }
`;
export const DELETE_TESTCTEMPLATE = gql`
  mutation DeleteTestcTemplate($id: ID!) {
    deleteTestcTemplate(id: $id) {
      id
    }
  }
`;

export const CREATE_TASK = gql`
  mutation CreateTask($input: createTaskInput!) {
    createTask(input: $input) {
      id
      value
    }
  }
`;

export const UPDATE_TASK = gql`
  mutation UpdateTask($id: ID!, $input: updateTaskInput!) {
    updateTask(id: $id, input: $input) {
      id
      value
      description
      messages
      solution
      status
      date
      when
      action {
        id
        value
        messages {
          value
          receiver {
            value
            description
          }
        }
      }
      testc {
        id
        value
        description
        risks {
          value
          process {
            value
            controls {
              value
            }
          }
        }
      }
    }
  }
`;
export const DELETE_TASK = gql`
  mutation DeleteTask($id: ID!) {
    deleteTask(id: $id) {
      id
    }
  }
`;

export const EXPORT_TASKS = gql`
  mutation ExportTasks {
    exportTasks
  }
`;

export const ADD_TASK_MESSAGE = gql`
  mutation AddTaskMessage($id: ID!, $input: updateTaskInput!) {
    addTaskMessage(id: $id, input: $input) {
      id
      value
    }
  }
`;

export const CREATE_TESTCSOURCE = gql`
  mutation CreateTestcSource($input: createTestcSourceInput!) {
    createTestcSource(input: $input) {
      id
    }
  }
`;
export const UPDATE_TESTCSOURCE = gql`
  mutation UpdateTestcSource($id: ID!, $input: updateTestcSourceInput!) {
    updateTestcSource(id: $id, input: $input) {
      id
    }
  }
`;
export const DELETE_TESTCSOURCE = gql`
  mutation DeleteTestcSource($id: ID!) {
    deleteTestcSource(id: $id) {
      id
    }
  }
`;
