import React, { useState } from "react";
import { Table } from "elements";
import { useMutation } from "@apollo/client";
import { REVIEW_NOTES } from "graphql/queries";
import {
  UPDATE_REVIEWNOTE,
  DELETE_REVIEWNOTE,
  CREATE_REVIEWNOTE,
} from "graphql/mutations";
import { useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/styles";
import { getName } from "tools";
import _ from "lodash";
import { useAuthDataContext } from "context/Context";
import { ReviewNote } from "components/SharedComponents";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

const useStyles = makeStyles((theme) => ({
  tab: {
    paddingRight: 45,
  },
}));

export default (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [updateReviewNote] = useMutation(UPDATE_REVIEWNOTE);
  const [createReviewNote] = useMutation(CREATE_REVIEWNOTE);
  const [deleteReviewNote] = useMutation(DELETE_REVIEWNOTE);
  const [note, setNote] = useState(false);
  const [open, setOpen] = useState(false);
  const [all, setAll] = useState(props.all || false);
  const { user } = useAuthDataContext();

  let variables = {};
  if (props.auditId) variables["audits"] = [props.auditId];
  if (props.testId) variables["tests"] = [props.testId];
  if (all) variables["all"] = all;
  if (props.open) variables["open"] = props.open;

  const { data, loading } = useQuery(REVIEW_NOTES, { variables, pollInterval: 30000,  fetchPolicy: "no-cache"}, );
  if (loading) return false;

  let { reviewNotes } = data;
  // if (!all)
  //   reviewNotes = reviewNotes.filter((rn) =>
  //     _.find(rn.auditors, { id: user.id })
  //   );

  const handleShowAll = (__, value) => {
    setAll(value);
  };
  const setData = () => {
    console.log("setData");
  };
  const showNote = (idx) => {
    setNote(reviewNotes[idx].id);
    setOpen(true);
  };

  const refetchQueries = () => {
    if (props.testId) variables.tests = [props.testId];
    else if (props.auditId) variables.audits = [props.auditId];
    return [{ query: REVIEW_NOTES, variables }];
  };
  const _updateReviewNote = async (idx, name, value) => {
    let row = reviewNotes[idx];

    const { data } = await updateReviewNote({
      variables: { id: row.id, input: { [name]: value } },
      refetchQueries: refetchQueries(),
    });

    enqueueSnackbar(
      `${getName("ReviewNote")} ${
        data.updateReviewNote.value
      } została zaktualizowana`,
      { variant: "success" }
    );
  };
  const _createReviewNote = async (values) => {
    let input = { ...values };
    input.test = props.testId;
    input.audit = props.auditId;
    if (input.status) input.status = parseInt(input.status);

    if (input.auditors) input.auditors = input.auditors.split(",");

    const { data } = await createReviewNote({
      variables: { input },
      refetchQueries: refetchQueries(),
    });
    enqueueSnackbar(
      `${getName("ReviewNote")} ${data.createReviewNote.value} została dodana`,
      { variant: "success" }
    );
  };
  const _deleteReviewNote = async (fs) => {
    if (fs.length === 0) return false;
    let snackText = [
      fs.length > 1 ? getName("ReviewNote", 1) : getName("ReviewNote"),
      "",
      fs.length > 1 ? "zostały usunięte" : "została usunięta",
    ];
    let names = [];
    for (let i = fs.length - 1; i > 0; i--) {
      await deleteReviewNote({ variables: { id: fs[i].id } });
      names.push(fs[i].value);
    }
    await deleteReviewNote({
      variables: { id: fs[0].id },
      refetchQueries: refetchQueries(),
    });
    names.push(fs[0].value);
    snackText[1] = names.join(", ");
    enqueueSnackbar(snackText.join(" "), { variant: "success" });
  };

  return (
    <>
      <Table
        typename="ReviewNote"
        columns={
          props.columns || [
            {
              Header: "Otwarta/zamknięta",
              accessor: "status",
              type: "switch",
              width: "15%",
              disabled: true,
              inputProps: {
                ownerOnly: true,
                owner: user.id,
                disabled: props.disabled,
              },
            },
            {
              Header: "Nazwa",
              accessor: "value",
              type: "label",
              width: "20%",
            },

            {
              Header: "Audytorzy",
              accessor: "auditors",
              type: "team",
              width: "15%",
              inputProps: { readOnly: true },
            },

            {
              Header: "Aktualizacja",
              accessor: "updatedAt",
              type: "label",
              width: "10%",
              inputProps: { type: "updatedAt" },
            },
            {
              Header: "",
              accessor: "lastAuditor.id",
              type: "user",
              width: "10%",
              inputProps: { readOnly: true },
            },
          ]
        }
        rowActions={[{ icon: <MoreHorizIcon />, onClick: showNote }]}
        setData={setData}
        updateMyData={_updateReviewNote}
        skipPageReset={true}
        onAdd={props.disabled ? null : _createReviewNote}
        onDelete={_deleteReviewNote}
        handleShowAll={handleShowAll}
        {...props}
        data={reviewNotes}
      />
      {note && <ReviewNote id={note} open={open} setOpen={setOpen} />}
    </>
  );
};
