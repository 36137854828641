import React from "react";
// import { makeStyles } from '@material-ui/core/styles';
import { Typography, Divider } from "@material-ui/core";

export default (props) => {
  return (
    <>
      <Typography
        variant="h4"
        style={{
          padding: "5px 20px",

          color: "white",
        }}
      >
        {props.title}
      </Typography>
      <Divider />
    </>
  );
};
