import React, { useContext } from "react";
import moment from "moment";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useQuery, useMutation } from "@apollo/client";
import { PROCES } from "graphql/queries";
import { UPDATE_PROCES } from "graphql/mutations";
import { Risks, Controls, Info } from "./components";
import { useSnackbar } from "notistack";
import { useLocation, Link, useParams } from "react-router-dom";
import { getName } from "tools";
import { ErrorPage, Panel, RightBar, RiskList, ControlList } from "elements";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    height: "100%",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    width: "20%",
  },
  tabsContent: {
    width: "80%",
  },
}));

function Proces({ data, num, refetch }) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(num);
  const [rightbarData, setRightbarData] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [updateProces] = useMutation(UPDATE_PROCES);

  let location = useLocation();
  React.useEffect(() => {
    try {
      setValue(
        parseInt(location.pathname.match(/\/\d+\/?$/)[0].replace(/\//, ""))
      );
    } catch (err) {
      setValue(0);
    }
  }, [location]);

  const handleChange = (_, newValue) => {
    let p = window.location.pathname.replace(/\/\d+\/?$/, "") + "/" + newValue;
    window.history.pushState({}, null, p);
    // setPath(p);
    setValue(newValue);
  };

  const onUpdateRisks = async (risks, id) => {
    await updateProces({
      variables: { id, input: { risks } },
    });

    refetch();

    enqueueSnackbar(`${getName("Proces")} został zaktualizowany`, {
      variant: "success",
    });
  };

  const onUpdateControls = async (controls, id) => {
    await updateProces({
      variables: { id, input: { controls } },
    });

    refetch();

    enqueueSnackbar(`${getName("Proces")} został zaktualizowany`, {
      variant: "success",
    });
  };

  const onClose = async () => {
    setRightbarData(false);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        orientation="vertical"
        variant="scrollable"
        className={classes.tabs}
      >
        {/* <Tab label="Informacje" {...a11yProps(0)} /> */}
        <Tab label={getName("Risk", 1)} {...a11yProps(0)} />
        <Tab label={getName("Control", 1)} {...a11yProps(1)} />
      </Tabs>

      {/* <TabPanel value={value} index={0} className={classes.tabsContent}>
        <Panel title="Informacje podstawowe">
          <Info data={data} refetch={refetch} />
        </Panel>
      </TabPanel> */}
      <TabPanel value={value} index={0} className={classes.tabsContent}>
        <Panel title={getName("Risk", 1)}>
          <Risks
            data={data}
            refetch={refetch}
            setRightbarData={setRightbarData}
          />
        </Panel>
      </TabPanel>
      <TabPanel value={value} index={1} className={classes.tabsContent}>
        <Panel title={getName("Control", 1)}>
          <Controls
            data={data}
            refetch={refetch}
            setRightbarData={setRightbarData}
          />
        </Panel>
      </TabPanel>
      <RightBar open={rightbarData !== false} onClose={onClose}>
        {rightbarData.__typename === "Risk" && (
          <RiskList data={rightbarData} onUpdate={onUpdateRisks} />
        )}
        {rightbarData.__typename === "Control" && (
          <ControlList data={rightbarData} onUpdate={onUpdateControls} />
        )}
      </RightBar>
    </div>
  );
}

export default (props) => {
  let { id, num } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { data, loading, refetch } = useQuery(PROCES, {
    variables: { id },
    pollInterval: 20000,
    fetchPolicy: "no-cache",
  });

  if (loading) return false;
  if (!data.proces) {
    enqueueSnackbar(`${getName("Proces")} został usunięty`, {
      variant: "error",
    });
    return <ErrorPage />;
  }

  return <Proces data={data.proces} refetch={refetch} />;

  // return <ContinuousAudit refetch={refetch} data={data.audit} num={num ? parseInt(num) : 0} />;
};
