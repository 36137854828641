import React, { forwardRef } from "react";
import { Tree, Table, RightBar } from "elements";
import { useQuery, useMutation, useSubscription } from "@apollo/client";
import { INCIDENTS } from "graphql/queries";
import {
  UPDATE_INCIDENT,
  DELETE_INCIDENT,
  CREATE_INCIDENT,
  EXPORT_INCIDENTS,
} from "graphql/mutations";
import { FILE_URL } from "vars";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useSnackbar } from "notistack";
import { isEmpty } from "underscore";
import { makeStyles } from "@material-ui/styles";
import { useForm } from "react-hook-form";
import { getName } from "tools";
import BarChartIcon from "@material-ui/icons/BarChart";
// import GridOnIcon from "@material-ui/icons/GridOn";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

import { useAuthDataContext } from "context/Context";

const useStyles = makeStyles((theme) => ({}));

export default (props) => {
  const {
    user: { permissions },
  } = useAuthDataContext();
  const [updateIncident] = useMutation(UPDATE_INCIDENT);
  const [createIncident] = useMutation(CREATE_INCIDENT);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteIncident] = useMutation(DELETE_INCIDENT);
  const [exportIncidents] = useMutation(EXPORT_INCIDENTS);

  const setData = (_1, _2, _3) => {
    console.log("setData", _1, _2, _3);
  };
  const _updateIncident = async (idx, name, value) => {
    let row = props.data[idx];

    const { data } = await updateIncident({
      variables: { id: row.id, input: { [name]: value } },
      refetchQueries: [{ query: INCIDENTS }],
    });
    enqueueSnackbar(
      `${getName("Incident")} ${
        data.updateIncident.value
      } został zaktualizowany`,
      { variant: "success" }
    );
  };

  // const skipPageReset = (_1,_2,_3) => {
  //   console.log(skipPageReset, _1,_2,_3)
  // }

  const onDeleteIncidents = async (ps) => {
    if (ps.length === 0) return false;
    let snackText = [
      ps.length > 1 ? getName("Incident", 1) : getName("Incident"),
      "",
      ps.length > 1 ? "zostały usunięte" : "został usunięty",
    ];
    let names = [];
    for (let i = ps.length - 1; i > 0; i--) {
      await deleteIncident({ variables: { id: ps[i].id } });
      names.push(ps[i].value);
    }
    await deleteIncident({
      variables: { id: ps[0].id },
      refetchQueries: [{ query: INCIDENTS }],
    });
    names.push(ps[0].value);
    snackText[1] = names.join(", ");
    enqueueSnackbar(snackText.join(" "), { variant: "success" });
  };

  const onSubmitIncidentForm = async (input) => {
    const { data } = await createIncident({
      variables: { input },
      refetchQueries: [{ query: INCIDENTS }],
    });
    enqueueSnackbar(
      `${getName("Incident")} ${data.createIncident.value} został dodany`,
      { variant: "success" }
    );
  };

  const exportToFile = async () => {
    const result = await exportIncidents();
    download(result.data.exportIncidents);
    enqueueSnackbar("Dane poprawie wyeksportowane", { variant: "success" });
  };

  const download = (name) => {
    const link = document.createElement("a");
    // link.targe="_blank"
    link.href = `${FILE_URL}/${name}/${name}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  // let data = props.data.map(d=>({...d, variantsCount: d.variants.length}))

  return (
    <Table
      typename="Incident"
      columns={[
        {
          Header: "Otwarty/zamknięty",
          accessor: "status",
          type: "switch",
          width: "12%",
        },
        {
          Header: "Nazwa",
          accessor: "value",
          type: "text",
          width: "40%",
        },
        {
          Header: "Opis",
          accessor: "desc",
          type: "longText",
          width: "6%",
        },
        {
          Header: "Zostało zrobione",
          accessor: "done",
          type: "longText",
          width: "12%",
        },
        {
          Header: "Pracownik",
          accessor: "employeeName",
          type: "text",
          width: "15%",
        },
        {
          Header: "Email",
          accessor: "employeeEmail",
          type: "text",
          width: "15%",
        },
      ]}
      setData={setData}
      updateMyData={_updateIncident}
      skipPageReset={true}
      onAdd={permissions.incidents.incidents_edit ? onSubmitIncidentForm : null}
      onDelete={permissions.incidents.incidents_edit ? onDeleteIncidents : null}
      rowActions={[{ icon: <BarChartIcon />, onClick: props.showDetails }]}
      toolbarButtons={[
        {
          label: "Eksportuj do Excel'a",
          icon: <CloudDownloadIcon />,
          onClick: exportToFile,
        },
      ]}
      {...props}
    />
  );
};
