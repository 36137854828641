import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Divider, Drawer } from "@material-ui/core";
import { useAuthDataContext } from "context/Context";
import { useLocation, useHistory } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import BusinessSharpIcon from "@material-ui/icons/BusinessSharp";
import EventNoteSharpIcon from "@material-ui/icons/EventNoteSharp";
import VerifiedUserSharpIcon from "@material-ui/icons/VerifiedUserSharp";
// import TextFieldsIcon from '@material-ui/icons/TextFields';
// import ImageIcon from '@material-ui/icons/Image';
// import AccountBoxIcon from '@material-ui/icons/AccountBox';
// import SettingsIcon from '@material-ui/icons/Settings';
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import MyLocationSharpIcon from "@material-ui/icons/MyLocationSharp";
import ReportSharpIcon from "@material-ui/icons/ReportSharp";
import SettingsSharpIcon from "@material-ui/icons/SettingsSharp";
import GroupSharpIcon from "@material-ui/icons/GroupSharp";
import GpsFixedIcon from "@material-ui/icons/GpsFixed";
import HomeSharpIcon from "@material-ui/icons/HomeSharp";
import ContactsIcon from "@material-ui/icons/Contacts";
import LibraryAddCheckIcon from "@material-ui/icons/LibraryAddCheck";
import StorageIcon from "@material-ui/icons/Storage";
import BuildIcon from "@material-ui/icons/Build";

import { Profile, SidebarNav } from "./components";
import { Logo } from "elements";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 280,
    [theme.breakpoints.up("lg")]: {
      // marginTop: 64,
      // height: 'calc(100% - 64px)',
      height: "100%",
    },
  },
  root: {
    backgroundColor: "#ffffff",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}));

const Sidebar = (props) => {
  const { open, variant, onClose, className, ...rest } = props;
  const {
    settings: { tabs },
    setSettings,
    user,
  } = useAuthDataContext();

  let history = useHistory();
  let location = useLocation();

  const classes = useStyles();

  function onTabClose(idx) {
    let sb = [...tabs];
    sb.splice(idx, 1);
    setSettings({ tabs: sb });

    let link = null;
    if (location.pathname === tabs[idx].href) {
      try {
        link = sb[idx].href;
      } catch (err) {
        link = sb.length
          ? sb[sb.length - 1].href
          : "/" + location.pathname.split("/")[1] + "s";
      }

      try {
        history.push(link);
      } catch (err) {
        history.push("/");
      }
    }
  }

  let pages = [];

  pages.push({
    title: "Procesy",
    href: "/cprcs",
    icon: <BusinessSharpIcon />,
    fixed: true,
  });
  pages.push({
    title: "Ryzyka",
    href: "/risks",
    icon: <BusinessSharpIcon fontSize="small" />,
    fixed: true,
  });

  pages.push({
    title: "Kontrole",
    href: "/controls",
    icon: <BusinessSharpIcon />,
    fixed: true,
  });
  pages.push({
    title: "Adresaci",
    href: "/receivers",
    icon: <ContactsIcon fontSize="small" />,
    fixed: true,
  });
  pages.push({
    title: "Źrodła danych",
    href: "/sources",
    icon: <StorageIcon fontSize="small" />,
    fixed: true,
  });

  pages.push({
    title: "Audyt ciągły",
    href: "/auditsc",
    icon: <LibraryAddCheckIcon fontSize="small" />,
    fixed: true,
  });

  pages.push({
    title: "Działania",
    href: "/tasks",
    icon: <BuildIcon fontSize="small" />,
    fixed: true,
  });
  pages.push({
    title: "Raporty",
    href: "/reports",
    icon: <ReportSharpIcon fontSize="small" />,
    fixed: true,
  });
  // user?.permissions?.recommendations?.recommendations_view &&
  //   pages.push({
  //     title: "Rekomendacje",
  //     href: "/findings",
  //     icon: <MyLocationSharpIcon fontSize="small"  />,
  //     fixed: true,
  //   });
  // user?.permissions?.incidents?.incidents_view &&
  //   pages.push({
  //     title: "Sygnalista",
  //     href: "/incidents",
  //     icon: <ReportSharpIcon fontSize="small"  />,
  //     fixed: true,
  //   });

  pages = pages.concat([
    {
      title: "Ustawienia",
      href: "/settings",
      icon: <SettingsSharpIcon fontSize="small" />,
      fixed: true,
    },
  ]);

  return (
    <Drawer
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      sx={{
        width: 280,
        flexShrink: 0,
        boxShadow: "none",
        "& .MuiDrawer-paper": {
          width: 280,
          boxSizing: "border-box",
        },
      }}
      variant="permanent"
      anchor="left"
      PaperProps={{ elevation: 0 }}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        {/* <Divider className={classes.divider} /> */}
        <br />
        <div style={{ position: "relative" }}>
          <Divider className={classes.divider} />
          <Profile user={user} />
          <Divider className={classes.divider} />
        </div>

        <SidebarNav
          className={classes.nav}
          onTabClose={onTabClose}
          pages={pages}
          tabs={tabs || []}
        />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,

  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default Sidebar;
