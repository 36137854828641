import React from "react";
import { Table, Form, Input } from "elements";
import { useAuthDataContext } from "context/Context";
import TextField from '@material-ui/core/TextField';
import {map, filter} from 'lodash'
import { useForm } from "react-hook-form";


export default ({ data, onUpdate }) => {
  const { user } = useAuthDataContext();
  const form = useForm()
  // const [inputs, setInputs] = React.useState(JSON.parse(data.inputs || "[]"))
  const [ code, setCode ] = React.useState(data.code)
  const [ pcode, setPCode ] = React.useState(data.prediction_code)

  const handleUpdate = async(values) => {
    let s = []
    // s.push({...values, id: s.length})
   
    await onUpdate( { inputs: JSON.stringify(s)} )
    // setInputs(s)
  }

  const onChange1 = async(event) => {
    setCode(event.target.value)
  }

  const onChange2 = async(event) => {
    setPCode(event.target.value)
  }

  const onBlur1 = async(event) => {
    await onUpdate( { code } )
  }

  const onBlur2 = async() => {
    await onUpdate( { prediction_code: pcode } )
  }


  return (
    <div style={{padding: 20}}>
        {/* <Input inputProps={{ type: 'longText' }}/> */}
       
        <TextField
          id="filled-multiline-flexible"
          label="Kod #1"
          multiline
          maxRows={10}
          value={code}
          onChange={onChange1}
          onBlur={onBlur1}
          fullWidth={true}
        />
        <br/><br/><br/>
        <TextField
          id="filled-multiline-flexible"
          label="Kod #2"
          multiline
          maxRows={10}
          value={pcode}
          onChange={onChange2}
          onBlur={onBlur2}
          fullWidth={true}
        />

    
    </div>
  );
};
