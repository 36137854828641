import React, { useState, useRef } from "react";
import { Tree, Table, RightBar } from "elements";
import { useQuery, useMutation, useSubscription } from "@apollo/client";
import {
  VARIANT,
  INSTANCES,
  AUDITS,
  SELECTEDAUDITGROUPS,
} from "graphql/queries";
import {
  UPDATE_INSTANCE,
  DELETE_INSTANCE,
  CREATE_INSTANCE,
  CREATE_AUDIT,
  RANK_INSTANCES,
  EXPORT_INSTANCES,
} from "graphql/mutations";
import { RANK } from "graphql/subscriptions";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/styles";
import { getName } from "tools";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Button from "@material-ui/core/Button";
import BlurCircularIcon from "@material-ui/icons/BlurCircular";
import { FILE_URL } from "vars";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

const useStyles = makeStyles((theme) => ({
  tab: {
    paddingRight: 45,
  },
}));

export default (props) => {
  const [value, setValue] = useState(props.value);
  const [updateInstance] = useMutation(UPDATE_INSTANCE);
  const [createInstance] = useMutation(CREATE_INSTANCE);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [deleteInstance] = useMutation(DELETE_INSTANCE);
  const [createAudit] = useMutation(CREATE_AUDIT);
  const [rankInstances] = useMutation(RANK_INSTANCES);
  const snackRef = useRef(null);
  const [exportInstances] = useMutation(EXPORT_INSTANCES);

  let variantsById = {};
  props.variants.map((v) => (variantsById[v.id] = v));

  const onSubscriptionData = ({ subscriptionData }) => {
    // console.log(1111111)
    const { instanceFeed } = subscriptionData.data.rank;

    props.refetch();

    closeSnackbar(snackRef.current);
    // snackRef.current = ""
    enqueueSnackbar("Ocena ryzyka zakończona", { variant: "success" });
  };
  useSubscription(RANK, {
    onSubscriptionData: onSubscriptionData,
  });

  const setData = (_1, _2, _3) => {
    console.log("setData", _1, _2, _3);
  };
  const _updateInstance = async (idx, name, value) => {
    let row = props.data[idx];
    // console.log(idx,name,value, row)
    const refetchQueries = [{ query: INSTANCES }];
    // name==='perspective' && refetchQueries.push({query: PERSPECTIVES })

    const { data } = await updateInstance({
      variables: { id: row.id, input: { [name]: value } },
      refetchQueries,
    });
    enqueueSnackbar(
      `${getName("Instance")} ${
        data.updateInstance.value
      } został zaktualizowany`,
      { variant: "success" }
    );
  };

  // const skipPageReset = (_1,_2,_3) => {
  //   console.log(skipPageReset, _1,_2,_3)
  // }

  const onDeleteInstances = async (ps) => {
    if (ps.length === 0) return false;

    let snackText = [
      ps.length > 1 ? getName("Instance", 1) : getName("Instance"),
      "",
      ps.length > 1 ? "zostały usunięte" : "został usunięty",
    ];
    let names = [];
    for (let i = ps.length - 1; i > 0; i--) {
      await deleteInstance({ variables: { id: ps[i].id } });
      names.push(ps[i].value);
    }
    await deleteInstance({
      variables: { id: ps[0].id },
      refetchQueries: [{ query: INSTANCES }],
    });
    names.push(ps[0].value);
    snackText[1] = names.join(", ");

    enqueueSnackbar(snackText.join(" "), { variant: "success" });
  };

  const onSubmitInstanceForm = async ({ value, variants }) => {
    let name = [],
      vv = variants.split(",");
    vv.map((v) => name.push(variantsById[v].value));

    const { data } = await createInstance({
      variables: {
        input: { value: name.join("→"), variants: variants.split(",") },
      },
      refetchQueries: [{ query: INSTANCES }],
    });
    enqueueSnackbar(
      `${getName("Instance")} ${data.createInstance.value} została dodana`,
      { variant: "success" }
    );
  };
  const onSubmitAuditForm = async ({ value, instances, auditGroup }) => {
    // console.log(value, instances)

    const { data } = await createAudit({
      variables: {
        input: { value, auditGroup, instances: instances.split(",") },
      },
      refetchQueries: [{ query: AUDITS }, { query: SELECTEDAUDITGROUPS }],
    });
    enqueueSnackbar(
      `${getName("Audit")} ${data.createAudit.value} został dodany`,
      { variant: "success" }
    );
  };

  const showPast = () => {};

  const rank = async () => {
    const result = await rankInstances();
    if (result)
      snackRef.current = enqueueSnackbar(
        "  Trwa ocena ryzyka! Może to potrwać kilka minut.",
        { persist: true }
      );

    props.forTest(result.data.rankInstances);
  };

  const download = (name) => {
    const link = document.createElement("a");
    // link.targe="_blank"
    link.href = `${FILE_URL}/${name}/${name}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const exportToFile = async () => {
    const result = await exportInstances();
    download(result.data.exportInstances);
    enqueueSnackbar("Dane poprawie wyeksportowane", { variant: "success" });
  };

  return (
    <div>
      <Table
        typename="Instance"
        columns={[
          {
            Header: "Ranking",
            accessor: "risk",
            type: "risk",
            width: "7%",
            inputProps: {
              show: "rank",
            },
          },
          {
            Header: "Nazwa",
            accessor: "value",
            type: "instancesName",
            width: "58%",
          },
          // {

          //   Header: getName('Variant',1),
          //   accessor: 'variants',
          //   type: 'info',
          //   width: '10%',
          //   inputProps: {
          //     all: 0,
          //     columns:[{name: "Nazwa", value:"value", width: "100%"}]
          //   }
          // },
          {
            Header: "Ostatnio audytowany",
            accessor: "lastAudited",
            type: "label",
            width: "12%",
            inputProps: {
              type: "date",
            },
          },
          // {
          //   Header: "Ostatnio ocena",
          //   accessor: "lastScale",
          //   type: "label",
          //   width: "12%",
          //   inputProps: {
          //     type: "date",
          //   },
          // },
        ]}
        setData={setData}
        updateMyData={_updateInstance}
        skipPageReset={true}
        onAdd={onSubmitInstanceForm}
        onDelete={onDeleteInstances}
        rowActions={[{ icon: <MoreHorizIcon />, onClick: props.showDetails }]}
        toolbarActions={[
          {
            label: getName("Audit", 2),
            typename: "Audit",
            onSubmit: onSubmitAuditForm,
          },
        ]}
        toolbarButtons={[
          {
            label: "Wnioskuj ryzyko",
            icon: <BlurCircularIcon />,
            onClick: rank,
          },
          // {
          //   label: "Zapisane wyniki",
          //   icon: <BlurCircularIcon />,
          //   onClick: props.showPast,
          // },
          {
            label: "Eksportuj do Excel'a",
            icon: <CloudDownloadIcon />,
            onClick: exportToFile,
          },
        ]}
        {...props}
        value={value}
      />
    </div>
  );
};
