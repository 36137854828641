import React from "react";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Input } from "elements";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import Fade from "@material-ui/core/Fade";
import { green, yellow, blue } from "@material-ui/core/colors";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import teal from "@material-ui/core/colors/teal";

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
    "& .MuiBadge-badge svg": {
      background: "#fff",
      borderRadius: 9,
    },
    "& .MuiBadge-badge": {
      visibility: "hidden",
    },
    "&:hover .MuiBadge-badge": {
      visibility: "visible",
    },
  },
  popper: {
    minWidth: 200,
  },
  avatar: {
    width: 28,
    height: 28,
    fontSize: 11,
    fontFamily: "bold!important",
    cursor: "s-resize",
  },
  table: {
    maxWidth: 350,
  },

  date: {
    width: 100,
  },
  field: {
    marginBottom: 5,
  },
  ul: {
    listStyleType: "none",
    textAlign: "center",
  },
  num1: {
    // fontFamily: "heading",
    fontSize: 13,
    color: "#fff",
    display: "block",
  },
  num2: {
    // fontFamily: "heading",
    display: "block",
    fontSize: 11,
    color: "#fff",
    letterSpacing: -1,
  },
}));

export default (props) => {
  const { value, fields, singelColumn } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [changed, setChanged] = React.useState(false);
  const [v, setV] = React.useState(
    !value ? null : typeof value === "string" ? JSON.parse(value) : value
  );
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const handleClick = (event) => {
    // setLabel("Zamknij")
    setChanged(false);
    setAnchorEl(event.currentTarget);
    setOpen(!open);
    changed && props.onChange(JSON.stringify(v));
  };

  const onChange = (key) => (val) => {
    let _v = { ...v };
    val ? (_v[key] = val) : delete _v[key];
    setV(_v);
    // console.log(12345,_v)
    setChanged(true);
  };
  const onChangeFirst = (key) => (val) => {
    if (Object.keys(v).length) {
      setV({});
    } else {
      setV({ ...v, [key]: value });
    }

    setChanged(true);
  };

  //   const getTableHead = () => {
  //     return <TableHead>
  //         <TableRow>
  //             {columns.map(column=> <TableCell style={{ width: column.width}}>{column.name}</TableCell>)}
  //         </TableRow>
  //     </TableHead>
  //   }

  // const getTableRows = () => {
  //     return fields.map((field,i)=><TableRow key={i}>
  //         <TableCell scope="row" >{field.label}</TableCell>
  //         <TableCell scope="row" className={classes[field.type] ? classes[field.type]: ""}>
  //             <Input inputProps={{onChange: onChange(field.key), value: v && v[field.key] ? v[field.key]: null }} type={field.type} />
  //         </TableCell>
  //     </TableRow>
  //   )
  // }

  const len = v ? Object.keys(v).length : 0;
  const i = len / fields.length;
  // let cls = (parseInt(parseFloat(value.num/value.all)*10)*100)
  const cls = i === 0 ? 0 : i === 1 ? 600 : 200;

  const getFields = () => {
    const nof = Math.floor(fields.length / 2) + 1;
    if (nof > 3 && !singelColumn) {
      const fields1 = fields.slice(1, nof);
      const fields2 = fields.slice(nof);
      return (
        <Grid container>
          <Grid item xs={12}>
            <Input
              inputProps={{
                label: fields[0].label,
                onChange: onChangeFirst(fields[0].key),
                value: v && v[fields[0].key] ? v[fields[0].key] : null,
              }}
              type={fields[0].type}
            />
          </Grid>
          <Grid item xs={6}>
            {fields1.map((field, i) => (
              <div key={i} className={classes.field}>
                <Input
                  inputProps={{
                    disabled: !v[fields[0].key],
                    label: field.label,
                    onChange: onChange(field.key),
                    value: v && v[field.key] ? v[field.key] : null,
                  }}
                  type={field.type}
                />
              </div>
            ))}
          </Grid>
          <Grid item xs={6}>
            {fields2.map((field, i) => (
              <div key={i} className={classes.field}>
                <Input
                  inputProps={{
                    disabled: !v[fields[0].key],
                    label: field.label,
                    onChange: onChange(field.key),
                    value: v && v[field.key] ? v[field.key] : null,
                  }}
                  type={field.type}
                />
              </div>
            ))}
          </Grid>
        </Grid>
      );
    } else {
      return fields.map((field, i) => (
        <div key={i} className={classes.field}>
          <Input
            inputProps={{
              label: field.label,
              onChange: onChange(field.key),
              value: v && v[field.key] ? v[field.key] : null,
            }}
            type={field.type}
          />
        </div>
      ));
    }
  };

  return (
    <div className={classes.root}>
      <Avatar
        style={{ backgroundColor: teal[cls], color: "#fff" }}
        className={classes.avatar}
        onClick={handleClick}
        variant="square"
      >
        <ul className={classes.ul}>
          <li className={classes.num1}>{len}</li>
          <li className={classes.num2}>{fields.length}</li>
        </ul>
      </Avatar>

      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        transition
        className={classes.popper}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper style={{ padding: 10, maxWidth: 500 }}>
              {getFields()}
              <div style={{ textAlign: "right" }}>
                <Button onClick={handleClick}>
                  {changed ? "Zapisz" : "Zamknij"}
                </Button>
              </div>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
};
