import React from "react";
import { Router } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { FINDING } from "graphql/queries";
import { ADD_FINDING_MESSAGE } from "graphql/mutations";
import { ErrorPage } from "elements";
import { getName } from "tools";
import SendIcon from "@material-ui/icons/Send";
import { Logo, Input } from "elements";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Truncate from "react-truncate";
import {
  Grid,
  Paper,
  Tab,
  TextField,
  AppBar,
  Typography,
  Box,
  Tabs,
  Button,
  Dialog,
} from "@material-ui/core";
import { FileList } from "elements";

import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

// import { useParams } from "react-router-dom";

// import { SnackbarProvider, useSnackbar } from "notistack";
// import CircularProgress from "@material-ui/core/CircularProgress";
// import { ConfirmProvider } from "material-ui-confirm";
const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.default,
    height: "100%",
  },

  form: {
    position: "absolute",
    top: "0%",
    left: "0%",
    height: "100%",
    background: "white",
    zIndex: 100,
    width: "55%",
    overflow: "hidden",
    marign: 10,
  },
  banner: {
    zIndex: 99,
    position: "absolute",
    bottom: "5%",
    right: "5%",
    width: 400,
  },
  button: {
    marginTop: "15%",
    position: "absolute",
    right: "10%",
  },
  avatarLeft: {
    float: "left",
    marginLeft: 10,
    marginTop: 10,
  },
  avatarRight: {
    float: "right",
    marginRight: 10,
    marginTop: 10,
  },
  message: {
    // width: "65%",

    olor: "#444",
    fontSize: 11,
  },
  value: {
    // width: "65%",

    // backgroundColor: "#f4f6f8",
    fontWeight: "bold",
    fontSize: 11,
  },
  time: {
    textAlign: "right",
    fontSize: 11,
    width: "100%",
  },
  logo: {
    position: "absolute",
    top: "5%",
    right: "5%",
  },

  label: {
    fontWeight: "bold",
  },
  value: {
    fontSize: 12,
  },
  window: {
    padding: "4%",
    overflow: "scroll",
    paddingBottom: 100,
  },
  bottom: {
    position: "absolute",
    width: "100%",
    height: 70,
    borderTop: "1px solid #e4e6e8",
    bottom: 0,
    paddingTop: 18,
    textAlign: "center",
  },
}));

function Log(props) {
  const [messages, setMessages] = React.useState(props.messages);
  const classes = useStyles();

  return (
    <Timeline>
      {messages &&
        messages.map((m, i) => (
          <TimelineItem>
            <TimelineOppositeContent>
              <Typography color="textSecondary">
                <div className={classes.time}>
                  {moment.unix(parseInt(m.time)).format("YYYY-MM-DD HH:mm")}
                </div>
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Typography className={classes.message}>{m.message}</Typography>
              <Typography className={classes.value}>{m.value}</Typography>
            </TimelineContent>
          </TimelineItem>
        ))}
    </Timeline>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default ({ data, tab }) => {
  //   const path = window.location.pathname.split("/");
  const [expanded, setExpanded] = React.useState(false);
  const [value, setValue] = React.useState(tab || 0);
  const classes = useStyles();
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const inputs = React.useRef({});
  const [addFindingMessage] = useMutation(ADD_FINDING_MESSAGE);

  //   let id = path[3];
  //   if (!id || id.length !== 24) window.location.assign("/universe");

  //   const { data, loading } = useQuery(FINDING, {
  //     variables: { id },
  //   });

  //   if (loading) return false;
  //   if (!data.finding) {
  //     return <ErrorPage />;
  //   }

  const handleClickOpen1 = () => {
    setOpen1(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleSubmit1 = async () => {
    if (!inputs.current.reason || !inputs.current.date) return false;

    const time = +new Date() / 1000;
    const _messages = data.finding.messages
      ? JSON.parse(data.finding.messages)
      : [];

    _messages.unshift({
      message:
        "Prośba o przesunięcie terminu wdrożenia. Nowy termin: " +
        moment.unix(inputs.current.date / 1000).format("YYYY-MM-DD"),
      value: inputs.current.reason,
      status: 1,
      time,
    });

    const na = await addFindingMessage({
      variables: {
        id: data.finding.id,
        input: { messages: JSON.stringify(_messages), status: 1 },
      },
    });

    setOpen1(false);
    inputs.current = {};
  };

  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleSubmit2 = async () => {
    if (!inputs.current.info) return false;

    const time = +new Date() / 1000;
    const _messages = data.finding.messages
      ? JSON.parse(data.finding.messages)
      : [];

    _messages.push({
      message: "Informacja o wdrożeniu rekomendacji",
      value: inputs.current.info,
      status: 4,
      time,
    });

    const na = await addFindingMessage({
      variables: {
        id: data.finding.id,
        input: { messages: JSON.stringify(_messages), status: 4 },
      },
    });

    setOpen2(false);
    inputs.current = {};
  };

  const onChange = (name) => (value) => {
    inputs.current[name] = value;
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  //   5ef6033034ff3873d9ab1

  return (
    <div className={classes.root}>
      <div className={classes.skew}></div>

      <Grid className={classes.grid} container>
        <Grid className={classes.quoteContainer} item lg={5}></Grid>
        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <div className={classes.logo}>
                <Logo size="big" />
              </div>
              <img
                className={classes.banner}
                src="http://riskshield.com.pl/assets/images/hero/01.jpg"
                title=""
                alt=""
              />
              <Paper elevation={5} className={classes.form}>
                <AppBar position="static" color="finding">
                  <Tabs value={value} onChange={handleTabChange} aria-label="">
                    <Tab label="Podstawowe informacje" {...a11yProps(0)} />
                    <Tab label="Pliki" {...a11yProps(1)} />
                    <Tab label="Log" {...a11yProps(2)} />
                  </Tabs>
                </AppBar>

                <TabPanel value={value} index={0}>
                  <div className={classes.window}>
                    <div className={classes.label}>Nazwa</div>
                    <div className={classes.value}>{data.finding.value}</div>
                    <br />
                    <div className={classes.label}>Data wdrożenia</div>
                    <div className={classes.value}>
                      {moment.unix(data.finding.date).format("YYYY-MM-DD")}
                    </div>
                    <br />
                    <div className={classes.label}>Opis</div>
                    <div className={classes.value}>{data.finding.desc}</div>
                    <br />
                    <div className={classes.label}>Uwagi</div>
                    <div className={classes.value}>{data.finding.remark}</div>
                    <br />
                    <div className={classes.label}>Rekomendacje</div>
                    <div className={classes.value}>
                      {data.finding.recomendation}
                    </div>
                    <br />
                    <div className={classes.label}>Rozwiązanie</div>
                    <div className={classes.value}>{data.finding.solution}</div>
                  </div>

                  {/* <Grid className={classes.label} item>Data rozwiązanie</Grid> 
                    <Grid className={classes.value} item>{moment.unix(data.finding.value).format("YYYY-MM-DD")}</Grid>  */}
                </TabPanel>
                <TabPanel value={value} index={1} className={classes.tab}>
                  <FileList finding={data.finding.id} />
                </TabPanel>

                <TabPanel value={value} index={2} className={classes.tab}>
                  <Log
                    id={data.finding.id}
                    messages={JSON.parse(data.finding.messages)}
                    author={data.finding.responsible}
                  />
                </TabPanel>

                <div className={classes.bottom}>
                  <Button variant="contained" onClick={handleClickOpen1}>
                    Poprość o przedłużenie
                  </Button>
                  &nbsp;
                  <Button
                    variant="contained"
                    onClick={handleClickOpen2}
                    color="secondary"
                  >
                    Oznacz jako wdrożoną
                  </Button>
                </div>
              </Paper>
            </div>
          </div>
        </Grid>
      </Grid>
      <Dialog
        open={open1}
        onClose={handleClose1}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Poprość o przedłużenie</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Wpisz powód dla którego chcesz przedłużyc wdrożenie rekomendacji
            oraz nową proponowaną datę wdrożenia.
          </DialogContentText>
          <Input
            inputProps={{
              onChange: onChange("reason"),
            }}
            type="text"
          />
          <br />
          <Input
            type="date"
            inputProps={{
              onChange: onChange("date"),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose1} color="primary">
            Anuluj
          </Button>
          <Button onClick={handleSubmit1} color="primary">
            Wyślij
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open2}
        onClose={handleClose2}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Oznacz jako wdrożoną</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Opisz sposób wdrożenia rekomendacji.
          </DialogContentText>
          <Input
            inputProps={{
              onChange: onChange("info"),
            }}
            type="text"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2} color="primary">
            Anuluj
          </Button>
          <Button onClick={handleSubmit2} color="primary">
            Wyślij
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
