import React from "react";

import {
  BarChart,
  Legend,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import moment from "moment";

function CustomizedLabel(props) {
  const { x, y, stroke, value } = props;
  console.log(props);
  return (
    <text
      x={x}
      y={y}
      dy={-6}
      dx={10}
      fill="#666"
      fontSize={8}
      textAnchor="middle"
    >
      {value}
    </text>
  );
}
function CustomizedXTick(props) {
  const { x, y, stroke, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={0}
        fontSize={8}
        textAnchor="end"
        fill="#666"
        transform="rotate(-35)"
      >
        {payload.value}
      </text>
    </g>
  );
}

function CustomizedYTick(props) {
  const { x, y, options, payload } = props;

  console.log(11, props);
  return <div>aaa</div>;
}

export default (props) => {
  const formatTicks = (value) => {
    return props.options[value];
  };

  return (
    <BarChart
      width={props.width || 500}
      height={props.height || 300}
      data={props.data}
      barSize={props.barSize || 20}
    >
      <XAxis
        dataKey="time"
        padding={{ left: 10, right: 10 }}
        tick={<CustomizedXTick />}
      />
      <YAxis
        tick={{ fontSize: 10, padding: 20 }}
        tickFormatter={formatTicks}
        ticks={[0, 2, 4, 6, 8, 10]}
      />
      <Tooltip />
      <CartesianGrid strokeDasharray="3 3" />
      <Bar
        dataKey={props.dataKey}
        fill="#8884d8"
        background={{ fill: "#eee" }}
      />
    </BarChart>
  );
};
