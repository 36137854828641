import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Fab, Divider, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { green, pink } from "@material-ui/core/colors";
import SaveIcon from "@material-ui/icons/Save";
import { Text, Rich, FileButton } from "elements";
import NewRow from "elements/table/newRow";
import { useMutation } from "@apollo/client";
import { FINDINGS } from "graphql/queries";
import { CREATE_FINDING, UPDATE_TEST } from "graphql/mutations";
import { useSnackbar } from "notistack";
import { getName } from "tools";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { useAuthDataContext } from "context/Context";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    position: "relative",
    padding: 10,
  },
  pink: {
    color: theme.palette.getContrastText(pink[500]),
    backgroundColor: pink[500],
    position: "absolute",
    right: 30,
    top: -60,
    zIndex: 999999,
    cursor: "pointer",
  },
  green: {
    color: "#fff",
    backgroundColor: green[500],
    position: "absolute",
    right: 90,
    top: -60,
    cursor: "pointer",
  },
}));

export default ({ test }) => {
  const [createFinding] = useMutation(CREATE_FINDING);
  const [updateTest] = useMutation(UPDATE_TEST);
  const { register, handleSubmit, watch, errors } = useForm();
  const [save, setSave] = useState(false);
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { user } = useAuthDataContext();

  const onSubmit = async (input) => {
    const { data } = await updateTest({ variables: { id: test.id, input } });
    enqueueSnackbar(`${getName("Test")} ${test.value} został zaktualizowany`, {
      variant: "success",
    });

    setSave(false);
  };

  const addFinding = async (values) => {
    let input = { ...values };
    input.test = test.id;
    input.audit = test.audit.id;
    // if (input.financialImpactA)
    //   input.financialImpactA = parseInt(input.financialImpactA);
    // if (input.financialImpactP)
    //   input.financialImpactP = parseInt(input.financialImpactP);
    const { data } = await createFinding({
      variables: { input },
      refetchQueries: [{ query: FINDINGS }],
    });
    enqueueSnackbar(
      `${getName("Finding")} ${data.createFinding.value} został dodany`,
      { variant: "success" }
    );
  };

  const onChange = () => setSave(true);
  return (
    <div className={classes.root}>
      {/* <Avatar className={classes.pink}>
                <PageviewIcon />
            </Avatar>
            <Avatar className={classes.green}>
                <AssignmentIcon />
            </Avatar> */}

      <NewRow addRow={addFinding} typename="Finding" />
      <FileButton audit={test.audit.id} test={test.id} />
      <Divider />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ padding: 30 }}>
          <Typography variant="h6">Do wykonania</Typography>
          <Typography variant="div">{test.todo}</Typography>
        </div>
        <Divider />
        <div style={{ padding: 30 }}>
          <Typography variant="h6">Próbka</Typography>
          <Typography variant="div">{test.sample}</Typography>
        </div>

        <Divider />
        <div style={{ padding: 30 }}>
          <Typography variant="h6">Przeprowadzone procedury</Typography>
          <Text
            disabled={test.status === 1 || user.id !== test.auditor.id}
            defaultValue={test.done}
            onChange={onChange}
            register={register}
            name={"done"}
            multiline
            rows={10}
          />
        </div>
        {/* <div style={{height: 500}}>
                    <Rich />
                </div> */}

        {save && (
          <Fab
            type="submit"
            color="primary"
            aria-label="zapisz"
            className="fab"
          >
            <SaveIcon />
          </Fab>
        )}
      </form>
    </div>
  );
};
