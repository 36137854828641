import React from "react";
import { Table, Loading } from "elements";
import { useAuthDataContext } from "context/Context";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import _ from "lodash";
import { useHistory } from "react-router";
import { Templates } from ".";
import { useQuery, useMutation} from "@apollo/client";
import { TESTCTEMPLATES } from "graphql/queries";
import {
  // UPDATE_TESTCTEMPLATE,
  DELETE_TESTCTEMPLATE,
  CREATE_TESTCTEMPLATE,
  UPDATE_TESTCTEMPLATE
} from "graphql/mutations";
import { getName } from "tools";
import { useSnackbar } from "notistack";

function Templatesc ({ data }) {
  const { user, settings, setSettings} = useAuthDataContext();
  const history = useHistory();
  // const [updateTestcTemplate ] = useMutation(UPDATE_TESTCTEMPLATE);
  const [createTestcTemplate ] = useMutation(CREATE_TESTCTEMPLATE);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [deleteTestcTemplate ] = useMutation(DELETE_TESTCTEMPLATE);
  const [updateTestTemplate ] = useMutation(UPDATE_TESTCTEMPLATE);

  function goToTemplate(i) {
    const template = data[i];

    console.log(template)

    const tabs = settings.tabs ? [...settings.tabs] : [];
    if (_.findIndex(tabs, { id: template.id }) < 0) {
      setSettings({
        tabs: [
          {
            id: template.id,
            title:
              template.value.length > 20
                ? template.value.slice(0, 20) + "..."
                : template.value,
            full: template.value,
            parent: null,
            href: "/templatec/" + template.id,
          },
          ...tabs,
        ],
      });
    }
    history.push("/templatec/" + template.id);
  }

  const onAdd = async ({ value, description }) => {

    const { data } = await createTestcTemplate({
      variables: {
        input: { value },
      },
      refetchQueries: [{ query: TESTCTEMPLATES }],
    });
    enqueueSnackbar(
      `${getName("TestcTemplate")} został dodany`,
      { variant: "success" }
    );
  };

  const onDelete = async (ps) => {
    if (ps.length === 0) return false;

    let snackText = [
      ps.length > 1 ? getName("TestcTemplate", 1) : getName("TestcTemplate"),
      "",
      ps.length > 1 ? "zostały usunięte" : "został usunięty",
    ];
    let names = [];
    for (let i = ps.length - 1; i > 0; i--) {
      await deleteTestcTemplate({ variables: { id: ps[i].id } });
      names.push(ps[i].value);
    }
    await deleteTestcTemplate({
      variables: { id: ps[0].id },
      refetchQueries: [{ query: TESTCTEMPLATES}],
    });

    enqueueSnackbar(snackText.join(" "), { variant: "success" });
  }

 

  const onUpdate = async (idx, name, value) => {
    let row = data[idx];
  
    await updateTestTemplate({
      variables: { id: row.id, input: { [name]: value } },
      refetchQueries: [{ query: TESTCTEMPLATES }],
    });

    enqueueSnackbar(
      `Schemat został zaktualizowany`,
      { variant: "success" }
    );
  };
 

  return (
    <Table
        typename="TestcTemplate"
        columns={[
         
          {
            Header: "Nazwa",
            accessor: "value",
            type: "label",
            width: "36%",
          },
          // {
          //   Header: "Zmienne wejsciowe",
          //   accessor: "inputs",
            
          //   type: "count",
          //   width: "12%",
          //   inputProps: {
          //     json: true,
          //     columns: [{name: "Nazwa", value: "name"}, { name: "typ", value: "type" },],
          //   }
          // },
          // {
          //   Header: "Zmienne wyjsciowe",
          //   accessor: "outputs",
          //   type: "count",
          //   width: "12%",
          //   inputProps: {
          //     columns: [{name: "Nazwa", value: "name"}, { name: "typ", value: "type" },],
          //   }
          // },
          // {
          //   Header: "Opis do źródeł",
          //   accessor: "sources",
          //   type: "longText",
          //   width: "12%"
          // },
          // {
          //   Header: "Kod #1",
          //   accessor: "code",
          //   type: "longText",
          //   width: "12%",
           
          // },
          // {
          //   Header: "Kod #2",
          //   accessor: "prediction_code",
          //   type: "longText",
          //   width: "12%",
            
          // },

         
        ]}
        // setData={setData}
        updateMyData={onUpdate}
        // skipPageReset={true}
        onAdd={onAdd}
        onDelete={onDelete}
        rowActions={[{ icon: <MoreHorizIcon />, onClick: goToTemplate }]}
       
        // {...props}
        data={data}
      />
  );
};


export default ({}) => {

  const {data, loading} = useQuery(TESTCTEMPLATES)

  if (loading) return <Loading />;

  data.testcTemplateFeed && data.testcTemplateFeed.map(tt=>{
    tt.inputs = JSON.parse(tt.inputs)
    tt.outputs = JSON.parse(tt.inputs)
    tt.vars = JSON.parse(tt.vars)
  })

  var templates = []

  data && data.testcTemplates.testcTemplateFeed && data.testcTemplates.testcTemplateFeed.map(d=>{
    templates.push({
      ...d,
      vars: d.vars ? {data: JSON.parse(d.vars), num: d.vars.length}: {data: [], num:0},
      outputs: d.outputs ? {data: JSON.parse(d.outputs), num: d.outputs.length } : {data: [], num:0},
      alerts: d.inputs ? {data: JSON.parse(d.inputs), num: d.inputs.lenght } : {data: [], num:0},
    })
  })

  // console.log(templates, data.testcTemplates.testcTemplateFeed, "!!!!!")

  return <Templatesc data={templates} />
}