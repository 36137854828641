import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Text } from "elements";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import Fade from "@material-ui/core/Fade";
import { green, yellow, blue } from "@material-ui/core/colors";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import Truncate from "react-truncate";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import NotesIcon from "@material-ui/icons/Notes";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
    "& .MuiBadge-badge svg": {
      background: "#fff",
      borderRadius: 9,
    },
    "& .MuiBadge-badge": {
      visibility: "hidden",
    },
    "&:hover .MuiBadge-badge": {
      visibility: "visible",
    },
  },
  paper: {
    width: 500,
  },
  avatar: {
    width: 28,
    height: 28,
    fontSize: 11,
    cursor: "s-resize",
    opacity: 0.3,
  },
  avatarGreen: {
    width: 28,
    height: 28,
    fontSize: 11,
    cursor: "s-resize",
    color: "#fff",
    opacity: 1,
  },
}));

export default (props) => {
  const { value } = props;
  const [changed, setChanged] = React.useState(false);
  const [v, setV] = React.useState(value);
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onChange = (_v) => {
    setV(_v);
    setChanged(true);
  };

  const onSave = (_1, _2) => {
    setChanged(false);
    changed && props.onChange(v);
    setOpen(false);
  };

  return (
    <div>
      {v && v.length > 0 && props.preview && (
        <div onClick={handleClickOpen}>{v}</div>
      )}
      {(!v || v.length === 0) && props.preview && (
        <div onClick={handleClickOpen}>edytuj</div>
      )}

      {!props.preview && (
        <Avatar
          className={v ? classes.avatarGreen : classes.avatar}
          onClick={handleClickOpen}
          variant="square"
        >
          <NotesIcon style={{ fontSize: 15 }} />
        </Avatar>
      )}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle color="primary">Edycja</DialogTitle>
        <DialogContent>
          <Text
            autoFocus
            value={v}
            inputProps={{
              multiline: true,
              rows: 20,
            }}
            onChange={onChange}
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Zamknij
          </Button>
          <Button disabled={!changed} onClick={onSave} color="primary">
            Zapisz
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

{
  /* <Truncate lines={1} ellipsis={<span>...</span>}>
    {value}
    </Truncate> */
}
