import React from "react";
import { File, Text, FileButton } from "elements";
import { useQuery } from "@apollo/client";
import { FILES } from "graphql/queries";

export default (props) => {
  const [files, setFiles] = React.useState(null);

  const { data, loading } = useQuery(FILES, { variables: { ...props } });
  if (loading) return false;

  const searchHandler = (value) => {
    let query = value.toLowerCase();
    if (query.length === 0) {
      setFiles(data.files);
      return true;
    }

    setFiles(
      data.files.filter((el) => {
        let searchValue = el.filename.toLowerCase();
        return searchValue.indexOf(query) !== -1;
      })
    );
  };

  return (
    <div style={{ position: "relative" }}>
      {!props.disableAdd && <FileButton {...props} />}
      <Text
        onChange={searchHandler}
        type="search"
        placeholder="Szukaj pliku..."
        style={{
          width: "55%",
          position: "absolute",
          right: "2%",
          top: "-30px",
        }}
      />
      {(files || (data && data.files)).map((f) => (
        <File data={f} variables={props} disableDelete={props.disableAdd} />
      ))}
    </div>
  );
};
