import React from "react";

import InputBase from "@material-ui/core/InputBase";
import { alpha, makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import FindInPageOutlinedIcon from "@material-ui/icons/FindInPageOutlined";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: 10,
    marginLeft: 0,
    width: 700,
  },
}));

const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) => {
  const classes = useStyles();
  const count = preGlobalFilteredRows.length;

  return (
    <div className={classes.search}>
      <TextField
        value={globalFilter || ""}
        variant="outlined"
        size="small"
        onChange={(e) => {
          setGlobalFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`${count} ${
          count > 4 ? "elementów" : count > 1 ? "elementy" : "element"
        }`}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        fullWidth
        inputProps={{ "aria-label": "search" }}
        InputProps={{
          startAdornment: <FindInPageOutlinedIcon color="#999999" />,
        }}
      />
    </div>
  );
};

GlobalFilter.propTypes = {
  preGlobalFilteredRows: PropTypes.array.isRequired,
  // globalFilter: PropTypes.string.isRequired,
  setGlobalFilter: PropTypes.func.isRequired,
};

export default GlobalFilter;
