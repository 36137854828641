import React from "react";
import { RightBar } from "elements";
import { Avatar, Panel, Status, Input } from "elements";
import { makeStyles } from "@material-ui/styles";
import {
  TextField,
  Divider,
  Card,
  Button,
  CardActions,
  CardContent,
  Switch,
  Grid,
} from "@material-ui/core";

import SendIcon from "@material-ui/icons/Send";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { useSnackbar } from "notistack";
import moment from "moment";
import { useAuthDataContext } from "context/Context";
import { Messages } from "components/SharedComponents";
import { getName } from "tools";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    overflow: "hidden",
  },

  avatarLeft: {
    float: "left",
    marginLeft: 10,
    marginTop: 10,
  },
  avatarRight: {
    float: "right",
    marginRight: 10,
    marginTop: 10,
  },
  messageLeft: {
    width: 600,
    marginLeft: 50,
    minHeight: 100,
    padding: 10,
    marginBottom: 10,
    backgroundColor: "#f4f6f8",
  },
  messageRight: {
    width: "65%",
    marginLeft: "30%",
    minHeight: 100,
    padding: 10,
    marginBottom: 10,
    backgroundColor: "#f4f6f8",
  },
  messageBoard: {
    // height: 200,
    // position: "fixed",
    top: 0,
    overflow: "scroll",
    scrollY: "auto",
    scrollX: "none",
    height: window.innerHeight - 400,
    paddingBottom: 10,
    paddingTop: 10,
  },
  messageNew: {
    // position: "fixed",
    // bottom: 0,
    // height: 160,
    // width: "79%",
    // backgroundColor: "#ffffff",
    // borderTop: "1px solid #f4f6f8",
    // padding: "5px 20px",
  },
  button: {
    float: "right",
    marginTop: 20,
  },
  message: {
    fontSize: 14,
  },
  time: {
    textAlign: "right",
    fontSize: 11,
    width: "100%",
  },
}));

export default ({ onSend, ...props }) => {
  const classes = useStyles();
  const el = React.useRef(null);
  const [messages, setMessages] = React.useState(props.messages);
  const message = React.useRef();

  const { enqueueSnackbar } = useSnackbar();
  const { systemSettings, user } = useAuthDataContext();
  const dateFromat = systemSettings.dateFormat || "YYYY-MM-DD";

  const handleChange = (event) => {
    message.current = event.target.value;
  };

  React.useEffect(() => {
    setMessages(props.messages);
    // console.log("In", JSON.parse(props.messages));
  }, [props.messages]);

  const users = {};

  setTimeout(() => {
    try {
      el.current.scrollIntoView({ block: "end" });
    } catch (e) {}
  }, 200);

  const getStatus = () => {};

  const handleClick = async () => {
    const time = +new Date() / 1000;
    const _messages = [
      ...messages,
      {
        message: message.current,
        auditor: {
          value: user.value,
          avatar: user.avatar,
          id: user.id,
          color: user.color,
        },
        time,
      },
    ];

    await onSend({
      messages: JSON.stringify(_messages),
    });
    setMessages(_messages);
    message.current = null;
    el.current.scrollIntoView({ block: "end" });
  };

  return (
    <div className="container">
      <div id="mb" className={classes.messageBoard}>
        {messages.map((m, i) => (
          <div>
            {m.auditor && (
              <div className={classes.avatarLeft}>
                <Avatar
                  key={"avatar" + i}
                  color={m.auditor.color}
                  value={m.auditor.value}
                >
                  {m.auditor.avatar || user.avatar}
                </Avatar>
              </div>
            )}
            <Card
              className={m.auditor ? classes.messageLeft : classes.messageRight}
            >
              <CardContent className={classes.message}>{m.message}</CardContent>
              <CardActions>
                <div className={classes.time}>
                  {moment.unix(parseInt(m.time)).format(dateFromat + " HH:mm")}
                </div>
              </CardActions>
            </Card>
          </div>
        ))}
        <div id={"el"} ref={el}></div>
      </div>
      <Divider />
      <div className={classes.messageNew}>
        <TextField
          label="Odpowiedź"
          multiline
          rows={5}
          onChange={handleChange}
          fullWidth
        />
        <Button
          className={classes.button}
          startIcon={<SendIcon />}
          onClick={handleClick}
        >
          Wyślij
        </Button>
      </div>
    </div>
  );
};
