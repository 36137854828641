import React, {useRef, useState, useEffect} from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

const StyledSlider = withStyles({
  root: {
    color: '#3880ff',
    height: 2,
    padding: '30px 0 0 0',
  },
  thumb: {
    height: 14,
    width: 14,
    backgroundColor: '#fff',
    boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)',
    marginTop: -7,
    marginLeft: -7,
    '&:focus, &:hover, &$active': {
      boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)',
      },
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 0px)',
    top: -22,
    '& *': {
      background: 'transparent',
      color: '#000',
    },
  },
  track: {
    height: 2,
  },
  rail: {
    height: 2,
    opacity: 0.5,
    backgroundColor: '#bfbfbf',
  },
  mark: {
    backgroundColor: 'transparent',
    height: 8,
    width: 1,
    marginTop: -3,
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor',
    // height: 4
  },
  
})(Slider);

const useStyles = makeStyles((theme) => ({
    container: {
    },
    root: {
      width: 300,
    },
    margin: {
      height: theme.spacing(3),
    },label:{
      fontSize: 14, marginBottom: 20,
      marginTop: 15
    }
  }));



export default ({onChange, value, labels, label, register, validation, name,  ...rest}) => {
   
    // const [error, setError] = useState(null)
    const classes = useStyles();
    const [v, setV] = useState(parseInt(value) || rest.min)
    const tout = useRef(null)

   
    const setItTimeout = (newValue) => {
      onChange && onChange(newValue)
      tout.current = null
    }

    const setIt = (e,newValue) => {

      tout.current && clearTimeout(tout.current)
      tout.current = setTimeout(()=>setItTimeout(newValue), 100)
      setV(newValue)
    }

    const valueLabelFormat = (value) => labels[value]
    useEffect(() => {
      setV(value)
    }, [value])

    return  <div style={{ marginRight: 30 }}>
      {label && <div className={classes.label}>{label}</div>}
      <StyledSlider
      value={v}
      aria-labelledby="discrete-slider-custom"
      valueLabelDisplay="on"
      valueLabelFormat={valueLabelFormat}
      onChange={setIt} 
      {...rest}
    />
    {register && <input ref={register} name={name} value={v} type="hidden"/>}
    </div>
  
}
