import React from "react";

import { useConfirm } from "material-ui-confirm";
import Checkbox from "@material-ui/core/Checkbox";
import MaUTable from "@material-ui/core/Table";
import PropTypes from "prop-types";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "./tablePaginationActions";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TextField from "@material-ui/core/TextField";
import TableToolbar from "./tableToolbar";
import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { Input } from "elements";
import { getName } from "tools";
import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <Checkbox ref={resolvedRef} {...rest} />
      </>
    );
  }
);

const inputStyle = {
  padding: 0,
  margin: 0,
  border: 0,
  background: "transparent",
};

// Create an editable cell renderer
const EditableCell = (props) => {
  // We need to keep and update the state of the cell normally
  let {
    value: initialValue,
    row: { index },
    column: { id, type, inputProps },
    updateMyData, // This is a custom function that we supplied to our table instance
    data,
  } = props;

  const [value, setValue] = React.useState(initialValue);
  // const [field, setField]  = React.useState(null)

  const onChange = (v) => {
    if (value === v) return false;
    setValue(v);
    if (["text", "number", "label"].indexOf(type) < 0) {
      updateMyData(index, id.split(".")[0], v);
    }
  };
  const onKeyPress = (e) => {
    var key = e.keyCode || e.which;
    if (key == 13) {
      e.target.blur();
    }
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () =>
    ["text", "number", "label"].indexOf(type) >= 0
      ? updateMyData(index, id.split(".")[0], value)
      : true;

  const defaultProps = {
    data: data[index],
    value,
    onChange,
    onKeyPress,
    onBlur,
  };

  const getField = () => {
    return (
      <Input
        {...{
          type,
          defaultProps,
          inputProps,
        }}
      />
    );
  };

  // If the initialValue is changed externall, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return getField();
};

// EditableCell.propTypes = {
//   cell: PropTypes.shape({
//     value: PropTypes.any.isRequired,
//   }),
//   row: PropTypes.shape({
//     index: PropTypes.number.isRequired,
//   }),
//   column: PropTypes.shape({
//     id: PropTypes.string.isRequired,
//   }),
//   updateMyData: PropTypes.func.isRequired,
// }

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
  Cell: EditableCell,
};

const EnhancedTable = ({
  columns,
  data,
  setData,
  updateMyData,
  skipPageReset,
  skipSelect,
  onAdd,
  onDelete,
  customConfirmDelete,
  typename,
  toolbarActions,
  toolbarButtons,
  rowActions,
  handleShowAll,
}) => {
  const confirm = useConfirm();
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize, selectedRowIds, globalFilter },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      autoResetPage: !skipPageReset,
      // updateMyData isn't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer!
      updateMyData,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (skipSelect) return false;
      hooks.allColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox.  Pagination is a problem since this will select all
          // rows even though not all rows are on the current page.  The solution should
          // be server side pagination.  For one, the clients should not download all
          // rows in most cases.  The client should only download data for the current page.
          // In that case, getToggleAllRowsSelectedProps works fine.
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  const handleChangePage = (event, newPage) => {
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(Number(event.target.value));
  };

  const removeByIndexs = (array, indexs) =>
    array.filter((_, i) => !indexs.includes(i));

  const deleteRow = (event) => {
    // const newData = removeByIndexs(
    //   data,
    //   Object.keys(selectedRowIds).map(x => parseInt(x, 10))
    // )
    // setData(newData)
    let dt = [],
      names = [];
    Object.keys(selectedRowIds).map((idx) => {
      dt.push(data[idx]);
      names.push(data[idx].value);
    });

    if (customConfirmDelete) onDelete(dt);
    else
      confirm({
        description: `Potwierdź usunięcie ${names.join(
          ", "
        )}. Operacji nie można.`,
        confirmationText: "Usuń",
      }).then(() => onDelete(dt));
  };

  const addRow = (user) => {
    const newData = data.concat([user]);
    setData(newData);
  };

  const getSelected = () => {
    let selected = [];
    Object.keys(selectedRowIds).map((idx) => selected.push(data[idx]));
    return selected;
  };

  // Render the UI for your table
  return (
    <TableContainer
      className={rowActions ? "withSelect withAction" : "withSelect"}
    >
      <TableToolbar
        numSelected={Object.keys(selectedRowIds).length}
        selected={getSelected()}
        deleteRow={onDelete ? deleteRow : null}
        addRow={addRow}
        toolbarActions={toolbarActions}
        toolbarButtons={toolbarButtons}
        preGlobalFilteredRows={preGlobalFilteredRows}
        setGlobalFilter={setGlobalFilter}
        globalFilter={globalFilter}
        addRow={onAdd}
        handleShowAll={handleShowAll}
        typename={typename}
      />
      <MaUTable {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup, i) => (
            <TableRow
              {...headerGroup.getHeaderGroupProps()}
              key={"headerRow" + i}
            >
              {headerGroup.headers.map((column, i) => (
                <TableCell
                  {...(column.id === "selection"
                    ? column.getHeaderProps()
                    : column.getHeaderProps(column.getSortByToggleProps()))}
                  style={{ width: column.render("width") }}
                  key={"headerCell" + i}
                >
                  {column.render("Header")}
                  {column.id !== "selection" ? (
                    <TableSortLabel
                      active={column.isSorted}
                      // react-table has a unsorted state which is not treated here
                      direction={column.isSortedDesc ? "desc" : "asc"}
                    />
                  ) : null}
                </TableCell>
              ))}
              {rowActions && <TableCell>&nbsp;</TableCell>}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <TableRow key={"tableRow" + i} {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <TableCell {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </TableCell>
                  );
                })}
                {rowActions && (
                  <TableCell style={{ width: "6%" }}>
                    {rowActions.map((a, j) => {
                      if (!a.conditionVar || data[i][a.conditionVar]) {
                        return (
                          <IconButton
                            key={"b" + j}
                            onClick={() => a.onClick(i)}
                            color="primary"
                            aria-label="szczegoly"
                            component="span"
                          >
                            {a.icon}
                          </IconButton>
                        );
                      } else return null;
                    })}
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>

        {pageSize < data.length && (
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[
                  10,
                  20,
                  { label: "Wszystkie", value: data.length },
                ]}
                colSpan={3}
                // style={{float: 'right'}}
                count={data.length}
                rowsPerPage={pageSize}
                page={pageIndex}
                SelectProps={{
                  inputProps: { label: "rows" },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        )}
      </MaUTable>
    </TableContainer>
  );
};

// EnhancedTable.propTypes = {
//   columns: PropTypes.array.isRequired,
//   data: PropTypes.array.isRequired,
//   updateMyData: PropTypes.func.isRequired,
//   setData: PropTypes.func.isRequired,
//   skipPageReset: PropTypes.bool.isRequired,
// }

export default EnhancedTable;
