import React, { useContext } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useQuery } from "@apollo/client";
import { TEST } from "graphql/queries";
import { Files } from "./components";
import { Work, Findings, ReviewNotes, First, Logs } from "./components";
import { useSnackbar } from "notistack";
import { ErrorPage, Panel, Tabs, Tab, Title } from "elements";
import {
  // BrowserRouter as Router,
  // Switch,
  // Route,
  // Link,
  useParams,
} from "react-router-dom";
import { getName } from "tools";

import AppBar from "@material-ui/core/AppBar";
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    padding: "2%",
  },
  sub: {
    fontSize: 14,
    marginLeft: 10,
  },
}));

function TestDetails({ test, num }) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(num);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Title
          color="test"
          title={
            <div>
              {test.value}
              <span className={classes.sub}>{test.audit.value}</span>
            </div>
          }
        />
        <Tabs value={value} onChange={handleChange} aria-label="" color="test">
          <Tab color="test" label="Szczegóły" {...a11yProps(0)} />
          <Tab
            color="test"
            label="Przeprowadzone procedury"
            {...a11yProps(1)}
          />
          <Tab color="test" label="Pliki" {...a11yProps(2)} />
          <Tab color="test" label={getName("Finding", 1)} {...a11yProps(3)} />
          <Tab
            color="test"
            label={getName("ReviewNote", 1)}
            {...a11yProps(4)}
          />
          <Tab color="audit" label="Log" {...a11yProps(8)} />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0} dir={theme.direction}>
        <Panel title="Status">
          <div className={classes.container}>
            <First test={test} />
          </div>
        </Panel>
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        <Panel title="Przeprowadzone procedury">
          <Work test={test} />
        </Panel>
      </TabPanel>
      <TabPanel value={value} index={2} dir={theme.direction}>
        <Panel title="Pliki testów">
          <Files test={test} />
        </Panel>
      </TabPanel>
      <TabPanel value={value} index={3} dir={theme.direction}>
        <Panel title={getName("Finding", 1)}>
          <Findings test={test} />
        </Panel>
      </TabPanel>
      <TabPanel value={value} index={4} dir={theme.direction}>
        <Panel title={getName("ReviewNote", 1)}>
          <ReviewNotes test={test} />
        </Panel>
      </TabPanel>
      <TabPanel value={value} index={5} dir={theme.direction}>
        <Panel title="Logs">
          <Logs test={test} />
        </Panel>
      </TabPanel>
    </div>
  );
}

export default (props) => {
  let { id, num } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const { data, loading } = useQuery(TEST, {
    variables: { id },
  });

  if (loading) return false;
  if (!data.test) {
    enqueueSnackbar(`${getName("Test")} został usunięty`, { variant: "error" });
    return <ErrorPage />;
  }
  return <TestDetails test={data.test} num={num ? parseInt(num) : 0} />;
};
