import React from "react";
import { useAuthDataContext } from "context/Context";

import { makeStyles } from "@material-ui/styles";

import { useHistory } from "react-router";
import { Logs } from "elements";
const useStyles = makeStyles((theme) => ({
  tab: {
    paddingRight: 45,
  },
}));

export default (props) => {
  return <Logs variables={{ test: props.test.id }} />;
};
