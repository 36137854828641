import React, { useEffect } from "react";
import { Table } from "elements";
import { useMutation } from "@apollo/client";
import { AUDITGROUPS } from "graphql/queries";
import {
  UPDATE_AUDITGROUP,
  DELETE_AUDITGROUP,
  CREATE_AUDITGROUP,
} from "graphql/mutations";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/styles";
import { getName } from "tools";
import { useLocation } from "react-router-dom";
import { useAuthDataContext } from "context/Context";

const useStyles = makeStyles((theme) => ({
  tab: {
    paddingRight: 45,
  },
}));

export default (props) => {
  const {
    user: { permissions },
  } = useAuthDataContext();
  const [updateAuditGroup] = useMutation(UPDATE_AUDITGROUP);
  const [createAuditGroup] = useMutation(CREATE_AUDITGROUP);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteAuditGroup] = useMutation(DELETE_AUDITGROUP);
  let location = useLocation();

  const setData = (_1, _2, _3) => {
    console.log("setData", _1, _2, _3);
  };
  const _updateAuditGroup = async (idx, name, value) => {
    let row = props.data[idx];
    // console.log(idx,name,value, row)
    const refetchQueries = [{ query: AUDITGROUPS }];

    const { data } = await updateAuditGroup({
      variables: { id: row.id, input: { [name]: value } },
      refetchQueries,
    });

    enqueueSnackbar(
      `${getName("AuditGroup")} ${
        data.updateAuditGroup.value
      } został zaktualizowany`,
      { variant: "success" }
    );
  };

  useEffect(() => {
    console.log(1234, location);
  });

  // const skipPageReset = (_1,_2,_3) => {
  //   console.log(skipPageReset, _1,_2,_3)
  // }

  const onDeleteAudits = async (ps) => {
    if (ps.length === 0) return false;

    let snackText = [
      ps.length > 1 ? getName("AuditGroup", 1) : getName("AuditGroup"),
      "",
      ps.length > 1 ? "została usunięta" : "zostały usunięte",
    ];
    let names = [];
    for (let i = ps.length - 1; i > 0; i--) {
      await deleteAuditGroup({ variables: { id: ps[i].id } });
      names.push(ps[i].value);
    }
    await deleteAuditGroup({
      variables: { id: ps[0].id },
      refetchQueries: [{ query: AUDITGROUPS }],
    });
    names.push(ps[0].value);
    snackText[1] = names.join(", ");

    enqueueSnackbar(snackText.join(" "), { variant: "success" });
  };

  const onSubmitAuditForm = async ({ value, weight }) => {
    const { data } = await createAuditGroup({
      variables: { input: { value } },
      refetchQueries: [{ query: AUDITGROUPS }],
    });
    enqueueSnackbar(
      `${getName("AuditGroup")} ${data.createAuditGroup.value} została dodana`,
      { variant: "success" }
    );
  };

  return (
    <Table
      typename="AuditGroup"
      columns={[
        {
          Header: "Nazwa",
          accessor: "value",
          type: permissions.audit.audit_editTags ? "text" : "label",
        },
        {
          Header: getName("Audit", 1),
          accessor: "audits.length",
          type: "label",
        },
      ]}
      setData={setData}
      updateMyData={_updateAuditGroup}
      skipPageReset={true}
      onAdd={permissions?.audit?.audit_editTags ? onSubmitAuditForm : null}
      onDelete={permissions?.audit?.audit_editTags ? onDeleteAudits : null}
      {...props}
    />
  );
};
