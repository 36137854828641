import React from "react";
import { FileList } from "elements";
import { useAuthDataContext } from "context/Context";

export default ({ test }) => {
  const { user } = useAuthDataContext();

  return (
    <div>
      <FileList
        test={test.id}
        disableAdd={
          test.audit.status === 19 ||
          test.status === 1 ||
          user.id !== test.auditor.id
        }
      />
    </div>
  );
};
