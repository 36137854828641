import React from "react";
import { Switch, Redirect } from "react-router-dom";

import { RouteWithLayout } from "./components";
import { Main as MainLayout, Minimal as MinimalLayout } from "./layouts";

import {
  // Dashboard as DashboardView,
  // ProductList as ProductListView,
  // UserList as UserListView,
  // Typography as TypographyView,
  // Icons as IconsView,
  Home as HomeView,
  Account as AccountView,
  Settings as SettingsView,
  SignUp as SignUpView,
  SignIn as SignInView,
  NotFound as NotFoundView,
  Risk as RiskView,
  Risks as RisksView,
  Process as ProcessView,
  Proces as ProcesView,
  Controls as ControlsView,
  Universe as UniverseView,
  // Schedule as ScheduleView,
  Audit as AuditView,
  Audits as AuditsView,
  AuditC as AuditCView,
  AuditsC as AuditsCView,
  Sources as SourcesView,
  TemplateC as TemplateCView,
  Test as TestView,
  TestC as TestCView,
  Finding as FindingView,
  Receivers as ReceiversView,
  Receiver as ReceiverView,
  Team as TeamView,
  Incidents as IncidentsView,
  Findings as FindingsView,
  Tasks as TasksView,
  Task as TaskView,
  Reports as ReportsView,
  UnsignedFindingMessage as UnsignedFindingMessageView,
} from "./views";

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/risks" />
      <RouteWithLayout
        component={HomeView}
        exact
        layout={MainLayout}
        path="/home/:num?"
      />
      <RouteWithLayout
        component={UniverseView}
        exact
        layout={MainLayout}
        path="/universe/:num?"
      />
      <RouteWithLayout
        component={RiskView}
        exact
        layout={MainLayout}
        path="/risk/:num?"
      />
      <RouteWithLayout
        component={RisksView}
        exact
        layout={MainLayout}
        path="/risks/:num?"
      />
      <RouteWithLayout
        component={ProcessView}
        exact
        layout={MainLayout}
        path="/cprcs/:num?"
      />
      <RouteWithLayout
        component={ProcesView}
        exact
        layout={MainLayout}
        path="/cprc/:id/:num?"
      />
      <RouteWithLayout
        component={ControlsView}
        exact
        layout={MainLayout}
        path="/controls/:num?"
      />
      {/* <RouteWithLayout
        component={ScheduleView}
        exact
        layout={MainLayout}
        path="/schedule/:num?"
      /> */}
      <RouteWithLayout
        component={AuditsView}
        exact
        layout={MainLayout}
        path="/audits/:num?"
      />
      <RouteWithLayout
        component={ReceiversView}
        exact
        layout={MainLayout}
        path="/receivers/:num?"
      />
      <RouteWithLayout
        component={ReceiverView}
        exact
        layout={MainLayout}
        path="/receiver/:id/:num?"
      />
      <RouteWithLayout
        component={AuditView}
        exact
        layout={MainLayout}
        path="/audit/:id/:num?"
      />
      <RouteWithLayout
        component={TestView}
        exact
        layout={MainLayout}
        path="/test/:id/:num?"
      />
      <RouteWithLayout
        component={TestCView}
        exact
        layout={MainLayout}
        path="/testc/:id/:num?"
      />
      <RouteWithLayout
        component={FindingView}
        exact
        layout={MainLayout}
        path="/finding/:id/:num?"
      />
      <RouteWithLayout
        component={IncidentsView}
        exact
        layout={MainLayout}
        path="/incidents"
      />
      <RouteWithLayout
        component={FindingsView}
        exact
        layout={MainLayout}
        path="/findings"
      />

      <RouteWithLayout
        component={TeamView}
        exact
        layout={MainLayout}
        path="/team"
      />
      <RouteWithLayout
        component={SourcesView}
        exact
        layout={MainLayout}
        path="/sources/:num?"
      />
      <RouteWithLayout
        component={AuditsCView}
        exact
        layout={MainLayout}
        path="/auditsc/:num?"
      />
      <RouteWithLayout
        component={AuditCView}
        exact
        layout={MainLayout}
        path="/auditc/:id/:num?"
      />
      <RouteWithLayout
        component={TemplateCView}
        exact
        layout={MainLayout}
        path="/templatec/:id/:num?"
      />
      <RouteWithLayout
        component={ReportsView}
        exact
        layout={MainLayout}
        path="/reports/:num?"
      />
      <RouteWithLayout
        component={TasksView}
        exact
        layout={MainLayout}
        path="/tasks/:num?"
      />
      <RouteWithLayout
        component={TaskView}
        exact
        layout={MainLayout}
        path="/task/:id/:num?"
      />

      {/*
      <RouteWithLayout
        component={TypographyView}
        exact
        layout={MainLayout}
        path="/typography"
      />
      <RouteWithLayout
        component={IconsView}
        exact
        layout={MainLayout}
        path="/icons"
      /> 
       <RouteWithLayout
        component={AccountView}
        exact
        layout={MainLayout}
        path="/account"
      /> */}
      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings/:num?"
      />
      <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />

      <RouteWithLayout
        component={UnsignedFindingMessageView}
        exact
        layout={MinimalLayout}
        path="/un/finding/:id?"
      />

      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
