import gql from "graphql-tag";
export const PerspectiveCreated = gql`
  subscription perspectiveCreated {
    perspectiveCreated {
      name
      weight
    }
  }
`;

export const RANK = gql`
  subscription rank {
    rank {
      instanceFeed {
        id
        value
        risk
        prevRisks {
          time
          risk
        }
        updatedAt
        variants {
          value
        }
      }
    }
  }
`;

export const REPORT = gql`
  subscription report {
    report {
      id
      value
    }
  }
`;

export const FINDINGSUB = gql`
  subscription finding {
    finding {
      id
      messages
    }
  }
`;

export const TASKSUB = gql`
  subscription task {
    task {
      id
      messages
    }
  }
`;
