import React from "react";
import { Select } from "elements";
import { useQuery } from "@apollo/client";
import { SOURCES } from "graphql/queries";

export default (props) => {
  const { data, loading } = useQuery(SOURCES);

  if (loading) return false;
  // const setIt=(value)=>{
  //     console.log("aaa", value)
  //     onChange && onChange(value)
  // }

  // console.log("data", data)

  return <Select {...props} data={data.sources.sourceFeed} />;
};
