import React from "react";
import { useAuthDataContext } from "context/Context";
import { Table, Loading } from "elements";
import { useMutation, useQuery } from "@apollo/client";
import { AUDITS, AUDITGROUPS, AUDIT } from "graphql/queries";
import { UPDATE_AUDIT, DELETE_AUDIT, CREATE_AUDIT } from "graphql/mutations";
import { SELECTEDAUDITGROUPS } from "graphql/queries";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/styles";
import { getName } from "tools";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import _ from "lodash";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  tab: {
    // paddingRight: 45,
  },
}));

export default (props) => {
  const [ids, setIds] = React.useState();
  const { user, settings, setSettings } = useAuthDataContext();
  const a = useQuery(SELECTEDAUDITGROUPS, {
    variables: { ids },
  });
  const [updateAudit] = useMutation(UPDATE_AUDIT);
  const [createAudit] = useMutation(CREATE_AUDIT);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteAudit] = useMutation(DELETE_AUDIT);
  const history = useHistory();
  const [audits, setAudits] = React.useState(null);

  React.useEffect(() => {
    let au = null;
    if (a.data) {
      try {
        au = _.flatMap(a.data.auditGroups, (a) => a.audits);
        au = au.filter(
          (aa) => aa.leader.id === user.id || _.find(aa.team, { id: user.id })
        );
      } catch (err) {}
    }
    setAudits(au);
  }, [a.data]);

  const columns = [
    {
      Header: "Nazwa",
      accessor: "value",
      width: "24%",
      type: "label",
    },
    {
      Header: getName("AuditGroup"),
      accessor: "auditGroup.value",
      type: "label",
      width: "16%",
    },
    {
      Header: getName("Instance", 1),
      accessor: "instances",
      type: "info",
      width: "8%",
      inputProps: {
        columns: [
          { name: "Nazwa", value: "value", width: "80%" },
          { name: "Ryzyko", value: "risk", width: "20%" },
        ],
      },
    },
    {
      Header: "Lider",
      accessor: "leader.id",
      type: "user",
      width: "6%",
      inputProps: {
        disabled: true,
      },
    },
    {
      Header: "Zespół",
      accessor: "team",
      type: "team",
      disabled: true,
      width: "20%",
      inputProps: {
        disabled: true,
      },
    },
    {
      Header: "Daty",
      accessor: "dates",
      type: "extension",
      width: "10%",

      inputProps: {
        fields: [
          {
            label: "Rozpoczęcie (planowanie)",
            key: "date1",
            type: "date",
          },
          { label: "Field work", key: "date2", type: "date" },
          { label: "Wrap-up", key: "date3", type: "date" },
          { label: "Zakończenie", key: "date4", type: "date" },
        ],
      },
    },
  ];

  React.useEffect(() => {
    setIds(props.ids);
  }, [props.ids]);

  if (a.loading) return <Loading />;

  const setData = (_1, _2, _3) => {
    console.log("setData", _1, _2, _3);
  };
  const _updateAudit = async (idx, name, value) => {
    let row = audits[idx];
    // console.log(idx,name,value, row)
    const refetchQueries = [
      { query: AUDITS },
      { query: AUDIT, variables: { id: row.id } },
    ];
    name === "auditGroup" && refetchQueries.push({ query: AUDITGROUPS });

    const { data } = await updateAudit({
      variables: { id: row.id, input: { [name]: value } },
      refetchQueries,
    });

    enqueueSnackbar(
      `${getName("Audit")} ${data.updateAudit.value} został zaktualizowany`,
      { variant: "success" }
    );
  };

  // const skipPageReset = (_1,_2,_3) => {
  //   console.log(skipPageReset, _1,_2,_3)
  // }

  const onDeleteAudits = async (ps) => {
    if (ps.length === 0) return false;
    // console.log(settings.tabs, ps)

    let snackText = [
      ps.length > 1 ? getName("Audit", 1) : getName("Audit"),
      "",
      ps.length > 1 ? "zostały usunięte" : "został usunięty",
    ];
    let names = [];

    for (let i = ps.length - 1; i > 0; i--) {
      await deleteAudit({ variables: { id: ps[i].id } });
      names.push(ps[i].value);
    }
    await deleteAudit({
      variables: { id: ps[0].id },
      refetchQueries: [{ query: AUDITS }, { query: SELECTEDAUDITGROUPS }],
    });
    names.push(ps[0].value);
    snackText[1] = names.join(", ");

    let tabs = [...settings.tabs];

    ps.map(
      (p) => (tabs = _.filter(tabs, (o) => o.id !== p.id && o.parent !== p.id))
    );

    setSettings({ tabs });

    enqueueSnackbar(snackText.join(" "), { variant: "success" });
  };

  const onSubmitAuditForm = async (_input) => {
    let input = {};
    Object.keys(_input).map((i) =>
      _input[i].length ? (input[i] = _input[i]) : null
    );
    if (input["days"]) input["days"] = parseInt(input["days"]);
    if (input["instances"]) input["instances"] = input["instances"].split(",");
    const { data } = await createAudit({
      variables: { input },
      refetchQueries: [{ query: AUDITS }, { query: SELECTEDAUDITGROUPS }],
    });
    enqueueSnackbar(
      `${getName("Audit")} ${data.createAudit.value} został dodany`,
      { variant: "success" }
    );
  };
  function goToAudit(i) {
    const audit = audits[i];
    const tabs = settings.tabs ? [...settings.tabs] : [];

    if (_.findIndex(tabs, { id: audit.id }) < 0) {
      setSettings({
        tabs: [
          {
            id: audit.id,
            title:
              audit.value.length > 20
                ? audit.value.slice(0, 20) + "..."
                : audit.value,
            full: audit.value,
            parent: null,
            href: "/audit/" + audit.id,
          },
          ...tabs,
        ],
      });
    }

    history.push("/audit/" + audit.id);
  }

  const handleShowAll = (event, checked) => {
    let au = null;
    au = _.flatMap(a.data.auditGroups, (a) => a.audits);
    if (!checked)
      au = au.filter(
        (aa) => aa.leader.id === user.id || _.find(aa.team, { id: user.id })
      );

    setAudits(au);
  };

  // console.log(audits)

  return (
    audits && (
      <Table
        typename="Audit"
        columns={columns}
        setData={setData}
        updateMyData={_updateAudit}
        skipPageReset={true}
        onAdd={onSubmitAuditForm}
        onDelete={onDeleteAudits}
        handleShowAll={
          user.permissions.audit.audit_viewAll ? handleShowAll : null
        }
        data={audits}
        rowActions={[{ icon: <KeyboardArrowRightIcon />, onClick: goToAudit }]}
        {...props}
      />
    )
  );
};
