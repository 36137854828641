import React, { useState, useRef } from "react";
import { Tree, Table, RightBar } from "elements";
import { useQuery, useMutation } from "@apollo/client";
import { FINDINGS } from "graphql/queries";
import { FILE_URL } from "vars";
import {
  UPDATE_FINDING,
  DELETE_FINDING,
  CREATE_FINDING,
  EXPORT_FINDINGS,
} from "graphql/mutations";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/styles";
import { getName } from "tools";
import { useAuthDataContext } from "context/Context";
import GridOnIcon from "@material-ui/icons/GridOn";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import _ from "lodash";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  tab: {
    paddingRight: 45,
  },
}));

export default (props) => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [updateFinding] = useMutation(UPDATE_FINDING);
  const [createFinding] = useMutation(CREATE_FINDING);
  const [deleteFinding] = useMutation(DELETE_FINDING);
  const [exportFindings] = useMutation(EXPORT_FINDINGS);
  const { systemSettings, settings, setSettings } = useAuthDataContext();
  const history = useHistory();

  let data = [];
  props.data.map((d, i) => {
    data.push({
      ...d,
      link: [d.audit ? d.audit : null, d.test ? d.test : null],
    });
    // data.push({
    //   ...d,
    //   info: [
    //     {
    //       goTo: goToUrl,
    //       type: "audit",
    //       name: d.audit ? d.audit.value : null,
    //       id: d.audit ? d.audit.id : null,
    //     },
    //     {
    //       goTo: goToUrl,
    //       type: "test",
    //       name: d.test ? d.test.value : null,
    //       id: d.test ? d.test.id : null,
    //     },
    //   ],
    // });
  });

  const setData = () => {
    console.log("setData");
  };
  const _updateFinding = async (idx, name, value) => {
    let row = props.data[idx];
    // console.log(idx,name,value, row)
    const refetchQueries = [{ query: FINDINGS }];

    const { data } = await updateFinding({
      variables: { id: row.id, input: { [name]: value } },
      refetchQueries,
    });

    enqueueSnackbar(
      `${getName("Finding")} ${
        data.updateFinding.value
      } została zaktualizowany`,
      { variant: "success" }
    );
  };
  const _createFinding = async (values) => {
    let input = { ...values };
    // input.test=props.test.id
    // input.audit=props.test.audit.id
    if (input.riskSelect) input.riskSelect = parseInt(input.riskSelect);
    // if (input.financialImpactA)
    //   input.financialImpactA = parseInt(input.financialImpactA);
    // if (input.financialImpactP)
    //   input.financialImpactP = parseInt(input.financialImpactP);
    const { data } = await createFinding({
      variables: { input },
      refetchQueries: [{ query: FINDINGS }],
    });
    enqueueSnackbar(
      `${getName("Finding")} ${data.createFinding.value} została dodany`,
      { variant: "success" }
    );
  };
  const _deleteFinding = async (fs) => {
    if (fs.length === 0) return false;
    let snackText = [
      fs.length > 1 ? getName("Finding", 1) : getName("Finding"),
      "",
      fs.length > 1 ? "zostały usunięte" : "została usunięty",
    ];
    let names = [];
    for (let i = fs.length - 1; i > 0; i--) {
      await deleteFinding({ variables: { id: fs[i].id } });
      names.push(fs[i].value);
    }
    await deleteFinding({
      variables: { id: fs[0].id },
      refetchQueries: [{ query: FINDINGS }],
    });
    names.push(fs[0].value);
    snackText[1] = names.join(", ");
    enqueueSnackbar(snackText.join(" "), { variant: "success" });
  };

  const exportToFile = async () => {
    const result = await exportFindings();

    download(result.data.exportFindings);
    enqueueSnackbar(getName("Finding", 1) + " poprawie wyeksportowane ", {
      variant: "success",
    });
  };

  const download = (name) => {
    const link = document.createElement("a");
    // link.targe="_blank"
    link.href = `${FILE_URL}/${name}/findings.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getLabel = (num) => {
    if (
      systemSettings &&
      systemSettings.riskLabel &&
      systemSettings.riskLabel[num]
    )
      return systemSettings.riskLabel[num];
    else return num;
  };

  let columns = [
    // {
    //   Header: "Otw./zamk.",
    //   accessor: "status",
    //   type: "switch",
    //   width: "10%",
    // },
    {
      Header: "Status",
      accessor: "status",
      type: "status",
      inputProps: {
        labels: {
          0: { label: "W trakcie" },
          1: { label: "Prośba o przedłużenie" },
          2: { label: "Prośba odrzucona" },
          4: { label: "Wdrożona" },
          5: { label: "Zamknięty" },
        },
      },
      width: "10%",
    },
    {
      Header: "Data",
      accessor: "date",
      type: "daysTo",
      width: "10%",
    },
    {
      Header: "Nazwa",
      accessor: "value",
      type: "text",
      width: "25%",
      // inputProps: {
      //   subtext: "info",
      // },
    },
    {
      Header: "Link",
      accessor: "link",
      type: "link",
      width: "20%",
    },

    {
      Header: "Os. odpow.",
      accessor: "responsible",
      type: "longText",
      width: "7%",
    },
    {
      Header: "Podsumowanie",
      accessor: "desc",
      type: "longText",
      width: "10%",
    },
    // ,
    // {
    //   Header: "Wn",
    //   accessor: "auditValue",
    //   type: "label",
    //   width: "5%",
    // },
    // {
    //   Header: "Rek.",
    //   accessor: "recomendation",
    //   type: "longText",
    //   width: "7%",
    // },
    // {
    //   Header: "Ryzyko",
    //   accessor: "risk",
    //   type: "longText",
    //   width: "7%",
    // },
    // {
    //   Header: "Os. odp.",
    //   accessor: "responsibleEmail",
    //   type: "longText",
    //   width: "8%",
    // },
    ,
  ];

  function goToFinding(i) {
    const finding = props.data[i];
    const tabs = settings.tabs ? [...settings.tabs] : [];

    if (_.findIndex(tabs, { id: finding.id }) < 0) {
      setSettings({
        tabs: [
          {
            id: finding.id,
            title:
              finding.value.length > 20
                ? finding.value.slice(0, 20) + "..."
                : finding.value,
            full: finding.value,
            parent: null,
            href: "/finding/" + finding.id,
          },
          ...tabs,
        ],
      });
    }

    history.push("/finding/" + finding.id);
  }

  return (
    <Table
      typename="Finding"
      columns={columns}
      setData={setData}
      updateMyData={_updateFinding}
      skipPageReset={true}
      onAdd={_createFinding}
      onDelete={_deleteFinding}
      rowActions={[{ icon: <KeyboardArrowRightIcon />, onClick: goToFinding }]}
      toolbarButtons={[
        {
          label: "Eksportuj do Excel'a",
          icon: <GridOnIcon />,
          onClick: exportToFile,
        },
      ]}
      {...props}
      data={data}
    />
  );
};
