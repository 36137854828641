import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { System, Password, AccountDetails, AccountProfile } from "./components";
import { useParams, useHistory } from "react-router-dom";
import { useAuthDataContext } from "context/Context";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    height: "100%",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    width: "20%",
  },
  tabsContent: {
    width: "80%",
  },
}));

export default function VerticalTabs() {
  const classes = useStyles();
  const history = useHistory();
  let { num } = useParams();
  const [value, setValue] = React.useState(parseInt(num) || 0);
  const { user, settings, setSettings, systemSettings, setSystemSettings } =
    useAuthDataContext();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    window.location.reload();
  };

  let tabs = [
    [
      <Tab label="Profil" {...a11yProps(0)} key={"tab0"} />,
      <TabPanel
        key={"tabC0"}
        className={classes.tabsContent}
        value={value}
        index={0}
      >
        <AccountProfile user={user} settings={settings} />
        <br />
        <AccountDetails user={user} settings={settings} />
      </TabPanel>,
    ],

    [
      <Tab label="Zabezpieczenia i hasło" key={"tab2"} {...a11yProps(2)} />,
      <TabPanel
        key={"tabC2"}
        className={classes.tabsContent}
        value={value}
        index={1}
      >
        <Password />
      </TabPanel>,
    ],
  ];

  let i = 1;

  user?.permissions?.main?.systemSettings_edit &&
    tabs.push([
      <Tab label="Ustawienia systemu" {...a11yProps(++i)} key={"tab" + i} />,
      <TabPanel
        className={classes.tabsContent}
        value={value}
        index={i}
        key={"tabC" + i}
      >
        <System
          systemSettings={systemSettings}
          setSystemSettings={setSystemSettings}
        />
      </TabPanel>,
    ]);

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        className={classes.tabs}
      >
        {tabs.map((t) => t[0])}
        <Tab label="Wyloguj" onClick={handleLogout} key={"tab9"} />
      </Tabs>

      {tabs.map((t) => t[1])}
    </div>
  );
}
