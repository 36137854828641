import React from "react";
import { Table } from "elements";
import { useAuthDataContext } from "context/Context";

export default ({ data }) => {
  const { user } = useAuthDataContext();
  const [outputs, setOutputs] = React.useState(
    JSON.parse(data.outputs || "[]")
  );

  const onUpdate = (_1, _2, _3) => {
    console.log("upd", _1, _2, _3);
  };
  const onAdd = (_1, _2, _3) => {
    console.log("upd", _1, _2, _3);
  };
  const onDelete = (_1, _2, _3) => {
    console.log("upd", _1, _2, _3);
  };

  return (
    <Table
      typename="Outputs"
      columns={[
        {
          Header: "Wyświetlana nazwa",
          accessor: "displayName",
          type: "text",
          type: "label",
        },
        {
          Header: "Wskazówka",
          accessor: "hint",
          type: "info",
        },
        {
          Header: "Nazwa zmiennej",
          accessor: "name",

          type: "label",
        },
        // {
        //   Header: "Typ zmiennej",
        //   accessor: "type",
        //   type: "select",
        //   inputProps: {
        //     data: [
        //       { id: "normalized_double", value:"od -1 do 1"  },
        //       { id: "normalized_unsigned_double", value: "od 0 do 1" },
        //       { id: "double", value: "liczba zmiennoprzecinkowa" },
        //     ],
        //   },

        // },
        {
          Header: "Wartość z ostatniego testu",
          accessor: "example",
          type: "label",
        },
      ]}
      // setData={setData}
      // updateMyData={onUpdate}
      // onAdd={onAdd}
      // onDelete={onDelete}

      // {...props}
      data={outputs}
    />
  );
};
