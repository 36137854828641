import React from "react";
import { AUDITS, AUDIT, REVIEW_NOTES } from "graphql/queries";

import { UPDATE_AUDIT } from "graphql/mutations";
import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "@apollo/client";
import { useAuthDataContext } from "context/Context";
import moment from "moment";
import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Input } from "elements";
import { getName } from "tools";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: 12,
  },
  field: {
    marginBottom: 20,
  },
  list: {
    marginLeft: 30,
  },
  first: {
    "& .MuiInput-underline:before": {
      borderBottom: 0,
    },
  },
}));

export default ({ audit, refetch }) => {
  const { user } = useAuthDataContext();
  const [updateAudit] = useMutation(UPDATE_AUDIT);
  const rn = useQuery(REVIEW_NOTES, {
    variables: { all: true, audits: [audit.id] },
  });
  let rnCount = {
    num: 0,
    all: 0,
  };

  const { enqueueSnackbar } = useSnackbar();
  const { systemSettings } = useAuthDataContext();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [, refresh] = React.useState(false);
  const [scale, setScale] = React.useState(undefined);
  const [checklist, setChecklist] = React.useState(
    audit.checklist ? [...JSON.parse(audit.checklist)] : [false, false, false]
  );

  const handleClose = () => {
    setOpen(false);
  };

  if (rn.loading) return false;
  else {
    rn.data &&
      rn.data.reviewNotes.map((r) => {
        if (!r.status) {
          rnCount.num += 1;
        }
        rnCount.all += 1;
      });
  }

  const changeStatus = () => {
    const refetchQueries = [
      { query: AUDITS },
      { query: AUDIT, variables: { id: audit.id } },
    ];

    updateAudit({
      variables: { id: audit.id, input: { status: 5 } },
      refetchQueries,
    });

    // enqueueSnackbar(`Audyt został rozpoczęty`, { variant: 'success' });
  };
  if (audit.status === 0) changeStatus();

  let riskChange = [];
  if (audit.riskChange) riskChange = JSON.parse(audit.riskChange);

  const fields = [
    { label: "Rozpoczęcie (planowanie)", key: "date1", type: "date" },
    { label: "Field work", key: "date2", type: "date" },
    { label: "Wrap-up", key: "date3", type: "date" },
    { label: "Zakończenie", key: "date4", type: "date" },
  ];

  const onChange = (name) => async (value) => {
    if (audit.status === 19) {
      enqueueSnackbar(
        `${getName("Audit")} jest zamknięty i nie może być edytowany.`,
        { variant: "error" }
      );
      return false;
    }

    const refetchQueries = [
      { query: AUDITS },
      { query: AUDIT, variables: { id: audit.id } },
    ];

    const na = await updateAudit({
      variables: { id: audit.id, input: { [name]: value } },
      refetchQueries,
    });

    enqueueSnackbar(
      `${getName("Audit")} ${na.data.updateAudit.value} został zaktualizowany`,
      { variant: "success" }
    );
  };
  const onChangeDate = (key) => async (value) => {
    var d = moment(parseInt(value));
    d = d.startOf("day");

    let _dates = { ...dates, [key]: d.valueOf() };
    await onChange("dates")(JSON.stringify(_dates));
  };
  const dates = JSON.parse(audit.dates);
  const getDate = (key) => {
    let r = undefined;
    try {
      r = dates[key];
    } catch (error) {}

    return r;
  };

  const onCloseAudit = async (value) => {
    if (checklist.indexOf(false) >= 0) {
      enqueueSnackbar(
        `${getName(
          "Audit"
        )} nie został zamknięty. Checklista nie została uzupełniona.`,
        { variant: "error" }
      );
      // audit.status = 9;
      // refresh();
      return false;
    }
    if (rnCount.num > 0) {
      enqueueSnackbar(
        `${getName("Audit")} nie został zamknięty. Otwarte ${getName(
          "ReviewNote",
          1
        )}.`,
        { variant: "error" }
      );
      return false;
    }

    if (value) {
      setOpen(true);
    } else {
      const refetchQueries = [
        { query: AUDITS },
        { query: AUDIT, variables: { id: audit.id } },
      ];

      const na = await updateAudit({
        variables: { id: audit.id, input: { status: 9 } },
        refetchQueries,
      });
      refetch()

      enqueueSnackbar(
        `${getName("Audit")} ${
          na.data.updateAudit.value
        } został ponownie otwarty`,
        { variant: "success" }
      );
    }
  };

  const onChangeScale = (value) => setScale(value);

  const closeAudit = async () => {
    const refetchQueries = [
      { query: AUDITS },
      { query: AUDIT, variables: { id: audit.id } },
    ];

    const na = await updateAudit({
      variables: { id: audit.id, input: { status: 19, scale } },
      refetchQueries,
    });
    handleClose();
    refetch()
    enqueueSnackbar(
      `${getName("Audit")} ${na.data.updateAudit.value} został zamknięty`,
      { variant: "success" }
    );
  };

  const handleChangeChecklist = (num) => async ({ target }) => {
    let cl = [...checklist];
    cl[num] = target.checked;
    await onChange("checklist")(JSON.stringify(cl));
    refetch()
    setChecklist(cl);
  };

  return (
    <Grid container className={classes.first}>
      {/* { audit.status===0 && <Button onClick={changeStatus}>Rozpocznij audyt</Button> } */}
      <Grid xs={5}>
        <div className={classes.label}>Lider audytu</div>
        <div className={classes.field}>
          <Input
            type="user"
            inputProps={{
              onChange: onChange("leader"),
              value: audit.leader.id,
              withName: true,
              disabled: audit.status === 19 || (audit.status !== 19 && (audit.leader.id !== user.id && !user.permissions?.audit?.audit_editAll))
    
            }}
          />
        </div>
        <div className={classes.label}>Zespół audytowy</div>
        <div className={classes.field}>
          <Input
            type="team"
            inputProps={{
              onChange: onChange("team"),
              value: audit.team,
              disabled: audit.status === 19 || (audit.status !== 19 && (audit.leader.id !== user.id && !user.permissions?.audit?.audit_editAll)),
            }}
          />
        </div>

        <div className={classes.label}>Etykieta audytowa</div>
        <div className={classes.field}>
          <Input
            type="auditGroup"
            inputProps={{
              onChange: onChange("auditGroup"),
              disabled: audit.status === 19 || (audit.status !== 19 && (audit.leader.id !== user.id && !user.permissions?.audit?.audit_editAll)),

              value: audit.auditGroup.id,
            }}
          />
        </div>
        <div className={classes.label}>Daty audytu</div>
        <div className={classes.field}>
          <br />
          {fields.map((field, i) => (
            <Input
              inputProps={{
                label: field.label,
                onChange: onChangeDate(field.key),
                disabled: audit.status === 19 || (audit.status !== 19 && (audit.leader.id !== user.id && !user.permissions?.audit?.audit_editAll)),

                value: getDate(field.key),
                minDate: i > 0 ? getDate(fields[i - 1].key) : undefined,
              }}
              type={field.type}
            />
          ))}
        </div>
      </Grid>
      <Grid xs={7}>
        <div className={classes.label}>Audytowane zbiory</div>
        {/* <ul className={classes.list}>
          {audit.instances.map((i) => (
            <li>{i ? i.value : <i>zbiór został usunięty</i>}</li>
          ))}
        </ul> */}
        <Input
          type="instances"
          inputProps={{
            onChange: onChange("instances"),
            disabled: audit.status === 19 || (audit.status !== 19 && (audit.leader.id !== user.id && !user.permissions?.audit?.audit_editAll)),

            value: audit.instances,
          }}
        />
        <br />
        <FormControl component="fieldset" style={{ width: "100%" }}>
          <FormLabel component="legend">Statystyki</FormLabel>
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>Otwarte Review Notes:</Grid>
            <Grid item>
              {" "}
              <Input type="count" inputProps={{ value: rnCount }} />
            </Grid>
          </Grid>
        </FormControl>
        <br />
        <br />
        <FormControl component="fieldset" style={{ width: "100%" }}>
          <FormLabel component="legend">Lista kontrolna</FormLabel>
          <FormGroup aria-label="position">
            <FormControlLabel
              control={
                <Checkbox
                  checked={checklist[0]}
                  onChange={handleChangeChecklist(0)}
                  disabled={audit.status === 19 || (audit.status !== 19 && (audit.leader.id !== user.id && !user.permissions?.audit?.audit_editAll))}
                  color="primary"
                />
              }
              label="Wszystkie testy zrealizowane"
              labelPlacement="end"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={checklist[1]}
                  onChange={handleChangeChecklist(1)}
                  disabled={audit.status === 19 || (audit.status !== 19 && (audit.leader.id !== user.id && !user.permissions?.audit?.audit_editAll))}
                  color="primary"
                />
              }
              label="Wszystkie noty zamknięte"
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={checklist[2]}
                  onChange={handleChangeChecklist(2)}
                  disabled={audit.status === 19 || (audit.status !== 19 && (audit.leader.id !== user.id && !user.permissions?.audit?.audit_editAll))}
                  color="primary"
                />
              }
              label="Raport wygenerowny"
              labelPlacement="end"
            />
          </FormGroup>
        </FormControl>
        <br />
        <br />
        <FormControl component="fieldset" style={{ width: "100%" }}>
          <FormLabel component="legend">Status audytu</FormLabel>
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>Otwarty</Grid>
            <Grid item>
              <Input
                type="switch"
                inputProps={{
                  onChange: onCloseAudit,
                  value: audit.status === 19,
                  disabled:
                    checklist.indexOf(false) >= 0 || !getDate('date1') || !getDate('date2') || !getDate('date3') || !getDate('date4') || 
                    // rnCount.num > 0 || flieds
                    // audit.leader.id !== user.id ||
                    (audit.status === 19 && !user.permissions?.audit?.audit_reopen),
                  
                }}
              />
            </Grid>
            <Grid item>Zamknięty</Grid>
          </Grid>
          {audit.status === 19 && (
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item>Ocena:</Grid>
              <Grid item>
                {" "}
                <Input
                  type="label"
                  inputProps={{ value: audit.scale, label: "" }}
                />
              </Grid>
            </Grid>
          )}
        </FormControl>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Zamkanie audytu</DialogTitle>
          <DialogContent>
            <DialogContentText>Ocena z audytu</DialogContentText>

            <Input
              type="simpleSelect"
              inputProps={{
                fullWidth: true,
                onChange: onChangeScale,
                data: systemSettings.auditScale,
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Anuluj
            </Button>
            <Button onClick={closeAudit} color="primary" disabled={!scale}>
              Zamknij audyt
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  );
};
