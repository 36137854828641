import React from "react";
import { Table, Loading, Form } from "elements";
import { useAuthDataContext } from "context/Context";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { isEmpty } from "underscore";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { useForm } from "react-hook-form";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { MESSAGES, RECEIVERS } from "graphql/queries";
import { useMutation, useQuery } from "@apollo/client";
// import { useSnackbar } from "notistack";
import { UPDATE_MESSAGE, DELETE_MESSAGE } from "graphql/mutations";
import { useSnackbar } from "notistack";
import { getName } from "tools";
import PeopleIcon from "@material-ui/icons/People";
import { map, filter } from "lodash";

const MessagesFroReceiver = ({ data, receiver, receivers, refetch }) => {
  const { user } = useAuthDataContext();

  const [updateMessage] = useMutation(UPDATE_MESSAGE);
  const [deleteMessage] = useMutation(DELETE_MESSAGE);
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState();
  const selected = React.useRef(null);
  const form = useForm();

  let data2 = [];
  data.map((d, i) => {
    // console.log(d.action.testc)
    data2.push({
      ...d,
      link: [d.action.testc ? d.action.testc : null],
    });
    // data.push({
    //   ...d,
    //   info: [
    //     {
    //       goTo: goToUrl,
    //       type: "audit",
    //       name: d.audit ? d.audit.value : null,
    //       id: d.audit ? d.audit.id : null,
    //     },
    //     {
    //       goTo: goToUrl,
    //       type: "test",
    //       name: d.test ? d.test.value : null,
    //       id: d.test ? d.test.id : null,
    //     },
    //   ],
    // });
  });

  const onDelete = async (ps) => {
    if (ps.length === 0) return false;

    let snackText = [
      ps.length > 1 ? getName("Message", 1) : getName("Message"),
      "",
      ps.length > 1 ? "zostało usunięte" : "zostały usunięte",
    ];
    let names = [];
    for (let i = ps.length - 1; i > 0; i--) {
      await deleteMessage({ variables: { id: ps[i].id } });
      names.push(ps[i].value);
    }
    await deleteMessage({
      variables: { id: ps[0].id },
      refetchQueries: [{ query: MESSAGES, variables: { receiver } }],
    });
    refetch();
    enqueueSnackbar(snackText.join(" "), { variant: "success" });
  };

  const onUpdate = async (idx, name, value) => {
    let row = data[idx];

    await updateMessage({
      variables: { id: row.id, input: { [name]: value } },
      refetchQueries: [{ query: MESSAGES, variables: { receiver } }],
    });

    enqueueSnackbar(`${getName("Message")} zostało zaktualizowane`, {
      variant: "success",
    });
  };

  const passIt = async (_1, _2) => {
    selected.current = map(_1, "id");
    setOpen(true);
  };

  const passMessage = async (id, r) => {
    updateMessage({
      variables: { id, input: { receiver: r } },
      refetchQueries: [{ query: MESSAGES, variables: { receiver: r } }],
    });
  };

  const onSubmit = async () => {
    const values = form.getValues();
    console.log(values, selected.current);

    const unresolvedPromises = selected.current.map((id) =>
      passMessage(id, values.receiver)
    );
    const results = await Promise.all(unresolvedPromises);
    refetch();

    setOpen(false);
  };
  // console.log("D:",data2)

  return (
    <>
      <Table
        typename="Messages"
        columns={[
          {
            Header: "Działanie",
            accessor: "action.value",
            type: "label",
            width: "30%",
          },
          {
            Header: "Link",
            accessor: "link",
            type: "link",
            width: "10%",
          },
          // {
          //   Header: "Ryzyka",
          //   accessor: "action.testc.risks",
          //   type: "info",
          //   width: "10%",
          //   inputProps: {
          //     all: 100,
          //     columns: [{ name: "Nazwa", value: "value", width: "100%" }],
          //   },
          // },
        ]}
        toolbarActions={[
          {
            label: "Przekaż",
            icon: <PeopleIcon />,
            onClick: passIt,
          },
        ]}
        data={data2}
        updateMyData={onUpdate}
        // onDelete={onDelete}
      />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle id="form-dialog-title"></DialogTitle>
        <DialogContent style={{ width: 600 }}>
          <form>
            <Form
              fields={[
                {
                  label: "Adresat",
                  type: "select",
                  fullWidth: true,
                  name: "receiver",
                  data: receivers,
                },
              ]}
              form={form}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Anuluj
          </Button>
          <Button onClick={onSubmit} color="primary">
            Przekaz
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default (props) => {
  // let { num } = useParams();
  const a = useQuery(MESSAGES, {
    fetchPolicy: "no-cache",
    variables: { receiver: props.receiver },
  });
  const r = useQuery(RECEIVERS);

  if (a.loading || r.loading) return <Loading />;

  return (
    <MessagesFroReceiver
      receivers={filter(
        r.data.receivers.receiverFeed,
        (n) => n.id !== props.receiver
      )}
      refetch={a.refetch}
      data={a.data.messages.messageFeed}
      receiver={props.receiver}
    />
  );

  // return <ContinuousAudit refetch={refetch} data={data.audit} num={num ? parseInt(num) : 0} />;
};
