import React, { useState } from "react";
import { Tree, Table, RightBar } from "elements";
import { useQuery, useMutation, useSubscription } from "@apollo/client";
import { MultiSelect } from "elements";
import { VARIANTS, PERSPECTIVES, INSTANCES } from "graphql/queries";
import {
  UPDATE_VARIANT,
  DELETE_VARIANT,
  CREATE_VARIANT,
  CREATE_INSTANCE,
} from "graphql/mutations";
import { useSnackbar } from "notistack";
import { VariantDetails, VariantEdit } from "../../Risk/components";
import { isEmpty } from "underscore";
import { makeStyles } from "@material-ui/styles";
import { useForm } from "react-hook-form";
import { getName } from "tools";
import { useHistory } from "react-router";
import BarChartIcon from "@material-ui/icons/BarChart";
import { useAuthDataContext } from "context/Context";

const useStyles = makeStyles((theme) => ({
  tab: {
    paddingRight: 45,
  },
}));

export default (props) => {
  const [updateVariant] = useMutation(UPDATE_VARIANT);
  const [createVariant] = useMutation(CREATE_VARIANT);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteVariant] = useMutation(DELETE_VARIANT);
  const [createInstance] = useMutation(CREATE_INSTANCE);
  const { systemSettings } = useAuthDataContext();

  const history = useHistory();

  const setData = (_1, _2, _3) => {
    console.log("setData", _1, _2, _3);
  };
  const _updateVariant = async (idx, name, value) => {
    let row = props.data[idx];
    // console.log(idx,name,value, row)
    const refetchQueries = [{ query: VARIANTS }, { query: INSTANCES }];
    name === "perspective" && refetchQueries.push({ query: PERSPECTIVES });

    const { data } = await updateVariant({
      variables: { id: row.id, input: { [name]: value } },
      refetchQueries,
    });

    enqueueSnackbar(
      `${getName("Variant")} ${data.updateVariant.value} został zaktualizowany`,
      { variant: "success" }
    );
  };

  // const skipPageReset = (_1,_2,_3) => {
  //   console.log(skipPageReset, _1,_2,_3)
  // }

  const onDeleteVariants = async (ps) => {
    if (ps.length === 0) return false;

    let snackText = [
      ps.length > 1 ? getName("Variant", 1) : getName("Variant"),
      "",
      ps.length > 1 ? "zostały usunięte" : "został usunięty",
    ];
    let names = [];
    for (let i = ps.length - 1; i > 0; i--) {
      await deleteVariant({ variables: { id: ps[i].id } });
      names.push(ps[i].value);
    }
    await deleteVariant({
      variables: { id: ps[0].id },
      refetchQueries: [{ query: VARIANTS }],
    });
    names.push(ps[0].value);
    snackText[1] = names.join(", ");

    enqueueSnackbar(snackText.join(" "), { variant: "success" });
    // history.go('/universe')
    props.reload(Math.random() * 100);
  };

  const onSubmitVariantForm = async ({ value, perspective, risk }) => {
    const { data } = await createVariant({
      variables: { input: { value, perspective, risk: parseInt(risk) } },
      refetchQueries: [{ query: VARIANTS }, { query: PERSPECTIVES }],
    });
    enqueueSnackbar(
      `${getName("Variant")} ${data.createVariant.value} została dodany`,
      { variant: "success" }
    );
    // history.go('/universe')
    props.reload(Math.random() * 100);
  };

  const addInstance = () => {
    console.log("addInstance");
  };

  const onSubmitInstanceForm = async ({ value, variants }, typename) => {
    const { data } = await createInstance({
      variables: { input: { value, variants: variants.split(",") } },
      refetchQueries: [{ query: INSTANCES }],
    });
    enqueueSnackbar(
      `${getName("Instance")} ${data.createInstance.value} została dodana`,
      { variant: "success" }
    );
  };

  let labels = {};
  if (systemSettings.riskLabel)
    systemSettings.riskLabel.map((r, i) => (labels[i * 2] = r));
  else labels = { 0: "0", 2: "2", 4: "4", 6: "6", 8: "8", 10: "10" };

  return (
    <Table
      typename="Variant"
      columns={[
        {
          Header: "Nazwa",
          accessor: "value",
          type: "text",
          width: "34%",
        },
        {
          Header: getName("Perspective"),
          accessor: "perspective.id",
          type: "perspective",
          // inputProps:{
          //   data: props.perspectives
          // }
          width: "20%",
        },
        // {
        //   Header: 'Ryzyko',
        //   accessor: 'risk',
        //   type: 'select',
        //   options: [
        //     {id:0, value:0},
        //     {id:2, value:2},
        //     {id:4, value:4},
        //     {id:6, value:6},
        //     {id:8, value:8},
        //     {id:10, value:10}
        //   ]
        // },
        {
          Header: "Ryzyko",
          accessor: "risk",
          type: "slider",
          inputProps: {
            min: 0,
            max: 10,
            step: 2,
            labels,
          },
          width: "30%",
        },
      ]}
      setData={setData}
      updateMyData={_updateVariant}
      skipPageReset={false}
      onAdd={onSubmitVariantForm}
      onDelete={onDeleteVariants}
      rowActions={[{ icon: <BarChartIcon />, onClick: props.showDetails }]}
      // toolbarActions={[
      //   { label: getName("Instance",2), typename: 'Instance', width: "25%" , onSubmit: onSubmitInstanceForm }
      // ]}
      {...props}
    />
  );
};
