import React, { useState } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  Chip,
  Divider,
  Grid,
  Button,
  Fab,
  // TextField
} from "@material-ui/core";
import { Text, Color, Input } from "elements";
import AddIcon from "@material-ui/icons/Add";
import { useMutation } from "@apollo/client";
import { UPDATE_USER } from "graphql/mutations";
import { USER } from "graphql/queries";
import { useSnackbar } from "notistack";
import moment from "moment";
import _ from "lodash";

const useStyles = makeStyles(() => ({
  root: {},
}));

const Day = ({ label, onDelete }) => {
  return (
    <Chip
      label={label}
      onDelete={onDelete}
      style={{ marginRight: 2, marginBottom: 4 }}
    />
  );
};

const Holidays = ({ user, settings }) => {
  const { register, handleSubmit, getValues } = useForm();
  let holidays = React.useRef(user.holidays ? user.holidays.split(",") : []);
  let values = React.useRef({});
  const [updateUser] = useMutation(UPDATE_USER);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const classes = useStyles();

  const onChange = (name) => (value) => {
    values.current[name] = value / 1000;
  };

  const handleDelete = (chipToDelete) => async () => {
    holidays.current = holidays.current.filter((chip) => chip !== chipToDelete);

    const { data } = await updateUser({
      variables: {
        input: {
          holidays: holidays.current.join(","),
        },
      },
      refetchQueries: [{ query: USER }],
    });
    enqueueSnackbar(`Twoje dane zostały zaktualizowane`, {
      variant: "success",
    });
  };

  const removeOld = (a) => {
    const nw = moment().unix() - 31536000;
    console.log(nw, 1111);
    return a.filter((aa) => aa > nw);
  };

  const insertInto = (v, a) => {
    let day = moment.unix(v);
    if (day.isoWeekday() > 5) return a;
    day.set({ h: 23, m: 59 });
    day = "" + day.unix();
    // console.log(day);
    if (a.indexOf(day) >= 0) return a;
    a.splice(_.sortedIndex(a, day), 0, day);

    return a;
  };

  const onSubmit = async () => {
    // console.log(values);
    // return 0;

    let days = holidays.current || [],
      end = 0 + values.current.start;

    if (
      values.current.end &&
      values.current.start &&
      values.current.start <= values.current.end
    ) {
      while (values.current.end > end) {
        days = insertInto(end, days);
        end += 86400;
      }
    }
    days = insertInto(values.current.end, days);

    holidays.current = removeOld(days);

    const { data } = await updateUser({
      variables: { input: { holidays: holidays.current.join(",") } },
      refetchQueries: [{ query: USER }],
    });
    enqueueSnackbar(`Twoje dane zostały zaktualizowane`, {
      variant: "success",
    });
  };

  const clear = async () => {
    const { data } = await updateUser({
      variables: { input: { holidays: [].join(",") } },
      refetchQueries: [{ query: USER }],
    });
  };

  return (
    <>
      <Card className={classes.root}>
        <CardHeader
          subheader="Tutaj możesz dodać swoje urlopy"
          title="Twoje urlopy"
        />
        <Divider />
        <CardContent>
          {user.holidays &&
            user.holidays
              .split(",")
              .map(
                (a) =>
                  a && (
                    <Day
                      key={a}
                      label={moment
                        .unix(a)
                        .format(settings.dateFormat || "YYYY-MM-DD")}
                      onDelete={handleDelete(a)}
                    />
                  )
              )}
        </CardContent>
        <Divider />
      </Card>
      <br />
      <Card className={classes.root}>
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)} noValidate>
          <CardHeader
            subheader="Tutaj możesz dodać swoje urlopy"
            title="Nowy urlop"
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={3} xs={5}>
                <Input
                  type="date"
                  inputProps={{
                    disablePast: true,
                    label: "Data rozpoczęcia",
                    onChange: onChange("start"),
                  }}
                />
              </Grid>
              <Grid item md={3} xs={5}>
                <Input
                  type="date"
                  register={register}
                  inputProps={{
                    disablePast: true,
                    label: "Data zakończenia",
                    onChange: onChange("end"),
                  }}
                />
              </Grid>

              <Grid item md={6} xs={2}>
                <Button
                  type="submit"
                  color="primary"
                  aria-label="dodaj"
                  className="fab"
                >
                  <AddIcon />
                </Button>
                <Button onClick={clear}>
                  <AddIcon />
                </Button>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
        </form>
      </Card>
    </>
  );
};

export default Holidays;
