import React from "react";
import { useQuery } from "@apollo/client";
import { FINDING, TASK } from "graphql/queries";
import { ErrorPage } from "elements";
import { PublicFinding, PublicTask } from 'views'

export default ({type, id, tab}) => {
  // const path = window.location.pathname.split("/");
  // let id = path[3];
  // if (!id || id.length !== 24) window.location.assign("/universe");
  let o = null;

  if(type==='findings'){
    o = useQuery(FINDING, {variables: { id }});
  }else if(type==='task'){
    o = useQuery(TASK, {variables: { id }});
  }

  if (o.loading) return false;
  if (!o.data) {
    return <ErrorPage />;
  }

 
  const getIt = () => {
    if(type==='findings') return <PublicFinding refetch={o.refetch} data={o.data} tab={tab || 0}/>
    else if(type==='task') return <PublicTask refetch={o.refetch} data={o.data} tab={tab || 0} />
  }

  return (
    <div>{ getIt() }</div>
  );
};
