import React, { useState, useRef } from "react";
import { Tree, Table, RightBar } from "elements";
import { useQuery, useMutation } from "@apollo/client";
import { CONTROLS } from "graphql/queries";
import {
  UPDATE_CONTROL,
  DELETE_CONTROL,
  CREATE_CONTROL,
  UPDATE_PROCES,
} from "graphql/mutations";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/styles";
import { getName } from "tools";
import { useAuthDataContext } from "context/Context";
import GridOnIcon from "@material-ui/icons/GridOn";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import _ from "lodash";
import { useHistory } from "react-router";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { map } from "lodash";
import { filter } from "underscore";

const useStyles = makeStyles((theme) => ({
  tab: {
    paddingRight: 45,
  },
}));

function ControlTemplate(props) {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [updateControl] = useMutation(UPDATE_CONTROL);
  const [createControl] = useMutation(CREATE_CONTROL);
  const [deleteControl] = useMutation(DELETE_CONTROL);
  const [updateProces] = useMutation(UPDATE_PROCES);
  const { systemSettings, settings, setSettings } = useAuthDataContext();
  const history = useHistory();

  const setData = () => {
    console.log("setData");
  };

  const addControl = () => {
    // console.log(data);
    props.setRightbarData({
      __typename: "Control",
      controls: props.data,
      id: props.proces,
    });
  };

  const updateControlsInProces = async (controls) => {
    await updateProces({
      variables: { id: props.proces, input: { controls } },
      refetchQueries: [{ query: CONTROLS }],
    });

    enqueueSnackbar(`${getName("Proces")} zostało zaktualizowane`, {
      variant: "success",
    });
    props.refetch();
  };

  const onAdd = async ({ value, description }) => {
    const r = await createControl({
      variables: {
        input: { value, description },
      },
    });

    await updateControlsInProces([
      ...map(props.data, "id"),
      r.data.createControl.id,
    ]);
  };

  const _updateControl = async (idx, name, value) => {
    let row = props.data[idx];
    // console.log(idx,name,value, row)
    const refetchQueries = [
      { query: CONTROLS, variables: { audits: [props.audit.id] } },
    ];

    const { data } = await updateControl({
      variables: { id: row.id, input: { [name]: value } },
      refetchQueries,
    });

    enqueueSnackbar(
      `${getName("Control")} ${data.updateControl.value} został zaktualizowany`,
      { variant: "success" }
    );
  };

  const _deleteControl = async (fs) => {
    if (fs.length === 0) return false;

    console.log(fs);
    // let snackText = [
    //   fs.length > 1 ? getName("Control", 1) : getName("Control"),
    //   "",
    //   fs.length > 1 ? "zostały usunięte" : "został usunięty",
    // ];
    // let names = [];
    // for (let i = fs.length - 1; i > 0; i--) {
    //   await deleteControl({ variables: { id: fs[i].id } });
    //   // names.push(fs[i].value);
    // }
    // await deleteControl({
    //   variables: { id: fs[0].id },
    //   refetchQueries: [
    //     { query: CONTROLS, variables: { audits: [props.audit.id] } },
    //   ],
    // });
    // names.push(fs[0].value);
    // snackText[1] = names.join(", ");
    // enqueueSnackbar(snackText.join(" "), { variant: "success" });

    const ids = map(fs, "id");
    let _c = filter(props.data, (c) => !ids.includes(c.id));
    console.log(_c);
    await updateControlsInProces([...map(_c, "id")]);
  };

  let columns = [
    {
      Header: "Nazwa",
      accessor: "value",
      type: "label",
      width: "26%",
      // inputProps: {
      //   subtext: "auditValue",
      // },
    },
    {
      Header: "Opis",
      accessor: "description",
      type: "info",
      width: "20%",
    },
  ];

  return (
    <Table
      typename="Control"
      columns={columns}
      setData={setData}
      updateMyData={_updateControl}
      skipPageReset={true}
      onDelete={_deleteControl}
      onAdd={onAdd}
      toolbarButtons={[
        {
          label: "Istniejąca kontrola",
          icon: <AddBoxIcon />,
          onClick: addControl,
        },
      ]}
      {...props}
    />
  );
}

export default ({ data, refetch, setRightbarData }) => {
  return (
    <div>
      <ControlTemplate
        data={data.controls}
        proces={data.id}
        refetch={refetch}
        setRightbarData={setRightbarData}
      />
    </div>
  );
};
