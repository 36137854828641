import React, { useContext } from "react";
import moment from "moment";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useQuery, useSubscription } from "@apollo/client";
import { FINDING } from "graphql/queries";
import { FINDINGSUB } from "graphql/subscriptions";
import Button from "@material-ui/core/Button";
import { First, Logs, Files } from "../Finding/components";
import { useSnackbar } from "notistack";
import {
  // BrowserRouter as Router,
  // Switch,
  // Route,
  Link,
  useParams,
} from "react-router-dom";

import { getName } from "tools";
import { ErrorPage, Panel, Title, Tabs, Tab } from "elements";
import AppBar from "@material-ui/core/AppBar";
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    padding: 0,
  },

  container: {
    padding: "0% 2%",
  },

  container2: {
    padding: "2%",
  },
}));

function FindingDetails({ data, num }) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(num);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label=""
          color="finding"
        >
          <Tab color="finding" label="Informacje" {...a11yProps(0)} />
          <Tab color="finding" label="Pliki" {...a11yProps(1)} />
          <Tab color="finding" label="Log" {...a11yProps(2)} />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0} dir={theme.direction}>
        <First finding={data} />
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        <Files finding={data.id} />
      </TabPanel>
      <TabPanel value={value} index={2} dir={theme.direction}>
        <Panel title="Logs">
          <Logs finding={data} />
        </Panel>
      </TabPanel>
    </div>
  );
}

export default (props) => {
  let { id, num } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const { data, loading, refetch } = useQuery(FINDING, {
    variables: { id },
  });

  const onSubscriptionData = ({ subscriptionData }) => {
    // enqueueSnackbar("Finding zaktualizowany", { variant: "success" });

    // console.log("SUB", subscriptionData);
    refetch();
  };

  useSubscription(FINDINGSUB, {
    onSubscriptionData,
  });

  if (loading) return false;
  if (!data.finding) {
    enqueueSnackbar(`${getName("Finding")} została usunięty`, {
      variant: "error",
    });
    return <ErrorPage />;
  }

  return <FindingDetails data={data.finding} num={num ? parseInt(num) : 0} />;
};
