import React from "react";
import { Table } from "elements";
import { useSnackbar } from "notistack";
import Grid from "@material-ui/core/Grid";
import { useMutation } from "@apollo/client";
import { UPDATE_TESTCSOURCE } from "graphql/mutations";
import { useForm } from "react-hook-form";
import {map, filter} from 'lodash'

export default ({showDetails, testc, refetch}) => {
  const [updateTestcSource] = useMutation(  UPDATE_TESTCSOURCE );
  const { enqueueSnackbar } = useSnackbar();
  const sourcesInfo = JSON.parse(testc.template.sources || "[]")


  const handleUpdate = async(idx, fieldName, fieldValue) => {

    console.log(testc.sources[idx].id, {input: { [fieldName]: fieldValue }})

    await updateTestcSource({   variables: { id: testc.sources[idx].id, input: { [fieldName]: fieldValue }} })
      refetch()
    enqueueSnackbar(
      `Żródło zostało zaktualizowane`,
      { variant: "success" }
    );
  }

  // const addNew = () => {
  //     setOpen(true)
  // }

  // const onDelete = async (selected) => {
  //   let s = map(selected, 'id'),
  //     u = filter(testc.sources, (ts)=>s.indexOf(ts.id)<0)

  //   await onUpdate(testc.id, { sources: map(u, 'id') } )
  //   handleClose()
  // }

  // const handleClose = () => {
  //     setOpen(false)
  // }

  const getHint = (source) => {
    return <div >
    {/* <div>Nazwa: {source.name}</div> */}
    <div>{source.displayName}</div>
   
    <div style={{ fontSize: '11px', lineHeight: '13px' }}>{source.hint}</div>
    <br/>
  </div>
  }

  return (
    <Grid container> 
      <Grid item md={9}> 
          <Table
            typename="TestC"
            columns={[{
                Header: "Wyświetlana nazwa",
                accessor: "value",
                type: "label",
                width: "22%",
              }
              // ,{
              //   Header: "Nazwa",
              //   accessor: "name",
              //   type: "label",
              //   width: "18%",
              // }
              // ,{
              //   Header: "Typ",
              //   accessor: "type",
              //   type: "select",
              //   inputProps: {
              //     data: [
              //       { id: "file", value: <span><InsertDriveFileIcon fontSize="small"/> Plik </span> },
              //       { id: "link", value: <span><LinkIcon fontSize="small"/> Scieka sieciowa </span> },
              //     ],
              //   },
              // }
              ,{
                Header: "Kolumna daty",
                accessor: "timeColumn",
                type: "text",
                width: "12%",
              }
              ,{
                Header: "Kolumny wartości",
                accessor: "column",
                type: "text",
                width: "18%",
              }
              ,{
                Header: "Źródło",
                accessor: "source.id",
                type: "source",
                width: "18%",
              }
            ]}
            updateMyData={handleUpdate}
            // onAdd={()=>showDetails()}
            // onDelete={onDelete}
            // rowActions={[{ icon: <CachedIcon />, onClick: ()=>{}} , { icon: <MoreHorizIcon />, onClick: showDetails }]}
            toolbarButtons={[
                // {
                //   label: "Nowe  źródło danych",
                //   icon: <AddCircleIcon />,
                //   onClick: showDetails,
                // },
                // {
                //   label: "Zapisane wyniki",
                //   icon: <BlurCircularIcon />,
                //   onClick: props.showPast,
                // },
                // {
                //   label: "Dodaj źródło danych",
                //   icon: <AddCircleOutlineIcon />,
                //   onClick: addNew,
                // },
              ]}
          
            // {...props}
            data={testc.sources}
          />

         
        </Grid>
        <Grid item  md={2} style={{ borderLeft: '1px solid #eee', height: '100vh', padding: 10 }}> 
                <h4> Wymagane źródła danych </h4><br/>
                { sourcesInfo.map(getHint) }
        </Grid>
     
     
    
      </Grid>

  );
};


// <Dialog open={open} onClose={handleClose}>
// <DialogTitle id="form-dialog-title">
// Zdefiniuj źródło danych dla testu
// </DialogTitle>
// <DialogContent style={{ width: 600 }}>
//   <form>
// <Form fields={[ 
// {
//   label: 'Źródło',
//   type: 'select',
//   fullWidth: true,
//   name: 'source',
//   data: data.sources.sourceFeed
// }
// ]} form={form} />

// </form>
//   {/* <Input type="select" inputProps={{
//       fullWidth: true,
//       data: data.sources.sourceFeed
//   }}/> */}
// </DialogContent>
// <DialogActions>
  
//   <Button onClick={handleClose} color="primary">
//     Anuluj
//   </Button>
//   <Button
//     onClick={form.handleSubmit(handleUpdate)} 
//     type="submit" color="primary"
//   >
//     Dodaj
//   </Button>
// </DialogActions>

// </Dialog>