import React from "react";
import { TASKS, TASK } from "graphql/queries";

import { UPDATE_TASK } from "graphql/mutations";
import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "@apollo/client";
import { useAuthDataContext } from "context/Context";
import moment from "moment";
import { Button, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Input, Panel } from "elements";
import { getName } from "tools";
// import ExpansionPanel from "@material-ui/core/ExpansionPanel";
// import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
// import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
// import Typography from "@material-ui/core/Typography";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import Truncate from "react-truncate";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import text from "elements/inputs/text";

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: 12,
  },
  field: {
    marginBottom: 20,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    flexBasis: "66.66%",
  },
  list: {
    marginLeft: 30,
  },
  rightGrid: {
    // borderLeft: "1px solid #cdcdcd",
  },
  tabContainer: {
    width: "90%",
    position: "relative",
  },
  first: {
    padding: "2%",
    "& .MuiInput-underline:before": {
      borderBottom: 0,
    },
  },
  actionButtons: {
    textAlign: "right",
    width: "95%",
    marginTop: -20,
  },
  alert: {
    backgroundColor: "#d32f2f",
    color: "white",

    width: "100%",
    fontWeight: "bold",
    fontSize: 18,
    textAlign: "center",
    padding: 10,
    fontFamily: "heading !important",
    borderRadius: 5,
  },
}));

export default ({ task, refetch }) => {
  const [updateTask] = useMutation(UPDATE_TASK);
  const [f, setF] = React.useState({ ...task });
  const [status, setStatus] = React.useState(task.status);
  const saveIt = React.useRef({});
  const timeout = React.useRef(false);
  const [expanded, setExpanded] = React.useState(false);
  const { systemSettings, user } = useAuthDataContext();
  const [open2, setOpen2] = React.useState(false);

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [checklist, setChecklist] = React.useState(
    task.checklist ? [...task.checklist] : [false, false, false, false]
  );

  const onCloseTest = async (value) => {
    const refetchQueries = [
      { query: TASKS },
      { query: TASK, variables: { id: task.id } },
    ];

    const na = await updateTask({
      variables: { id: task.id, input: { status: value ? 1 : 0 } },
      refetchQueries,
    });

    enqueueSnackbar(
      `${getName("Task")} ${na.data.updateTask.value} został ${
        value ? "zamknięty" : "otwarty"
      } `,
      { variant: "success" }
    );
  };
  const onChange = (name) => (value) => {
    saveIt.current[name] = value;
  };

  const onChangeTimeout = async () => {
    const refetchQueries = [
      { query: TASKS },
      { query: TASK, variables: { id: task.id } },
    ];

    const na = await updateTask({
      variables: { id: task.id, input: saveIt.current },
      refetchQueries,
    });

    setF({ ...na.data.updateTask });
    // setStatus(na.data.updateTask.status);
    saveIt.current = {};
    timeout.current = null;

    enqueueSnackbar(
      `${getName("Task")} ${na.data.updateTask.value} został zaktualizowany`,
      { variant: "success" }
    );
  };

  // taskstatus
  // 0 otwarte
  // 1 w realizacji
  // 1 ----
  // 2 ----
  // 4 przekazane do weryfikacji
  // 5 zakończone

  async function changeStatus(num) {
    saveIt.current.status = num;
    if (num === 2 || num === 0) {
      saveIt.current.description = "";
      saveIt.current.solution = "";
      saveIt.current.when = "";
      saveIt.current.status = 0;
      setStatus(0);
    } else {
      setStatus(num);
    }
    await onChangeTimeout();
    refetch();
  }

  const actionButtons = [
    <Button variant="contained" color="primary" onClick={() => setOpen2(true)}>
      Oznacz jako wdrożony
    </Button>,
    <Button variant="contained" color="primary" onClick={() => changeStatus(2)}>
      Odrzuć
    </Button>,
    <Button variant="contained" color="primary" onClick={() => changeStatus(5)}>
      Zaakceptuj
    </Button>,
    <Button variant="contained" color="primary" onClick={() => changeStatus(0)}>
      Otwórz ponownie
    </Button>,
    <Button variant="contained" color="primary" onClick={() => changeStatus(1)}>
      Rozpocznij wdrożenie
    </Button>,
  ];

  const statusName = [
    "Otwarte",
    "W realizacji",
    "",
    "",
    "Przekazane do weryfikacji",
    "Zakończone",
  ];

  const actionButtonsSet = {
    0: <div className={classes.actionButtons}>{actionButtons[4]}</div>,
    1: <div className={classes.actionButtons}>{actionButtons[0]}</div>,
    4: (
      <div className={classes.actionButtons}>
        {actionButtons[1]}&nbsp;{actionButtons[2]}
      </div>
    ),

    5: <div className={classes.actionButtons}>{actionButtons[3]}</div>,
  };

  return (
    <Panel
      subheader={actionButtonsSet[status]}
      title={<div className="header">Status: {statusName[status]}</div>}
    >
      <Grid container className={classes.first}>
        {f.action && (
          <Typography variant="h3" className={classes.alert}>
            {f.action.value}
          </Typography>
        )}

        <Grid md={6} item>
          <FormControl
            component="fieldset"
            style={{ width: "100%" }}
          ></FormControl>
          <br />

          <FormControl component="fieldset" style={{ width: "100%" }}>
            <FormLabel component="legend">Dodany</FormLabel>
            <div className={classes.field}>
              {moment(f.createdAt).format("YYYY-MM-DD")}
            </div>
          </FormControl>

          <FormControl component="fieldset" style={{ width: "100%" }}>
            <FormLabel component="legend">Ostatnio aktualizowany</FormLabel>
            <div className={classes.field}>
              {moment.duration(moment().diff(f.updatedAt)).humanize()}
            </div>
          </FormControl>

          <FormControl component="fieldset" style={{ width: "100%" }}>
            {f.testc && (
              <>
                <div className={classes.label}>Test ciągły - nazwa </div>
                <div className={classes.value}>{f.testc.value}</div>
                <br />
                <div className={classes.label}>Test ciągły - opis</div>
                <div className={classes.value}>{f.testc.value}</div>
              </>
            )}
          </FormControl>

          <FormControl component="fieldset" style={{ width: "100%" }}>
            {f.action && (
              <>
                <br />

                <div className={classes.label}>Odbiorcy</div>
                <ul className={classes.value}>
                  {f.action.messages &&
                    f.action.messages.map((r) => (
                      <li style={{ marginLeft: 30 }}>
                        {r.receiver.value}: {r.value}
                      </li>
                    ))}
                </ul>
                <br />
              </>
            )}
          </FormControl>

          {f.description && (
            <FormControl component="fieldset" style={{ width: "100%" }}>
              <FormLabel component="legend">Co zostało zrobione.</FormLabel>
              <div className={classes.field}>{f.description}</div>
            </FormControl>
          )}
          {f.solution && (
            <FormControl component="fieldset" style={{ width: "100%" }}>
              <FormLabel component="legend">
                Jaki jest przewidywany efekt działania?
              </FormLabel>
              <div className={classes.field}>{f.solution}</div>
            </FormControl>
          )}
          {f.when && (
            <FormControl component="fieldset" style={{ width: "100%" }}>
              <FormLabel component="legend">
                Kiedy będzie widoczny efekt działania?
              </FormLabel>
              <div className={classes.field}>{f.when}</div>
            </FormControl>
          )}
        </Grid>
        <Grid md={6} item>
          <FormControl
            component="fieldset"
            style={{
              width: "100%",
              marginTop: 50,
              borderLeft: "1px solid",
              padding: 10,
              marginLeft: 10,
            }}
          >
            {f.testc && (
              <>
                <div className={classes.label}>Ryzyka</div>
                <ul className={classes.value}>
                  {f.testc.risks.map((r) => (
                    <li style={{ marginLeft: 30 }}>
                      {r.value}
                      <ul>
                        {r.process &&
                          r.process.map((p) => (
                            <li style={{ marginLeft: 40 }}>
                              {p.value}
                              <ul>
                                {p.controls &&
                                  p.controls.map((c) => (
                                    <li style={{ marginLeft: 40 }}>
                                      {c.value}
                                    </li>
                                  ))}
                              </ul>
                            </li>
                          ))}
                      </ul>
                    </li>
                  ))}
                </ul>
                <br />
              </>
            )}
          </FormControl>
        </Grid>
      </Grid>
      <Dialog
        open={open2}
        onClose={handleClose2}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Oznacz jako wdrożoną</DialogTitle>
        <DialogContent>
          <DialogContentText>Co zostało zrobione.</DialogContentText>
          <Input
            inputProps={{
              onChange: onChange("description"),
            }}
            type="text"
          />
          <DialogContentText>
            Jaki jest przewidywany efekt działania.
          </DialogContentText>
          <Input
            inputProps={{
              onChange: onChange("solution"),
            }}
            type="text"
          />
          <DialogContentText>
            Kiedy będzie widoczny efekt działania.
          </DialogContentText>
          <Input
            inputProps={{
              onChange: onChange("when"),
            }}
            type="text"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2} color="primary">
            Anuluj
          </Button>
          <Button
            onClick={async () => {
              setOpen2(false);
              await changeStatus(5);
            }}
            color="primary"
          >
            Wyślij
          </Button>
        </DialogActions>
      </Dialog>
    </Panel>
  );
};
