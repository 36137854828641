import React from "react";

import {
  XAxis,
  YAxis,
  CartesianGrid,
  LineChart,
  Line,
  Tooltip,
} from "recharts";
import moment from "moment";

export default (props) => {
  return (
    <LineChart width={props.width} height={props.height} data={props.data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="time" />
      <YAxis />
      <Tooltip />
      <Line
        type="monotone"
        dataKey={props.dataKey}
        stroke="#8884d8"
        activeDot={{ r: 0 }}
        dot={false}
      />
    </LineChart>
  );
};
