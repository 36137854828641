import React, { createContext, useState, useEffect, useRef, useContext } from "react";
import { useQuery, useMutation } from '@apollo/client';
import { USER } from 'graphql/queries'
import { UPDATE_USER } from 'graphql/mutations'

export const AuthDataContext = createContext(null);
const initialAuthData = {};

const AuthDataProvider = props => {
  const [authData, setAuthData] = useState(initialAuthData);
  const userSettings = useRef([]);
  const sysSettings = useRef([]);
  const user = useQuery(USER)
  const [updateUser] = useMutation(UPDATE_USER);
 
  useEffect(() => {
    if (user.data) {
      const {settings, systemSettings, ...rest} = user.data.user
      setAuthData({user: {...rest, permissions: JSON.parse(rest.permissions || "{}")} });
      userSettings.current = JSON.parse(settings)
      sysSettings.current = JSON.parse(systemSettings)
    }
  }, [user.loading]);

  const updateUserSettings = async (newSetting) => {
    userSettings.current = {...userSettings.current, ...newSetting}
    await updateUser({ variables: { input: { settings: JSON.stringify(userSettings.current) } } })
  }

  const updateSystemSettings = async (newSetting) => {
    sysSettings.current = {...sysSettings.current, ...newSetting}
    await updateUser({ variables: { input: { systemSettings: JSON.stringify(sysSettings.current) } } })
  }

  const onLogout = () => setAuthData(initialAuthData);
  const onLogin = newAuthData => setAuthData(newAuthData);

  if( user.loading ) return false

  return <AuthDataContext.Provider value={{ 
      ...authData, 
      onLogin, 
      onLogout, 
      settings: {auditGroupFilter: [], tabs:[], ...userSettings.current},
      systemSettings: sysSettings.current,
      setSettings: updateUserSettings,
      setSystemSettings: updateSystemSettings
     }} {...props} />;
};

export const useAuthDataContext = () => useContext(AuthDataContext);
export default AuthDataProvider;