import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {Text} from 'elements'
import { useForm } from "react-hook-form";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { useMutation} from '@apollo/client';
import { UPDATE_USER } from 'graphql/mutations'
import {
  Card,
  CardHeader,
  CardContent,
  Fab,
  Divider,
  Button,
  TextField
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { useSnackbar } from 'notistack';
import * as yup from 'yup'; // you will have to install yup

const useStyles = makeStyles(() => ({
  root: {}
}));


const Password = props => {
  const { register, handleSubmit, setError,clearError, errors, reset } = useForm({
    validationSchema: yup.object().shape({
        oldPassword: yup.string().required(),
        password: yup.string()
          .required() 
          .min(4)
          .matches(/[a-z+A-Z+]/, 'Hasło powinno zawierać litery i liczby'),
        confirm: yup.string().required(),
     })
  });
  const [save, setSave] = React.useState(false)
  const [updateUser] = useMutation(UPDATE_USER);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const classes = useStyles();

  const values = React.useRef({
    password: '',
    confirm: ''
  });


  const handleChange = event => {
    values.current = ({
      ...values.current,
      [event.target.name]: event.target.value
    });

    if(values.current.confirm && values.current.password !== values.current.confirm){
      setError("confirm", "notMatch", "hasła nie są jednakowe");
      setSave(false)
    }else{
      clearError(["confirm"]); 
      setSave(true)
    } 
  };

  const onSubmit = async(input) => {
    const { password, oldPassword } = input
    try{
      const {data} = await updateUser({ variables: { input: { password, oldPassword } } })
      enqueueSnackbar(`Twoje hasło zostało zaktualizowane`, { variant: 'success' });
    }catch(err){
      enqueueSnackbar(`Podałeś błędne hasło`, { variant: 'error' });
      
    }
    
    reset()
    // "password" : "$2a$12$nWXMCFJGUdAHEj.w7andxeZaJ6CN962RVzgO7KVnDG5NtZ9oEaaFG",

    setSave(false)
  }

  return (
    <Card
      className={classes.root}
    > 
      <CardHeader
          subheader="Tutaj możesz zmienić swoje hasło"
          title="Hasło"
        />
        <Divider />
        <CardContent>
        <form
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
          <Text 
            label="Stare hasło"
            name="oldPassword"
            register={register}
            type="password"
            onChange={handleChange}
            error={errors.oldPassword}
            />
            <br/><br/><br/>
          <Text 
            label="Nowe hasło"
            name="password"
            register={register}
            type="password"
            onChange={handleChange}
            error={errors.password}
            />
          <Text 
            label="Potwierdź hasło"
            name="confirm"
            register={register}
            style={{ marginTop: '1rem' }}
            type="password"
            onChange={handleChange}
            error={errors.confirm}/>
            {
              save && <Fab type='submit' color="primary" aria-label="zapisz" className="fab">
                    <SaveIcon />
                </Fab>
            }
        </form>
        <br/><br/>
        </CardContent>
        <Divider />
        
    </Card>
  );
};

Password.propTypes = {
  className: PropTypes.string
};

export default Password;
