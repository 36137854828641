import React, { useContext } from "react";
import moment from "moment";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useQuery, useMutation } from "@apollo/client";
import { CONTROLS } from "graphql/queries";
import {
  UPDATE_CONTROL,
  DELETE_CONTROL,
  CREATE_CONTROL,
} from "graphql/mutations";
import { Loading } from "elements";

import { useSnackbar } from "notistack";
import { useLocation, useParams } from "react-router-dom";
import { getName } from "tools";
import { Panel, RightBar, Table, ActionsList } from "elements";

// import { DrawActions } from "elements/inputs/drawselect/drawactions";
import AppBar from "@material-ui/core/AppBar";
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    padding: 0,
  },

  container: {
    padding: "2%",
  },
}));

function Controls(props) {
  let { data } = props;
  let data2 = JSON.parse(JSON.stringify(data));

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [rightbarData, setRightbarData] = React.useState(false);
  const actionsRef = React.useRef(false);
  const actionsLevels = React.useRef(false);

  const [updateControl] = useMutation(UPDATE_CONTROL);
  const [createControl] = useMutation(CREATE_CONTROL);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [deleteControl] = useMutation(DELETE_CONTROL);

  let location = useLocation();
  React.useEffect(() => {
    try {
      setValue(
        parseInt(location.pathname.match(/\/\d+\/?$/)[0].replace(/\//, ""))
      );
    } catch (err) {
      setValue(0);
    }
  }, [location]);

  // React.useEffect(() => {
  //   data2.map(d=>{
  //     d.onClick = onDrawSelectClick
  //     if(d.actionsLevels) d.actionsLevels=JSON.parse(d.actionsLevels)
  //     else d.actionsLevels = {}
  //   })

  // });

  const handleChange = (_, newValue) => {
    let p = window.location.pathname.replace(/\/\d+\/?$/, "") + "/" + newValue;
    window.history.pushState({}, null, p);
    // setPath(p);
    setValue(newValue);
  };

  // const handleChangeIndex = (index) => {
  //   setValue(index);
  // };

  // const showDetails = (idx) => {
  //   console.log({idx, ...data.alerts[idx]})
  //   setRightbarData({__typename : "Data"});
  // };

  const onDrawSelectClick = (obj) => {
    setRightbarData({
      __typename: "Action",
      value: obj.value,
      actions: obj.actions,
      actionsLevels: obj.actionsLevels,
      id: obj.id,
    });
  };

  const onAdd = async ({ value, description }) => {
    const { data } = await createControl({
      variables: {
        input: { value, description },
      },
      refetchQueries: [{ query: CONTROLS }],
    });
    enqueueSnackbar(`Kontrola została dodana`, { variant: "success" });
    props.refetch();
  };

  const onDelete = async (ps) => {
    if (ps.length === 0) return false;

    let snackText = [
      ps.length > 1 ? getName("Control", 1) : getName("Control"),
      "",
      ps.length > 1 ? "zostały usunięte" : "została usunięta",
    ];
    let names = [];
    for (let i = ps.length - 1; i > 0; i--) {
      await deleteControl({ variables: { id: ps[i].id } });
      names.push(ps[i].value);
    }
    await deleteControl({
      variables: { id: ps[0].id },
      refetchQueries: [{ query: CONTROLS }],
    });

    enqueueSnackbar(snackText.join(" "), { variant: "success" });
    props.refetch();
  };

  const onUpdate = async (idx, name, value) => {
    let row = props.data[idx];
    // console.log(idx,name,value, row)
    const refetchQueries = [{ query: CONTROLS }];

    const { data } = await updateControl({
      variables: { id: row.id, input: { [name]: value } },
      refetchQueries,
    });

    enqueueSnackbar(`${getName("Control")} została zaktualizowana`, {
      variant: "success",
    });
    props.refetch();
  };

  const onUpdateActions = async (d, value) => {
    actionsRef.current = { id: d.id, input: { actions: value } };
  };

  const onUpdateActionsLevels = async (d, id, value) => {
    d.actionsLevels = { ...d.actionsLevels, [id]: value };
    actionsLevels.current = {
      id: d.id,
      input: { actionsLevels: JSON.stringify(d.actionsLevels) },
    };
  };

  const closeDrawer = async () => {
    if (actionsLevels.current !== false) {
      const { data } = await updateControl({
        variables: actionsLevels.current,
        refetchQueries: [{ query: CONTROLS }],
      });
    }

    if (actionsRef.current !== false) {
      const { data } = await updateControl({
        variables: actionsRef.current,
        refetchQueries: [{ query: CONTROLS }],
      });
    }

    if (actionsRef.current !== false || actionsLevels.current !== false) {
      enqueueSnackbar(`${getName("Control")} została zaktualizowana`, {
        variant: "success",
      });
      actionsRef.current = false;
      actionsLevels.current = false;
    }

    setRightbarData(false);
  };

  return (
    <div className={classes.root}>
      <TabPanel value={value} index={0} dir={theme.direction}>
        <Panel subheader={"Lista kontroli"} title={"Kontrole"}>
          <Table
            typename="Control"
            columns={[
              {
                Header: "Nazwa",
                accessor: "value",
                type: "text",
                width: "40%",
              },
              {
                Header: "Opis",
                accessor: "description",
                type: "longText",
                width: "15%",
              },
              {
                Header: getName("Proces", 1),
                accessor: "process",
                type: "info",
                width: "10%",
                inputProps: {
                  all: 0,
                  columns: [{ name: "Nazwa", value: "value", width: "100%" }],
                },
              },
            ]}
            // toolbarButtons={[
            //   {
            //     label: "Nowe",
            //     icon: <AddCircleIcon />,
            //     onClick: onAdd,

            //   }
            // ]}

            // setData={setData}
            updateMyData={onUpdate}
            // skipPageReset={true}
            onAdd={onAdd}
            onDelete={onDelete}
            // rowActions={[{ icon: <MoreHorizIcon />, onClick: ()=>setRightbarData({__typename: "Data"}) }]}

            // {...props}
            data={data2}
          />
        </Panel>
      </TabPanel>

      {/* <TabPanel value={value} index={1} dir={theme.direction}>
        <Panel>
         
          <Outer showDetails={ showData} />
            
          
        </Panel>
      </TabPanel> */}

      <RightBar open={rightbarData !== false} onClose={closeDrawer}>
        {rightbarData.__typename === "Action" && (
          <ActionsList
            data={rightbarData}
            onUpdate={onUpdateActions}
            onUpdateLevels={onUpdateActionsLevels}
          />
        )}
      </RightBar>
    </div>
  );
}

export default (props) => {
  let { num } = useParams();

  const r = useQuery(CONTROLS, {
    fetchPolicy: "no-cache",
  });

  // const { data, loading} = useQuery(CONTROL, {
  //   variables: { id: '60dd8e5e4a049059af66d474' },
  //   fetchPolicy: "no-cache"
  // });

  if (r.loading) return <Loading />;

  return (
    <Controls
      num={num}
      data={r.data.controls.controlFeed}
      refetch={r.refetch}
    />
  );

  // return <ContinuousTest refetch={refetch} data={data.audit} num={num ? parseInt(num) : 0} />;
};
