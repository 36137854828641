/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { List, ListItem, Button, colors, Divider } from "@material-ui/core";
// import CloseIcon from '@material-ui/icons/Close';
// import HighlightOffSharpIcon from '@material-ui/icons/HighlightOffSharp';
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 10,
  },
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
    textOverflow: "ellipsis",
    overflow: "hidden",
  },

  buttonMain: {
    color: colors.blueGrey[800],
    padding: "4px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontWeight: "bold !important",
    fontFamily: "heading !important",

    "& span": {
      textTransform: "uppercase !important",
    },
  },

  button: {
    color: colors.blueGrey[800],
    padding: "4px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",

    marginRight: theme.spacing(1),
  },
  iconClose: {
    color: theme.palette.icon,
    width: 20,
    height: 20,
    display: "flex",
    alignItems: "center",
    // marginRight: theme.spacing(1),
    cursor: "pointer",
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    "& $icon": {
      color: theme.palette.primary.main,
    },
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const SidebarNav = (props) => {
  const { pages, tabs, className, onTabClose, ...rest } = props;

  const classes = useStyles();

  return (
    <List {...rest} className={clsx(classes.root, className)}>
      {pages.map((page, idx) => (
        <ListItem
          className={classes.item}
          disableGutters
          key={page.title + "-" + idx}
        >
          <Button
            activeClassName={classes.active}
            className={classes.buttonMain}
            component={CustomRouterLink}
            to={page.href}
          >
            {/* <div className={classes.icon}>{page.icon}</div> */}
            {page.title}
          </Button>
        </ListItem>
      ))}
      {tabs.length > 0 && <Divider className={classes.divider} />}
      {tabs.map((page, idx) => (
        <ListItem
          className={classes.item}
          disableGutters
          key={page.title + "-" + idx}
          // activeClassName={classes.active}
        >
          <Button
            className={classes.button}
            component={CustomRouterLink}
            to={page.href}
            title={page.full}
          >
            {page.title}
          </Button>
          <div onClick={() => onTabClose(idx)} className={classes.iconClose}>
            <CloseRoundedIcon style={{ fontSize: 10 }} />
          </div>
        </ListItem>
      ))}
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired,
  onTabClose: PropTypes.func,
};

export default SidebarNav;
