import React from 'react'
import { useQuery, useMutation } from '@apollo/client';
import { AUDITPLANS } from 'graphql/queries'
import { UPDATE_AUDITPLAN, CREATE_AUDITPLAN } from 'graphql/mutations'
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Grid, Button, ButtonGroup, IconButton } from '@material-ui/core';
import SortableTree,  { addNodeUnderParent, removeNodeAtPath, changeNodeAtPath } from 'react-sortable-tree';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';
import 'react-sortable-tree/style.css'; 
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { v1 as uuidv1 } from 'uuid';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import { useSnackbar } from 'notistack';
import { getName } from 'tools'


const useStyles = makeStyles({
    root: {
        width: "100%", height: window.innerHeight, margin: 20,
        '& .MuiInputBase-inputMarginDense':{
            paddingBottom: 2
        },
        '& .rstcustom__node .rstcustom__toolbarButton':{
            display: 'none'
        },
        '& .rstcustom__node:hover .rstcustom__toolbarButton':{
            display: 'inline-block'
        }

    },
    left:{
        borderRight: '1px solid #e0e0e0'
    },
    right: {
        padding: '0px 10px'
    },
    buttons: {
        // display: 'none'
    }
});

function AuditPlanPanel({auditPlan, addTest}) {

    const classes = useStyles();
    const [treeData, setTreeData] = React.useState(JSON.parse(auditPlan.tree))
   
    const { enqueueSnackbar } = useSnackbar();

    const getNodeKey = ({ treeIndex }) => treeIndex;
    // const getRandomName = () => Math.random().toString(36).substring(7);
   
    const addToTest = (node) => {
        addTest(node)
    }

    const addMultiToTest = (node) => {
        
        node.children.map((n)=>n.isTest? addToTest(n): n.chlidren && n.children.length && addMultiToTest(n))
    }
    
    
    const generateNodeProps = ({node, path}) => {

        let buttons = node.isTest ? [<IconButton 
                title="Dodaj test" onClick={()=>addToTest(node)}
                aria-label="dodaj" className={classes.margin} size="small">
                <AddIcon fontSize="inherit" />
            </IconButton>] :[<IconButton 
                title="Dodaj wszystkie testy z katalogu" onClick={()=>addMultiToTest(node)}
                aria-label="dodaj" className={classes.margin} size="small">
                <CreateNewFolderIcon fontSize="inherit" />
            </IconButton>] 
        
       
      
        return {
            title: ( <div onClick={ ()=> node.isTest ? addToTest(node): addMultiToTest(node) }>{node.value}</div> ),
            buttons
        }
    }

    
    
   
    return (
        <div className={classes.root}>

            <SortableTree
                treeData={treeData}
                theme={FileExplorerTheme}
                canDrag={false}
                canNodeHaveChildren={node => !node.isTest}
                generateNodeProps={generateNodeProps}
            />
        </div>
    )
}

export default ({addTest}) => {
    const {data, loading} = useQuery(AUDITPLANS)
    const { enqueueSnackbar } = useSnackbar();

    if(loading) return null
    else if(data && data.auditPlans.length===0){
        enqueueSnackbar(`${getName("AuditPlan")} nie został jeszcze dodany`, { variant: 'error' });

        return <div> Brak planu audytów<br/> <Button>Dodaj plan</Button> </div>
    }

    return  <AuditPlanPanel auditPlan={data.auditPlans[0]} addTest={addTest}/>
}
