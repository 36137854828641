import React from "react";
import DrawList from "./drawlist";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery, useMutation } from "@apollo/client";
import { CONTROLS } from "graphql/queries";
import { Loading } from "elements";
import { map } from "lodash";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1, 1, 0, 0),
  },
}));

export default ({ data, onUpdate }) => {
  // const [value, setValue] = React.useState("1");
  const m = React.useRef({});

  const a = useQuery(CONTROLS);
  if (a.loading) return <Loading />;

  const onChange = (values) => {
    onUpdate(values, data.id);
  };

  let selected = [];
  map(data.controls, (m) => selected.push(m.id));

  return (
    <DrawList
      data={a.data.controls.controlFeed}
      selected={selected}
      onChange={onChange}
    />
  );
};
