import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Fab, Divider, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { green, pink } from "@material-ui/core/colors";
import SaveIcon from "@material-ui/icons/Save";
import { Text, Rich, FileButton } from "elements";
import NewRow from "elements/table/newRow";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_FINDING } from "graphql/mutations";

import { FINDINGS, FINDING } from "graphql/queries";
import { useSnackbar } from "notistack";
import { getName } from "tools";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { useAuthDataContext } from "context/Context";
import { Messages } from "components/SharedComponents";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    position: "relative",
    width: "60%",
    // padding: 10,
  },
}));

export default (props) => {
  const [updateFinding] = useMutation(UPDATE_FINDING);
  const { register, handleSubmit, watch, errors } = useForm();
  const [messages, setMessages] = useState(
    props.messages ? JSON.parse(props.messages) : []
  );
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { user } = useAuthDataContext();

  React.useEffect(() => {
    setMessages(props.messages ? JSON.parse(props.messages) : []);
    // console.log("In", JSON.parse(props.messages));
  }, [props.messages]);

  const onSubmit = async (input) => {
    const refetchQueries = [
      { query: FINDINGS },
      { query: FINDING, variables: { id: props.id } },
    ];

    const na = await updateFinding({
      variables: {
        id: props.id,
        input,
      },
      refetchQueries,
    });

    enqueueSnackbar(`Wiadomość została wysłana`, { variant: "success" });
  };
  console.log("Rerender");
  return (
    <div className={classes.root}>
      <Messages messages={messages} onSend={onSubmit} />
    </div>
  );
};
