import React from "react";

import { RightBar, MultiSelect, Panel, Loading } from "elements";
import { useQuery } from "@apollo/client";
import { INCIDENTS } from "graphql/queries";
import { useSnackbar } from "notistack";
import { IncidentList, IncidentDetails } from "./components";
import { isEmpty } from "underscore";
import { makeStyles } from "@material-ui/styles";
import { getName } from "tools";
import _ from "lodash";
import {
  Card,
  CardHeader,
  CardContent,
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  Divider,
} from "@material-ui/core";
import {
  // BrowserRouter as Router,
  // Switch,
  // Route,
  // Link,
  useParams,
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default () => {
  let { num } = useParams();
  const [pids, setPids] = React.useState([]);
  const i = useQuery(INCIDENTS);

  const classes = useStyles();
  const [value, setValue] = React.useState(parseInt(num) || 0);
  const [, reload] = React.useState(0);
  const [rightbarData, setRightbarData] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getRightBar = () => {};

  if (i.loading) return <Loading />;
  // else variants.current=v.data.variants.variantFeed

  const showIncidentDetails = (idx) => {
    setRightbarData(i.data.incidents.incidentsFeed[idx]);
  };

  return (
    <>
      <AppBar position="static" color="transparent">
        <Tabs value={value} onChange={handleChange} aria-label="">
          <Tab label={getName("Incident", 1)} {...a11yProps(0)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} className={classes.tab}>
        <Panel
          subheader={"Lista incydentów zgłoszonych przez sygnalistów"}
          title={getName("Incident", 1)}
        >
          <Divider />
          <IncidentList
            showDetails={showIncidentDetails}
            reload={reload}
            data={i.data.incidents.incidentFeed}
          />
          <br />
        </Panel>
      </TabPanel>

      <RightBar open={rightbarData} onClose={() => setRightbarData(false)}>
        {rightbarData.__typename === "Incident" && (
          <IncidentDetails id={rightbarData.id} />
        )}
      </RightBar>
    </>
  );
};

{
  /* <button onClick={onClick}> Dodaj zakaldke </button> */
}
