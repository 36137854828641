import React from "react";
import Switch from "@material-ui/core/Switch";

export default (props) => {
  const [value, setValue] = React.useState(Boolean(props.value) || false);

  let disabled = props.disabled || false;
  if (
    props.ownerOnly &&
    props.data.owner &&
    props.data.owner.id !== props.owner
  ) {
    disabled = true;
  }

  const handleChange = (event) => {
    setValue(event.target.checked);
    props.onChange && props.onChange(event.target.checked ? 1 : 0);
  };

  React.useEffect(() => {
    setValue(Boolean(props.value));
  }, [props.value]);

  return (
    <Switch
      checked={value}
      onChange={handleChange}
      name="checkedA"
      disabled={disabled}
      inputProps={{ "aria-label": "secondary checkbox" }}
    />
  );
};
