import React from "react";
import { RightBar, MultiSelect, Panel, Loading } from "elements";
import { useQuery } from "@apollo/client";
import { FINDINGS } from "graphql/queries";
import { useSnackbar } from "notistack";
import { FindingsList, FindingDetails } from "./components";
import { isEmpty } from "underscore";
import { getName } from "tools";
import _ from "lodash";
import { AppBar, Tabs, Tab, Typography, Box, Divider } from "@material-ui/core";
import { useParams, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default () => {
  let { num } = useParams();
  const [value, setValue] = React.useState(parseInt(num) || 0);
  const [rightbarData, setRightbarData] = React.useState(false);
  const { data, loading } = useQuery(FINDINGS);
  const classes = useStyles();
  let location = useLocation();

  React.useEffect(() => {
    try {
      setValue(
        parseInt(location.pathname.match(/\/\d+\/?$/)[0].replace(/\//, ""))
      );
    } catch (err) {
      setValue(0);
    }
  }, [location]);

  const handleChange = (event, newValue) => {
    let p = window.location.pathname.replace(/\/\d+\/?$/, "") + "/" + newValue;
    window.history.pushState({}, null, p);

    setValue(newValue);
  };

  if (loading) return <Loading />;
  const showFindingDetails = (idx) => {
    setRightbarData(data.findings.findingFeed[idx]);
  };

  return (
    <>
      <AppBar position="static" color="transparent">
        <Tabs value={value} onChange={handleChange} aria-label="">
          <Tab label={getName("Finding", 1)} {...a11yProps(0)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} className={classes.tab}>
        <Panel
          subheader={"Lista kwestii i rekomendacji audytowych"}
          title={getName("Finding", 1)}
        >
          <Divider />
          <FindingsList showDetails={showFindingDetails} data={data.findings} />
          <br />
        </Panel>
      </TabPanel>

      <RightBar open={rightbarData} onClose={() => setRightbarData(false)}>
        {rightbarData.__typename === "Finding" && (
          <FindingDetails id={rightbarData.id} />
        )}
      </RightBar>
    </>
  );
};

{
  /* <button onClick={onClick}> Dodaj zakaldke </button> */
}
