import React, { useState, useRef } from "react";
import { Table, Loading } from "elements";
import { useQuery, useMutation, useSubscription } from "@apollo/client";
import { TASKS } from "graphql/queries";
import { FILE_URL } from "vars";
import {
  UPDATE_TASK,
  DELETE_TASK,
  CREATE_TASK,
  EXPORT_TASKS,
} from "graphql/mutations";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/styles";
import { getName } from "tools";
import { useAuthDataContext } from "context/Context";
import _ from "lodash";
import { useHistory } from "react-router";
import moment from "moment";
import { TASKSUB } from "graphql/subscriptions";

import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";

const useStyles = makeStyles((theme) => ({
  tab: {
    paddingRight: 45,
  },
}));

const TaskList = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [updateTask] = useMutation(UPDATE_TASK);
  const [createTask] = useMutation(CREATE_TASK);
  const [deleteTask] = useMutation(DELETE_TASK);
  const [exportTasks] = useMutation(EXPORT_TASKS);
  const { systemSettings, settings, setSettings } = useAuthDataContext();
  const history = useHistory();

  let data = [];
  let mails = [];
  console.log(props.data, "BBB");
  props.data.length &&
    props.data.map((d, i) => {
      d.action &&
        d.action.messages &&
        d.action.messages.map((m) => {
          // console.log(m)
          m.receiver.emails.split(";").map((em) => mails.push({ value: em }));
        });

      data.push({
        ...d,
        updatated: moment.duration(moment().diff(d.updatedAt)).humanize(),
        created: moment(d.createdAt).format("YYYY-MM-DD"),
        mails,
        risks: d.testc && d.testc.risks ? d.testc.risks : null,
        //link: [d.audit ? d.audit : null, d.test ? d.test : null],
      });
      mails = [];
    });

  const setData = () => {
    console.log("setData");
  };
  const _updateTask = async (idx, name, value) => {
    let row = props.data[idx];
    // console.log(idx,name,value, row)
    const refetchQueries = [{ query: TASKS }];

    const { data } = await updateTask({
      variables: { id: row.id, input: { [name]: value } },
      refetchQueries,
    });

    enqueueSnackbar(
      `${getName("Task")} ${data.updateTask.value} zostało zaktualizowane`,
      { variant: "success" }
    );
  };
  const _createTask = async (values) => {
    let input = { ...values };
    console.log(input);
    if (input.riskSelect) input.riskSelect = parseInt(input.riskSelect);

    const { data } = await createTask({
      variables: { input },
      refetchQueries: [{ query: TASKS }],
    });
    enqueueSnackbar(
      `${getName("Task")} ${data.createTask.value} zostało dodane`,
      { variant: "success" }
    );
  };
  const _deleteTask = async (fs) => {
    if (fs.length === 0) return false;
    let snackText = [
      fs.length > 1 ? getName("Task", 1) : getName("Task"),
      fs.length > 1 ? "zostały usunięte" : "został usunięty",
    ];
    let names = [];
    for (let i = fs.length - 1; i > 0; i--) {
      await deleteTask({ variables: { id: fs[i].id } });
      //names.push(fs[i].value);
    }
    await deleteTask({
      variables: { id: fs[0].id },
      refetchQueries: [{ query: TASKS }],
    });
    // names.push(fs[0].value);
    //nackText[1] = names.join(", ");
    props.refetch();
    enqueueSnackbar(snackText.join(" "), { variant: "success" });
  };

  const exportToFile = async () => {
    const result = await exportTasks();

    download(result.data.exportTasks);
    enqueueSnackbar(getName("Task", 1) + " poprawie wyeksportowane ", {
      variant: "success",
    });
  };

  const download = (name) => {
    const link = document.createElement("a");
    // link.targe="_blank"
    link.href = `${FILE_URL}/${name}/tasks.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getLabel = (num) => {
    if (
      systemSettings &&
      systemSettings.riskLabel &&
      systemSettings.riskLabel[num]
    )
      return systemSettings.riskLabel[num];
    else return num;
  };

  let columns = [
    // {
    //   Header: "Otw./zamk.",
    //   accessor: "status",
    //   type: "switch",
    //   width: "10%",
    // },
    {
      Header: "Status",
      accessor: "status",
      type: "status",
      inputProps: {
        labels: {
          0: { label: "Otwarte" },
          1: { label: "W realizacji" },
          4: { label: "Przekazane do weryfikacji" },
          5: { label: "Zakończone" },
        },
      },
      width: "10%",
    },

    {
      Header: "Test",
      accessor: "testc",
      type: "link",
      width: "25%",
      inputProps: { readOnly: true },
      // inputProps: {
      //   subtext: "info",
      // },
    },

    {
      Header: "Ryzyka",
      accessor: "risks",
      type: "info",
      width: "10%",
      inputProps: {
        columns: [{ name: "Nazwa", value: "value" }],
      },
    },

    // {
    //   Header: "Adresaci",
    //   accessor: "mails",
    //   type: "info",
    //   width: "10%",
    //   inputProps: {
    //     columns: [{ name: "Nazwa", value: "value" }],
    //   },
    // },

    {
      Header: "Utworzone",
      accessor: "created",
      type: "label",
      width: "10%",
      // inputProps: {
      //   subtext: "info",
      // },
    },
    {
      Header: "Aktualizowane",
      accessor: "updatated",
      type: "label",
      width: "10%",
    },
  ];

  function goToTask(i) {
    const task = props.data[i];
    const tabs = settings.tabs ? [...settings.tabs] : [];

    if (_.findIndex(tabs, { id: task.id }) < 0) {
      setSettings({
        tabs: [
          {
            id: task.id,
            title:
              task.value.length > 20
                ? task.value.slice(0, 20) + "..."
                : task.value,
            full: task.value,
            parent: null,
            href: "/task/" + task.id,
          },
          ...tabs,
        ],
      });
    }

    history.push("/task/" + task.id);
  }

  return (
    <Table
      typename="Task"
      columns={columns}
      setData={setData}
      updateMyData={_updateTask}
      skipPageReset={true}
      onAdd={_createTask}
      onDelete={_deleteTask}
      rowActions={[{ icon: <DoubleArrowIcon />, onClick: goToTask }]}
      // toolbarButtons={[
      //   {
      //     label: "Eksportuj do Excel'a",
      //     icon: <GridOnIcon />,
      //     onClick: exportToFile,
      //   },
      // ]}
      {...props}
      data={data}
    />
  );
};

export default () => {
  const { loading, data, refetch } = useQuery(TASKS, {
    pollInterval: 20000,
    fetchPolicy: "no-cache",
  });
  const onSubscriptionData = ({ subscriptionData }) => {
    // enqueueSnackbar("Finding zaktualizowany", { variant: "success" });

    // console.log("SUB", subscriptionData);
    refetch();
  };
  useSubscription(TASKSUB, {
    onSubscriptionData,
  });

  if (loading) return <Loading />;

  return (
    <TaskList
      data={data && data.tasks ? data.tasks.taskFeed : []}
      refetch={refetch}
    />
  );
};
