import React from "react";
import { FINDINGS, FINDING } from "graphql/queries";

import { UPDATE_FINDING } from "graphql/mutations";
import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "@apollo/client";
import { useAuthDataContext } from "context/Context";
import moment from "moment";
import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Input, SimpleSelect } from "elements";
import { getName } from "tools";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Truncate from "react-truncate";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { Panel } from "elements";

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: 12,
  },
  field: {
    marginBottom: 20,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    flexBasis: "66.66%",
  },
  list: {
    marginLeft: 30,
  },
  rightGrid: {
    // borderLeft: "1px solid #cdcdcd",
  },
  tabContainer: {
    width: "90%",
    position: "relative",
  },
  first: {
    padding: "2%",
    "& .MuiInput-underline:before": {
      borderBottom: 0,
    },
  },
  actionButtons: {
    textAlign: "right",
    width: "95%",
    marginTop: -20,
  },
}));

export default ({ finding }) => {
  const [updateFinding] = useMutation(UPDATE_FINDING);
  const [f, setF] = React.useState({ ...finding });
  const [status, setStatus] = React.useState(finding.status);
  const saveIt = React.useRef({});
  const timeout = React.useRef(false);
  const [expanded, setExpanded] = React.useState(false);
  const { systemSettings, user } = useAuthDataContext();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [checklist, setChecklist] = React.useState(
    finding.checklist ? [...finding.checklist] : [false, false, false, false]
  );

  const onCloseTest = async (value) => {
    const refetchQueries = [
      { query: FINDINGS },
      { query: FINDING, variables: { id: finding.id } },
    ];

    const na = await updateFinding({
      variables: { id: finding.id, input: { status: value ? 1 : 0 } },
      refetchQueries,
    });

    enqueueSnackbar(
      `${getName("Finding")} ${na.data.updateFinding.value} został ${
        value ? "zamknięty" : "otwarty"
      } `,
      { variant: "success" }
    );
  };

  const onChange = (name) => async (value) => {
    let rest = {};
    if (name === "date") {
      value = parseInt(value / 1000);

      if (!f.messages) rest["messages"] = [];
      else rest.messages = JSON.parse(f.messages);

      rest.messages.unshift({
        message:
          "Nowy termin wdrożenia: " + moment.unix(value).format("YYYY-MM-DD"),
        value: moment.unix(value).format("YYYY-MM-DD"),
        auditor: {
          value: user.value,
          avatar: user.avatar,
          id: user.id,
          color: user.color,
        },
        time: +new Date() / 1000,
      });
      rest.messages = JSON.stringify(rest.messages);
    } else if (name === "responsible") {
      if (!f.messages) rest["messages"] = [];
      else rest.messages = JSON.parse(f.messages);
      rest.messages.unshift({
        message: "Nowy osoba odpowiedzialna:",
        value,
        auditor: {
          value: user.value,
          avatar: user.avatar,
          id: user.id,
          color: user.color,
        },
        time: +new Date() / 1000,
      });

      rest.messages = JSON.stringify(rest.messages);
    }
    saveIt.current = { ...saveIt.current, [name]: value, ...rest };
    setF({ ...f, [name]: value, ...rest });
    if (timeout.current) clearTimeout(timeout.current);
    timeout.current = setTimeout(onChangeTimeout, 2000);
  };

  const onChangeTimeout = async () => {
    const refetchQueries = [
      { query: FINDINGS },
      { query: FINDING, variables: { id: finding.id } },
    ];

    const na = await updateFinding({
      variables: { id: finding.id, input: saveIt.current },
      refetchQueries,
    });

    saveIt.current = {};
    timeout.current = null;

    enqueueSnackbar(
      `${getName("Finding")} ${
        na.data.updateFinding.value
      } został zaktualizowany`,
      { variant: "success" }
    );
  };

  // finding status
  // 0 w trakcie
  // 1 prosba o przedluzenie
  // 2 prosba odrzucona
  // 4 wdrozony
  // 5 zamknięty

  async function changeStatus(num) {
    saveIt.current.status = num;
    await onChangeTimeout();
    setStatus(num);
  }

  const actionButtons = [
    <Button variant="outlined" onClick={() => changeStatus(5)}>
      Oznacz jako wdrożony
    </Button>,
    <Button variant="outlined" onClick={() => changeStatus(2)}>
      Odrzuć
    </Button>,
    <Button variant="outlined" onClick={() => changeStatus(0)}>
      Zaakceptuj
    </Button>,
    <Button variant="outlined" onClick={() => changeStatus(0)}>
      Otwórz ponownie
    </Button>,
  ];

  const statusName = [
    "W trakcie wdrożenia",
    "Prośba o przedłużenie",
    "Prośba o przedłużenie odrzucone",
    "",
    "Prośba o akceptację wdrożenia",
    "Zamknięty",
  ];

  const actionButtonsSet = [
    <div className={classes.actionButtons}>{actionButtons[0]}</div>,
    <div className={classes.actionButtons}>
      {actionButtons[1]}&nbsp;{actionButtons[2]}
    </div>,
    <div className={classes.actionButtons}>{actionButtons[0]}</div>,
    <div className={classes.actionButtons}></div>,
    <div className={classes.actionButtons}>
      {actionButtons[1]}&nbsp;{actionButtons[0]}
    </div>,
    <div className={classes.actionButtons}>{actionButtons[3]}</div>,
  ];

  return (
    <Panel
      subheader={actionButtonsSet[status]}
      title={<div className="header">Status: {statusName[status]}</div>}
    >
      <Grid container className={classes.first}>
        <Grid md={3} item>
          <FormControl
            component="fieldset"
            style={{ width: "100%" }}
          ></FormControl>
          <br />
          <FormControl component="fieldset" style={{ width: "100%" }}>
            <FormLabel component="legend">Nazwa</FormLabel>
            <div className={classes.field}>
              <Input
                inputProps={{ onChange: onChange("value"), value: f.value }}
                type="text"
              />
            </div>
          </FormControl>
          <FormControl component="fieldset" style={{ width: "100%" }}>
            <FormLabel component="legend">Szacowana wartość</FormLabel>
            <div className={classes.field}>
              <Input
                inputProps={{
                  onChange: onChange("financialImpactA"),
                  value: f.financialImpactA,
                }}
                type="number"
              />
            </div>
          </FormControl>
          <br />
          <hr />
          <br />
          <FormControl component="fieldset" style={{ width: "100%" }}>
            <FormLabel component="legend">Ocena ryzyka</FormLabel>
            {systemSettings.riskScale1 && (
              <>
                <SimpleSelect
                  label="Prawdopodobienstwo"
                  value={f.riskSelect0}
                  data={systemSettings.riskScale0}
                  onChange={onChange("riskSelect0")}
                  fullWidth
                />
                <SimpleSelect
                  label="Wpływ"
                  value={f.riskSelect1}
                  data={systemSettings.riskScale1}
                  onChange={onChange("riskSelect1")}
                  fullWidth
                />
              </>
            )}
            {!systemSettings.riskScale1 && (
              <SimpleSelect
                label=""
                value={f.riskSelect0}
                data={systemSettings.riskScale0}
                onChange={onChange("riskSelect0")}
              />
            )}
          </FormControl>
          <br />
          <br />
          <hr />
          <br />
          <FormControl component="fieldset" style={{ width: "100%" }}>
            <FormLabel component="legend">
              Osoba odpowiedzialna (imię i nazwisko)
            </FormLabel>
            <div className={classes.field}>
              <Input
                inputProps={{
                  onChange: onChange("responsible"),
                  value: f.responsible,
                }}
                type="text"
              />
            </div>
          </FormControl>
          <FormControl component="fieldset" style={{ width: "100%" }}>
            <FormLabel component="legend">
              Osoba odpowiedzialna (email)
            </FormLabel>
            <div className={classes.field}>
              <Input
                inputProps={{
                  onChange: onChange("responsibleEmail"),
                  value: f.responsibleEmail,
                }}
                type="text"
              />
            </div>
          </FormControl>
          <FormControl component="fieldset" style={{ width: "100%" }}>
            <FormLabel component="legend">Data wdrożenia</FormLabel>

            <div className={classes.field}>
              <Input
                inputProps={{
                  onChange: onChange("date"),
                  value: f.date * 1000,
                }}
                type="date"
              />
            </div>
          </FormControl>
        </Grid>
        <Grid md={1} item></Grid>
        <Grid md={8} item>
          <div className={classes.tabContainer}>
            <ExpansionPanel
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography className={classes.heading}>Obserwacje</Typography>
                <Typography className={classes.secondaryHeading}>
                  {expanded !== "panel1" && (
                    <Truncate lines={1} ellipsis={<span>...</span>}>
                      {f.remark}
                    </Truncate>
                  )}
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Input
                  inputProps={{
                    onChange: onChange("remark"),
                    value: f.remark,
                    multiline: true,
                    cols: 5,
                    preview: true,
                  }}
                  type="longText"
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography className={classes.heading}>
                  Rekomendacje
                </Typography>
                <Typography className={classes.secondaryHeading}>
                  {expanded !== "panel2" && (
                    <Truncate lines={1} ellipsis={<span>...</span>}>
                      {f.recomendation}
                    </Truncate>
                  )}
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Input
                  inputProps={{
                    onChange: onChange("recomendation"),
                    value: f.recomendation,
                    multiline: true,
                    cols: 5,
                    preview: true,
                  }}
                  type="longText"
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography className={classes.heading}>Ryzyko</Typography>
                <Typography className={classes.secondaryHeading}>
                  {expanded !== "panel3" && (
                    <Truncate lines={1} ellipsis={<span>...</span>}>
                      {f.risk}
                    </Truncate>
                  )}
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                {/* {f.desc} */}
                <Input
                  inputProps={{
                    onChange: onChange("risk"),
                    value: f.risk,
                    multiline: true,
                    cols: 5,
                    preview: true,
                  }}
                  type="longText"
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography className={classes.heading}>
                  Podsumowanie
                </Typography>
                <Typography className={classes.secondaryHeading}>
                  {expanded !== "panel4" && (
                    <Truncate lines={1} ellipsis={<span>...</span>}>
                      {f.desc}
                    </Truncate>
                  )}
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                {/* {f.desc} */}
                <Input
                  inputProps={{
                    onChange: onChange("desc"),
                    value: f.desc,
                    multiline: true,
                    cols: 5,
                    preview: true,
                  }}
                  type="longText"
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === "panel5"}
              onChange={handleChange("panel5")}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel5bh-content"
                id="panel5bh-header"
              >
                <Typography className={classes.heading}>Rozwiązanie</Typography>
                <Typography className={classes.secondaryHeading}>
                  {expanded !== "panel5" && (
                    <Truncate lines={1} ellipsis={<span>...</span>}>
                      {f.solution}
                    </Truncate>
                  )}
                </Typography>
              </ExpansionPanelSummary>

              <ExpansionPanelDetails>
                <Input
                  inputProps={{
                    onChange: onChange("solution"),
                    value: f.solution,
                    multiline: true,
                    cols: 5,
                    preview: true,
                  }}
                  type="longText"
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        </Grid>
      </Grid>
    </Panel>
  );
};
