import React from "react";
import { useAuthDataContext } from "context/Context";
import { ReviewNotes } from "components/SharedComponents";

export default ({ audit }) => {
  const { user } = useAuthDataContext();
  return (
    <ReviewNotes
      auditId={audit.id}
      disabled={audit.status === 19 || audit.status === 1}
      columns={[
        {
          Header: "Otwarta/zamknięta",
          accessor: "status",
          type: "switch",
          width: "10%",
          inputProps: { ownerOnly: true, owner: user.id },
        },
        {
          Header: "Nazwa",
          accessor: "value",
          type: "label",
          width: "20%",
        },
        
        {
          Header: "Test",
          accessor: "test",
          type: "link",
          width: "12%",
          inputProps: { readOnly: true },
        },
        {
          Header: "Audytorzy",
          accessor: "auditors",
          type: "team",
          width: "15%",
          inputProps: { readOnly: true },
        },

        {
          Header: "Aktualizacja",
          accessor: "updatedAt",
          type: "label",
          width: "10%",
          inputProps: { type: "updatedAt" },
        },
        {
          Header: "",
          accessor: "lastAuditor.id",
          type: "user",
          width: "10%",
          inputProps: { readOnly: true },
        },
      ]}
    />
  );
};
