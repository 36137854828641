import React, { useState } from "react";
import { ButtonProgress, TestResult, Input } from "elements";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import { useMutation } from "@apollo/client";
import { PERFORM_TESTC } from "graphql/mutations";
import moment from "moment";
import { useAuthDataContext } from "context/Context";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 20,
  },
  alert: { fontSize: 12 },
  reason: {
    fontSize: 10,
  },
  blue: {
    color: "blue",
  },
  red: { color: "red" },
}));

const First = ({ data, onUpdate }) => {
  const [performTestc] = useMutation(PERFORM_TESTC);
  const { settings } = useAuthDataContext();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [results, setResults] = useState(JSON.parse(data.results || "{}"));
  const [prm, setPrm] = useState({
    day: data.day,
    hour: data.hour,
    minute: data.minute,
    inweek: data.inweek,
  });
  const [status, setStatus] = useState(false);
  const [frequency, setFrequency] = useState(data.frequency);
  // const [deleteMessages] = useMutation(DELETE_MESSAGES);

  const [checklist, setChecklist] = React.useState([true, true, true]);
  const [state, setState] = React.useState({
    checkedB: data.status,
  });

  const handleChange = (event) => {
    onUpdate(data.id, { status: event.target.checked ? 1 : 0 });
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleChangeChecklist =
    (num) =>
    async ({ target }) => {
      let cl = [...checklist];
      cl[num] = target.checked;

      setChecklist(cl);
    };

  const perform = async () => {
    setStatus("loading");
    const result = await performTestc({
      variables: { id: data.id },
    });
    setStatus("");

    result.data.performTestc &&
      setResults(JSON.parse(result.data.performTestc));
  };

  const onChange = async (what, event) => {
    setPrm({ ...prm, [what]: event.target.value });
  };

  const onBlur = async (what) => {
    await onUpdate(data.id, { [what]: prm[what] });
  };

  const onChangeF = async (value) => {
    setFrequency(value);
    await onUpdate(data.id, { frequency: value });
  };

  const onChangeIW = async (value) => {
    setPrm({ ...prm, inweek: value });
    await onUpdate(data.id, { inweek: value });
  };

  return (
    <Grid container className={classes.container}>
      <Grid xs={4} style={{ paddingRight: 30 }}>
        <h3>Informacje podstawowe</h3>
        <br />
        <h5>Ostatnie wykonanie</h5>
        {/* <div>{moment.unix(Object.keys(results)[0]).format(settings.dateFormat || "YYYY-MM-DD hh:mm")}</div> */}
        <div>
          {Object.keys(results).length
            ? moment
                .unix(parseInt(Object.keys(results).reverse()[0]) / 1000)
                .format("YYYY-MM-DD hh:mm")
            : "-"}
        </div>
        <br />
        <h5>Status</h5>
        {/* <FormLabel component="legend">Lista kontrolna</FormLabel>
        <FormGroup aria-label="position">
          <br />
          <FormControlLabel
            control={
              <Checkbox
                checked={checklist[0]}
                onChange={handleChangeChecklist(0)}
                // disabled={true}
                color="primary"
              />
            }
            label="Zródla danych określone"
            labelPlacement="end"
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={checklist[1]}
                onChange={handleChangeChecklist(1)}
                // disabled={true}
                color="primary"
              />
            }
            label="Określenie akcji dla ryzyk"
            labelPlacement="end"
          />
        </FormGroup> */}
        <br />
        <FormControlLabel
          control={
            <Switch
              checked={state.checkedB}
              onChange={handleChange}
              name="checkedB"
              color="primary"
            />
          }
          label={state.checkedB ? "Aktywny" : "Oczekuje"}
        />
        <br /> <br />
        <h5>Parametry wykonania</h5>
        <Input
          type="select"
          inputProps={{
            value: frequency,
            onChange: onChangeF,
            fullWidth: true,

            data: [
              { id: "1 minute", value: "1 minuta" },
              { id: "1 hour", value: "1 godz." },
              { id: "1 day", value: "1 dzień" },
              { id: "1 week", value: "1 tydz." },
              { id: "1 month", value: "1 mies." },
            ],
          }}
        />
        {["1 month"].indexOf(frequency) >= 0 && (
          <TextField
            label="Dzień miesiąca"
            type="number"
            onChange={(e) => onChange("day", e)}
            onBlur={() => onBlur("day")}
            value={prm["day"]}
            fullWidth={true}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
        {["1 day", "1 month", "1 week"].indexOf(frequency) >= 0 && (
          <TextField
            label="Godzina"
            type="number"
            onChange={(e) => onChange("hour", e)}
            onBlur={() => onBlur("hour")}
            value={prm["hour"]}
            fullWidth={true}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
        {["1 hour", "1 day", "1 month", "1 week"].indexOf(frequency) >= 0 && (
          <TextField
            label="Minuta"
            type="number"
            onChange={(e) => onChange("minute", e)}
            onBlur={() => onBlur("minute")}
            value={prm["minute"]}
            fullWidth={true}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
        {["1 week"].indexOf(frequency) >= 0 && (
          <Input
            type="select"
            inputProps={{
              value: prm["inweek"],
              onChange: onChangeIW,
              fullWidth: true,
              label: "Dzień tygodnia",
              data: [
                { id: "0", value: "niedziela" },
                { id: "1", value: "poniedziałek" },
                { id: "2", value: "wtorek" },
                { id: "3", value: "środa" },
                { id: "4", value: "czwartek" },
                { id: "5", value: "piątek" },
                { id: "6", value: "sobota" },
              ],
            }}
          />
        )}
      </Grid>
      <Grid xs={7}>
        <ButtonProgress
          status={status}
          label="Uruchom"
          color="primary"
          onClick={() => perform()}
        />

        <br />
        <h3>Wyniki</h3>
        <br />
        {Object.keys(results)
          .reverse()
          .map((d, i) => i < 16 && <TestResult date={d} result={results[d]} />)}
        {Object.keys(results).length === 0 &&
          "Test nie został jeszcze wykonany"}
        {/*<br/>
            <h4>Alerty</h4>

            <div class={classes.alert}>Ryzyko 1 <span class={classes.red}>0.95</span></div>
            <div class={classes.reason}>slope JEST &#62;0.5 ORAZ 	mean JEST &#62;0</div>
            
            <div class={classes.alert}>Ryzyko 2 <span class={classes.blue}>0.03</span></div>
            <div class={classes.reason}>slope JEST &#60;0 	ORAZ 	mean JEST &#62;0</div>
            <br/>
            
            <h4>Future alerts</h4>
            <h5>Okres prognozy (dni): 3</h5>
            <div class={classes.alert}>Ryzyko 1 <span class={classes.red}>0.95</span></div>
            <div class={classes.reason}>slope JEST &#62;0.5 ORAZ 	mean JEST &#62;0</div>
            <div class={classes.alert}>Ryzyko 2 <span class={classes.blue}>0.03</span></div>
            <div class={classes.reason}>slope JEST &#60;0 	ORAZ 	mean JEST &#62;0</div>
            <br/>

            <h5>Okres prognozy (dni): 7</h5>
            <div class={classes.alert}>Ryzyko 1 <span class={classes.red}>0.95</span></div>
            <div class={classes.reason}>slope JEST &#62;0.5 ORAZ 	mean JEST &#62;0</div>
            <div class={classes.alert}>Ryzyko 2 <span class={classes.blue}>0.03</span></div>
            <div class={classes.reason}>slope JEST &#60;0 	ORAZ 	mean JEST &#62;0</div>
            <br/>


            <br/>
             */}
      </Grid>
    </Grid>
  );
};

export default ({ data, onUpdate }) => {
  // let { id, num } = useParams();
  // const { enqueueSnackbar } = useSnackbar();
  // const { data, loading, refetch } = useQuery(AUDIT, {
  //   variables: { id },
  //   pollInterval: 20000,  fetchPolicy: "no-cache"
  // });

  // if (loading) return false;
  // if (!data.audit) {
  //   enqueueSnackbar(`${getName("Audit")} został usunięty`, {
  //     variant: "error",
  //   });
  //   return <ErrorPage />;
  // }

  return <First onUpdate={onUpdate} data={data} />;
};
