import React, { useState } from "react";
import { Table, Input } from "elements";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Paper from "@material-ui/core/Paper";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import Select from "@material-ui/core/Select";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { useTabContext } from "@material-ui/lab";

export default (props) => {
  let { rules, id } = props.alert;

  let outputs =
    props.outputs && props.outputs.length > 0
      ? props.outputs
      : [
          {
            name: "slope",
            displayName: "Współczynnik nachylenia krzywej",
            hint: "Wartość ta wskazuje na trend wzrostowy (&gt;0) lub spadkowy (&lt;0) w badanym okresie. Wartości bliskie 0 świadczą o braku trendu. Wartościami skrajnymi są -1 oraz 1.",
            example: 0,
          },
          {
            name: "mean",
            displayName: "Średnia wartość",
            hint: "Średnia wartość współczynnika testu Benforda. Jeśli wartość ta jest większa od przyjętego progu istotności to należy odrzucić hipotezę o zachodzeniu testu  Benforda. Przykładowo jeśli wartość mean=0.1 to oznacza że przy progu istotności 0.05, należy odrzucić hipotezę testu Benforda i przyjąć, że dane wejściowe mają dopuszczalny rozkład.",
            example: 0,
          },
        ];
  const [anchorEl, setAnchorEl] = React.useState([null, null]);
  const [cond, setCond] = React.useState(rules.cond || "and");
  const [list, setList] = React.useState(rules.list);

  const [rule3, setRule3] = React.useState();
  const [rule2, setRule2] = React.useState();
  const [rule1, setRule1] = React.useState();

  const element = React.useRef([false, false, false]);

  const addIt = async () => {
    console.log(rule2, rule3);

    const l = [...list];
    l.push([rule1, rule2, rule3]);

    setList(l);

    await props.onUpdate({ ...rules, list: l });
    // setRule1(null)
    // setRule2("")
    // setRule3("")
  };

  const deleteIt = async (idx) => {
    const l = [...list];
    l.splice(idx, 1);

    setList(l);
    await props.onUpdate({ ...rules, list: l });
  };

  const handlePopoverOpen = (i) => {
    let a = [...anchorEl];
    a[i] = true;
    setAnchorEl([...a]);
  };

  const handlePopoverClose = (i) => {
    let a = [...anchorEl];
    a[i] = false;
    setAnchorEl([...a]);
  };

  const onChange1 = (e) => setRule1(e.target.value);

  const onChange2 = (value) => setRule2(value);

  const onChange3 = (value) => setRule3(value);

  const setCondition = async (cond) => {
    await props.onUpdate({ ...rules, cond });
    setCond(cond);
  };

  const getName = (x) => {
    return outputs.find((o) => o.name === x);
  };

  return (
    <div style={{ padding: 20 }}>
      <div>Jezeli</div>
      <RadioGroup
        row
        aria-label="position"
        name="position"
        defaultValue={rules}
      >
        <FormControlLabel
          value="and"
          control={
            <Radio
              color="primary"
              checked={cond === "and"}
              onChange={() => setCondition("and")}
            />
          }
          label="oraz"
          labelPlacement="start"
        />
        <FormControlLabel
          value="or"
          control={
            <Radio
              color="primary"
              checked={cond === "or"}
              onChange={() => setCondition("or")}
            />
          }
          label="lub"
          labelPlacement="start"
        />
      </RadioGroup>
      <br />
      <br />
      {list.map((l, i) => (
        <Grid container spacing={1} style={{ paddingLeft: 5 }}>
          <Grid
            item
            xs={1}
            onClick={() => deleteIt(i)}
            style={{ cursor: "pointer" }}
          >
            {" "}
            <CloseIcon style={{ fontSize: "small" }} />{" "}
          </Grid>
          <Grid
            item
            xs={7}
            onClick={() => handlePopoverOpen(i)}
            title="tutaj opis zmiennej"
          >
            {" "}
            {getName(l[0]).displayName}
          </Grid>
          <Grid item xs={2}>
            {l[1]}{" "}
          </Grid>
          <Grid item xs={2}>
            {" "}
            {l[2]}{" "}
          </Grid>
        </Grid>
      ))}
      <br />
      <hr />
      <br />
      <Grid container spacing={3}>
        <Grid item xs={1}>
          {" "}
        </Grid>
        <Grid style={{ marginTop: 0 }} item xs={7}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel htmlFor="grouped-select">
              {" "}
              Zmienna wyjściowa{" "}
            </InputLabel>
            <Select
              id="grouped-select"
              defaultValue={rule1}
              onChange={onChange1}
            >
              {outputs.map((o) => (
                <MenuItem
                  value={o.name}
                  style={{
                    display: "block",
                    whiteSpace: "unset",
                  }}
                >
                  {o.displayName}
                  <div
                    style={{ fontSize: 11, padding: "5px 0px", width: 300 }}
                    dangerouslySetInnerHTML={{ __html: o.hint }}
                  ></div>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* <Input type="select" inputProps={{ fullWidth: true,  data: [{id:1, value: "Współczynnik nachylenia krzywej"}, {id:1, value: "Średnia wartość"}] }}  /> */}
        </Grid>
        <Grid style={{ marginTop: 10 }} item xs={2}>
          <Input
            type="select"
            inputProps={{
              value: rule2,
              onChange: onChange2,
              fullWidth: true,
              data: [
                { id: "", value: "" },
                { id: "<", value: "<" },
                { id: "<=", value: "<=" },
                { id: ">", value: ">" },
                { id: ">=", value: ">=" },
                { id: "=", value: "=" },
              ],
            }}
          />
        </Grid>
        <Grid style={{ marginTop: 10 }} item xs={2}>
          <Input
            type="text"
            inputProps={{ value: rule3, onChange: onChange3 }}
          />
        </Grid>
        <div style={{ width: "100%", textAlign: "right", marginRight: 20 }}>
          <Button type="submit" onClick={addIt}>
            Dodaj
          </Button>
        </div>

        {/* <Paper>  </Paper> */}
      </Grid>
    </div>
  );
};
