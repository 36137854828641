import React from "react";
import _ from "lodash";

export default (props) => {
  console.log(props, 987);
  return (
    props.register && (
      <input
        type="hidden"
        name={props.name}
        ref={props.register(props.validation)}
        value={_.map(props.value, "id")}
      />
    )
  );
};
