/* eslint-disable no-use-before-define */
import React from "react";
import useAutocomplete from "@material-ui/lab/useAutocomplete";
import NoSsr from "@material-ui/core/NoSsr";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import styled from "styled-components";
import { USERS } from "graphql/queries";
import { useQuery } from "@apollo/client";
import { Avatar } from "elements";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import { pluck } from "underscore";
import { useAuthDataContext } from "context/Context";

const Error = styled("label")`
  color: #e53935;
  display: block;
  font-size: 11px;
  letter-spacing: 0.33px;
  margin-top: 5px;
  margin-left: -5px;
`;

const Label = styled("label")`
  padding: 0 0 4px;
  display: block;
  font-size: 14px;
  margin-top: 20px;
`;

const InputWrapper = styled("div")`
  width: 300px;

  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;

  &:hover {
  }

  & input {
    font-family: "regular";
    font-size: 14px;
    height: 30px;
    background: transparent;

    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`;

const Tag = styled(({ label, onDelete, ...props }) => (
  <div {...props}>
    <span>{label}</span>
    <CloseIcon onClick={onDelete} />
  </div>
))`
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: #40a9ff;
    background-color: #e6f7ff;
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`;

const Listbox = styled("ul")`
  width: 300px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: #fff;
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected="true"] {
    background-color: #fafafa;
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li[data-focus="true"] {
    background-color: #e6f7ff;
    cursor: pointer;

    & svg {
      color: #000;
    }
  }
`;

function TeamSelect(props) {

  console.log(111,props)

  const [v, setV] = React.useState(props.value);
  const [ids, setIds] = React.useState(pluck(props.value, "id"));
  const update = React.useRef(false);
  const setIt = (e, v) => {
    setV(v);
    update.current = true;
    setIds(pluck(v, "id"));
  };

  const closeIt = async () => {
    if (props.disabled) return false;
    update.current && props.onChange && (await props.onChange(ids));
    update.current = false;
  };

  React.useEffect(() => {
    setV(props.value);
    setIds(pluck(props.value, "id"));
  }, [props.value]);

  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: "customized-hook-demo",
    value: v,
    multiple: true,
    options: props.users,
    getOptionLabel: (option) => option.value,
    // disabled: props.disabled,
    onChange: setIt,
    onClose: closeIt,
    disableCloseOnSelect: true,
  });

  const onClick = (id) => () => {
    if (props.disabled) return false;
    const newV = v.filter((obj) => obj.id !== id);
    const newIds = pluck(newV, "id");

    // setV([...newV])
    // setIds([...newIds])

    props.onChange && props.onChange(newIds);
  };

  return (
    <NoSsr>
      <div style={{ marginLeft: 5 }}>
        <div {...getRootProps()}>
          <InputWrapper ref={setAnchorEl} className={focused ? "focused" : ""}>
            <AvatarGroup max={5}>
              {value.map((option, index) => (
                <Avatar
                  onClick={!props.readOnly && onClick(option.id)}
                  key={"avatar" + option.id}
                  value={option.value}
                  color={option.color}
                  group={true}
                  {...getTagProps({ index })}
                  style={{ marginLeft: -8 }}
                  readOnly={props.disabled || props.readOnly}
                >
                  {option.avatar}
                </Avatar>
              ))}
            </AvatarGroup>
            {value.length === 0 && (
              <Avatar style={{ position: "absolute" }} group={true}></Avatar>
            )}
            <input
              {...getInputProps()}
              disabled={props.disabled || props.readOnly}
              placeholder={value.length === 0 ? "wybierz audytorów" : ""}
            />
          </InputWrapper>
        </div>
        {groupedOptions.length > 0 ? (
          <Listbox {...getListboxProps()}>
            {groupedOptions.map((option, index) => (
              <li {...getOptionProps({ option, index })}>
                <span>{option.value}</span>
                <CheckIcon fontSize="small" />
              </li>
            ))}
          </Listbox>
        ) : null}
        {props.register && (
          <input
            type="hidden"
            ref={props.register(props.validation || {})}
            name={props.name}
            value={ids}
          />
        )}
        {props.error && <Error>Pole wymagane</Error>}
      </div>
    </NoSsr>
  );
}

export default (props) => {

  

  const { user } = useAuthDataContext();
  let users = [],
    _users = [],
    usersById = {},
    selected = [];
  if (!props.users) {
    const { data, loading } = useQuery(USERS);
    if (loading) return false;
    _users = data.users;
  } else _users = props.users;

  if (props.excludeMe) {
    _users = _users.filter((u) => u.id !== user.id);
  }

  let usr = null;
  _users.map((user) => {
    let { id, email, value, avatar, color, position } = user;
    let _user = {
      id,
      email,
      value,
      avatar,
      color,
      position,
    };
    users.push(_user);
    usersById[user.id] = _user;
  });
  props.value &&
    props.value.map((user) => {
      if (user) {
        usr = user.id ? usersById[user.id] : usersById[user];
        usr && selected.push(usr);
      }
    });

  return (
    <div className="team">
      {props.label && (
        <Label style={{ color: props.error ? "#e53935" : "inherit" }}>
          {props.label}
        </Label>
      )}
      <TeamSelect users={users} {...props} value={selected} />
    </div>
  );
};
