import React from "react";
import { useAuthDataContext } from "context/Context";
import { RightBar, Panel } from "elements";
import { useQuery } from "@apollo/client";
import { INSTANCES, VARIANTS } from "graphql/queries";
// import { useSnackbar } from 'notistack';
import { InstanceList, Survey } from "./components";
import { isEmpty } from "underscore";
import { makeStyles } from "@material-ui/styles";
import { getName } from "tools";
import {
  Card,
  CardHeader,
  CardContent,
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  Divider,
} from "@material-ui/core";
import {
  // BrowserRouter as Router,
  // Switch,
  // Route,
  // Link,
  useParams,
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  tab: {
    paddingRight: 45,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default () => {
  let { num } = useParams();

  const i = useQuery(INSTANCES);
  const v = useQuery(VARIANTS);
  const classes = useStyles();
  const [value, setValue] = React.useState(parseInt(num) || 0);
  const [rightbarData, setRightbarData] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const setSelected = (_1,_2,_3) => {
  //     console.log(_1,_2, _3)
  //     setRightbarData(_1)
  // }

  const getRightBar = () => {
    if (isEmpty(rightbarData.current)) return null;

    return null;
  };

  const showDetails = () => {
    setRightbarData(true);
  };

  if (i.loading || v.loading) return false;

  return (
    <div>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label={getName("Instance", 1)} {...a11yProps(0)} />
          <Tab label={getName("Survey", 1)} {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} className={classes.tab}>
        <Panel
          subheader={
            <div className="subheader">Tutaj podtytuł co to jest instancja</div>
          }
          title={getName("Instance", 1)}
        >
          <InstanceList
            showDetails={showDetails}
            refetch={i.refetch}
            data={i.data.instances.instanceFeed}
            variants={v.data.variants.variantFeed}
          />
        </Panel>
      </TabPanel>

      <TabPanel
        className="treeWithSelection"
        value={value}
        index={1}
        className={classes.tab}
      >
        <Panel
          subheader={
            <div className="subheader">
              Parametryzacja algorytmu szeregowania ryzyka
            </div>
          }
          title={"Ankieta audytowa"}
        >
          <Survey />
        </Panel>
      </TabPanel>

      <RightBar
        open={rightbarData}
        data={rightbarData}
        onClose={() => setRightbarData(false)}
      >
        {getRightBar()}
      </RightBar>
    </div>
  );
};
