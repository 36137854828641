import React from "react";
import {
  Text,
  Select,
  Date,
  Count,
  VariantSelect,
  VariantFromPerspectivesMultiSelect,
  Tags,
  Risk,
  Slider,
  Team,
  MultiSelect,
  User,
  Number,
  Label,
  Info,
  Hidden,
  Extension,
  LongText,
  Checkbox,
  Color,
  SimpleSelect,
  InstancesName,
  PerspectiveSelect,
  AuditGroupSelect,
  VariantsMultiSelect,
  InstancesMultiSelect,
  
  Switch,
  DaysTo,
  DrawSelect,
  Status,
  Link,
  SourceSelect,
} from "elements";

export default ({ type, defaultProps, inputProps }) => {
  const TagName = {
    text: Text,
    longText: LongText,
    number: Number,
    select: Select,
    multiselect: MultiSelect,
    label: Label,
    slider: Slider,
    date: Date,
    team: Team,
    user: User,
    hidden: Hidden,
    variant: VariantSelect,
    source: SourceSelect,
    variantFromPerspectives: VariantFromPerspectivesMultiSelect,
    perspective: PerspectiveSelect,
    auditGroup: AuditGroupSelect,
    variants: VariantsMultiSelect,
    instances: InstancesMultiSelect,
    instancesName: InstancesName,
    info: Info,
    risk: Risk,
    extension: Extension,
    checkbox: Checkbox,
    count: Count,
    color: Color,
    switch: Switch,
    status: Status,
    daysTo: DaysTo,
    tags: Tags,
    link: Link,
    simpleSelect: SimpleSelect,
    drawSelect: DrawSelect
  }[type];

  return <TagName {...defaultProps} {...inputProps} />;
};
