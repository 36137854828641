import React from "react";
import { makeStyles } from "@material-ui/styles";
import Avatar from "@material-ui/core/Avatar";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Button } from "@material-ui/core";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import Fade from "@material-ui/core/Fade";
import NotesIcon from "@material-ui/icons/Notes";
import amber from "@material-ui/core/colors/amber";
import red from "@material-ui/core/colors/red";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  num1: {
    fontSize: 13,
    color: "#222",
    display: "block",
  },
  num2: {
    display: "block",
    fontSize: 9,
    color: "#555555",
  },
}));

export default (props) => {
  let m = moment.unix(props.value);
  let today = moment().startOf("day");

  // date.format(systemSettings.dateFormat)

  let days = Math.round(moment.duration(today - m).asDays()) * -1;

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverToogle = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (!props.value) {
    return <div>-</div>;
  } else {
    let clr = null;

    if (days <= 0) clr = red[400];
    else if (days === 1) clr = amber[600];
    else if (days <= 3) clr = amber[500];
    else if (days <= 7) clr = amber[400];
    else if (days <= 10) clr = amber[300];
    else if (days <= 20) clr = amber[200];
    else clr = amber[100];

    return (
      <>
        <Avatar
          onClick={handlePopoverToogle}
          style={{ backgroundColor: clr }}
          variant="square"
        >
          <ul className={classes.ul}>
            <li className={classes.num1}>{days}</li>
            <li className={classes.num2}>dni</li>
          </ul>
        </Avatar>
      </>
    );
  }
};
