import React from "react";
import DrawList from "./drawlist"
import { makeStyles } from "@material-ui/core/styles";
import { useQuery, useMutation } from "@apollo/client";
import { ACTIONS } from "graphql/queries";
import { Loading } from "elements";
import {map} from 'lodash'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1, 1, 0, 0),
  },
}));

export default ({data, onUpdate, onUpdateLevels}) => {
  // const [value, setValue] = React.useState("1");

  const a = useQuery(ACTIONS);
  if (a.loading) return <Loading />;



 
  const handleChange = (event) => {
    
  };

  const onChange = (values) => {
  
    onUpdate(data, values)
    // console.log(a.data.actions.actionFeed[idx])
  };

  // const onChangeLevels = (d,values) => {
  
  //   onUpdateLevels(data, d, values)
  //   // console.log(a.data.actions.actionFeed[idx])
  // };

  return (
    <DrawList 
      data={a.data.actions.actionFeed} 
      selected={map(data.actions, 'id')} 
      levels={data.actionsLevels}
      onChange={onChange} 
      // onChangeLevels={onChangeLevels}
    />
  );
};
