import React from "react";
import { makeStyles } from "@material-ui/styles";
import Avatar from "@material-ui/core/Avatar";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Button } from "@material-ui/core";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import Fade from "@material-ui/core/Fade";
import NotesIcon from "@material-ui/icons/Notes";
import amber from "@material-ui/core/colors/amber";

const useStyles = makeStyles((theme) => ({
  num1: {
    fontSize: 13,
    color: "#222",
    display: "block",
  },
  num2: {
    display: "block",
    fontSize: 9,
    color: "#666",
  },
  popover: {
    pointerEvents: "none",
  },
  withText: {
    cursor: "s-resize",
  },
  empty: {
    opacity: ".1",
  },
  table: {
    maxWidth: 300,
  },
  text: {
    padding: 20,
  },
  ul: {
    listStyleType: "none",
    textAlign: "center",
  },
}));

export default ({ value, columns }) => {
  if (!value) value = { num: 0, all: 0 };
  // columns.length===1 ? v=
  // const v = value ? value: count()
  // console.log(value,columns)

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverToogle = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const getTableHead = () => {
    return (
      <TableHead>
        <TableRow>
          {columns.map((column) => (
            <TableCell style={{ width: column.width }}>{column.name}</TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  const getTableRows = () => {
    return value.data.map((v, i) => (
      <TableRow key={i}>
        {columns.map((column) => (
          <TableCell scope="row">{v && v[column.value]}</TableCell>
        ))}
      </TableRow>
    ));
  };
  let n = parseFloat(value.num / value.all);
  let cls = parseInt(n * 10) * 100;
  if (cls > 900) cls = 900;
  if (n > 0 && n < 0.1) cls = 100;

  return (
    <>
      <Avatar
        onClick={handlePopoverToogle}
        style={{ backgroundColor: amber[cls] }}
        variant="square"
      >
        <ul className={classes.ul}>
          <li className={classes.num1}>{value.num}</li>
          <li className={classes.num2}>{value.all}</li>
        </ul>
      </Avatar>
      {columns && (
        <Popper
          open={open}
          anchorEl={anchorEl}
          placement="bottom-start"
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                {columns ? (
                  <Table className={classes.table} aria-label="info table">
                    {getTableHead()}
                    <TableBody>{getTableRows()}</TableBody>
                  </Table>
                ) : (
                  <div className={classes.text}>{value}</div>
                )}
                <Button onClick={handlePopoverClose} style={{ width: "100%" }}>
                  Zamknij
                </Button>
              </Paper>
            </Fade>
          )}
        </Popper>
      )}
    </>
  );
};
