import React from "react";
import { Table, Loading, Input } from "elements";
import { useAuthDataContext } from "context/Context";
import GetAppIcon from "@material-ui/icons/GetApp";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import _ from "lodash";
import { useHistory } from "react-router";
import CachedIcon from "@material-ui/icons/Cached";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import LinkIcon from "@material-ui/icons/Link";
import { useQuery, useMutation } from "@apollo/client";
import { SOURCES } from "graphql/queries";
import { UPDATE_SOURCE, DELETE_SOURCE, CREATE_SOURCE } from "graphql/mutations";
import { getName } from "tools";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { useSnackbar } from "notistack";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Typography } from "@material-ui/core";

function SourcesODialog({ handleClose, onAdd }) {
  const [out, setOut] = React.useState(false);
  const values = React.useRef({});

  React.useEffect(() => {
    fetch(
      "https://zyuy27zsy9.execute-api.eu-west-1.amazonaws.com/default/externalSources"
    )
      .then((response) => response.json())
      .then((data) => {
        data.dict = {};
        data.endpoints.map((d) => (data.dict[d.id] = d.value));
        setOut(data);
      });
  }, []);

  if (!out) return <Loading />;

  console.log(out, "!!");

  const addIt = () => {
    onAdd(values.current);
    values.current = {};
    handleClose();
  };

  const onChange = (url) => {
    values.current = {
      ...values.current,
      value: out.dict[url],
      path: url,
    };
  };

  const onChangeF = (frequency) => {
    values.current = {
      ...values.current,
      frequency,
    };
  };

  return (
    <>
      <DialogTitle
        id="form-dialog-title"
        style={{ background: "#009688", fontWeight: "bold" }}
      >
        Żródla danych
      </DialogTitle>
      <DialogContent>
        <DialogContentText>Wybierz źródło danych</DialogContentText>

        <Input
          type="select"
          inputProps={{
            label: "Nazwa",
            data: out.endpoints,
            fullWidth: true,
            onChange: onChange,
          }}
        />

        {/* <Input type="select" 
      inputProps={{
          label: "Odświeanie",
          data: [
            { id: "0", value: "ręcznie" },
            { id: "30", value: "co 30 min." },
            { id: "60", value: "co godzinę" },
            { id: "1440", value: "raz dziennie" },
            { id: "43800", value: "raz w mies." },
          ],
          fullWidth: true,
          onChange: onChangeF
      }}
    /> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Anuluj
        </Button>
        <Button onClick={addIt} color="primary">
          Dodaj
        </Button>
      </DialogActions>
    </>
  );
}

function SourcesO({ showDetails, data }) {
  const [updateSource] = useMutation(UPDATE_SOURCE);
  const [createSource] = useMutation(CREATE_SOURCE);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [deleteSource] = useMutation(DELETE_SOURCE);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onDelete = async (ps) => {
    if (ps.length === 0) return false;

    let snackText = [
      ps.length > 1 ? getName("Source", 1) : getName("Source"),
      "",
      ps.length > 1 ? "zostały usunięte" : "zostało usunięte",
    ];
    let names = [];
    for (let i = ps.length - 1; i > 0; i--) {
      await deleteSource({ variables: { id: ps[i].id } });
      // names.push(ps[i].value);
    }
    await deleteSource({
      variables: { id: ps[0].id },
      refetchQueries: [{ query: SOURCES, variables: { out: true } }],
    });

    enqueueSnackbar(snackText.join(" "), { variant: "success" });
  };

  const onUpdate = async (idx, name, value) => {
    let row = data[idx];

    await updateSource({
      variables: { id: row.id, input: { [name]: value } },
      refetchQueries: [{ query: SOURCES, variables: { out: true } }],
    });

    enqueueSnackbar(`${getName("Source")} zostało zaktualizowane`, {
      variant: "success",
    });
  };

  const onAdd = async (input) => {
    const { data } = await createSource({
      variables: {
        input: { ...input, inout: false },
      },
      refetchQueries: [{ query: SOURCES, variables: { out: true } }],
    });
    enqueueSnackbar(`${getName("Source")} zostało dodane`, {
      variant: "success",
    });
  };

  return (
    <>
      <Table
        typename="Source"
        columns={[
          {
            Header: "Nazwa",
            accessor: "value",
            type: "label",
            width: "78%",
          },
          // {
          //   Header: "Odświeźanie",
          //   accessor: "frequency",
          //   type: "select",

          //   width: "15%",
          //   inputProps: {
          //     readOnly: true,
          //     data: [
          //       { id: "0", value: "ręcznie" },
          //       { id: "30", value: "co 30 min." },
          //       { id: "60", value: "co godzinę" },
          //       { id: "1440", value: "raz dziennie" },
          //       { id: "43800", value: "raz w mies." },              ],
          //   },
          // },
        ]}
        // onAdd={onAdd}
        onDelete={onDelete}
        updateMyData={onUpdate}
        rowActions={[
          { icon: <CachedIcon />, onClick: () => {} },
          { icon: <MoreHorizIcon />, onClick: (idx) => showDetails(data[idx]) },
        ]}
        toolbarButtons={[
          {
            label: getName("Source", 2) + " zewnętrzne",
            icon: <AddBoxIcon />,
            onClick: handleClickOpen,
          },
        ]}
        // {...props}
        data={data}
      />
      <Dialog open={open} onClose={handleClose}>
        <SourcesODialog handleClose={handleClose} onAdd={onAdd} />
      </Dialog>
    </>
  );
}

export default (props) => {
  const { data, loading } = useQuery(SOURCES, { variables: { out: true } });

  if (loading) return <Loading />;

  return (
    <SourcesO data={data.sources.sourceFeed} showDetails={props.showDetails} />
  );
};
