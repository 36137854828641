import React from "react";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { getName } from "tools";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { Form } from "elements";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { INSTANCES } from "graphql/queries";
import { UPDATE_INSTANCE } from "graphql/mutations";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
  variant: {
    cursor: "pointer",
    float: "left",
    "&::after": {
      content: "'→'",
    },
    "&:last-child::after": {
      content: "''",
    },
  },
}));

export default (props) => {
  const [value, setValue] = React.useState(null);
  const [updateInstance] = useMutation(UPDATE_INSTANCE);

  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = React.useState(false);
  // const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();
  const form = useForm();

  React.useEffect(() => {
    console.log(123, props.value);
    setValue(props.value ? JSON.parse(props.value) : null);
  }, [props.value]);

  const update = async () => {};

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdate = async ({ variants }) => {
    const refetchQueries = [{ query: INSTANCES }];

    setOpen(false);
    const { data } = await updateInstance({
      variables: {
        id: value[0].instanceId,
        input: { variants: variants.split(",") },
      },
      refetchQueries,
    });
    enqueueSnackbar(`${getName("Instance")} została zaktualizowana`, {
      variant: "success",
    });
  };

  const fields = [
    {
      label: getName("Variant", 1),
      name: "variants",
      type: "variantFromPerspectives",
      value,
      validation: { required: true },
    },
  ];
  if (!value) return <></>;

  return (
    <>
      <div onClick={handleOpen}>
        {value.map((vv, i) => (
          <div key={i} title={vv.perspective} className={classes.variant}>
            {vv.variant}
          </div>
        ))}
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={form.handleSubmit(handleUpdate)}>
          <DialogTitle id="form-dialog-title">
            {getName("Instance", 2)}
          </DialogTitle>
          <DialogContent>
            <Form fields={fields} form={form} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Anuluj
            </Button>
            <Button type="submit" color="primary">
              Zapisz
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
