import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { getError } from "tools";
const useStyles = makeStyles((theme) => ({
  container: {},
  formControl: {
    // minWidth: '100%',
    // paddingLeft: 20
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default (props) => {
  const {
    value,
    label,
    data,
    name,
    validation,
    error,
    onChange,
    setValue,
    register,
    disabled,
    readOnly,
  } = props;
  const classes = useStyles();
  const [v, setV] = useState(value || undefined);

  useEffect(() => {
    register && register({ name }, validation);
  }, [register]);

  useEffect(() => {
    setV(value);
  }, [value]);

  const setIt = (e) => {
    setV(e.target.value);
    setValue && setValue(name, e.target.value);
    onChange && onChange(e.target.value);
  };

  const onClose = () => {
    // !props.focusOnSelect && setTimeout(() => {
    //     document.activeElement.blur();
    // }, 0);

    setTimeout(() => {
      document.activeElement.blur();
    }, 0);
  };

  return (
    <div className={classes.container}>
      <FormControl
        className={classes.formControl}
        style={{ minWidth: "100%" }}
        error={error && true}
        size="small"
      >
        <InputLabel>{label}</InputLabel>
        <Select
          value={v}
          label={label}
          onChange={setIt}
          disabled={disabled}
          onClose={onClose}
          readOnly={readOnly}
        >
          {data.map((d) => (
            <MenuItem key={d.id} value={d.id}>
              {d.value}
            </MenuItem>
          ))}
        </Select>
        {error && <FormHelperText>{getError(error.type)}</FormHelperText>}
        {props.register && (
          <input
            type="hidden"
            value={v}
            name={props.name}
            ref={props.register(props.validation || {})}
          />
        )}
      </FormControl>
    </div>
  );
};
