import React from 'react'
import { Select } from 'elements'
import { useQuery } from '@apollo/client';
// import { VARIANTS } from 'graphql/queries'
import { PERSPECTIVES } from 'graphql/queries'


export default (props) => {
    const {data, loading} = useQuery(PERSPECTIVES)
    const [value, setValue] = React.useState(props.value ? props.value.map(v=>v.variantId) : [])
    let pValues = [], variants = {}, selects = []

    const onChange = (i) => (_value) => {
        let v = value.filter(v=>variants[v].perspective.id!==variants[_value].perspective.id)
        v.push(_value)

        setValue([...v])
    }

    if(loading) return false


    data.perspectives.perspectiveFeed.map((p,i)=>{
        p.variants.map(v=>variants[v.id]={...v, perspective: p})
        p.variants.length>0 && selects.push(
            <div key={"vfp"+i}>
                <Select fullWidth={true} onChange={onChange(i)} label={p.value} data={p.variants} value={props.value && props.value[i] ? props.value[i].variantId : undefined} />
            </div>)
    })
    value.map(v=>pValues.push(variants[v].perspective.id))


    return <span>
        {selects}
        <input type="hidden" ref={props.register()} name={props.name} value={value} />
    </span>
    // <MultiSelect {...props} data={data.variants.variantFeed} />
}