import React from "react";

import { RightBar, Panel } from "elements";
import { TaskDetails, TaskList, TaskListClose } from "./components";
import { makeStyles } from "@material-ui/styles";
import { getName } from "tools";
import _ from "lodash";
import { AppBar, Typography, Box } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {
  // BrowserRouter as Router,
  // Switch,
  // Route,
  // Link,
  useParams,
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    height: "100%",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    width: "20%",
  },
  tabsContent: {
    width: "80%",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default () => {
  let { num } = useParams();

  const classes = useStyles();
  const [value, setValue] = React.useState(parseInt(num) || 0);
  const [, reload] = React.useState(0);
  const [rightbarData, setRightbarData] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        orientation="vertical"
        variant="scrollable"
        className={classes.tabs}
      >
        <Tab label={getName("Task", 1) + " aktywne"} {...a11yProps(0)} />
        <Tab label={getName("Task", 1) + " wdrożone"} {...a11yProps(1)} />
      </Tabs>

      <TabPanel value={value} index={0} className={classes.tabsContent}>
        <Panel
          subheader={"Lista otwartych działań naprawczych"}
          title={getName("Task", 1) + " aktywne"}
        >
          <TaskList />
        </Panel>
      </TabPanel>
      <TabPanel value={value} index={1} className={classes.tabsContent}>
        <Panel
          subheader={"Lista zamkniętych działań naprawczych"}
          title={getName("Task", 1) + " wdrożone"}
        >
          <TaskListClose />
        </Panel>
      </TabPanel>

      <RightBar open={rightbarData} onClose={() => setRightbarData(false)}>
        {rightbarData.__typename === "Task" && (
          <TaskDetails id={rightbarData.id} />
        )}
      </RightBar>
    </div>
  );
};

{
  /* <button onClick={onClick}> Dodaj zakaldke </button> */
}
