import React from "react";
import ReactDOM from "react-dom";
// import './index.css';
import App from "./App";
import AppUnauth from "./AppUnauth";
// import './i18n';
import * as serviceWorker from "./serviceWorker";
import {
  ApolloProvider,
  useQuery,
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  split,
} from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { WebSocketLink } from "@apollo/link-ws";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { createUploadLink } from "apollo-upload-client";

import { HTTP_URL, WS_URL } from "vars";
import moment from "moment";
moment.locale("pl");

const wsClient = new SubscriptionClient(WS_URL, {
  reconnect: true,
  connectionParams: {
    authToken: localStorage.getItem("token"),
  },
});

const uploadLink = createUploadLink({
  // nie potrzeba httpLink
  uri: HTTP_URL, // Apollo Server is served from port 4000
  headers: {
    "keep-alive": "true",
    Authorization: "Bearer " + localStorage.getItem("token"),
    "client-name": "Armour [web]",
    "client-version": "0.0.1",
  },
});

const wsLink = new WebSocketLink(wsClient);

const terminatingLink = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  uploadLink
);

const link = ApolloLink.from([terminatingLink]);
const cache = new InMemoryCache();

const client = new ApolloClient({
  link,
  cache,
});

function IsLoggedIn() {
  const pth = window.location.pathname.split("/");
  return pth.length >= 4 && pth[1] === "un" ? (
    <AppUnauth type={pth[2]} id={pth[3]} tab={pth.length === 5 ? pth[4] : 0} />
  ) : (
    <App />
  );
}

ReactDOM.render(
  <ApolloProvider client={client}>{IsLoggedIn()}</ApolloProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
