import React, { useContext } from "react";
import moment from "moment";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useQuery, useMutation } from "@apollo/client";
import { PROCESS } from "graphql/queries";
// import { useSnackbar } from "notistack";
import { UPDATE_PROCES, DELETE_PROCES, CREATE_PROCES } from "graphql/mutations";
// import Button from "@material-ui/core/Button";
import { useAuthDataContext } from "context/Context";
// import { useAuthDataContext } from "context/Context";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
// import { isEmpty } from "underscore";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Loading } from "elements";

import { useSnackbar } from "notistack";
import {
  // BrowserRouter as Router,
  // Switch,
  // Route,
  useLocation,
  useParams,
} from "react-router-dom";
import { getName } from "tools";
import { Panel, RightBar, Table, ActionsList } from "elements";

// import { DrawActions } from "elements/inputs/drawselect/drawactions";
import AppBar from "@material-ui/core/AppBar";
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import _ from "lodash";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    padding: 0,
  },

  container: {
    padding: "2%",
  },
}));

function Process(props) {
  let { data } = props;
  let data2 = JSON.parse(JSON.stringify(data));

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [rightbarData, setRightbarData] = React.useState(false);
  const actionsRef = React.useRef(false);
  const actionsLevels = React.useRef(false);

  const { settings, setSettings } = useAuthDataContext();

  const [updateProces] = useMutation(UPDATE_PROCES);
  const [createProces] = useMutation(CREATE_PROCES);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [deleteProces] = useMutation(DELETE_PROCES);
  const history = useHistory();

  let location = useLocation();
  React.useEffect(() => {
    try {
      setValue(
        parseInt(location.pathname.match(/\/\d+\/?$/)[0].replace(/\//, ""))
      );
    } catch (err) {
      setValue(0);
    }
  }, [location]);

  // React.useEffect(() => {
  //   data2.map(d=>{
  //     d.onClick = onDrawSelectClick
  //     if(d.actionsLevels) d.actionsLevels=JSON.parse(d.actionsLevels)
  //     else d.actionsLevels = {}
  //   })

  // });

  const handleChange = (_, newValue) => {
    let p = window.location.pathname.replace(/\/\d+\/?$/, "") + "/" + newValue;
    window.history.pushState({}, null, p);
    // setPath(p);
    setValue(newValue);
  };

  // const handleChangeIndex = (index) => {
  //   setValue(index);
  // };

  // const showDetails = (idx) => {
  //   console.log({idx, ...data.alerts[idx]})
  //   setRightbarData({__typename : "Data"});
  // };

  const onDrawSelectClick = (obj) => {
    setRightbarData({
      __typename: "Action",
      value: obj.value,
      actions: obj.actions,
      actionsLevels: obj.actionsLevels,
      id: obj.id,
    });
  };

  const onAdd = async ({ value, description }) => {
    const { data } = await createProces({
      variables: {
        input: { value, description },
      },
      refetchQueries: [{ query: PROCESS }],
    });
    enqueueSnackbar(`Proces został dodany`, { variant: "success" });
  };

  const onDelete = async (ps) => {
    if (ps.length === 0) return false;

    let snackText = [
      ps.length > 1 ? getName("Proces", 1) : getName("Proces"),
      "",
      ps.length > 1 ? "zostały usunięte" : "został usunięty",
    ];
    let names = [];
    for (let i = ps.length - 1; i > 0; i--) {
      await deleteProces({ variables: { id: ps[i].id } });
      names.push(ps[i].value);
    }
    await deleteProces({
      variables: { id: ps[0].id },
      refetchQueries: [{ query: PROCESS }],
    });

    enqueueSnackbar(snackText.join(" "), { variant: "success" });
  };

  const onUpdate = async (idx, name, value) => {
    let row = props.data[idx];
    // console.log(idx,name,value, row)
    const refetchQueries = [{ query: PROCESS }];

    const { data } = await updateProces({
      variables: { id: row.id, input: { [name]: value } },
      refetchQueries,
    });

    enqueueSnackbar(`${getName("Proces")} został zaktualizowany`, {
      variant: "success",
    });
  };

  const onUpdateActions = async (d, value) => {
    actionsRef.current = { id: d.id, input: { actions: value } };
  };

  const onUpdateActionsLevels = async (d, id, value) => {
    d.actionsLevels = { ...d.actionsLevels, [id]: value };
    actionsLevels.current = {
      id: d.id,
      input: { actionsLevels: JSON.stringify(d.actionsLevels) },
    };
  };

  const closeDrawer = async () => {
    if (actionsLevels.current !== false) {
      const { data } = await updateProces({
        variables: actionsLevels.current,
        refetchQueries: [{ query: PROCESS }],
      });
    }

    if (actionsRef.current !== false) {
      const { data } = await updateProces({
        variables: actionsRef.current,
        refetchQueries: [{ query: PROCESS }],
      });
    }

    if (actionsRef.current !== false || actionsLevels.current !== false) {
      enqueueSnackbar(`${getName("Proces")} został zaktualizowany`, {
        variant: "success",
      });
      actionsRef.current = false;
      actionsLevels.current = false;
    }

    setRightbarData(false);
  };
  function goToProces(i) {
    const proces = props.data[i];
    const tabs = settings.tabs ? [...settings.tabs] : [];

    if (_.findIndex(tabs, { id: proces.id }) < 0) {
      setSettings({
        tabs: [
          {
            id: proces.id,
            title:
              proces.value.length > 20
                ? proces.value.slice(0, 20) + "..."
                : proces.value,
            full: proces.value,
            parent: null,
            href: "/cprc/" + proces.id,
          },
          ...tabs,
        ],
      });
    }

    history.push("/cprc/" + proces.id);
  }

  return (
    <div className={classes.root}>
      <TabPanel value={value} index={0} dir={theme.direction}>
        <Panel subheader={"Lista procesów"} title={"Proces"}>
          <Table
            typename="Proces"
            columns={[
              {
                Header: "Nazwa",
                accessor: "value",
                type: "text",
                width: "60%",
              },
              {
                Header: "Opis",
                accessor: "description",
                type: "longText",
                width: "15%",
              },
            ]}
            // toolbarButtons={[
            //   {
            //     label: "Nowe",
            //     icon: <AddCircleIcon />,
            //     onClick: onAdd,

            //   }
            // ]}

            // setData={setData}
            updateMyData={onUpdate}
            // skipPageReset={true}
            rowActions={[{ icon: <DoubleArrowIcon />, onClick: goToProces }]}
            onAdd={onAdd}
            onDelete={onDelete}
            // rowActions={[{ icon: <MoreHorizIcon />, onClick: ()=>setRightbarData({__typename: "Data"}) }]}

            // {...props}
            data={data2}
          />
        </Panel>
      </TabPanel>

      {/* <TabPanel value={value} index={1} dir={theme.direction}>
        <Panel>
         
          <Outer showDetails={ showData} />
            
          
        </Panel>
      </TabPanel> */}

      <RightBar open={rightbarData !== false} onClose={closeDrawer}>
        {rightbarData.__typename === "Action" && (
          <ActionsList
            data={rightbarData}
            onUpdate={onUpdateActions}
            onUpdateLevels={onUpdateActionsLevels}
          />
        )}
      </RightBar>
    </div>
  );
}

export default (props) => {
  let { num } = useParams();

  const r = useQuery(PROCESS);

  // const { data, loading} = useQuery(PROCES, {
  //   variables: { id: '60dd8e5e4a049059af66d474' },
  //   fetchPolicy: "no-cache"
  // });

  if (r.loading) return <Loading />;

  return <Process num={num} data={r.data.process.procesFeed} />;

  // return <ContinuousTest refetch={refetch} data={data.audit} num={num ? parseInt(num) : 0} />;
};
