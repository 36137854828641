import React, { useEffect, useState } from "react";
import { useAuthDataContext } from "context/Context";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
// import { instancesName } from
import { LineChart, Input } from "elements";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ padding: "0 20px" }}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "5%",
  },
}));

function InstanceDetils(props) {
  const { settings, systemSettings } = useAuthDataContext();
  const classes = useStyles();
  const dateFromat = settings.dateFormat || "YYYY-MM-DD";

  const getPrev = (data) => {
    let j = 0,
      i = 0,
      pre = [];
    data.prevRisks.map((p) => {
      pre[i]
        ? pre[i].push({
            risk: p.risk / 10.0,
            time: moment.unix(p.time).format(dateFromat),
          })
        : (pre[i] = [
            {
              risk: p.risk / 10.0,
              time: moment.unix(p.time).format(dateFromat),
            },
          ]);

      j += 1;
      if (j === 10) {
        i += 1;
        j = 0;
      }
    });
    return pre;
  };

  const [idx, setIdx] = useState(0);
  const [prev, setPrev] = useState();
  const [value, setValue] = React.useState(0);
  const getOptions = () => {
    let rL = {},
      opt = [0, 2, 4, 6, 8, 10];
    systemSettings.riskLabel.map((r, i) => (rL[opt[i]] = r));
    return rL;
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setPrev(getPrev(props.data));
    return () => {};
  }, [props.data]);

  if (!prev) return false;

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs value={value} onChange={handleChange} aria-label="">
          <Tab label="Szczególy" {...a11yProps(0)} />
          {/* <Tab label="Log" {...a11yProps(1)} /> */}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <div className={classes.container}>
          <div>
            Nazwa:
            <Input
              type="instancesName"
              inputProps={{ value: props.data.value }}
            />
            <br />
          </div>
          <div>Ocena ryzyka: {props.data.risk / 10.0}</div> <br />
          <div>
            Ostatnia ocena ryzyka:{" "}
            {props.data.prevRisks.length
              ? moment.unix(props.data.prevRisks[0].time).format(dateFromat)
              : "-"}
          </div>
          <br />
          <div>Historia oceny ryzyka</div>
          <br />
          {prev.length > 0 && (
            <LineChart
              width={500}
              height={300}
              data={prev[0].reverse()}
              dataKey="risk"
              options={getOptions()}
            />
          )}
          {prev.length === 0 && <div> Brak historii</div>}
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        in progress
      </TabPanel>
    </div>
  );
}

export default (props) => {
  //   const { data, loading } = useQuery(INSTANCE, {
  //     variables: { id: props.id },
  //   });

  //   if (loading) return false;

  //   console.log(props, data);
  return <InstanceDetils data={props.data} />;
};
