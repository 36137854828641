import React from "react";
import { useHistory, useLocation } from "react-router-dom";
// import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/styles";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { Grid, Typography, Paper } from "@material-ui/core";
import { Text, ButtonProgress } from "elements";
import gql from "graphql-tag";
import { useSnackbar } from "notistack";
// import Alert from '@material-ui/lab/Alert';
import { Logo } from "elements";

const LOGIN_USER = gql`
  mutation Login($email: String!, $password: String!) {
    login(input: { email: $email, password: $password }) {
      token
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#009688",
    height: "100%",
  },

  content: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  contentHeader: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  logoImage: {
    marginLeft: theme.spacing(4),
  },
  contentBody: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },

  title: {
    marginTop: theme.spacing(3),
    textAlign: "center",
    fontWeight: "bold",
  },
  logo: {
    position: "absolute",
    top: "10%",
    left: "40%",
  },

  form: {
    position: "absolute",
    top: "20%",
    left: "30%",
    background: "white",
    zIndex: 100,
    paddingLeft: 50,
    paddingRight: 50,

    flexBasis: 700,

    height: "50%",
    width: "30%",
    opacity: 0.9,
  },
  banner: {
    zIndex: 99999999,
  },
}));

export default (props) => {
  // const { history } = props;
  const history = useHistory();
  const location = useLocation();
  const { register, handleSubmit, errors, setError } = useForm();
  const { enqueueSnackbar } = useSnackbar();
  // const [ err, setErr ] = React.useState(null)
  const [status, setStatus] = React.useState("");
  const classes = useStyles();
  // console.log("===", location.pathname )
  // const client = useApolloClient();
  const [login, { loading, error }] = useMutation(LOGIN_USER, {
    onCompleted({ login: { token } }) {
      localStorage.setItem("token", token);
      // client.writeData({ data: { isLoggedIn: true } });
      location.pathname === "/sign-in"
        ? history.push("/")
        : window.location.reload();
    },
  });

  const signIn = async (values) => {
    const { email, password } = values;

    if (!email.length || !password.length) {
      if (!email.length && !password.length) {
        enqueueSnackbar("Podaj email i hasło", { variant: "info" });
        setError(
          "email",
          "required",
          "Użytkownik o tym adresie email nie istnieje"
        );
        setError(
          "password",
          "required",
          "Użytkownik o tym adresie email nie istnieje"
        );
      } else if (!email.length) {
        enqueueSnackbar("Podaj email", { variant: "info" });
        setError(
          "email",
          "required",
          "Użytkownik o tym adresie email nie istnieje"
        );
      } else if (!password.length) {
        enqueueSnackbar("Podaj hasło", { variant: "info" });
        setError(
          "password",
          "required",
          "Użytkownik o tym adresie email nie istnieje"
        );
      }

      return false;
    }

    setStatus("loading");

    try {
      await login({ variables: { email, password } });
    } catch (err) {
      console.log(err.message);
      if (err.message === "GraphQL error: User not found") {
        enqueueSnackbar("Użytkownik o tym adresie email nie istnieje", {
          variant: "error",
        });
        setError(
          "email",
          "notMatch",
          "Użytkownik o tym adresie email nie istnieje"
        );
      } else if (err.message === "GraphQL error: Incorrect Password") {
        enqueueSnackbar("Hasło nie jest poprawne", { variant: "error" });
        setError(
          "email",
          "notMatch",
          "Użytkownik o tym adresie email nie istnieje"
        );
      }
      setStatus("");
    }

    return true;
  };

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.quoteContainer} item lg={5}>
          <img className={classes.banner} src="01.jpg" title="" alt="" />
        </Grid>
        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <div className={classes.logo}>
                <Logo style="big" />
              </div>

              <Paper elevation={5} className={classes.form}>
                <form onSubmit={handleSubmit(signIn)}>
                  <Typography className={classes.title} variant="h3">
                    Panel logowania
                  </Typography>
                  <br />
                  <br />
                  <br />

                  <Text
                    required
                    label="Adres email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    register={register}
                    disabled={status === "loading"}
                    error={errors.email}
                  />
                  <br />
                  <Text
                    required
                    name="password"
                    label="Hasło"
                    type="password"
                    autoComplete="current-password"
                    register={register}
                    disabled={status === "loading"}
                    error={errors.password}
                  />
                  <br />
                  <ButtonProgress
                    type="submit"
                    status={status}
                    label="Zaloguj"
                    variant="contained"
                  />
                </form>
              </Paper>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
