import React, { useContext } from "react";
import moment from "moment";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useQuery, useMutation } from "@apollo/client";
import { ACTIONS, TESTC } from "graphql/queries";
import {
  CREATE_MESSAGE,
  DELETE_MESSAGES,
  UPDATE_ACTION,
  UPDATE_TESTSC,
} from "graphql/mutations";
import Button from "@material-ui/core/Button";
import { Actions, Outputs, First, Data, Risks, Inputs } from "./components";
import { useSnackbar } from "notistack";
import {
  // BrowserRouter as Router,
  // Switch,
  // Route,
  useLocation,
  Link,
  useParams,
} from "react-router-dom";
import { getName } from "tools";
import {
  Loading,
  Panel,
  Title,
  RightBar,
  ReceiverList,
  RuleList,
  RiskList,
} from "elements";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import AppBar from "@material-ui/core/AppBar";
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import { map } from "lodash";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    height: "100%",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    width: "20%",
  },
  tabsContent: {
    width: "80%",
  },
}));

function ContinuousTest({ data, num, refetch }) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(num);
  const newRules = React.useRef(false);

  const [rightbarData, setRightbarData] = React.useState(false);
  const [createMessage] = useMutation(CREATE_MESSAGE);
  const [deleteMessages] = useMutation(DELETE_MESSAGES);
  const [updateAction] = useMutation(UPDATE_ACTION);
  const [updateTestc] = useMutation(UPDATE_TESTSC);
  const messages = React.useRef(false);
  const { enqueueSnackbar } = useSnackbar();

  let location = useLocation();
  React.useEffect(() => {
    try {
      setValue(
        parseInt(location.pathname.match(/\/\d+\/?$/)[0].replace(/\//, ""))
      );
    } catch (err) {
      setValue(0);
    }
  }, [location]);

  const handleChange = (_, newValue) => {
    let p = window.location.pathname.replace(/\/\d+\/?$/, "") + "/" + newValue;
    window.history.pushState({}, null, p);
    // setPath(p);
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const showData = (idx) => {
    console.log({ idx, ...data.alerts[idx] });
    setRightbarData({ __typename: "Data" });
  };

  // const showAlert = (idx) => {
  //   console.log({idx, ...data.alerts[idx]})
  //   setRightbarData({idx, ...data.alerts[idx]});
  // };

  const addToAlert = (what, values) => {
    let alerts = [...data.alerts];
    try {
      alerts[rightbarData.idx][what].list.push(values);
    } catch (err) {
      alerts[rightbarData.idx][what].list = [values];
    }

    // console.log(alerts)

    // setData({...data, alerts})
  };

  const onUpdateReceiver = async (values) => {
    let current = {};
    values.map((v) => {
      if (messages.current[v]) current[v] = messages.current[v];
      else current[v] = "";
    });
    messages.current = { ...current };
    console.log();
  };

  const onUpdateReceiverMessage = async (id, value) => {
    let current = { ...messages.current };
    current[id] = value;
    messages.current = { ...current };
  };

  const addMessage = async (input) => {
    // let arr=[...m]
    const result = await createMessage({
      variables: { input },
      // refetchQueries: [
      //   { query: ACTIONS },
      //   { query: TESTS, variables: { audit: props.audit.id } },
      // ],
    });
    // arr.push(result.data.createMessage.id)
    return result.data.createMessage.id;
  };

  const onClose = async () => {
    if (messages.current) {
      const unresolvedPromises = Object.keys(messages.current).map((receiver) =>
        addMessage({
          value: messages.current[receiver],
          action: rightbarData.id,
          receiver,
        })
      );
      const results = await Promise.all(unresolvedPromises);

      await updateAction({
        variables: { id: rightbarData.id, input: { messages: results } },
        refetchQueries: [{ query: ACTIONS, variables: { testc: data.id } }],
      });

      await deleteMessages({
        variables: { input: { ids: map(rightbarData.messages, "id") } },
        // refetchQueries: [
        //   { query: ACTIONS },
        //   { query: TESTS, variables: { audit: props.audit.id } },
        // ],
      });

      enqueueSnackbar(`${getName("Action")} zostało zaktualizowane`, {
        variant: "success",
      });
      messages.current = false;
    } else if (newRules.current) {
      console.log(newRules.current);

      // const rules = JSON.stringify({ cond: newRules.current['cond'], list:newRules.current['list'] })

      await updateAction({
        variables: {
          id: rightbarData.id,
          input: { rules: JSON.stringify(newRules.current) },
        },
        refetchQueries: [{ query: ACTIONS, variables: { testc: data.id } }],
      });

      newRules.current = false;
      enqueueSnackbar(`${getName("Testc")} został zaktualizowany`, {
        variant: "success",
      });
    }

    setRightbarData(false);
  };

  const onUpdate = async (id, input) => {
    await updateTestc({
      variables: { id, input },
    });
    refetch();
    enqueueSnackbar(`${getName("Testc")} został zaktualizowany`, {
      variant: "success",
    });
  };

  const onUpdateRule = (rules) => (newRules.current = rules);

  const onUpdateRisks = async (risks, id) => {
    await updateTestc({
      variables: { id, input: { risks } },
    });

    refetch();

    enqueueSnackbar(`${getName("Testc")} został zaktualizowany`, {
      variant: "success",
    });
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        orientation="vertical"
        variant="scrollable"
        className={classes.tabs}
      >
        <Tab color="testc" label="Wynik" {...a11yProps(0)} />
        <Tab color="testc" label="Ryzyka" {...a11yProps(1)} />
        <Tab color="testc" label="Zródła danych" {...a11yProps(2)} />
        <Tab color="testc" label="Zmienne wejściowe" {...a11yProps(3)} />
        <Tab color="testc" label="Zmienne wyjściowe" {...a11yProps(4)} />

        <Tab color="testc" label="Działania naprawcze" {...a11yProps(5)} />
      </Tabs>

      <TabPanel value={value} index={0} className={classes.tabsContent}>
        <Panel>
          <First data={data} onUpdate={onUpdate} />
        </Panel>
      </TabPanel>
      <TabPanel value={value} index={1} className={classes.tabsContent}>
        <Panel>
          <Risks
            refetch={refetch}
            testc={data.id}
            data={data.risks}
            setRightbarData={setRightbarData}
          />
        </Panel>
      </TabPanel>

      <TabPanel value={value} index={2} className={classes.tabsContent}>
        <Panel>
          <Data
            showDetails={showData}
            testc={data}
            onUpdate={onUpdate}
            refetch={refetch}
          />
        </Panel>
      </TabPanel>

      <TabPanel value={value} index={3} className={classes.tabsContent}>
        <Panel>
          <Inputs testc={data} onUpdate={onUpdate} />
        </Panel>
      </TabPanel>
      <TabPanel value={value} index={4} className={classes.tabsContent}>
        <Panel>
          <Outputs data={data} />
        </Panel>
      </TabPanel>

      <TabPanel value={value} index={5} className={classes.tabsContent}>
        <Panel>
          <Actions testc={data.id} showDetails={setRightbarData} />
        </Panel>
      </TabPanel>

      <RightBar open={rightbarData !== false} onClose={onClose}>
        {rightbarData.__typename === "Rule" && (
          <RuleList
            alert={rightbarData}
            addToAlert={addToAlert}
            onUpdate={onUpdateRule}
            outputs={JSON.parse(data.outputs || "[]")}
          />
        )}

        {rightbarData.__typename === "Receiver" && (
          <ReceiverList
            data={rightbarData}
            onUpdate={onUpdateReceiver}
            onUpdateEx={onUpdateReceiverMessage}
          />
        )}
        {rightbarData.__typename === "Risk" && (
          <RiskList data={rightbarData} onUpdate={onUpdateRisks} />
        )}
      </RightBar>
    </div>
  );
}

export default (props) => {
  let { id, num } = useParams();

  let { data, loading, refetch } = useQuery(TESTC, {
    variables: { id },
    pollInterval: 5000,
    fetchPolicy: "no-cache",
  });

  // if (loading) return false;
  // if (!data.audit) {
  //   enqueueSnackbar(`${getName("Audit")} został usunięty`, {
  //     variant: "error",
  //   });
  //   return <ErrorPage />;
  // }

  //   const [ data, setData ] = React.useState({id, value: "Wykrycie rocznego trendu wzrostowego w liczbie transakcji o wartości spełniającej test Benforda",
  //   vars: [
  //     { name: "dane", type: "period", displayName: "Okres", hint: "Od kiedy mają zostać pobrane dane do audytu.", default: "1m"},
  //     { name: "start", type: "date", displayName: "Początek audytu", hint: "W jakim okresie dane liczbowe mają zostać zagregowane.", default: "-1y"}
  // ] ,
  //   outputs: [
  //     { name: "slope", type: "normalized_double", displayName: "Współczynnik nachylenia krzywej", hint:" Wartość ta wskazuje na trend wzrostowy (>0) lub spadkowy (<0) w badanym okresie. Wartości bliskie 0 świadczą o braku trendu. Wartościami skrajnymi są -1 oraz 1.", example:"0.4"},
  //     { name: "mean", type: "normalized_unsigned_double", displayName: "Średnia wartość", hint:" Średnia wartość współczynnika testu Benforda. Jeśli wartość ta jest większa od przyjętego progu istotności to należy odrzucić hipotezę o zachodzeniu testu  Benforda. Przykładowo jeśli wartość 'mean'=0.1 to oznacza że przy progu istotności 0.05, należy odrzucić hipotezę testu Benforda i przyjąć, że dane wejściowe mają dopuszczalny rozkład.", example:"0.024"}

  // ],
  //   risks: [
  //     {id: 1, value: "Ryzyko spadku kursu usd"},
  //     {id: 2, value: "Ryzyko wzrostu ceny ropy"}
  //   ],

  // actions: [
  //   { value: "Informacja dla zarządu",  uid: 1,
  //   __typename:"Alert",
  //   ifs: { cond: 'and', list: [["Współczynnik nachylenia krzywej", ">", "0.5"],["Średnia wartość", ">", "0"]]} ,
  //   actions:[],
  // },

  //   { value: "Alert dla działu audytu",  uid: 1,
  //   __typename:"Alert",
  //   ifs: { cond: 'and', list: [["Współczynnik nachylenia krzywej", ">", "0"] ]} ,
  //   actions:[],
  // },

  // })
  if (loading) return <Loading />;

  return (
    <ContinuousTest
      refetch={refetch}
      data={data.testc}
      num={parseInt(num) || 0}
    />
  );

  // return <ContinuousTest refetch={refetch} data={data.audit} num={num ? parseInt(num) : 0} />;
};
