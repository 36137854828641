import React from "react";
import { useAuthDataContext } from "context/Context";
import { RightBar, MultiSelect, Panel } from "elements";
import { useQuery } from "@apollo/client";
import {
  // AUDITS,
  AUDITGROUPS,
  USERS,
  // SELECTEDAUDITGROUPS,
} from "graphql/queries";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
// import { useSnackbar } from 'notistack';
import { AuditList, AuditGroupList, AuditPlan } from "./components";
import { isEmpty } from "underscore";
import { makeStyles } from "@material-ui/styles";
// import { useApolloClient } from '@apollo/react-hooks';
import { getName } from "tools";
import {
  // BrowserRouter as Router,
  // Switch,
  // Route,
  // Link,
  useLocation,
  useParams,
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  tab: {
    // paddingRight: 45
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default () => {
  let { num } = useParams();
  const {
    user: { permissions },
    settings: { auditGroupFilter },
    setSettings,
  } = useAuthDataContext();
  const [ids, setIds] = React.useState(auditGroupFilter || []);
  const ag = useQuery(AUDITGROUPS);
  const u = useQuery(USERS);
  const classes = useStyles();
  const [value, setValue] = React.useState(parseInt(num) || 0);

  const [rightbarData, setRightbarData] = React.useState({});
  let location = useLocation();

  React.useEffect(() => {
    try {
      setValue(
        parseInt(location.pathname.match(/\/\d+\/?$/)[0].replace(/\//, ""))
      );
    } catch (err) {
      setValue(0);
    }
  }, [location]);

  const handleChange = (event, newValue) => {
    let p = window.location.pathname.replace(/\/\d+\/?$/, "") + "/" + newValue;
    window.history.pushState({}, null, p);
    setValue(newValue);
  };

  const setSelected = (_1, _2, _3) => {
    setRightbarData(_1);
  };

  const changeAuditGroup = (_ids) => {
    setIds(_ids);
    setSettings({ auditGroupFilter: _ids });
  };

  const getRightBar = () => {
    if (isEmpty(rightbarData.current)) return null;
    return null;
  };

  if (ag.loading || u.loading) return false;

  return (
    <div>
      <AppBar position="static" color="transparent">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label={getName("Audit", 1)} {...a11yProps(0)} />
          {permissions.audit.audit_viewTags && (
            <Tab label={getName("AuditGroup", 1)} {...a11yProps(1)} />
          )}
          <Tab label={getName("AuditPlan", 1)} {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} className={classes.tab}>
        <Panel
          subheader={
            <MultiSelect
              label={getName("Audit", 1)}
              value={ids}
              onChange={changeAuditGroup}
              data={ag.data.auditGroups}
              noBorder={true}
            />
          }
          title={getName("Audit")}
        >
          <AuditList ids={ids} onSelect={setSelected} users={u.data.users} />
        </Panel>
      </TabPanel>
      {permissions.audit.audit_viewTags && (
        <TabPanel
          className="treeWithSelection"
          value={value}
          index={1}
          className={classes.tab}
        >
          <Panel
            subheader={
              <div className="subheader">Grupowanie audytów w zbiory</div>
            }
            title={getName("AuditGroup")}
          >
            <AuditGroupList data={ag.data.auditGroups} />
          </Panel>
        </TabPanel>
      )}
      <TabPanel
        className="treeWithSelection"
        value={value}
        index={2}
        className={classes.tab}
      >
        <Panel
          subheader={
            <div className="subheader">
              Lista standardowych programów audytowych
            </div>
          }
          title={getName("AuditPlan")}
        >
          <AuditPlan />
        </Panel>
      </TabPanel>
    </div>
  );
};
