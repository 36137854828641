import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Grid,
  Divider,
  Fab
} from '@material-ui/core';
import { useForm } from "react-hook-form";
import SaveIcon from '@material-ui/icons/Save';
import { useSnackbar } from 'notistack';
import {Text, Select, Tags} from 'elements'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';



const useStyles = makeStyles(() => ({
  root: { width: '49%', float: 'left', marginBottom: 20},
  // root2: { width: '49%', float: 'left'},
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

export default({systemSettings, setSystemSettings}) => {
  const classes = useStyles();
  const { register, handleSubmit, setValue, getValues, unregister } = useForm();
  const [save, setSave] = React.useState(false)
  const [scale, setScale] = React.useState(systemSettings.riskScale1? "two":"one")
  const { enqueueSnackbar } = useSnackbar();


  const onSubmit = async(input) => {
    console.log(1, input)


    // return false
    // if(input.riskScale[0] && input.riskScale[0].length===0 ) input.riskScale[0]=null
    // if(input.riskScale[1] && input.riskScale[1].length===0 ) input.riskScale[1]=null
    // if(scale==='one' && input.riskScale.length===2) 
    //   input.riskScale.pop() 
    // if(scale==='two' && input.riskScale.length===1) 
    //   input.riskScale.push(null) 

    try{

      setSystemSettings({...systemSettings, ...input})
      enqueueSnackbar(`Ustawiania systemu zostały zaktualizowane`, { variant: 'success' });
    }catch(err){
      
    }
    setSave(false)
  }
  const handleChangeScale = ({target}) => { 
    setScale(target.value)
    if(target.value==='one')
      setValue("riskScale1", null)
    setSave(true)
  }

  const onChange = () => { setSave(true) }
  return (
<>    
<Card
  className={classes.root}
  style={{ marginRight: '2%' }}
>
<CardHeader
    subheader="Tutaj możesz zmienić etykiety w systemie"
    title="Etykiety ryzyk"
  />
<form
  autoComplete="off"
  onSubmit={handleSubmit(onSubmit)}
  noValidate
>
  <Divider />
  <CardContent>
    <Text 
      name="riskLabel[0]"
      register={register}
      onChange={onChange}
      label="Poziom 0"
      value={systemSettings.riskLabel && systemSettings.riskLabel[0] || 0} 
    /><br /><br />
    <Text 
      name="riskLabel[1]"
      register={register}
      onChange={onChange}
      label = "Poziom 2"
      value={systemSettings.riskLabel && systemSettings.riskLabel[1] || 2} 
    /><br /><br />
    <Text 
      name="riskLabel[2]"
      register={register}
      onChange={onChange}
      label = "Poziom 4"
      value={systemSettings.riskLabel && systemSettings.riskLabel[2] || 4}  
    /><br /><br />
    <Text 
      name="riskLabel[3]"
      register={register}
      onChange={onChange}
      label = "Poziom 6"
      value={systemSettings.riskLabel && systemSettings.riskLabel[3] || 6} 
    /><br /><br />
    <Text 
      name="riskLabel[4]"
      register={register}
      onChange={onChange}
      label = "Poziom 8"
      value={systemSettings.riskLabel && systemSettings.riskLabel[4] || 8} 
    /><br /><br />
    <Text 
      name="riskLabel[5]"
      onChange={onChange}
      register={register}
      label = "Poziom 10"
      value={systemSettings.riskLabel && systemSettings.riskLabel[5] || 10} 
    />
    </CardContent>   
    <Divider />
    {
      save && <Fab type='submit' color="primary" aria-label="zapisz" className="fab">
            <SaveIcon />
        </Fab>
    }
  </form>
</Card>

<Card className={classes.root}>
      <CardHeader
          subheader="Tutaj możesz zmienić słownik oceny ryzyka"
          title="Ocena ryzyka"
        />
        
       <form
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <Divider />
        <CardContent>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel component="legend">Skala</FormLabel>
          <RadioGroup defaultValue={scale} onChange={handleChangeScale}>
            <FormControlLabel value="one" control={<Radio />} label="jednostopniowa" />
            <FormControlLabel value="two" control={<Radio />} label="dwustopniowa" />    
        </RadioGroup>

        <Tags setValue={setValue} register={register} label={scale==='two' ? "prawdopodobieństwo": ""} value={systemSettings.riskScale0} onChange={onChange} name="riskScale0"/>
        { scale==='two' && <Tags setValue={setValue} label="wpływ" register={register} value={systemSettings.riskScale1} type="tags" onChange={onChange} name="riskScale1"/> }
      </FormControl>
        </CardContent>
        <Divider />
        {
          save && <Fab type='submit' color="primary" aria-label="zapisz" className="fab">
                <SaveIcon />
            </Fab>
        }
      </form>
    </Card>

<Card className={classes.root}>
      <CardHeader
          subheader="Tutaj możesz zdefiniować słownik ocen z audytu"
          title="Ocena z audytu"
        />
       <form
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <Divider />
        <CardContent>
        

        <Tags setValue={setValue} register={register} label={"Ocena z audytu"} value={systemSettings.auditScale} onChange={onChange} name="auditScale"/>
             
        </CardContent>
        <Divider />
        {
          save && <Fab type='submit' color="primary" aria-label="zapisz" className="fab">
                <SaveIcon />
            </Fab>
        }
      </form>
    </Card>


    
</>
  );
};
