import React from 'react'
import { MultiSelect } from 'elements'
import { useQuery } from '@apollo/client';
import { VARIANTS } from 'graphql/queries'


export default (props) => {
    const {data, loading} = useQuery(VARIANTS)
    
    if(loading) return false

    return <MultiSelect {...props} data={data.variants.variantFeed} />
}