import React, { useContext } from "react";
import moment from "moment";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useQuery, useMutation } from "@apollo/client";
import { TESTCTEMPLATE } from "graphql/queries";
import { UPDATE_TESTCTEMPLATE } from "graphql/mutations";
import Button from "@material-ui/core/Button";
import { Rules, Outputs, Inputs, CodeEditor, SourcesInfo } from "./components";

import { useSnackbar } from "notistack";
import {
  // BrowserRouter as Router,
  // Switch,
  // Route,
  useLocation,
  Link,
  useParams,
} from "react-router-dom";
import { getName } from "tools";
import { ErrorPage, Loading, Panel } from "elements";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    height: "100%",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    width: "20%",
  },
  tabsContent: {
    width: "80%",
  },
}));

function ContinuousTemplate({ data, num, refetch }) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(num);
  const [updateTestcTemplate] = useMutation(UPDATE_TESTCTEMPLATE);
  const { enqueueSnackbar } = useSnackbar();

  let location = useLocation();
  React.useEffect(() => {
    try {
      setValue(
        parseInt(location.pathname.match(/\/\d+\/?$/)[0].replace(/\//, ""))
      );
    } catch (err) {
      setValue(0);
    }
  }, [location]);

  const handleChange = (_, newValue) => {
    let p = window.location.pathname.replace(/\/\d+\/?$/, "") + "/" + newValue;
    window.history.pushState({}, null, p);
    // setPath(p);
    setValue(newValue);
  };

  // const handleChangeIndex = (index) => {
  //   setValue(index);
  // };

  // const handleAdd = (what, value) => {

  // }

  const onUpdate = async (input) => {
    await updateTestcTemplate({
      variables: { id: data.id, input },
    });
    refetch();
    enqueueSnackbar(`Schemat został zaktualizowany`, { variant: "success" });
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        orientation="vertical"
        variant="scrollable"
        className={classes.tabs}
      >
        <Tab color="auditc" label="Zmienne wejściowe" {...a11yProps(0)} />
        <Tab color="auditc" label="Kod" {...a11yProps(1)} />
        <Tab color="auditc" label="Źródła" {...a11yProps(2)} />
        <Tab color="auditc" label="Zmienne wyjściowe" {...a11yProps(3)} />
      </Tabs>

      <TabPanel value={value} index={0} className={classes.tabsContent}>
        <Panel>
          <Inputs data={data.inputs} onUpdate={onUpdate} />
        </Panel>
      </TabPanel>
      <TabPanel value={value} index={1} className={classes.tabsContent}>
        <Panel>
          <CodeEditor data={data} onUpdate={onUpdate} />
        </Panel>
      </TabPanel>
      <TabPanel value={value} index={2} className={classes.tabsContent}>
        <Panel>
          <SourcesInfo data={data} onUpdate={onUpdate} />
        </Panel>
      </TabPanel>
      <TabPanel value={value} index={3} className={classes.tabsContent}>
        <Panel>
          <Outputs data={data.outputs} onUpdate={onUpdate} />
        </Panel>
      </TabPanel>
    </div>
  );
}

export default (props) => {
  const { id, num } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { data, loading, refetch } = useQuery(TESTCTEMPLATE, {
    variables: { id },
  });

  if (loading) return <Loading />;

  if (!data.testcTemplate) {
    enqueueSnackbar(`${getName("TestcTemplate")} został usunięty`, {
      variant: "error",
    });
    return <ErrorPage />;
  }

  const template = {
    ...data.testcTemplate,
  };
  // var template={id: "i123dsdfsfsdfsd", value: "Schemat 1",
  //   vars: [{ name: "period", type: "double", displayName: "Okres", hint: "Wskazówka", default: "-1y"}] ,
  //   outputs: [{ name: "slope", type: "normalized_double", displayName: "Wynik", hint:"hint 1", example:"0.4"} ],
  //   alerts: [{ if: [{var: "slope", is: "<", value: "0.5"}],  then: [{alert: "UUID_1", probaility: "nieprawdopodobny"}] }]
  // }

  template.inputs = template.inputs ? JSON.parse(template.inputs) : [];
  template.outputs = template.outputs ? JSON.parse(template.outputs) : [];
  template.rules = template.rules ? JSON.parse(template.rules) : [];

  return <ContinuousTemplate data={template} refetch={refetch} />;
  // return <ContinuousTemplate refetch={refetch} data={data.audit} num={num ? parseInt(num) : 0} />;
};
