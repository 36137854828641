import React, { useState } from "react";

import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import { useForm } from "react-hook-form";
import { getName } from "tools";
import { makeStyles } from "@material-ui/styles";
import { Form } from "elements";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { useAuthDataContext } from "context/Context";

const useStyles = makeStyles((theme) => ({
  button: {
    fontFamily: "regular",
    textTransform: "none",
    letterSpacing: 0,
  },
}));

const NewRow = (props) => {
  const classes = useStyles();
  const block = React.useRef(false);
  const [row, setRow] = React.useState({});
  const { addRow, typename } = props;
  const [open, setOpen] = React.useState(false);
  const { systemSettings } = useAuthDataContext();
  const form = useForm();

  const [switchState, setSwitchState] = React.useState({
    addMultiple: false,
  });

  if (!typename) return false;

  const handleSwitchChange = (name) => (event) => {
    setSwitchState({ ...switchState, [name]: event.target.checked });
  };

  const resetSwitch = () => {
    setSwitchState({ addMultiple: false });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    resetSwitch();
  };

  const handleAdd = async (row) => {
    // console.log(row)
    if (block.current) return false;

    block.current = true;
    await addRow(row);
    setRow({});
    switchState.addMultiple ? setOpen(true) : setOpen(false);
    block.current = false;
  };

  const handleChange =
    (name) =>
    ({ target: { value } }) => {
      setRow({ ...row, [name]: value });
    };

  const getLabels = () => {
    let labels = {};
    if (systemSettings.riskLabel)
      systemSettings.riskLabel.map((r, i) => (labels[i * 2] = r));
    else labels = { 0: "0", 2: "2", 4: "4", 6: "6", 8: "8", 10: "10" };
    return labels;
  };

  const settings = {
    // Source: {
    //   fields: [
    //     {
    //       type: "text",
    //       label: "Nazwa",
    //       name: "value",
    //       validation: { required: true },
    //     },{
    //       type: "select",
    //       label: "Typ",
    //       name: "type",
    //       validation: { required: true },
    //       data: [
    //         { id: "file", value: "plik" },
    //         { id: "link", value: "link" }
    //       ],
    //     },
    //     {
    //       label: "Kolumna",
    //       name: "column",
    //       type: "text"
    //     },
    //     ,{
    //       type: "select",
    //       label: "Odświeanie",
    //       name: "frequency",
    //       validation: { required: true },
    //       data: [
    //         { id: "0", value: "ręcznie" },
    //         { id: "30", value: "co 30 min." },
    //         { id: "60", value: "co godzinę" },
    //         { id: "1440", value: "raz dziennie" },
    //         { id: "43800", value: "raz w mies." },
    //       ],
    //     },
    //   ],
    //   addLabel: getName("Source", 2),
    // },
    TestcTemplate: {
      fields: [
        {
          type: "text",
          label: "Nazwa",
          name: "value",
          validation: { required: true },
        },
        {
          label: "Opis",
          name: "description",
          type: "text",
        },
      ],
      addLabel: getName("TestcTemplate", 2),
    },
    Task: {
      fields: [
        {
          type: "text",
          label: "Nazwa",
          name: "value",
          validation: { required: true },
        },
      ],
      addLabel: getName("Task", 2),
    },
    Risk: {
      fields: [
        {
          type: "text",
          label: "Nazwa",
          name: "value",
          validation: { required: true },
        },
        {
          label: "Opis",
          name: "description",
          type: "text",
        },
      ],
      addLabel: getName("Risk", 2),
    },
    Proces: {
      fields: [
        {
          type: "text",
          label: "Nazwa",
          name: "value",
          validation: { required: true },
        },
        {
          label: "Opis",
          name: "description",
          type: "text",
        },
      ],
      addLabel: getName("Proces", 2),
    },
    Control: {
      fields: [
        {
          type: "text",
          label: "Nazwa",
          name: "value",
          validation: { required: true },
        },
        {
          label: "Opis",
          name: "description",
          type: "text",
        },
      ],
      addLabel: getName("Control", 2),
    },
    Action: {
      fields: [
        {
          type: "text",
          label: "Nazwa",
          name: "value",
          validation: { required: true },
        },
      ],
      addLabel: getName("Action", 2),
    },
    Receiver: {
      fields: [
        {
          type: "text",
          label: "Nazwa",
          name: "value",
          validation: { required: true },
        },
        {
          type: "text",
          label: "Opis",
          name: "description",
        },
        {
          type: "text",
          label: "Email",
          name: "emails",
          validation: { required: true },
        },
      ],
      addLabel: getName("Receiver", 2),
    },

    Perspective: {
      fields: [
        {
          type: "text",
          label: "Nazwa",
          name: "value",
          validation: { required: true },
        },
        {
          label: "Waga",
          name: "weight",
          type: "number",
          validation: { required: true, min: 1, max: 100 },
        },
      ],
      addLabel: getName("Perspective", 2),
    },
    Variant: {
      fields: [
        {
          type: "text",
          label: "Nazwa",
          name: "value",
          validation: { required: true },
        },
        {
          type: "perspective",
          label: getName("Perspective"),
          name: "perspective",
          validation: { required: true },
          fullWidth: true,
        },

        {
          label: "Ryzyko",
          name: "risk",
          type: "slider",
          min: 0,
          max: 10,
          step: 2,
          labels: getLabels(),
          value: 2,
        },
      ],
      addLabel: getName("Variant", 2),
    },
    Instance: {
      fields: [
        {
          label: getName("Variant", 1),
          name: "variants",
          type: "variantFromPerspectives",
          value: props.selected || [],
          validation: { required: true },
        },
      ],
      addLabel: getName("Instance", 2),
    },
    Audit: {
      fields: [
        {
          type: "auditGroup",
          label: getName("AuditGroup", 1),
          name: "auditGroup",
          validation: { required: true },
          fullWidth: true,
        },
        {
          type: "text",
          label: "Nazwa",
          name: "value",
          validation: { required: true },
        },
        {
          label: getName("Instance", 1),
          name: "instances",
          type: "instances",
          value: props.selected || [],
          fullWidth: true,
          // validation: {required: true}
        },
        // ,{
        //   label: "Lider",
        //   name: 'leader',
        //   type: 'user',
        //   // validation: {required: true}
        // },{
        //     type: 'text',
        //     label: 'Data rozpoczęcia',
        //     name: 'start'
        // },{
        //     type: 'number',
        //     label: 'Il. dni',
        //     name: 'days'
        // }
      ],
      addLabel: getName("Audit", 2),
    },

    AuditGroup: {
      fields: [
        {
          type: "text",
          label: "Nazwa",
          name: "value",
          validation: { required: true },
        },
      ],
      addLabel: getName("AuditGroup", 2),
    },
    Test: {
      fields: [
        {
          type: "text",
          label: "Nazwa",
          name: "value",
          validation: { required: true },
        },
        {
          type: "text",
          label: "Opis",
          name: "desc",
          // validation: {required: true},
          inputProps: {
            multiline: true,
            rows: 2,
          },
        },
        {
          type: "text",
          label: "Do zrobienia",
          name: "todo",
          // validation: {required: true},
          inputProps: {
            multiline: true,
            rows: 2,
          },
        },
      ],
      addLabel: getName("Test", 2),
    },

    Team: {
      fields: [
        {
          type: "user",
          label: "Użytkownik",
          name: "user",
          validation: { required: true },
          inputProps: {
            withName: true,
          },
        },
      ],
      addLabel: "Dodaj do zespołu",
    },
    TeamMove: {
      fields: [
        {
          type: "user",
          label: "Użytkownik",
          name: "user",
          fullWidth: true,
          validation: { required: true },
          inputProps: {
            withName: true,
          },
        },
        {
          name: "users",
          type: "hidden",
          value: props.selected || [],
          fullWidth: true,
          // validation: {required: true}
        },
      ],
      addLabel: "Przekaż testy",
    },

    User: {
      fields: [
        {
          type: "text",
          label: "Imię i nazwisko",
          name: "value",
          validation: { required: true },
        },
        {
          type: "text",
          label: "Email",
          name: "email",
          validation: { required: true },
        },
        {
          type: "text",
          label: "Inicjały",
          name: "avatar",
          validation: { required: true, maxLength: 3 },
        },
        {
          type: "color",
          label: "Kolor",
          name: "color",
        },
        {
          type: "text",
          label: "Hasło tymczasowe",
          name: "password",
          validation: { required: true, minLength: 6 },
        },
      ],
      addLabel: "Nowy audytor",
    },
    Finding: {
      fields: [
        {
          type: "text",
          label: "Nazwa",
          name: "value",
          validation: { required: true },
        },
        {
          type: "text",
          label: "Podsumowanie",
          name: "desc",
          // validation: {required: true},
          inputProps: {
            multiline: true,
          },
        },
        {
          type: "text",
          label: "Szacowana wartość",
          name: "financialImpactA",
        },

        {
          type: "text",
          label: "Obserwacja",
          name: "remark",
          inputProps: {
            multiline: true,
          },
        },
        {
          type: "text",
          label: "Ryzyko",
          name: "risk",
          validation: { required: true },
          inputProps: {
            multiline: true,
          },
        },
        {
          type: "text",
          label: "Rekomendacje",
          name: "recomendation",
          validation: { required: true },
          inputProps: {
            multiline: true,
          },
        },
      ],
      addLabel: getName("Finding", 2),
    },
    Report: {
      fields: [
        {
          type: "text",
          label: "Nazwa",
          name: "value",
          validation: { required: true },
        },

        {
          type: "select",
          label: "Typ",
          name: "type",
          fullWidth: true,
          validation: { required: true },
          data: [
            { id: "xlsx", value: "xlsx" },
            { id: "docx", value: "docx" },
            { id: "pdf", value: "pdf" },
          ],
        },
      ],
      addLabel: "Dodaj raport",
    },
    Sources: {
      fields: [
        {
          type: "text",
          label: "Wyświetlana nazwa",
          name: "displayName",
          validation: { required: true },
        },
        {
          type: "text",
          label: "Wskazówka",
          name: "hint",
          // validation: {required: true},
          inputProps: {
            multiline: true,
          },
        },
        {
          type: "text",
          label: "Nazwa zmiennej",
          name: "name",
          validation: { required: true },
        },
      ],
      addLabel: "Dodaj źródło",
    },
    Outputs: {
      fields: [
        {
          type: "text",
          label: "Wyświetlana nazwa",
          name: "displayName",
          validation: { required: true },
        },
        {
          type: "text",
          label: "Wskazówka",
          name: "hint",
          // validation: {required: true},
          inputProps: {
            multiline: true,
          },
        },
        {
          type: "text",
          label: "Nazwa zmiennej",
          name: "name",
          validation: { required: true },
        },

        // {
        //   type: "select",
        //   label: "Typ zmiennej",
        //   name: "type",
        //   validation: { required: true },
        //   fullWidth: true,
        //   data: [
        //     { id: "normalized_double", value: "od 0 dp 1" },
        //     { id: "double", value: "liczba zmiennoprzecinkowa" },
        //   ],
        // },
        {
          type: "text",
          label: "Wartość przykładowa",
          name: "example",
        },
      ],
      addLabel: "Dodaj zmienną wyjściową",
    },
    Vars: {
      fields: [
        {
          type: "text",
          label: "Nazwa",
          name: "displayName",
          validation: { required: true },
        },
        {
          type: "text",
          label: "Wskazówka",
          name: "hint",
          // validation: {required: true},
          inputProps: {
            multiline: true,
          },
        },
        {
          type: "text",
          label: "Nazwa zmiennej",
          name: "name",
          validation: { required: true },
        },
        {
          type: "text",
          label: "Typ zmiennej",
          name: "type",
        },

        {
          type: "text",
          label: "Przykładowa wartość",
          name: "value",
        },
      ],
      addLabel: "Dodaj zmienną",
    },
    Incident: {
      fields: [
        {
          type: "text",
          label: "Nazwa",
          name: "value",
          validation: { required: true },
        },
        {
          type: "text",
          label: "Opis",
          name: "desc",
          validation: { required: true },
        },
        {
          type: "text",
          label: "Imię i Nazwisko",
          name: "employeeName",
          validation: { required: true },
        },
        {
          type: "text",
          label: "Email",
          name: "employeeEmail",
          validation: { required: true },
        },
      ],
      addLabel: "Dodaj incydent",
    },
    ReviewNote: {
      fields: [
        {
          type: "text",
          label: "Nazwa",
          name: "value",
          validation: { required: true },
        },
        {
          type: "text",
          label: "Wiadomość",
          name: "messages",
          validation: { required: true },
        },
        {
          type: "team",
          label: "Audytorzy",
          name: "auditors",
          validation: { required: true },
          excludeMe: true,
        },
      ],
      addLabel: getName("ReviewNote", 2),
    },
  };

  if (systemSettings.riskScale1) {
    settings["Finding"].fields.push({
      type: "simpleSelect",
      label: "Prawdopodobieństwo",
      name: "riskSelect0",
      validation: { required: true },
      fullWidth: true,
      data: systemSettings.riskScale0,
    });
    settings["Finding"].fields.push({
      type: "simpleSelect",
      label: "Wpływ",
      name: "riskSelect1",
      validation: { required: true },
      fullWidth: true,
      data: systemSettings.riskScale1,
    });
  } else {
    settings["Finding"].fields.push({
      type: "simpleSelect",
      label: "Ocena ryzyka",
      name: "riskSelect0",
      validation: { required: true },
      fullWidth: true,
      data: systemSettings.riskScale0,
    });
  }

  return (
    <>
      <Button
        className={classes.button}
        key={"additionalButton" + (props.key || "-1")}
        startIcon={props.icon || <AddBoxIcon />}
        onClick={handleClickOpen}
      >
        {settings[typename].addLabel}
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={form.handleSubmit(handleAdd)}>
          <DialogTitle id="form-dialog-title">
            {" "}
            {settings[typename].addLabel}{" "}
          </DialogTitle>
          <DialogContent style={{ width: 600 }}>
            <Form fields={settings[typename].fields} form={form} />
          </DialogContent>
          <DialogActions>
            <Tooltip title="Dodaj wiele">
              <Switch
                checked={switchState.addMultiple}
                onChange={handleSwitchChange("addMultiple")}
                value="addMultiple"
                inputProps={{ "aria-label": "secondary checkbox" }}
                style={{ display: "none" }}
              />
            </Tooltip>
            <Button onClick={handleClose} color="primary">
              Anuluj
            </Button>
            <Button type="submit" color="primary">
              Dodaj
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

NewRow.propTypes = {
  addRow: PropTypes.func.isRequired,
};

export default NewRow;
