import React, { useState, useRef } from "react";
import { Tree, Table, RightBar, DateSelect } from "elements";
import { useQuery, useMutation } from "@apollo/client";
import { RISKS } from "graphql/queries";
import {
  UPDATE_RISK,
  DELETE_RISK,
  CREATE_RISK,
  UPDATE_PROCES,
} from "graphql/mutations";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/styles";
import { getName } from "tools";
import { useAuthDataContext } from "context/Context";
import GridOnIcon from "@material-ui/icons/GridOn";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import _ from "lodash";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { useHistory } from "react-router";
import { map, filter } from "lodash";

const useStyles = makeStyles((theme) => ({
  tab: {
    paddingRight: 45,
  },
}));

function RiskTemplate(props) {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [updateRisk] = useMutation(UPDATE_RISK);
  const [createRisk] = useMutation(CREATE_RISK);
  const [deleteRisk] = useMutation(DELETE_RISK);
  const [updateProces] = useMutation(UPDATE_PROCES);

  const { systemSettings, settings, setSettings } = useAuthDataContext();
  const history = useHistory();

  const setData = () => {
    console.log("setData");
  };

  const addRisk = () => {
    // console.log(data);
    props.setRightbarData({
      __typename: "Risk",
      risks: props.data,
      id: props.proces,
    });
  };

  const updateRisksInProces = async (risks) => {
    await updateProces({
      variables: { id: props.proces, input: { risks } },
      refetchQueries: [{ query: RISKS }],
    });

    enqueueSnackbar(`${getName("Proces")} zostało zaktualizowane`, {
      variant: "success",
    });
    props.refetch();
  };

  const onAdd = async ({ value, description }) => {
    const r = await createRisk({
      variables: {
        input: { value, description },
      },
    });

    await updateRisksInProces([...map(props.data, "id"), r.data.createRisk.id]);
  };

  const _updateRisk = async (idx, name, value) => {
    let row = props.data[idx];
    // console.log(idx,name,value, row)
    const refetchQueries = [
      { query: RISKS, variables: { audits: [props.audit.id] } },
    ];

    const { data } = await updateRisk({
      variables: { id: row.id, input: { [name]: value } },
      refetchQueries,
    });

    enqueueSnackbar(
      `${getName("Risk")} ${data.updateRisk.value} został zaktualizowany`,
      { variant: "success" }
    );
  };

  const _deleteRisk = async (fs) => {
    // if (fs.length === 0) return false;
    // let snackText = [
    //   fs.length > 1 ? getName("Risk", 1) : getName("Risk"),
    //   "",
    //   fs.length > 1 ? "zostały usunięte" : "został usunięty",
    // ];
    // let names = [];
    // for (let i = fs.length - 1; i > 0; i--) {
    //   await deleteRisk({ variables: { id: fs[i].id } });
    //   names.push(fs[i].value);
    // }
    // await deleteRisk({
    //   variables: { id: fs[0].id },
    //   refetchQueries: [
    //     { query: RISKS, variables: { audits: [props.audit.id] } },
    //   ],
    // });
    // names.push(fs[0].value);
    // snackText[1] = names.join(", ");
    // enqueueSnackbar(snackText.join(" "), { variant: "success" });
    const ids = map(fs, "id");
    let _r = filter(props.data, (c) => !ids.includes(c.id));
    await updateRisksInProces([...map(_r, "id")]);
  };

  let columns = [
    {
      Header: "Nazwa",
      accessor: "value",
      type: "label",
      width: "26%",
      // inputProps: {
      //   subtext: "auditValue",
      // },
    },
    {
      Header: "Opis",
      accessor: "description",
      type: "info",
      width: "20%",
    },
  ];

  return (
    <Table
      typename="Risk"
      columns={columns}
      setData={setData}
      updateMyData={_updateRisk}
      skipPageReset={true}
      onDelete={_deleteRisk}
      {...props}
      onAdd={onAdd}
      toolbarButtons={[
        {
          label: "Istniejące ryzyko",
          icon: <AddBoxIcon />,
          onClick: addRisk,
        },
      ]}
    />
  );
}

export default ({ data, refetch, setRightbarData }) => {
  return (
    <div>
      <RiskTemplate
        data={data.risks}
        proces={data.id}
        refetch={refetch}
        setRightbarData={setRightbarData}
      />
    </div>
  );
};
