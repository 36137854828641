import React from "react";
import { Table, Form } from "elements";
import { useAuthDataContext } from "context/Context";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle"
import Button from "@material-ui/core/Button";
import {map, filter} from 'lodash'
import { useForm } from "react-hook-form";


export default ({ data, onUpdate }) => {
  const { user } = useAuthDataContext();
  const form = useForm()
  const [sources, setSources] = React.useState(JSON.parse(data.sources || "[]"))
  const [ open, setOpen ] = React.useState()


  const onAdd = async (values) => {
    let s = [...sources]
    s.push({...values, id: s.length})
   
    await onUpdate( { sources: JSON.stringify(s)} )
    setSources(s)
    handleClose()
  }
  const onDelete = async(_1) => {
    let s = [...sources]

    for(let i=0; i<_1.length; i++){
      s = filter(s, function(o) { return o.id!=_1[i].id }) 
    }
    await onUpdate({ sources: JSON.stringify(s)} )
    setSources(s)
  }

  const handleClose = () => {
    setOpen(false)
  } 


  const handleUpdate = async(idx, filedName, fieldValue) => {
    let s = [...sources]
    s[idx][filedName] = fieldValue
   
    await onUpdate( { sources: JSON.stringify(s)} )
    setSources(s)
    handleClose()
  }



  return (
    <>
    <Table
        typename="Sources"
        columns={[
         
          {
            Header: "Wyświetlana nazwa",
            accessor: "displayName",
            type: "text"  
          },
          {
            Header: "Wskazówka",
            accessor: "hint",
            type: "longText",
            
          },
          {
            Header: "Nazwa zmiennej",
            accessor: "name",
            type: "text",
           
          }
          
         
        ]}
       
        // setData={setData}
        updateMyData={handleUpdate}
        onAdd={onAdd}
        onDelete={onDelete}
       
        // {...props}
        data={sources}
      />
      
      </>
  );
};

