import React from "react";
import { useAuthDataContext } from "context/Context";

import { RightBar, MultiSelect, Panel, Loading } from "elements";
import { useQuery } from "@apollo/client";
import { PERSPECTIVES, VARIANTS, INSTANCES } from "graphql/queries";
// import { useSnackbar } from "notistack";
import {
  VariantList,
  PerspectiveList,
  VariantDetails,
  PerspectiveDetails,
  InstanceList,
  InstanceDetails,
  InstancePast,
  Survey,
} from "./components";
// import { isEmpty } from "underscore";
import { makeStyles } from "@material-ui/styles";
import { getName } from "tools";
import _ from "lodash";
import { AppBar, Tabs, Tab, Typography, Box, Divider } from "@material-ui/core";
import {
  // BrowserRouter as Router,
  // Switch,
  // Route,
  // Link,
  useLocation,
  useParams,
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default () => {
  let { num } = useParams();
  const [pids, setPids] = React.useState([]);
  const i = useQuery(INSTANCES);
  const p = useQuery(PERSPECTIVES);
  const v = useQuery(VARIANTS, {
    variables: { perspective: { in: pids } },
  });
  const classes = useStyles();
  const [value, setValue] = React.useState(parseInt(num) || 0);
  const [, reload] = React.useState(0);
  const [rightbarData, setRightbarData] = React.useState(false);
  const [filteredVariants, setFilteredVariants] = React.useState(null);
  const [testDate, setTestDate] = React.useState(null);
  const { user } = useAuthDataContext();
  let location = useLocation();

  React.useEffect(() => {
    try {
      setValue(
        parseInt(location.pathname.match(/\/\d+\/?$/)[0].replace(/\//, ""))
      );
    } catch (err) {
      setValue(0);
    }
  }, [location]);

  const handleChange = (event, newValue) => {
    let p = window.location.pathname.replace(/\/\d+\/?$/, "") + "/" + newValue;
    window.history.pushState({}, null, p);
    setValue(newValue);
  };

  if (p.loading || v.loading || i.loading) return <Loading />;
  // else variants.current=v.data.variants.variantFeed

  // function getRandomInt(max) {
  //   return Math.floor(Math.random() * Math.floor(max));
  // }

  const changePerspectiveFilter = (_pids) => {
    setPids(_pids);
    if (_pids.length === 0) setFilteredVariants(null);
    else
      setFilteredVariants(
        _.filter(
          v.data.variants.variantFeed,
          (v) => _pids.indexOf(v.perspective.id) >= 0
        )
      );
  };

  const showVariantDetails = (idx) => {
    setRightbarData((filteredVariants || v.data.variants.variantFeed)[idx]);
  };

  const showPerspectiveDetails = (idx) => {
    setRightbarData(p.data.perspectives.perspectiveFeed[idx]);
  };
  const showInstanceDetails = (idx) => {
    setRightbarData(i.data.instances.instanceFeed[idx]);
  };
  const showInstancePast = () => {
    setRightbarData({
      __typename: "past",
    });
  };

  return (
    <>
      <AppBar position="static" color="transparent">
        <Tabs value={value} onChange={handleChange} aria-label="">
          <Tab label={getName("Perspective", 1)} {...a11yProps(0)} />
          <Tab label={getName("Variant", 1)} {...a11yProps(1)} />

          <Tab label={getName("Instance", 1)} {...a11yProps(2)} />
          {user?.permissions?.universe?.survey_view && (
            <Tab label={getName("Survey")} {...a11yProps(3)} />
          )}
        </Tabs>
      </AppBar>
      <TabPanel
        className="treeWithSelection"
        value={value}
        index={0}
        className={classes.tab}
      >
        <Panel
          subheader={
            <div className="subheader">
              Najwyższa warstwa opisu przedsiębiorstwa
            </div>
          }
          title={getName("Perspective", 1)}
        >
          <PerspectiveList
            showDetails={showPerspectiveDetails}
            reload={reload}
            data={p.data.perspectives.perspectiveFeed}
          />
          <br />
        </Panel>
      </TabPanel>
      <TabPanel value={value} index={1} className={classes.tab}>
        <Panel
          subheader={
            <MultiSelect
              label={getName("Perspective", 1)}
              value={pids}
              onChange={changePerspectiveFilter}
              data={p.data.perspectives.perspectiveFeed}
              noBorder={true}
            />
          }
          title={getName("Variant", 1)}
        >
          <Divider />
          <VariantList
            showDetails={showVariantDetails}
            reload={reload}
            data={filteredVariants || v.data.variants.variantFeed}
            perspectives={p.data.perspectives.perspectiveFeed}
          />
          <br />
        </Panel>
      </TabPanel>

      <TabPanel value={value} index={2} className={classes.tab}>
        <Panel
          subheader={
            <div className="subheader">
              Opis relacji powiązań w przedsiębiorstwie
            </div>
          }
          title={getName("Instance", 1)}
        >
          <InstanceList
            forTest={(v) => setTestDate(v)}
            showDetails={showInstanceDetails}
            showPast={showInstancePast}
            refetch={i.refetch}
            data={i.data.instances.instanceFeed}
            variants={v.data.variants.variantFeed}
          />
        </Panel>
      </TabPanel>

      {user?.permissions?.universe?.survey_view && (
        <TabPanel
          className="treeWithSelection"
          value={value}
          index={3}
          className={classes.tab}
        >
          <Panel
            subheader={
              <div className="subheader">
                Parametryzacja algorytmu szeregowania ryzyka
              </div>
            }
            title={"Ankieta audytowa"}
          >
            <Survey />
          </Panel>
        </TabPanel>
      )}
      <RightBar
        open={rightbarData !== false}
        onClose={() => setRightbarData(false)}
      >
        {rightbarData.__typename === "Variant" && (
          <VariantDetails id={rightbarData.id} />
        )}
        {rightbarData.__typename === "Perspective" && (
          <PerspectiveDetails id={rightbarData.id} />
        )}
        {rightbarData.__typename === "Instance" && (
          <InstanceDetails data={rightbarData} />
        )}
        {rightbarData.__typename === "past" && <InstancePast />}
      </RightBar>
    </>
  );
};

{
  /* <button onClick={onClick}> Dodaj zakaldke </button> */
}
