


import React from "react";
import { Table, Form } from "elements";
import { useAuthDataContext } from "context/Context";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle"
import Button from "@material-ui/core/Button";
import {map, filter} from 'lodash'
import { useForm } from "react-hook-form";


export default ({ data, onUpdate }) => {
  const { user } = useAuthDataContext();
  const form = useForm()
  const [inputs, setInputs] = React.useState(data || [])
  const [ open, setOpen ] = React.useState()


  const onAdd = async (values) => {
    let s = [...inputs]
    s.push({...values, id: s.length+1})
   
    await onUpdate( { inputs: JSON.stringify(s)} )
    setInputs(s)
  }
  const onDelete = async(_1) => {
    let s = [...inputs]

    for(let i=0; i<_1.length; i++){
      s = filter(s, function(o) { return o.id!=_1[i].id }) 
    }
    await onUpdate({ inputs: JSON.stringify(s)} )
    setInputs(s)
  }



  const handleUpdate = async(idx, filedName, fieldValue) => {
    let s = [...inputs]
    s[idx][filedName] = fieldValue
    
    setInputs(s)
    await onUpdate( { inputs: JSON.stringify(s)} )
  }



  return (
    <>
    <Table
        typename="Vars"
        columns={[
         
          {
            Header: "Wyświetlana nazwa",
            accessor: "displayName",
            type: "text"  
          },
          {
            Header: "Wskazówka",
            accessor: "hint",
            type: "longText",
            
          },
          {
            Header: "Nazwa zmiennej",
            accessor: "name",
            type: "text",
           
          },
          {
            Header: "Typ zmiennej",
            accessor: "type",
            type: "text",
           
          },
          {
            Header: "Wartość",
            accessor: "value",
            type: "label",
           
          }
          
         
        ]}
        // toolbarButtons={[
        
        //     {
        //       label: "Dodaj zmienną",
        //       icon: <AddCircleOutlineIcon />,
        //       onClick: ()=>setOpen(true),
        //     },
        //   ]}
        // setData={setData}
        updateMyData={ handleUpdate}
        onAdd={onAdd}
        onDelete={onDelete}
       
        // {...props}
        data={inputs}
      />
     
      </>
  );
};
