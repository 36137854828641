import React from "react";
import Avatar from "@material-ui/core/Avatar";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
    "& .MuiBadge-badge svg": {
      background: "#fff",
      borderRadius: 9,
    },
    "& .MuiBadge-badge": {
      visibility: "hidden",
    },
    "&:hover .MuiBadge-badge": {
      visibility: "visible",
    },
  },

  avatar: {
    width: 28,
    height: 28,
    fontSize: 10,
    // fontFamily: "bold"
  },
  group: {
    border: "2px solid #F4F6F8",
    marginLeft: "-8px",
  },
}));

export default (props) => {
  console.log(222, props);
  const classes = useStyles();

  return props.onClick ? (
    <div key={props.key} onClick={props.onClick} className={classes.root}>
      <Badge
        overlap="circle"
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        badgeContent={!props.readOnly && <HighlightOffIcon fontSize="small" />}
      >
        <Avatar
          className={clsx(classes.avatar, props.group ? classes.group : "")}
          alt={props.value}
          style={{ backgroundColor: props.color, zIndex: props.zIndex }}
          variant="square"
        >
          {props.children}
        </Avatar>
      </Badge>
    </div>
  ) : (
    <Avatar
      key={props.key}
      className={clsx(classes.avatar, props.group ? classes.group : "")}
      alt={props.value}
      style={{ backgroundColor: props.color, zIndex: props.zIndex }}
    >
      {props.children}
    </Avatar>
  );
};
