import React from "react";

import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
  },
  icon: {
    marginRight: 5,
    float: "left",
    marginTop: 4,
  },
}));

export default ({ value, labels }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {labels[value] && labels[value].icon && (
        <div className={classes.icon}>{labels[value].icon}&nbsp;</div>
      )}

      {labels[value] ? labels[value].label : ""}
    </div>
  );
};
