


import React from "react";
import { Button } from "elements";
import { useAuthDataContext } from "context/Context";

export default ({ data }) => {
  const { user } = useAuthDataContext();

  return (
    <div></div>
  );
};
