import React, { useEffect, useState, useContext } from "react";
import { getError } from "tools";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import "moment/locale/pl";

const localeMap = {
  pl: "pl",
};

const useStyles = makeStyles((theme) => ({
  // container: {
  //    width: 90
  // },
  // field: {
  //   '& .MuiButtonBase-root':{
  //     position: 'absolute',
  //     left: 55
  //   },
  //   '& .MuiSvgIcon-root':{
  //     width: '.8em',
  //     height: '.8em'
  //   }
  // },
  label: {
    display: "inline-block",
    width: 80,
  },
}));

export default (props) => {
  const { error, name, onChange, value, label, register, ...rest } = props;
  const [err, setErr] = useState(null);
  const [v, setV] = useState();
  // const { ctx } = useContext(AppContext);
  const classes = useStyles();
  // const dateFromat = ctx.settings.dateFormat || "YYYY-MM-DD"
  const dateFromat = "YYYY-MM-DD";

  useEffect(() => {
    value && setV((value && moment.unix(parseInt(value) / 1000)) || null);
  }, [value]);

  useEffect(() => {
    setErr(error);
    return () => {};
  }, [error]);

  const handleDateChange = (date) => {
    // console.log(date, props,980)
    onChange && onChange(date ? "" + date.unix() * 1000 : null);
    setV(date || null);
  };

  return (
    <div className={classes.container}>
      <MuiPickersUtilsProvider utils={MomentUtils} locale="pl">
        <KeyboardDatePicker
          autoOk
          // fullWidth
          error={error && true}
          variant="inline"
          format={dateFromat}
          value={v || null}
          name={name}
          disabled={
            props.data && (props.data.status === 1 || props.data.status === 19)
          }
          className={classes.field}
          onChange={handleDateChange}
          label={label}
          placeholder={"podaj datę"}
          KeyboardButtonProps={{
            "aria-label": "zmien date",
          }}
          {...rest}
        />
      </MuiPickersUtilsProvider>
      {error && <FormHelperText error>{getError(error.type)}</FormHelperText>}
    </div>
  );
};
