


import React from "react";
import { Table } from "elements";
import { useAuthDataContext } from "context/Context";
import {map, filter} from 'lodash'

export default ({ data, onUpdate }) => {
  // const { user } = useAuthDataContext();
  const [outputs, setOutputs] = React.useState(data || [])

  const handleUpdate = async(idx, filedName, fieldValue) => {
    let s = [...outputs]
    s[idx][filedName] = fieldValue
    
    setOutputs(s)
    await onUpdate( { outputs: JSON.stringify(s)} )
 
  }

  const onAdd = async (values) => {
    let s = [...outputs]
    s.push({...values, id: s.length})
   
    await onUpdate( { outputs: JSON.stringify(s)} )
    setOutputs(s)
  }


  const onDelete = async(_1) => {
    let s = [...data]

    for(let i=0; i<_1.length; i++){
      s = filter(s, function(o) { return o.id!=_1[i].id }) 
    }
    await onUpdate({ outputs: JSON.stringify(s)} )
    setOutputs(s)
  }

  return (
    <Table
        typename="Outputs"
        columns={[
         
          {
            Header: "Wyświetlana nazwa",
            accessor: "displayName",
            type: "text"  },
          {
            Header: "Wskazówka",
            accessor: "hint",
            type: "longText",
            
          },
          {
            Header: "Nazwa zmiennej",
            accessor: "name",
            type: "text",
           
          },
          // {
          //   Header: "Typ zmiennej",
          //   accessor: "type",
          //   type: "select",
          //   inputProps: {
          //     data: [
          //       { id: "normalized_double", value: "od 0 do 1" },
          //       { id: "unsigned_normalized_double", value: "od -1 do 1" },
          //       { id: "double", value: "liczba zmiennoprzecinkowa" },
          //     ],
          //   },
            
          // },
          {
            Header: "Wartość przykładowa",
            accessor: "example",
            type: "text",
           
          },
          
         
        ]}
        // setData={setData}
        updateMyData={handleUpdate}
        onAdd={onAdd}
        onDelete={onDelete}
       
        // {...props}
        data={outputs}
      />
  );
};
