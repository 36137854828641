import React, { useContext } from "react";
import moment from "moment";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useQuery, useSubscription } from "@apollo/client";
import { TASK } from "graphql/queries";
import { TASKSUB } from "graphql/subscriptions";
import Button from "@material-ui/core/Button";
import { First, Logs, Files } from "./components";
import { useSnackbar } from "notistack";
import {
  // BrowserRouter as Router,
  // Switch,
  // Route,
  Link,
  useParams,
} from "react-router-dom";

import { getName } from "tools";
import { ErrorPage, Panel } from "elements";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    height: "100%",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    width: "20%",
  },
  tabsContent: {
    width: "80%",
  },
}));

function FindingDetails({ data, num, refetch }) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(num);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        orientation="vertical"
        variant="scrollable"
        className={classes.tabs}
      >
        <Tab label="Informacje" {...a11yProps(0)} />
        {/* <Tab label="Pliki" {...a11yProps(1)} /> */}
        {/* <Tab label="Log" {...a11yProps(1)} /> */}
      </Tabs>

      <TabPanel value={value} index={0} className={classes.tabsContent}>
        <First task={data} refetch={refetch} />
      </TabPanel>
      {/* <TabPanel value={value} index={1} className={classes.tabsContent}>
        <Files task={data.id} />
      </TabPanel> */}
      <TabPanel value={value} index={1} className={classes.tabsContent}>
        <Panel title="Logs">
          <Logs task={data} />
        </Panel>
      </TabPanel>
    </div>
  );
}

export default (props) => {
  let { id, num } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const { data, loading, refetch } = useQuery(TASK, {
    variables: { id },
  });

  // console.log(data, "data");

  const onSubscriptionData = ({ subscriptionData }) => {
    // enqueueSnackbar("Finding zaktualizowany", { variant: "success" });

    console.log("SUB", subscriptionData);
    refetch();
  };

  useSubscription(TASKSUB, {
    onSubscriptionData,
  });

  if (loading) return false;
  if (!data.task) {
    enqueueSnackbar(`${getName("Task")} zostało usunięte`, {
      variant: "error",
    });
    return <ErrorPage />;
  }

  console.log(data, "data111");

  return (
    <FindingDetails
      refetch={refetch}
      data={data.task}
      num={num ? parseInt(num) : 0}
    />
  );
};
