import React from "react";
import { Table, Input, Loading } from "elements";
import { useAuthDataContext } from "context/Context";
import GetAppIcon from "@material-ui/icons/GetApp";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import _ from "lodash";
import { useHistory } from "react-router";

import AddCircleIcon from "@material-ui/icons/AddCircle";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import RefreshIcon from "@material-ui/icons/Refresh";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { getName } from "tools";
import { useQuery, useMutation } from "@apollo/client";
import { TESTSC, TESTCTEMPLATES } from "graphql/queries";
import { UPDATE_TESTSC, DELETE_TESTSC, CREATE_TESTSC } from "graphql/mutations";
import { useSnackbar } from "notistack";
import { Form } from "elements";
import { useForm } from "react-hook-form";

const Testsc = ({ data, templates }) => {
  const { user, settings, setSettings } = useAuthDataContext();
  const history = useHistory();
  const [open, setOpen] = React.useState();
  const form = useForm();

  const [updateTestc] = useMutation(UPDATE_TESTSC);
  const [createTestc] = useMutation(CREATE_TESTSC);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [deleteTestc] = useMutation(DELETE_TESTSC);

  const handleClose = () => {
    setOpen(false);
  };

  const makeTest = () => {};

  const onCopy = async () => {};

  const onSubmit = async () => {
    const { value, template } = form.getValues();
    await createTestc({
      variables: {
        input: { value, template },
      },
      refetchQueries: [{ query: TESTSC }],
    });
    enqueueSnackbar(`${getName("Testc")} zostało dodane`, {
      variant: "success",
    });
    setOpen(false);
  };

  const onDelete = async (ps) => {
    if (ps.length === 0) return false;

    let snackText = [
      ps.length > 1 ? getName("Testc", 1) : getName("Testc"),
      "",
      ps.length > 1 ? "zostały usunięte" : "zostało usunięte",
    ];
    let names = [];
    for (let i = ps.length - 1; i > 0; i--) {
      await deleteTestc({ variables: { id: ps[i].id } });
      names.push(ps[i].value);
    }
    await deleteTestc({
      variables: { id: ps[0].id },
      refetchQueries: [{ query: TESTSC }],
    });

    enqueueSnackbar(snackText.join(" "), { variant: "success" });
  };

  const onUpdate = async (idx, name, value) => {
    let row = data[idx];

    await updateTestc({
      variables: { id: row.id, input: { [name]: value } },
      refetchQueries: [{ query: TESTSC }],
    });

    enqueueSnackbar(`${getName("Testc")} zostało zaktualizowane`, {
      variant: "success",
    });
  };

  function goToTest(i) {
    const test = data[i];
    const tabs = settings.tabs ? [...settings.tabs] : [];

    if (_.findIndex(tabs, { id: test.id }) < 0) {
      setSettings({
        tabs: [
          {
            id: test.id,
            title:
              test.value.length > 20
                ? test.value.slice(0, 20) + "..."
                : test.value,
            full: test.value,
            parent: null,
            href: "/testc/" + test.id,
          },
          ...tabs,
        ],
      });
    }

    history.push("/testc/" + test.id);
  }

  return (
    <>
      <Table
        typename="AuditCTests"
        columns={[
          {
            Header: "Nazwa",
            accessor: "value",
            type: "text",
            width: "38%",
          },
          {
            Header: "Schemat",
            accessor: "template.value",
            type: "info",
            width: "8%",
          },

          {
            Header: "Częstotliwość wyk.",
            accessor: "frequency",
            type: "label",
            readOnly: true,
            width: "16%",
            // inputProps: {
            //   data: [
            //     { id: "1 minute", value: "1 minuta" },
            //     { id: "1 hour", value: "1 godz." },
            //     { id: "1 day", value: "1 dzień" },
            //     { id: "1 week", value: "1 tydz." },
            //     { id: "1 month", value: "1 mies." },
            //   ],
            // },
          },
        ]}
        // setData={setData}
        updateMyData={onUpdate}
        // skipPageReset={true}
        // onAdd={()=>{}}
        toolbarButtons={[
          {
            label: "Nowy",
            icon: <AddCircleIcon />,
            onClick: () => setOpen(true),
          },
          {
            label: "Skopiuj",
            icon: <FileCopyIcon />,
            onClick: onCopy,
          },
        ]}
        onDelete={onDelete}
        rowActions={[
          { icon: <RefreshIcon />, onClick: makeTest },
          { icon: <GetAppIcon />, onClick: () => {} },
          { icon: <DoubleArrowIcon />, onClick: goToTest },
        ]}
        // {...props}
        data={data}
      />

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="form-dialog-title"></DialogTitle>
        <DialogContent style={{ width: 600 }}>
          <form>
            <Form
              fields={[
                {
                  label: "Nazwa",
                  name: "value",
                  type: "text",
                },
                {
                  label: "Schemat",
                  type: "select",
                  fullWidth: true,
                  name: "template",
                  data: templates,
                },
              ]}
              form={form}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Anuluj
          </Button>
          <Button onClick={onSubmit} color="primary">
            Dodaj
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default (props) => {
  const { data, loading } = useQuery(TESTCTEMPLATES);

  if (loading) return <Loading />;

  return (
    <Testsc
      data={props.data}
      templates={
        data && data.testcTemplates ? data.testcTemplates.testcTemplateFeed : []
      }
    />
  );

  // return <ContinuousAudit refetch={refetch} data={data.audit} num={num ? parseInt(num) : 0} />;
};
