import React from "react";
import { FILE_URL } from "vars";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import CardActionArea from "@material-ui/core/CardActionArea";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useMutation } from "@apollo/client";
import { DELETE_FILE } from "graphql/mutations";
import { FILES } from "graphql/queries";

const useStyles = makeStyles({
  root: {
    width: "18%",
    margin: "1%",
    display: "inline-block",
  },
  content: {
    height: 60,
    color: "#222",
    fontSize: "14px",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  delete: {
    marginLeft: "auto!important",
  },
});

export default ({ data, variables, disableDelete }) => {
  const classes = useStyles();
  const [deleteFile] = useMutation(DELETE_FILE);

  const deleteIt = async () => {
    await deleteFile({
      variables: { id: data.id },
      refetchQueries: [{ query: FILES }, { query: FILES, variables }],
    });
  };

  return (
    <Card key={data.id} className={classes.root}>
      <CardActionArea>
        <a
          download
          href={`${FILE_URL}/${data.id}-${data.versions[0]}/${data.filename}`}
        >
          <CardContent className={classes.content}>{data.filename}</CardContent>
        </a>
      </CardActionArea>
      <CardActions>
        <a
          download
          href={`${FILE_URL}/${data.id}-${data.versions[0]}/${data.filename}`}
        >
          <Button fontSize="small" startIcon={<GetAppIcon />}>
            Pobierz
          </Button>
        </a>
        {!disableDelete && (
          <IconButton aria-label="delete file" className={classes.delete}>
            <DeleteIcon fontSize="small" onClick={deleteIt} />
          </IconButton>
        )}
      </CardActions>
    </Card>
  );
};
