import React, { Component, useEffect } from "react";
import { gantt } from "dhtmlx-gantt";
import "dhtmlx-gantt/codebase/dhtmlxgantt.css";
// import 'dhtmlx-gantt/codebase/skins/dhtmlxgantt_material.css';
// import '../locale/locale_pl'
import { makeStyles } from "@material-ui/core/styles";
import { getColor } from "tools";

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: -1,

    "& .gantt_container": {
      fontFamily: "regular",
    },
    "& .gantt_tooltip": {
      fontFamily: "regular",
    },
    "& .resource_marker": {
      textAlign: "center",
    },
    "& .resource_marker div": {
      width: 28,
      height: 28,
      lineHeight: "29px",
      display: "inline-block",
      color: "#ffffff",
    },
    "& .resource_marker.workday_ok div": {
      borderRadius: 15,
      background: "#51c185",
    },

    "& .resource_marker.workday_over div": {
      borderRadius: 3,
      background: "#ff8686",

      padding: 0,
      margin: 0,
    },
    "& .week_end": {
      backgroundColor: "#EFF5FD",
    },
    "& .gantt_row": {
      borderBottom: 0,
    },
    "& .gantt_task_line": {
      backgroundColor: getColor("test")[500],
      border: "1px solid " + getColor("test")[700],
    },
    "& .gantt_task_line .gantt_task_progress": {
      backgroundColor: getColor("test")[300],
    },
    "& .group": {
      backgroundColor: getColor("audit")[500],
      border: "1px solid " + getColor("audit")[700],
    },
    "& .audit": {
      backgroundColor: getColor("audit")[300],
      border: "1px solid " + getColor("audit")[500],
    },
    "& .user": {
      backgroundColor: "#eee",
      border: "1px solid #ddd",
    },
    "& .error": {
      backgroundColor: getColor("error")[100],
      border: "1px solid " + getColor("error")[200],
    },
    "& .error .gantt_task_content": {
      color: "#222",
    },
    "& .user .gantt_task_content": {
      color: "#222",
    },
    "& .empty": {
      backgroundColor: getColor("error")[100],
      border: "1px solid " + getColor("error")[200],
    },
    "& .empty .gantt_task_content": {
      color: getColor("error")[100],
    },
    "& .gantt_task_line.gantt_project .gantt_task_progress": {
      backgroundColor: getColor("audit")[300],
    },
  },
}));

export default (props) => {
  let ganttContainer = null;
  // const [zoom, setZoom] = useState()
  const classes = useStyles();
  var resourceMode = "tasks";
  var resourceTemplates = {
    grid_row_class: function (start, end, resource) {
      var css = [];
      // console.log(resource, 2222)
      if (gantt.$resourcesStore.hasChild(resource.id)) {
        css.push("folder_row");
        css.push("group_row");
      }
      if (shouldHighlightResource(resource)) {
        css.push("highlighted_resource");
      }
      return css.join(" ");
    },

    task_row_class: function (start, end, resource) {
      var css = [];
      if (shouldHighlightResource(resource)) {
        css.push("highlighted_resource");
      }
      if (gantt.$resourcesStore.hasChild(resource.id)) {
        css.push("folder_row");
      }
      // if(gantt.$resourcesStore.hasChild(resource.id)){
      //     css.push("group_row");
      // }

      return css.join(" ");
    },
  };

  var resourceConfig = {
    scale_height: 20,
    subscales: [],
    columns: [
      {
        name: "name",
        label: "Nazwa",
        tree: true,
        width: 200,
        template: function (resource) {
          return resource.value;
        },
        resize: true,
      },
      // {
      //     name: "progress", label: "Postęp", align:"center",template: function (resource) {
      //         var tasks = getResourceTasks(resource.id);

      //         var totalToDo = 0,
      //             totalDone = 0;
      //         tasks.forEach(function(task){
      //             totalToDo += task.duration;
      //             totalDone += task.duration * (task.progress || 0);
      //         });

      //         var completion = 0;
      //         if(totalToDo){
      //             completion = Math.floor((totalDone / totalToDo)*100);
      //         }

      //         return Math.floor(completion) + "%";
      //     }, resize: true
      // },
      // {
      //     name: "workload", label: "Workload??", align:"center", template: function (resource) {
      //         var tasks = getResourceTasks(resource.id);
      //         var totalDuration = 0;
      //         tasks.forEach(function(task){
      //             totalDuration += task.duration;
      //         });

      //         return (totalDuration || 0) * 8 + "h";
      //     }, resize: true
      // },
      // {
      //     name: "capacity", label: "Capacity??", align:"center",template: function (resource) {
      //         var store = gantt.getDatastore(gantt.config.resource_store);
      //         var n = store.hasChild(resource.id) ? store.getChildren(resource.id).length : 1

      //         var state = gantt.getState();

      //         return gantt.calculateDuration(state.min_date, state.max_date) * n * 8 + "h";
      //     }
      // }
    ],
  };

  useEffect(() => {
    init();
    return () => {};
  }, []);

  // useEffect(() => {
  //   gantt.clearAll();
  //   setTimeout(() => {
  //     gantt.parse(props.tasks);
  //     let users = [];
  //     Object.keys(props.users).map((u) =>
  //       users.push({
  //         ...props.users[u],
  //         value: `${props.users[u].value} (${props.users[u].avatar})`,
  //       })
  //     );
  //     gantt.$resourcesStore.parse(users);
  //   }, 100);
  // }, [props.auditGroups, props.teamFilter]);

  function toggleGroups(input) {
    gantt.$groupMode = !gantt.$groupMode;
    if (gantt.$groupMode) {
      input.value = "show gantt view";

      var groups = gantt.$resourcesStore.getItems().map(function (item) {
        var group = gantt.copy(item);
        group.group_id = group.id;
        group.id = gantt.uid();
        return group;
      });

      gantt.groupBy({
        groups: groups,
        relation_property: gantt.config.resource_property,
        group_id: "group_id",
        group_text: "text",
      });
    } else {
      input.value = "show resource view";
      gantt.groupBy(false);
    }
  }

  function getResourceTasks(resourceId) {
    var store = gantt.getDatastore(gantt.config.resource_store),
      field = gantt.config.resource_property,
      tasks;

    if (store.hasChild(resourceId)) {
      tasks = gantt.getTaskBy(field, store.getChildren(resourceId));
    } else {
      tasks = gantt.getTaskBy(field, resourceId);
    }
    return tasks;
  }

  function shouldHighlightResource(resource) {
    var selectedTaskId = gantt.getState().selected_task;
    if (gantt.isTaskExists(selectedTaskId)) {
      var selectedTask = gantt.getTask(selectedTaskId),
        selectedResource = selectedTask[gantt.config.resource_property];

      if (resource.id === selectedResource) {
        return true;
      } else if (
        gantt.$resourcesStore.isChildOf(selectedResource, resource.id)
      ) {
        return true;
      }
    }
    return false;
  }

  function shouldHighlightTask(task) {
    var store = gantt.$resourcesStore;
    var taskResource = task[gantt.config.resource_property],
      selectedResource = store.getSelectedId();
    if (
      taskResource === selectedResource ||
      store.isChildOf(taskResource, selectedResource)
    ) {
      return true;
    }
  }

  const init = () => {
    gantt.config.xml_date = "%d-%m-%Y";
    // gantt.message({
    //     text:[
    //         "Displaying a resource usage diagram.",
    //         "The diagram is in sync with the main Gantt.",
    //         "Columns and resources are fully customizable, the resources can be changed using a public api."
    //     ].join("<br><br>"),
    //     expire: -1
    // });
    gantt.locale = {
      date: {
        month_full: [
          "Styczeń",
          "Luty",
          "Marzec",
          "Kwiecień",
          "Maj",
          "Czerwiec",
          "Lipiec",
          "Sierpień",
          "Wrzesień",
          "Październik",
          "Listopad",
          "Grudzień",
        ],
        month_short: [
          "Sty",
          "Lut",
          "Mar",
          "Kwi",
          "Maj",
          "Cze",
          "Lip",
          "Sie",
          "Wrz",
          "Paź",
          "Lis",
          "Gru",
        ],
        day_full: [
          "Niedziela",
          "Poniedziałek",
          "Wtorek",
          "Środa",
          "Czwartek",
          "Piątek",
          "Sobota",
        ],
        day_short: ["Nie", "Pon", "Wto", "Śro", "Czw", "Pią", "Sob"],
      },
      labels: {
        dhx_cal_today_button: "Dziś",
        day_tab: "Dzień",
        week_tab: "Tydzień",
        month_tab: "Miesiąc",
        new_event: "Nowe zdarzenie",
        icon_save: "Zapisz",
        icon_cancel: "Anuluj",
        icon_details: "Szczegóły",
        icon_edit: "Edytuj",
        icon_delete: "Usuń",
        confirm_closing: "", //Zmiany zostaną usunięte, jesteś pewien?
        confirm_deleting: "Zdarzenie zostanie usunięte na zawsze, kontynuować?",
        section_description: "Opis",
        section_time: "Okres czasu",
        section_type: "Typ",
        /* grid columns */

        column_text: "Nazwa zadania",
        column_start_date: "Początek",
        column_duration: "Czas trwania",
        column_add: "",

        /* link confirmation */
        link: "Link",
        confirm_link_deleting: "zostanie usunięty",
        link_start: " (początek)",
        link_end: " (koniec)",

        type_task: "Zadanie",
        type_project: "Projekt",
        type_milestone: "Milestone",

        minutes: "Minuty",
        hours: "Godziny",
        days: "Dni",
        weeks: "Tydzień",
        months: "Miesiące",
        years: "Lata",
      },
    };

    gantt.templates.grid_row_class = function (start, end, task) {
      var css = [];

      if (gantt.hasChild(task.id)) {
        css.push("folder_row");
      }

      if (task.$virtual) {
        css.push("group_row");
      }

      if (shouldHighlightTask(task)) {
        css.push("highlighted_resource");
      }

      return css.join(" ");
    };

    gantt.templates.task_row_class = function (_, __, task) {
      if (shouldHighlightTask(task)) {
        return "highlighted_resource";
      }
      return "";
    };

    // gantt.templates.task_row_class = function(start, end, task){
    //     return task.class;
    // };

    gantt.templates.task_class = function (_, __, task) {
      return task.class;
    };

    var holidays = [
      //USA, DC holidays
      new Date(2020, 0, 1),
      new Date(2020, 0, 21),
      new Date(2020, 3, 16),
      new Date(2020, 4, 12),
      new Date(2020, 4, 27),
      new Date(2020, 5, 16),
      new Date(2020, 6, 7),
      new Date(2020, 6, 8),
      new Date(2020, 9, 14),
      new Date(2020, 10, 28),
      new Date(2020, 11, 25),
    ];

    for (var i = 0; i < holidays.length; i++) {
      gantt.setWorkTime({
        date: holidays[i],
        hours: false,
      });
    }

    gantt.config.work_time = true;

    gantt.templates.timeline_cell_class = function (task, date) {
      if (!gantt.isWorkTime({ date: date, task: task })) return "week_end";
      return "";
    };

    gantt.templates.resource_cell_class = function (
      start_date,
      end_date,
      resource,
      tasks
    ) {
      var css = [];
      css.push("resource_marker");
      if (tasks.length <= 1) {
        css.push("workday_ok");
      } else {
        css.push("workday_over");
      }
      return css.join(" ");
    };

    gantt.templates.resource_cell_value = function (
      start_date,
      end_date,
      resource,
      tasks
    ) {
      var html = "<div>";
      if (resourceMode === "hours") {
        html += tasks.length * 8;
      } else {
        html += tasks.length;
      }
      html += "</div>";
      return html;
    };

    gantt.locale.labels.section_owner = "Audytor";
    gantt.config.lightbox.sections = [
      {
        name: "description",
        height: 38,
        map_to: "text",
        type: "textarea",
        focus: true,
      },
      {
        name: "owner",
        height: 22,
        map_to: "owner_id",
        type: "select",
        options: gantt.serverList("people"),
      },
      { name: "time", type: "duration", map_to: "auto" },
    ];

    // gantt.config.subscales = [
    //     {unit: "month", step: 1, date: "%F, %Y"}
    // ];

    gantt.config.auto_scheduling = false;
    // gantt.config.auto_scheduling_strict = true;
    gantt.config.work_time = true;
    gantt.config.columns = [
      { name: "text", tree: true, width: 200, resize: true },
      { name: "start_date", align: "center", width: 80, resize: true },
      // {name: "owner", align: "center", width: 80, label: "Audytor", template: function (task) {
      //     if(task.type === gantt.config.types.project){
      //         return "";
      //     }

      //     var store = gantt.getDatastore(gantt.config.resource_store);
      //     var owner = store.getItem(task[gantt.config.resource_property]);
      //     if (owner) {
      //         return owner.value;
      //     } else {
      //         return "Unassigned";
      //     }
      // }, resize: true},
      { name: "duration", width: 40, align: "center", resize: true },
      // {name: "add", width: 44}
    ];

    gantt.config.resource_store = "resource";
    gantt.config.resource_property = "owner_id";
    gantt.config.order_branch = true;
    gantt.config.open_tree_initially = true;
    gantt.config.scale_height = 50;
    gantt.config.duration_unit = "minute";
    gantt.config.duration_step = 60;
    gantt.config.layout = {
      css: "gantt_container",
      rows: [
        {
          gravity: 1,
          cols: [
            {
              view: "grid",
              group: "grids",
              scrollY: "scrollVer",
              //   scrollX: "scrollH",
            },
            { resizer: true, width: 1 },
            {
              rows: [
                {
                  view: "timeline",
                  scrollX: "scrollHor",
                  scrollY: "scrollVer",
                },
                { view: "scrollbar", id: "scrollHor" },
              ],

              //   scrollX: "scrollH",
            },

            { view: "scrollbar", id: "scrollVer" },
            // { view: "scrollbar", id: "scrollH" },
          ],
        },
        // { resizer: true, width: 1, next: "resources" },
        // {
        //     height: 35,
        //     cols: [
        //         { html:"", group:"grids"},
        //         { resizer: true, width: 1},
        //         { html:"<label class='active' >Godzin dziennie <input checked type='radio' name='resource-mode' value='hours'></label>" +
        //         "<label>Zadań dziennie <input type='radio' name='resource-mode' value='tasks'></label>", css:"resource-controls"}
        //     ]
        // },

        {
          gravity: 1,
          id: "resources",
          config: resourceConfig,
          templates: resourceTemplates,
          cols: [
            {
              view: "resourceGrid",
              group: "grids",
              scrollY: "resourceVScroll",
            },
            { resizer: true, width: 1 },
            {
              view: "resourceTimeline",
              scrollX: "scrollHor",
              scrollY: "resourceVScroll",
            },
            { view: "scrollbar", id: "resourceVScroll", group: "vertical" },
          ],
        },
      ],
    };
    gantt.config.readonly = true;
    gantt.attachEvent("onGanttReady", function () {
      var radios = [].slice.call(
        gantt.$container.querySelectorAll("input[type='radio']")
      );
      radios.forEach(function (r) {
        gantt.event(r, "change", function (e) {
          var radios = [].slice.call(
            gantt.$container.querySelectorAll("input[type='radio']")
          );
          radios.forEach(function (r) {
            r.parentNode.className = r.parentNode.className.replace(
              "active",
              ""
            );
          });

          if (this.checked) {
            resourceMode = this.value;
            this.parentNode.className += " active";
            gantt.getDatastore(gantt.config.resource_store).refresh();
          }
        });
      });
    });

    gantt.$resourcesStore = gantt.createDatastore({
      name: gantt.config.resource_store,
      type: "treeDatastore",
      initItem: function (item) {
        item.parent = item.parent || gantt.config.root_id;
        item[gantt.config.resource_property] = item.parent;
        item.open = true;
        return item;
      },
    });

    gantt.$resourcesStore.attachEvent("onAfterSelect", function (id) {
      gantt.refreshData();
    });

    gantt.config.drag_links = false;
    gantt.config.drag_resize = false;
    gantt.config.drag_move = false;

    gantt.init(ganttContainer);
    setTimeout(() => gantt.parse(props.tasks), 100);

    gantt.$resourcesStore.attachEvent("onParse", function () {
      var people = [];
      gantt.$resourcesStore.eachItem(function (res) {
        if (!gantt.$resourcesStore.hasChild(res.id)) {
          var copy = gantt.copy(res);
          copy.key = res.id;
          copy.label = res.value;
          people.push(copy);
        }
      });
      gantt.updateCollection("people", people);
    });

    // gantt.$resourcesStore.parse([
    //     {id: 1, text: "Główni audytorzy", parent:null},
    //     {id: 2, text: "Szeregowi audytorzy", parent:null},
    //     {id: 3, text: "Asystenci", parent:null},
    //     {id: 4, text: "Inni", parent:null},
    //     {id: 5, text: "Unassigned", parent:4},
    //     {id: 6, text: "JK", parent:1},
    //     {id: 7, text: "MZ", parent:2},
    //     {id: 8, text: "AN", parent:2},
    //     {id: 9, text: "BT", parent:3},
    //     {id: 10, text: "FI", parent:3}
    // ]);

    let users = [];
    Object.keys(props.users).map((u) =>
      users.push({
        ...props.users[u],
        value: `${props.users[u].value} (${props.users[u].avatar})`,
      })
    );

    gantt.$resourcesStore.parse(users);
  };

  return (
    <div
      ref={(input) => {
        ganttContainer = input;
      }}
      id="gantt_here"
      className={classes.root}
      style={{ height: "100%" }}
    ></div>
  );
};
