import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { getError } from "tools";
import { USERS } from "graphql/queries";
import { useQuery } from "@apollo/client";
import { Avatar } from "elements";
import { findWhere } from "underscore";

const useStyles = makeStyles((theme) => ({
  container: {
    height: 60,
    marginBottom: 10,
  },
  formControl: {
    // minWidth: '100%',
    "& .MuiInput-underline:before": {
      border: 0,
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  avatar: {
    width: 28,
    height: 28,
    fontSize: 16,
    fontFamily: "bold",
  },
  withName: {
    "& div": {
      float: "left",
    },
    "& span": {
      display: "inline-lock",
      height: 28,
      lineHeight: "36px",
      marginLeft: 10,
    },
  },
}));

export default (props) => {
  let { value, label, withName, error, onChange, inputProps, name } = props;
  if (!withName && inputProps && inputProps.withName)
    withName = inputProps.withName;

  const classes = useStyles();
  const [v, setV] = useState(value || "");

  useEffect(() => {
    setV(props.value);
  }, [props.value]);

  let { data, loading } = useQuery(USERS);
  if (loading) return false;

  let user = null;
  if (props.users)
    data = {
      users: props.users,
    };

  user = findWhere(data.users, { id: v });

  const setIt = (e) => {
    setV(e.target.value);
    onChange && onChange(e.target.value);
  };

  const onClose = () => {
    setTimeout(() => {
      document.activeElement.blur();
    }, 0);
  };

  return (
    <FormControl
      style={withName ? { minWidth: 150 } : {}}
      className={classes.formControl}
      error={error && true}
    >
      <InputLabel id="demo-simple-select-outlined-label">{label}</InputLabel>
      <Select
        value={v}
        label={label}
        onChange={setIt}
        onClose={onClose}
        // inputProps={{
        //   readOnly: props.data && props.data.status && props.data.status !== 0,
        // }}
        renderValue={(_) =>
          withName ? (
            <div className={classes.withName}>
              <Avatar value={user.value} color={user.color}>
                {user.avatar}
              </Avatar>{" "}
              <span>{user?.value}</span>
            </div>
          ) : (
            <Avatar value={user?.value} color={user?.color}>
              {user?.avatar}
            </Avatar>
          )
        }
        disabled={props.readOnly || props.disabled}
      >
        {data.users.map((d) => (
          <MenuItem key={d.id} value={d.id}>
            {d.value}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{getError(error.type)}</FormHelperText>}
      {props.register && (
        <input
          type="hidden"
          name={name}
          ref={props.register(props.validation)}
          value={v}
        />
      )}
    </FormControl>
  );
};
