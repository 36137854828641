import React from "react";
import { RightBar, MultiSelect, Panel, Loading } from "elements";
import { useQuery } from "@apollo/client";
import { FINDINGS } from "graphql/queries";
import { useSnackbar } from "notistack";
import { FindingsList, FindingDetails } from "views/Findings/components";
import { isEmpty } from "underscore";
import { getName } from "tools";
import _ from "lodash";
import { AppBar, Tabs, Tab, Typography, Box, Divider } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default () => {
  let { num } = useParams();
  const [value, setValue] = React.useState(parseInt(num) || 0);
  const [rightbarData, setRightbarData] = React.useState(false);
  const { data, loading } = useQuery(FINDINGS, { variables: { all: false }, pollInterval: 60000,  fetchPolicy: "no-cache" });
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (loading) return <Loading />;
  const showFindingDetails = (idx) => {
    setRightbarData(data.findings.findingFeed[idx]);
  };

  return (
    <>
      <FindingsList showDetails={showFindingDetails} data={data.findings} />

      <RightBar open={rightbarData} onClose={() => setRightbarData(false)}>
        {rightbarData.__typename === "Finding" && (
          <FindingDetails id={rightbarData.id} />
        )}
      </RightBar>
    </>
  );
};

{
  /* <button onClick={onClick}> Dodaj zakaldke </button> */
}
