import React, { useContext } from "react";
import moment from "moment";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useQuery } from "@apollo/client";
import { AUDIT } from "graphql/queries";
import Button from "@material-ui/core/Button";
import {
  Files,
  Tests,
  Findings,
  Reports,
  Schedule,
  Team,
  First,
  VariatsRisk,
  ReviewNotes,
  Logs,
} from "./components";
import { useSnackbar } from "notistack";
import {
  // BrowserRouter as Router,
  // Switch,
  // Route,
  useLocation,
  Link,
  useParams,
} from "react-router-dom";
import { getName } from "tools";
import { ErrorPage, Panel, Title, Tabs, Tab } from "elements";
import AppBar from "@material-ui/core/AppBar";
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    padding: 0,
  },

  container: {
    padding: "2%",
  },
}));

function AuditDetails({ data, num, refetch }) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(num);

  let location = useLocation();
  React.useEffect(() => {
    try {
      setValue(
        parseInt(location.pathname.match(/\/\d+\/?$/)[0].replace(/\//, ""))
      );
    } catch (err) {
      setValue(0);
    }
  }, [location]);

  const handleChange = (_, newValue) => {
    let p = window.location.pathname.replace(/\/\d+\/?$/, "") + "/" + newValue;
    window.history.pushState({}, null, p);
    // setPath(p);
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs value={value} onChange={handleChange} aria-label="" color="audit">
          <Tab color="audit" label="Informacje" {...a11yProps(0)} />
          <Tab color="audit" label={getName("Test", 1)} {...a11yProps(1)} />
          <Tab color="audit" label="Pliki" {...a11yProps(2)} />
          <Tab color="audit" label={getName("Finding", 1)} {...a11yProps(3)} />
          <Tab color="audit" label="Raport" {...a11yProps(4)} />
          <Tab color="audit" label="Zespół" {...a11yProps(5)} />
          <Tab color="audit" label="Firma" {...a11yProps(6)} />
          <Tab
            color="test"
            label={getName("ReviewNote", 1)}
            {...a11yProps(7)}
          />
          <Tab color="audit" label="Log" {...a11yProps(8)} />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0} dir={theme.direction}>
        <Panel title="Informacje podstawowe">
          <div className={classes.container}>
            <First audit={data} refetch={refetch} />
          </div>
        </Panel>
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        <Panel title={getName("Test")}>
          <Tests audit={data} />
        </Panel>
      </TabPanel>
      <TabPanel value={value} index={2} dir={theme.direction}>
        <Panel title="Pliki audytu">
          <Files audit={data} />
        </Panel>
      </TabPanel>
      <TabPanel value={value} index={3} dir={theme.direction}>
        <Panel title={getName("Finding", 1)}>
          <Findings audit={data} />
        </Panel>
      </TabPanel>
      <TabPanel value={value} index={4} dir={theme.direction}>
        <Panel title="Raportowanie">
          <Reports audit={data} />
        </Panel>
      </TabPanel>
      <TabPanel value={value} index={5} dir={theme.direction}>
        <Panel title="Zespół">
          <Team audit={data} />
        </Panel>
      </TabPanel>
      <TabPanel value={value} index={6} dir={theme.direction}>
        <Panel title="Zmiana ryzyka">
          <VariatsRisk audit={data} />
        </Panel>
      </TabPanel>
      <TabPanel value={value} index={7} dir={theme.direction}>
        <Panel title={getName("ReviewNote", 1)}>
          <ReviewNotes audit={data} />
        </Panel>
      </TabPanel>
      <TabPanel value={value} index={8} dir={theme.direction}>
        <Panel title="Logs">
          <Logs audit={data} />
        </Panel>
      </TabPanel>
    </div>
  );
}

export default (props) => {
  let { id, num } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { data, loading, refetch } = useQuery(AUDIT, {
    variables: { id },
    pollInterval: 20000,
    fetchPolicy: "no-cache",
  });

  if (loading) return false;
  if (!data.audit) {
    enqueueSnackbar(`${getName("Audit")} został usunięty`, {
      variant: "error",
    });
    return <ErrorPage />;
  }

  return (
    <AuditDetails
      refetch={refetch}
      data={data.audit}
      num={num ? parseInt(num) : 0}
    />
  );
};
