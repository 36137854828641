/* eslint-disable no-use-before-define */
import React from "react";
import useAutocomplete from "@material-ui/lab/useAutocomplete";
import NoSsr from "@material-ui/core/NoSsr";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import styled from "styled-components";
// import { USERS } from "graphql/queries";
// import { useQuery } from "@apollo/client";
// import { Avatar } from "elements";
// import AvatarGroup from "@material-ui/lab/AvatarGroup";
import { pluck } from "underscore";

const Label = styled("label")`
  line-height: 30px;
`;

const InputWrapper = styled("div")`
  width: 100%;

  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;

  &:hover {
  }

  & input {
    font-family: "regular";
    font-size: 14px;
    height: 30px;
    background: transparent;

    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`;

const Tag = styled(({ label, onDelete, ...props }) => (
  <div {...props}>
    <span>{label}</span>
    <CloseIcon onClick={onDelete} />
  </div>
))`
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: #40a9ff;
    background-color: #e6f7ff;
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 20px;
    cursor: pointer;
    padding: 4px;
  }
`;

const Listbox = styled("ul")`
  width: 300px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: #fff;
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected="true"] {
    background-color: #fafafa;
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li[data-focus="true"] {
    background-color: #e6f7ff;
    cursor: pointer;

    & svg {
      color: #000;
    }
  }
`;

function MultiSelect(props) {
  const [v, setV] = React.useState(props.value);
  const [ids, setIds] = React.useState(pluck(props.value, "id"));
  const update = React.useRef(false);
  const textInput = React.useRef();

  const closeIt = async () => {
    update.current && props.onChange && (await props.onChange(ids));
    // update.current && props.setValue&& props.setValue(ids)
    update.current = false;
  };

  const setIt = (e, v) => {
    setV(v);
    update.current = true;
    setIds(pluck(v, "id"));
    //  if(value.length === groupedOptions.length-1) props.onChange(ids)
  };

  React.useEffect(() => {
    setV(props.value);
    setIds(pluck(props.value, "id"));
  }, [props.value]);

  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: "customized-hook-demo",
    value: v,
    multiple: true,
    options: props.data,
    getOptionLabel: (option) => option.value,
    onChange: setIt,
    onClose: closeIt,
    disableCloseOnSelect: true,
  });

  const onClick = (id) => () => {
    const newV = v.filter((obj) => obj.id !== id);
    const newIds = pluck(newV, "id");

    setV([...newV]);
    setIds([...newIds]);

    props.onChange && props.onChange(newIds);
  };

  const getListbox = (listboxProps) => {
    // if(groupedOptions.length === ids.length)
    //   setTimeout(closeIt(), 1000)

    return (
      <Listbox {...listboxProps}>
        {groupedOptions.map((option, index) => (
          <li {...getOptionProps({ option, index })}>
            <span>{option.value}</span>
            <CheckIcon fontSize="small" />
          </li>
        ))}
      </Listbox>
    );
  };

  const isOpen = props.data && props.data.status !== 1;

  return (
    <NoSsr>
      <div>
        <div {...getRootProps()}>
          <InputWrapper ref={setAnchorEl} className={focused ? "focused" : ""}>
            <Label>{props.label ? props.label + ": " : ""}</Label>

            {value &&
              value.map((option, index) => (
                <Tag
                  label={option?.value}
                  {...getTagProps({ index })}
                  onDelete={onClick(option?.id)}
                />
              ))}

            <input
              {...getInputProps()}
              disabled={props.disabled}
              placeholder={ids.length ? "" : props.emptyText || "Wszystkie"}
            />
          </InputWrapper>
        </div>
        {isOpen && groupedOptions.length > 0 && getListbox(getListboxProps())}
        {props.register && (
          <input
            type="hidden"
            value={ids}
            name={props.name}
            ref={props.register(props.validation || {})}
          />
        )}
      </div>
    </NoSsr>
  );
}

export default (props) => {
  let data = [],
    selected = [],
    dataById = {};
  props.data.map((d) => {
    data.push(d);
    dataById[d.id] = d;
  });
  props.value &&
    props.value.map((d) => {
      if (d && d.id) {
        selected.push(dataById[d.id]);
      } else if (dataById[d]) {
        selected.push(dataById[d]);
      }
    });

  return <MultiSelect data={data} {...props} value={selected} />;
};
