import React from "react";
import { FileList } from "elements";
import { useAuthDataContext } from "context/Context";

export default ({ audit }) => {
  const { user } = useAuthDataContext();

  return (
    <FileList
      audit={audit.id}
      disableAdd={
        !user.permissions?.audit?.audit_editAll || audit.status === 19
      }
    />
  );
};
