import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import { AUDITS, USERS } from "graphql/queries";
import { CREATE_USER, DELETE_USER } from "graphql/mutations";
import { Table, Loading, RightBar } from "elements";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { useSnackbar } from "notistack";
import moment from "moment";
import { useAuthDataContext } from "context/Context";
import _ from "lodash";

export default ({ dates }) => {
  const a = useQuery(AUDITS);
  const u = useQuery(USERS);
  const [createUser] = useMutation(CREATE_USER);
  const [deleteUser] = useMutation(DELETE_USER);
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuthDataContext();
  const [rightBar, setRightBar] = React.useState(false);

  if (u.loading || a.loading) return <Loading />;

  const columns = [
    {
      Header: "Audytor",
      accessor: "value",
      type: "label",
      width: "50%",
    },
    {
      Header: "Zajęty",
      accessor: "avaliable",
      type: "count",
      width: "20%",
    },
    {
      Header: "Urlopy",
      accessor: "holidays",
      type: "count",
      width: "20%",
      inputProps: {
        columns: [{ name: "Data", value: "date", width: "100%" }],
      },
    },
  ];
  let data = [];
  let usr = {},
    all = { count: 0, hours: 0 };

  let d = null;

  const toTimestamp = (strDate) => parseInt(strDate);

  const getDays = (_from, _to) => {
    let days = {};
    let day = 24 * 60 * 60;
    let from = toTimestamp(_from);
    let to = toTimestamp(_to);
    // console.log(from, _from)
    // console.log(to, _to)

    for (; from <= to; from += day) days[from] = true;
    return days;
  };

  const getAvaliableDays = (_from, _to, _busy) => {
    let i = 0;
    let busyDict = {};

    if (_busy && _busy.length) {
      for (i = 0; i < _busy.length; i++) {
        if (_busy[i].length > 1) {
          busyDict = { ...busyDict, ...getDays(_busy[i][0], _busy[i][1]) };
        } else busyDict[parseInt(_busy[i][0])] = true;
      }
    }

    // console.log(
    //   999,
    //   _from,
    //   _to,
    //   Object.keys(getDays(_from, _to)),
    //   Object.keys(busyDict)
    // );

    return _.difference(
      Object.keys(getDays(_from, _to)),
      Object.keys(busyDict)
    );
  };

  const getHolidays = (hd) => {
    let res = [];
    if (hd.length === 0) return [];

    let d1 = parseInt(dates[0]) / 1000,
      d4 = parseInt(dates[1]) / 1000;

    return hd.filter((hh) => parseInt(hh) > d1 && parseInt(hh) < d4);
  };

  u.data.users.map((uu) => {
    // holidays[uu.id] = uu.holidays ? uu.holidays.split(",") : [];
    usr[uu.id] = {
      value: uu.value,
      data: [],
      count: 0,
      hours: 0,
      holidaysData: [],
      holidays: uu.holidays ? getHolidays(uu.holidays.split(",")) : [],
      busy: uu.holidays ? uu.holidays.split(",") : [],
    };
  });

  a.data.audits.auditFeed.map((aa) => {
    if (aa.dates) {
      d = JSON.parse(aa.dates);
      if (d.date1 && d.date4) {
        d = [parseInt(d.date1), parseInt(d.date4)];
        if (d[0] < dates[1] && d[1] > dates[0]) {
          [aa.leader, ...aa.team].map((t) => {
            usr[t.id].busy.push([`${d[0] / 1000}`, `${d[1] / 1000}`]);
          });
        }
      }
    }
  });

  let allHours2 = getDays(parseInt(dates[0]) / 1000, parseInt(dates[1]) / 1000);
  allHours2 = Object.keys(allHours2).length * 8;
  // getDays(parseInt(dates[0]) / 1000, parseInt(dates[1]) / 1000).length * 8;

  Object.keys(usr).map((id) => {
    let aD = getAvaliableDays(dates[0] / 1000, dates[1] / 1000, usr[id].busy),
      b = [];
    if (aD.length < 31) {
      aD.map(
        (a, i) => (aD[i] = moment(parseInt(a) * 1000).format("YYYY-MM-DD"))
      );
      // b = usr[id].busy.map()
    }

    data.push({
      value: usr[id].value,
      holidays: {
        num: usr[id].holidays.length,
        data: usr[id].holidays,
      },
      avaliable: {
        num: allHours2 - aD.length * 8,
        data: aD,
        all: allHours2,
      },
    });
    // console.log(dates[0], dates[1], usr[id].busy);
    // usr[id].avaliableDays = getAvaliableDays(dates[0], dates[1], usr[id].busy);
  });

  let allHours = 0,
    notAllAuditHasDates = false,
    res = [],
    audit = null;

  if (notAllAuditHasDates)
    enqueueSnackbar(`Daty nie wszystkich audytów zostały określone.`, {
      variant: "error",
    });

    const onDelete = async (ps) => {
      if (ps.length === 0) return false;
  
      let snackText = [
        ps.length > 1 ? "Konta" : "Konto",
        "",
        ps.length > 1 ? "zostały usunięte" : "zostało usunięte",
      ];
      let names = [];
  
      for (let i = ps.length - 1; i > 0; i--) {
        await deleteUser({ variables: { id: ps[i].id } });
        names.push(ps[i].value);
      }
      await deleteUser({
        variables: { id: ps[0].id },
  
        refetchQueries: [{ query: USERS }],
      });
      names.push(ps[0].value);
      snackText[1] = names.join(", ");
  
      enqueueSnackbar(snackText.join(" "), { variant: "success" });
    };
  
    const onSubmitUserForm = async (_input) => {
      let input = {};
  
      Object.keys(_input).map((i) =>
        _input[i].length ? (input[i] = _input[i]) : null
      );
  
      const { data } = await createUser({
        variables: { input },
        refetchQueries: [{ query: USERS }],
      });
      enqueueSnackbar(`Audytor został dodany`, { variant: "success" });
    };
    // function goTo(i) {
    //   setRightBar(true);
    //   console.log(data[i]);
    // }  

  return (
    <>
      <Table
        typename="User"
        columns={columns}
        skipPageReset={true}
        data={data}
        onAdd={user?.permissions?.team?.team_edit ? onSubmitUserForm: null}
        onDelete={user?.permissions?.team?.team_edit ?  onDelete: null}
        // rowActions={[{ icon: <KeyboardArrowRightIcon />, onClick: goTo }]}
      />
       <RightBar open={rightBar} onClose={() => setRightBar(false)}>
        abc
      </RightBar>
    </>
  );
};
