import React from "react";
import { useQuery, useMutation } from "@apollo/client";
import { AUDITPLANS } from "graphql/queries";
import {
  UPDATE_AUDITPLAN,
  CREATE_AUDITPLAN,
  EXPORT_AUDITPLAN,
} from "graphql/mutations";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, ButtonGroup, IconButton } from "@material-ui/core";
import SortableTree, {
  addNodeUnderParent,
  removeNodeAtPath,
  changeNodeAtPath,
} from "react-sortable-tree";
import FileExplorerTheme from "react-sortable-tree-theme-file-explorer";
import "react-sortable-tree/style.css";
import TextField from "@material-ui/core/TextField";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import GridOnIcon from "@material-ui/icons/GridOn";
import AppsIcon from "@material-ui/icons/Apps";
import SaveIcon from "@material-ui/icons/Save";
import { v1 as uuidv1 } from "uuid";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import { useSnackbar } from "notistack";
import { getName } from "tools";
import * as XLSX from "xlsx";
import { FileButton } from "elements";
import { FILE_URL } from "vars";

const useStyles = makeStyles({
  root: {
    width: "100%",
    padding: 15,
    "& .MuiInputBase-inputMarginDense": {
      paddingBottom: 2,
    },
    "& .rstcustom__node .rstcustom__toolbarButton": {
      display: "none",
    },
    "& .rstcustom__node:hover .rstcustom__toolbarButton": {
      display: "inline-block",
    },
  },
  left: {
    borderRight: "1px solid #e0e0e0",
  },
  right: {
    padding: "0px 10px",
  },
  buttons: {
    // display: 'none'
  },
});

function AuditPlanTest({ test, onChange }) {
  const p = React.useRef();
  const [t, setT] = React.useState();

  React.useEffect(() => {
    let { path, ...rest } = test;
    p.current = path;
    setT({ desc: "", todo: "", hours: "", ...rest });
  }, [test]);

  const handleChange = (e, name) => setT({ ...t, [name]: e.target.value });
  const handleBlur = (_) => {
    onChange(t, p.current);
  };

  if (!t) return null;

  return (
    <div style={{ width: "100%" }}>
      <TextField
        label="Numer testu"
        value={t.num}
        onChange={(e) => handleChange(e, "num")}
        onBlur={handleBlur}
        fullWidth
      />
      <br /> <br />
      <TextField
        autoFocus={true}
        fullWidth
        value={t.value || null}
        onBlur={handleBlur}
        onChange={(e) => handleChange(e, "value")}
      />
      <br /> <br />
      <TextField
        label="Il. godzin"
        type="number"
        value={t.hours}
        onChange={(e) => handleChange(e, "hours")}
        onBlur={handleBlur}
        InputLabelProps={{
          shrink: true,
        }}
        fullWidth
      />
      <br /> <br />
      <TextField
        id="outlined-multiline-flexible"
        label="Opis testu"
        multiline
        rowsMax={10}
        value={t.desc}
        onChange={(e) => handleChange(e, "desc")}
        onBlur={handleBlur}
        fullWidth
      />
      <br /> <br />
      <TextField
        id="outlined-multiline-flexible"
        label="Co należy zrobić?"
        multiline
        rowsMax={10}
        value={t.todo}
        onChange={(e) => handleChange(e, "todo")}
        onBlur={handleBlur}
        fullWidth
      />
      <br /> <br />
      <TextField
        type="number"
        label="Proponowana próbka"
        value={t.sample}
        onChange={(e) => handleChange(e, "sample")}
        onBlur={handleBlur}
        fullWidth
      />
      <br /> <br />
      <TextField
        label="Kontrola pokryta testem"
        value={t.controlCovered}
        onChange={(e) => handleChange(e, "todo")}
        onBlur={handleBlur}
        fullWidth
      />
      <br /> <br />
      <TextField
        label="Ryzyko pokryte testem"
        value={t.riskCovered}
        onChange={(e) => handleChange(e, "todo")}
        onBlur={handleBlur}
        fullWidth
      />
    </div>
  );
}

function TreeInput({ onChange, setTest, isTest, value }) {
  const [editable, setEditable] = React.useState(false);

  const handleChange = (e) => {
    value = e.target.value;
  };
  const handleBlur = () => {
    setEditable(false);
    onChange(value);
  };
  return !editable ? (
    <div
      onClick={() => {
        setTest();
        setEditable(!isTest);
      }}
    >
      {value}
    </div>
  ) : (
    <TextField
      autoFocus={true}
      defaultValue={value}
      size="small"
      onBlur={handleBlur}
      onChange={handleChange}
    />
  );
}

function AuditPlanPanel({ auditPlan }) {
  const classes = useStyles();
  const [treeData, setTreeData] = React.useState(JSON.parse(auditPlan.tree));
  const tData = React.useRef(JSON.parse(auditPlan.tree));
  const [updateAuditPlan] = useMutation(UPDATE_AUDITPLAN);
  const [exportAuditPlan] = useMutation(EXPORT_AUDITPLAN);
  const { enqueueSnackbar } = useSnackbar();
  const [addAsFirstChild, setAddAsFirstChild] = React.useState(false);
  const [json, setJson] = React.useState([]);
  const [test, setTest] = React.useState(null);
  const lastPath = React.useRef(null);

  const selectTest = (node, path) => () => {
    setTest({ ...node, path });
  };

  const onChangeTest = (newNode, path) => {
    const newTreeData = changeNodeAtPath({
      treeData,
      path,
      getNodeKey,
      newNode,
    });
    setTreeData(newTreeData);
    tData.current = newTreeData;

    updateAuditPlan({
      variables: {
        id: auditPlan.id,
        input: { tree: JSON.stringify(newTreeData) },
      },
      refetchQueries: [{ query: AUDITPLANS }],
    });
  };

  const onChange = (node, path) => (value) => {
    const newTreeData = changeNodeAtPath({
      treeData,
      path,
      getNodeKey,
      newNode: { ...node, value },
    });
    setTreeData(newTreeData);
    tData.current = newTreeData;

    updateAuditPlan({
      variables: {
        id: auditPlan.id,
        input: { tree: JSON.stringify(newTreeData) },
      },
      refetchQueries: [{ query: AUDITPLANS }],
    });

    // enqueueSnackbar(`${getName("AuditPlan")} został zaktualizowany`, { variant: 'success' });
  };

  const getNodeKey = ({ treeIndex }) => treeIndex;
  // const getRandomName = () => Math.random().toString(36).substring(7);

  const deleteIt = (path) => () => {
    const newTreeData = removeNodeAtPath({
      treeData,
      path,
      getNodeKey,
    });
    setTreeData(newTreeData);
    tData.current = newTreeData;

    updateAuditPlan({
      variables: {
        id: auditPlan.id,
        input: { tree: JSON.stringify(newTreeData) },
      },
      refetchQueries: [{ query: AUDITPLANS }],
    });
  };

  const add = async (path, isTest) => {
    let newNode = null;
    newNode = isTest
      ? { isTest: true, value: "Nowy test" }
      : { value: "Nowy folder" };

    const newTreeData = addNodeUnderParent({
      treeData,
      parentKey: path[path.length - 1],
      expandParent: true,
      getNodeKey,
      newNode,
      addAsFirstChild,
    }).treeData;

    setTreeData(newTreeData);
    tData.current = newTreeData;

    // console.log(newTreeData)

    await updateAuditPlan({
      variables: {
        id: auditPlan.id,
        input: { tree: JSON.stringify(newTreeData) },
      },
      refetchQueries: [{ query: AUDITPLANS }],
    });

    // enqueueSnackbar(`${data.updateAuditPlan.value} został zaktualizowany`, { variant: 'success' });
  };

  const addFolder = (path) => () => add(path, false);
  const addTest = (path) => () => add(path, true);

  const generateNodeProps = (_1) => {
    const { node, path } = _1;

    lastPath.current = path;

    let buttons = [
      <IconButton
        onClick={deleteIt(path)}
        aria-label="delete"
        className={classes.margin}
        size="small"
      >
        <DeleteIcon fontSize="inherit" />
      </IconButton>,
    ];
    if (!node.isTest)
      buttons = [
        <IconButton
          title="Stwórz nowy test"
          onClick={addTest(path)}
          aria-label="delete"
          className={classes.margin}
          size="small"
        >
          <AddIcon fontSize="inherit" />
        </IconButton>,
        <IconButton
          title="Stwórz nowy folder"
          onClick={addFolder(path)}
          aria-label="delete"
          className={classes.margin}
          size="small"
        >
          <CreateNewFolderIcon fontSize="inherit" />
        </IconButton>,
      ].concat(buttons);

    return {
      title: (
        <TreeInput
          value={node.value}
          isTest={node.isTest || false}
          setTest={selectTest(node, path)}
          onChange={onChange(node, path)}
        />
      ),
      buttons,
    };
  };
  const showDataExcel = async (files) => {
    var file = files[0];
    var reader = new FileReader();
    reader.onload = async function (event) {
      var data = event.target.result;
      var workbook = XLSX.read(data, {
        type: "binary",
      });

      workbook.SheetNames.forEach(async function (sheetName) {
        // Here is your object
        var XL_row_object = XLSX.utils.sheet_to_row_object_array(
          workbook.Sheets[sheetName]
        );

        // for (var i = 0; i < XL_row_object.length; i++){
        //     excelData.push(XL_row_object[i]["your column name"]);
        // }
        // [{"Grupa":"Plan audytów 1","Nazwa":"Test 11","Czas":110,"Opis":"Opis 11","CNZ":"CNZ 11"},{"Grupa":"Plan audytów 1","Nazwa":"Test 21","Czas":210,"Opis":"Opis 21","CNZ":"CNZ 21"},{"Grupa":"Plan audytów 1","Nazwa":"Test 31","Czas":310,"Opis":"Opis 31","CNZ":"CNZ 31"}]

        var json_object = JSON.stringify(XL_row_object);

        let lastGroup = null;
        // let _path = [...lastPath.current, 0]
        //console.log(treeData);
        let newTreeData = [
          { children: [], expanded: true, value: "Plan audytów" },
        ];
        let idx1 = 0,
          idx2 = 0;

        XL_row_object.map(async (r) => {
          //console.log(r, 999);
          if (lastGroup !== r.group) {
            idx1 = idx2 + 1;
            idx2 += 1;
            newTreeData = addNodeUnderParent({
              treeData: newTreeData,
              parentKey: 0,
              expandParent: true,
              getNodeKey,
              newNode: { value: r.group, isTest: false },
            }).treeData;
            console.log(newTreeData, 123);
            lastGroup = r.group;
          }
          newTreeData = addNodeUnderParent({
            treeData: newTreeData,
            parentKey: idx1,
            expandParent: true,
            getNodeKey,
            newNode: {
              from: r.group,
              num: r.number,
              sample: r.sample,
              value: r.name,
              desc: r.description,
              hours: r.time,
              todo: r.todo,
              controlCovered: r.controlCovered,
              riskCovered: r.riskCovered,
              isTest: true,
            },
          }).treeData;
          idx2 += 1;
        });
        await updateAuditPlan({
          variables: {
            id: auditPlan.id,
            input: { tree: JSON.stringify(newTreeData) },
          },
          refetchQueries: [{ query: AUDITPLANS }],
        });
        setTreeData(newTreeData);
      });
    };

    reader.onerror = function (ex) {
      console.log("Err", ex);
    };
    reader.readAsBinaryString(file);
  };

  const exportToFile = async () => {
    const result = await exportAuditPlan();
    console.log(result, 1234);
    download(result.data.exportAuditPlan);
    enqueueSnackbar("Dane poprawie wyeksportowane ", { variant: "success" });
  };

  const download = (name) => {
    const link = document.createElement("a");
    // link.targe="_blank"
    link.href = `${FILE_URL}/${name}/auditPlan.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  console.log("TD", treeData)
  return (
    <Grid container className={classes.root}>
      <Grid container item xs={6} className={classes.left}>
        <div style={{ width: "100%", height: window.innerHeight }}>
          <FileButton
            icon={<AppsIcon />}
            label="Importuj z Excel'a"
            onChange={showDataExcel}
          />
          <Button startIcon={<GridOnIcon />} onClick={exportToFile}>
            Eksportuj do Excel'a
          </Button>
          <br /> <br />
          <SortableTree
            treeData={treeData}
            onChange={onChange}
            //theme={FileExplorerTheme}
            canNodeHaveChildren={(node) => !node.isTest}
            generateNodeProps={generateNodeProps}
          />
        </div>
      </Grid>
      <Grid container item xs={6} className={classes.right}>
        {test && test.isTest && (
          <AuditPlanTest test={test} onChange={onChangeTest} />
        )}
      </Grid>
    </Grid>
  );
}

export default () => {
  const [createAuditPlan] = useMutation(CREATE_AUDITPLAN);
  const { data, loading } = useQuery(AUDITPLANS);

  if (loading) return null;
  else if (data && data.auditPlans.length === 0) {
    createAuditPlan({
      variables: {
        input: {
          value: "Plan audytów",
          tree: JSON.stringify([{ value: "Plan audytów" }]),
        },
      },
      refetchQueries: [{ query: AUDITPLANS }],
    });
    //   enqueueSnackbar(`${getName("Variant")} ${data.createVariant.value} została dodany`, { variant: 'success' });
    return null;
  }

  return <AuditPlanPanel auditPlan={data.auditPlans[0]} />;
};
