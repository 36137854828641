import React from "react";
import { useMutation } from "@apollo/client";
import { UPLOAD_FILE } from "graphql/mutations";
import { FILES } from "graphql/queries";
import Button from "@material-ui/core/Button";
import { DropzoneDialog } from "material-ui-dropzone";
import AttachFileIcon from "@material-ui/icons/AttachFile";

export default (props) => {
  const [upload] = useMutation(UPLOAD_FILE);
  const [open, setOpen] = React.useState(false);
  async function onChange(files) {
    files.map(
      async (file) =>
        await upload({
          variables: { input: { file ,...props } },
          refetchQueries: [
            { query: FILES },
            { query: FILES, variables: { ...props } },
          ],
        })
        
    );
  }

  return (
    <div style={{ display: "inline-block" }}>
      <Button
        startIcon={props.icon ? props.icon : <AttachFileIcon />}
        onClick={() => setOpen(true)}
      >
        {props.label ? props.label : "Dodaj plik"}
      </Button>

      <DropzoneDialog
        cancelButtonText={"anuluj"}
        submitButtonText={"dodaj"}
        dropzoneText="Przeciągnij tu plik lub kliknij"
        open={open}
        dialogTitle="Dodaj plik"
        onClose={() => setOpen(false)}
        onSave={(files) => {
          props.onChange ? props.onChange(files) : onChange(files);
          setOpen(false);
        }}
        showAlerts={false}
        showPreviews={true}
        showFileNamesInPreview={false}
        previewText="Wybrane pliki:"
        showFileNames={true}
        useChipsForPreview={true}
        previewChipProps={{}}
      />
    </div>
  );
};
