import React, { useState, useRef } from "react";
import { Tree, Table, RightBar } from "elements";
import { useQuery, useMutation } from "@apollo/client";
import { FINDINGS } from "graphql/queries";
import {
  UPDATE_FINDING,
  DELETE_FINDING,
  CREATE_FINDING,
} from "graphql/mutations";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/styles";
import { getName } from "tools";
import { useAuthDataContext } from "context/Context";
import _ from "lodash";
import { useHistory } from "react-router";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

const useStyles = makeStyles((theme) => ({
  tab: {
    paddingRight: 45,
  },
}));

function FindingsTemplate(props) {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [updateFinding] = useMutation(UPDATE_FINDING);
  const [createFinding] = useMutation(CREATE_FINDING);
  const [deleteFinding] = useMutation(DELETE_FINDING);
  const { user, systemSettings, setSettings, settings } = useAuthDataContext();
  const history = useHistory();

  const setData = () => {
    console.log("setData");
  };
  const _updateFinding = async (idx, name, value) => {
    let row = props.data[idx];
    // console.log(idx,name,value, row)
    const refetchQueries = [
      { query: FINDINGS, variables: { tests: [props.test.id] } },
    ];

    const { data } = await updateFinding({
      variables: { id: row.id, input: { [name]: value } },
      refetchQueries,
    });

    enqueueSnackbar(
      `${getName("Finding")} ${data.updateFinding.value} został zaktualizowany`,
      { variant: "success" }
    );
  };
  const _createFinding = async (values) => {
    let input = { ...values };
    input.test = props.test.id;
    input.audit = props.test.audit.id;
    if (input.riskSelect) input.riskSelect = parseInt(input.riskSelect);
    // if (input.financialImpactA)
    //   input.financialImpactA = parseInt(input.financialImpactA);
    // if (input.financialImpactP)
    //   input.financialImpactP = parseInt(input.financialImpactP);
    const { data } = await createFinding({
      variables: { input },
      refetchQueries: [
        { query: FINDINGS },
        { query: FINDINGS, variables: { audit: [props.test.audit.id] } },
        { query: FINDINGS, variables: { tests: [props.test.id] } },
      ],
    });
    enqueueSnackbar(
      `${getName("Finding")} ${data.createFinding.value} został dodany`,
      { variant: "success" }
    );
  };
  const _deleteFinding = async (fs) => {
    if (fs.length === 0) return false;
    let snackText = [
      fs.length > 1 ? getName("Finding", 1) : getName("Finding"),
      "",
      fs.length > 1 ? "zostały usunięte" : "został usunięty",
    ];
    let names = [];
    for (let i = fs.length - 1; i > 0; i--) {
      await deleteFinding({ variables: { id: fs[i].id } });
      names.push(fs[i].value);
    }
    await deleteFinding({
      variables: { id: fs[0].id },
      refetchQueries: [
        { query: FINDINGS, variables: { tests: [props.test.id] } },
      ],
    });
    names.push(fs[0].value);
    snackText[1] = names.join(", ");
    enqueueSnackbar(snackText.join(" "), { variant: "success" });
  };

  const getLabel = (num) => {
    if (
      systemSettings &&
      systemSettings.riskLabel &&
      systemSettings.riskLabel[num]
    )
      return systemSettings.riskLabel[num];
    else return num;
  };

  let columns = [
    // {
    //   Header: "Otw./zamk.",
    //   accessor: "status",
    //   type: "switch",
    //   width: "10%",
    // },
    {
      Header: "Status",
      accessor: "status",
      type: "status",
      inputProps: {
        labels: {
          0: { label: "W trakcie" },
          1: { label: "Prośba o przedłużenie" },
          2: { label: "Prośba odrzucona" },
          4: { label: "Wdrożona" },
          5: { label: "Zamknięty" },
        },
      },
      width: "10%",
    },
    {
      Header: "Data",
      accessor: "date",
      type: "daysTo",
      width: "12%",
    },
    {
      Header: "Nazwa",
      accessor: "value",
      type: "text",
      width: "26%",
      // inputProps: {
      //   subtext: "auditValue",
      // },
    },

    {
      Header: "Os. odpow.",
      accessor: "responsible",
      type: "label",
      width: "10%",
    },
    {
      Header: "Podsumowanie",
      accessor: "desc",
      type: "longText",
      width: "12%",
    },
    // ,
    // {
    //   Header: "Wn",
    //   accessor: "auditValue",
    //   type: "label",
    //   width: "5%",
    // },
    // {
    //   Header: "Rek.",
    //   accessor: "recomendation",
    //   type: "longText",
    //   width: "7%",
    // },
    // {
    //   Header: "Ryzyko",
    //   accessor: "risk",
    //   type: "longText",
    //   width: "7%",
    // },
    // {
    //   Header: "Os. odp.",
    //   accessor: "responsibleEmail",
    //   type: "longText",
    //   width: "8%",
    // },
    ,
  ];

  // if(systemSettings.riskScale1){
  //   columns.push({
  //     Header: 'Prawdopod.',
  //     accessor: 'riskSelect0',
  //     type: 'simpleSelect',
  //     width: '9%',
  //     inputProps:{data: systemSettings.riskScale0}

  //   })
  //   columns.push({
  //     Header: 'Wpływ',
  //     accessor: 'riskSelect1',
  //     type: 'simpleSelect',
  //     width: '9%',
  //     inputProps:{ data: systemSettings.riskScale1}

  //   })
  // }
  // else columns.push({
  //   Header: 'Ocena ryzyka',
  //   accessor: 'riskSelect0',
  //   type: 'simpleSelect',
  //   width: '18%',
  //   inputProps:{data: systemSettings.riskScale0}
  // })

  function goToFinding(i) {
    const finding = props.data[i];
    const tabs = settings.tabs ? [...settings.tabs] : [];

    if (_.findIndex(tabs, { id: finding.id }) < 0) {
      setSettings({
        tabs: [
          {
            id: finding.id,
            title:
              finding.value.length > 20
                ? finding.value.slice(0, 20) + "..."
                : finding.value,
            full: finding.value,
            parent: null,
            href: "/finding/" + finding.id,
          },
          ...tabs,
        ],
      });
    }

    history.push("/finding/" + finding.id);
  }

  return (
    <Table
      typename="Finding"
      columns={columns}
      setData={setData}
      updateMyData={_updateFinding}
      skipPageReset={true}
      onAdd={
        props.test.status === 1 || user.id !== props.test.auditor.id
          ? null
          : _createFinding
      }
      onDelete={_deleteFinding}
      rowActions={[{ icon: <KeyboardArrowRightIcon />, onClick: goToFinding }]}
      {...props}
    />
  );
}

export default ({ test }) => {
  const { data, loading } = useQuery(FINDINGS, {
    variables: { tests: [test.id] },
  });

  if (loading) return false;
  console.log(data, 98765);

  return (
    <div>
      <FindingsTemplate data={data.findings} test={test} />
    </div>
  );
};
