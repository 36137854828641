import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Grid,
  Divider,
  Fab
} from '@material-ui/core';
import { useForm } from "react-hook-form";
import SaveIcon from '@material-ui/icons/Save';
import { useSnackbar } from 'notistack';
import {Text, Select, Tags} from 'elements'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';



const useStyles = makeStyles(() => ({
  root: { width: '98%', float: 'left', marginBottom: 20},
  // root2: { width: '49%', float: 'left'},
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

export default({systemSettings, setSystemSettings}) => {
  const classes = useStyles();
  const { register, handleSubmit, setValue, getValues, unregister } = useForm();
  const [save, setSave] = React.useState(false)
  const [scale, setScale] = React.useState(systemSettings.riskScale1? "two":"one")
  const { enqueueSnackbar } = useSnackbar();


  const onSubmit = async(input) => {
    console.log(1, input)


    // return false
    // if(input.riskScale[0] && input.riskScale[0].length===0 ) input.riskScale[0]=null
    // if(input.riskScale[1] && input.riskScale[1].length===0 ) input.riskScale[1]=null
    // if(scale==='one' && input.riskScale.length===2) 
    //   input.riskScale.pop() 
    // if(scale==='two' && input.riskScale.length===1) 
    //   input.riskScale.push(null) 

    try{

      setSystemSettings({...systemSettings, ...input})
      enqueueSnackbar(`Ustawiania systemu zostały zaktualizowane`, { variant: 'success' });
    }catch(err){
      
    }
    setSave(false)
  }
  const handleChangeScale = ({target}) => { 
    setScale(target.value)
    if(target.value==='one')
      setValue("riskScale1", null)
    setSave(true)
  }

  const onChange = () => { setSave(true) }
  return (
<>    

<Card className={classes.root}>
 
      <CardHeader
          subheader="Tutaj możesz zmienić podstawowe lokalizacji systemu"
          title="Ustawienia lokalizacji"
        />
       <form
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <Divider />
        <CardContent>
          <Select
            data={[
              {id: 'YYYY-MM-DD', value: 'YYYY-MM-DD'},
              {id: 'YYYY/MM/DD', value: 'YYYY/MM/DD'},
              {id: 'YYYY.MM.DD', value: 'YYYY.MM.DD'},
              {id: 'DD-MM-YYYY', value: 'DD-MM-YYYY'},
              {id: 'DD/MM/YYYY', value: 'DD/MM/YYYY'},
              {id: 'DD.MM.YYYY', value: 'DD.MM.YYYY'},
            ]}
            
            register={register}
            onChange={onChange}
            label="Format daty"
            name="dateFormat"
            value={systemSettings.dateFormat || 'YYYY-MM-DD'}
          />
        </CardContent>
        <Divider />
        {
          save && <Fab type='submit' color="primary" aria-label="zapisz" className="fab">
                <SaveIcon />
            </Fab>
        }
      </form>
    </Card>
    <Card className={classes.root}>
    <CardHeader
          subheader="Tutaj możesz zmienić częstość powiadamień o działaniach naprawczych"
          title="Częstość powiadomień"
        />
       <form
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <Divider />
        <CardContent>
          <Select
            data={[
              {value: 'codziennie', id: '1'},
              {value: 'co 3 din', id: '3'},
              {value: 'co 5 dni', id: '5'},
              {value: 'co 7 dni', id: '7'},
              {value: 'co 14 dni', id: '14'},
              {value: 'co miesiąc', id: '30'},
            ]}
            
            register={register}
            onChange={onChange}
            label=""
            name="mailsFrequency"
            value={systemSettings.mailsFrequency || 'YYYY-MM-DD'}
          />
        </CardContent>
        <Divider />
        {
          save && <Fab type='submit' color="primary" aria-label="zapisz" className="fab">
                <SaveIcon />
            </Fab>
        }
      </form>
    </Card>

    
</>
  );
};
