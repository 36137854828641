import React, { useState } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  Fab,
  // TextField
} from "@material-ui/core";
import { Text, Color } from "elements";
import SaveIcon from "@material-ui/icons/Save";
import { useMutation } from "@apollo/client";
import { UPDATE_USER } from "graphql/mutations";
import { USER } from "graphql/queries";
import { useSnackbar } from "notistack";

const useStyles = makeStyles(() => ({
  root: {},
}));

const AccountDetails = ({ user, settings }) => {
  const { register, handleSubmit, watch, errors } = useForm();
  const [save, setSave] = React.useState(false);
  const [updateUser] = useMutation(UPDATE_USER);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const classes = useStyles();
  const onChange = () => {
    setSave(true);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    // client.writeData({ data: { isLoggedIn: false } });
    // history.push("/sign-in")
    window.location.reload();
  };

  const onSubmit = async (input) => {
    // console.log(input)
    const { data } = await updateUser({
      variables: { input },
      refetchQueries: [{ query: USER }],
    });
    enqueueSnackbar(`Twoje dane zostały zaktualizowane`, {
      variant: "success",
    });

    if (input.email) handleLogout();

    setSave(false);
  };

  return (
    <Card className={classes.root}>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)} noValidate>
        <CardHeader
          subheader="Tutaj możesz modyfikować swoje dane"
          title="Profil"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <Text
                register={register}
                name="value"
                label="Imię i nazwisko"
                defaultValue={user.value}
                onChange={onChange}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Text
                register={register}
                name="email"
                label="Email"
                defaultValue={user.email}
                onChange={onChange}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Text
                register={register}
                name="avatar"
                label="Inicjały"
                defaultValue={user.avatar}
                onChange={onChange}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Color
                name="color"
                register={register}
                onChange={onChange}
                label="Kolor"
                value={user.color}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Text
                register={register}
                name="position"
                label="Stanowisko"
                defaultValue={user.position}
                onChange={onChange}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        {save && (
          <Fab
            type="submit"
            color="primary"
            aria-label="zapisz"
            className="fab"
          >
            <SaveIcon />
          </Fab>
        )}
      </form>
    </Card>
  );
};

AccountDetails.propTypes = {
  className: PropTypes.string,
};

export default AccountDetails;
