import React from "react";
import { Input } from "elements";
import { useForm } from "react-hook-form";

export default ({ fields, form }) => {
  const { errors, register } = form;

  return (
    <div>
      {fields &&
        fields.map((field, i) => {
          const { type, ...rest } = field;
          return (
            <span key={"input" + i}>
              <Input
                type={type}
                inputProps={{
                  error: errors[field.name],
                  register: register,
                  ...rest,
                }}
              />
            </span>
          );
        })}
    </div>
  );
};
