import React from "react";
import { useAuthDataContext } from "context/Context";
import moment from "moment";
import { makeStyles } from "@material-ui/styles";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import {
  Typography
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  tab: {
    paddingRight: 45,
  },
}));

function Log(props) {
  const [messages, setMessages] = React.useState(props.messages);
  const classes = useStyles();

  return (
    <Timeline>
      {messages &&
        messages.map((m, i) => (
          <TimelineItem>
            <TimelineOppositeContent>
              <Typography color="textSecondary">
                <div className={classes.time}>
                  {moment.unix(parseInt(m.time)).format("YYYY-MM-DD HH:mm")}
                </div>
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Typography className={classes.message}>{m.message}</Typography>
              <Typography className={classes.value}>{m.value}</Typography>
            </TimelineContent>
          </TimelineItem>
        ))}
    </Timeline>
  );
}

export default (props) => {
  return <Log
  id={props.task.id}
  messages={JSON.parse(props.task.messages)}
  // author={data.task.responsible}
/>
};


