import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 64,
    height: "100%",
  },
  content: {
    height: "100%",
  },
  skew: {
    backgroundColor: "#03c",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: "-50%",
    right: "31%",
    transform: "skew(-35deg)",
    borderRadius: "0 0 180px 0",
    zIndex: 0,
  },
}));

const Minimal = (props) => {
  const { children } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* <Topbar /> */}

      <main className={classes.content}>{children}</main>
    </div>
  );
};

Minimal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Minimal;
