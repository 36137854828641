// import React from 'react';
// import { getFieldDef } from "graphql/execution/execute"
import * as colors from "@material-ui/core/colors";

function _getIdx(id, arr) {
  return arr.findIndex((el) => el.id === id);
}

function _updateArray(id, v, a) {
  let arr = [...a];
  const idx = _getIdx(id, arr);
  arr[idx] = {
    ...arr[idx],
    ...v,
  };
  return arr;
}

export function updatePerspectiveTool(id, values, perspectives) {
  return _updateArray(id, values, perspectives);
}

export function updateVariantTool(id, parent, values, perspectives) {
  const idx = _getIdx(parent, perspectives);
  if (!perspectives[idx].variants) return false;

  perspectives[idx].variants = _updateArray(
    id,
    values,
    perspectives[idx].variants
  );
  return perspectives;
}

export function getName(name, plural = 0) {
  return {
    Perspective: ["Grupa", "Grupy", "Nowa grupa"],
    Instance: ["Zbiór", "Zbiory", "Nowy zbiór"],
    Variant: ["Wariant", "Warianty", "Nowy wariant"],
    Survey: ["Ankieta", "Ankiety", "Nowa ankieta"],
    Audit: ["Audyt", "Audyty", "Nowy audyt"],
    AuditGroup: [
      "Etykieta audytu",
      "Etykiety audytowe",
      "Nowa etykieta audytowa",
    ],
    Test: ["Test", "Testy", "Nowy test"],
    AuditPlan: [
      "Program audytowy",
      "Programy audytowe",
      "Nowy program audytowy",
    ],
    Finding: ["Rekomendacja", "Rekomendacje", "Nowa rekomendacja"],
    ReviewNote: ["Review Note", "Review Notes", "Nowa review note"],
    Incident: ["Incydent", "Incydenty", "Nowy incydent"],
    Action: ["Działanie", "Działania", "Nowe działanie"],
    Risk: ["Ryzyko", "Ryzyka", "Nowe ryzyko"],
    Proces: ["Proces", "Procesy", "Nowy proces"],
    Control: ["Kontrola", "Kontrole", "Nowa kontrola"],
    Source: ["Źródło", "Źródła", "Nowe źródło"],
    Testc: ["Test ciągły", "Testy ciągłe", "Nowy test ciągły"],
    TestcTemplate: ["Schemat", "Schematy", "Nowy schemat"],
    Receiver: ["Adresat", "Adresaci", "Nowy adresat"],
    Message: ["Wiadomość", "Wiadomości", "Nowa wiadomość"],
    //dzialania do testow ciaglych
    Task: ["Działanie", "Działania", "Nowe działanie"],
  }[name][plural];
}

export function getColor(type) {
  return "white";
}

export function getError(type) {
  return {
    required: "Pole wymagane",
    maxLength: "Pole zbyt długie",
    minLength: "Pole zbyt krótkie",
    max: "Wartość zbyt duża",
    min: "Wartość zbyt mała",
    pattern: "Niepoprawna wartość pola",
    pattern: "Niepoprawna wartość pola",
    notMatch: "Hasła nie są jednakowe",
  }[type];
}

export function getSurveyData() {
  return {
    questionnaire: [
      {
        text: "W każdym wierszu proszę wskazać element tak samo ryzykowny jak (0, 10) lub miejsce, które rozdziela elementy mniej i bardziej ryzykowny",
        q: "(0,10)",
        sliders: [
          { min: 202, max: 210, width: 500, left: 30, markSlice: 0 },
          { min: 402, max: 410, width: 500, left: 130, markSlice: 5 },
          { min: 602, max: 610, width: 500, left: 230, markSlice: 10 },
          { min: 802, max: 810, width: 500, left: 330, markSlice: 16 },
        ],
      },
      {
        text: "W każdym wierszu proszę wskazać element tak samo ryzykowny jak (0,8) lub miejsce, które rozdziela elementy mniej i bardziej ryzykowne",
        q: "(0,8)",
        sliders: [
          { min: 202, max: 208, width: 400, left: 30, markSlice: 0 },
          { min: 402, max: 408, width: 400, left: 130, markSlice: 5 },
          { min: 602, max: 608, width: 400, left: 230, markSlice: 10 },
        ],
      },
      {
        text: "W każdym wierszu proszę wskazać element tak samo ryzykowny jak (4,6) lub miejsce, które rozdziela elementy mniej i bardziej ryzykowne.",
        condition: "(values[1] && values[1][2] && values[1][2]<605)",
        q: "(4,6)",
        sliders: [{ min: 800, max: 806, width: 400, left: 30, markSlice: 15 }],
      },
      {
        text: "W każdym wierszu proszę wskazać element tak samo ryzykowny jak (4,6) lub miejsce, które rozdziela elementy mniej i bardziej ryzykowne.",
        condition: "(values[0] && values[0][2] && values[0][2]<605)",
        q: "(4,6)",
        sliders: [
          { min: 1000, max: 1006, width: 400, left: 30, markSlice: 21 },
        ],
      },
      {
        text: "W każdym wierszu proszę wskazać element tak samo ryzykowny jak (0,6) lub miejsce, które rozdziela elementy mniej i bardziej ryzykowne",
        q: "(0,6)",
        sliders: [
          { min: 202, max: 206, width: 300, left: 30, markSlice: 0 },
          { min: 402, max: 406, width: 300, left: 130, markSlice: 5 },
        ],
      },
    ],
    marks: [
      { value: 202, label: "(2,2)" },
      { value: 204, label: "(2,4)" },
      { value: 206, label: "(2,6)" },
      { value: 208, label: "(2,8)" },
      { value: 210, label: "(2,10)" },
      { value: 402, label: "(4,2)" },
      { value: 404, label: "(4,4)" },
      { value: 406, label: "(4,6)" },
      { value: 408, label: "(4,8)" },
      { value: 410, label: "(4,10)" },
      { value: 602, label: "(6,2)" },
      { value: 604, label: "(6,4)" },
      { value: 606, label: "(6,6)" },
      { value: 608, label: "(6,8)" },
      { value: 610, label: "(6,10)" },
      { value: 800, label: "(8,0)" },
      { value: 802, label: "(8,2)" },
      { value: 804, label: "(8,4)" },
      { value: 806, label: "(8,6)" },
      { value: 808, label: "(8,8)" },
      { value: 810, label: "(8,10)" },
      { value: 1000, label: "(10,0)" },
      { value: 1002, label: "(10,2)" },
      { value: 1004, label: "(10,4)" },
      { value: 1006, label: "(10,6)" },
    ],
    lastQuestion: [
      { left: "(0,0)", right: "(10,10)" },
      { left: "(0,10)", right: "(2,10)" },
      { left: "(6,10)", right: "(8,8)" },
      { left: "(4,8)", right: "(6,6)" },
      { left: "(2,6)", right: "(4,4)" },
      { left: "(0,4)", right: "(2,2)" },
    ],
  };
}
