import React from "react";
import { useAuthDataContext } from "context/Context";
import { Table, Loading, RightBar } from "elements";
import { useMutation, useQuery } from "@apollo/client";
import { TESTS } from "graphql/queries";
import { UPDATE_TEST, DELETE_TEST, CREATE_TEST } from "graphql/mutations";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/styles";
import { getName } from "tools";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import _ from "lodash";
import { useHistory } from "react-router";
import { AuditPlan } from "../components";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";

const useStyles = makeStyles((theme) => ({
  tab: {
    paddingRight: 45,
  },
}));

export default (props) => {
  const [ids, setIds] = React.useState();
  const { user, settings, setSettings } = useAuthDataContext();
  const t = useQuery(TESTS, {
    variables: { audit: props.audit.id },
  });
  const [rightbarData, setRightbarData] = React.useState(false);
  const [updateTest] = useMutation(UPDATE_TEST);
  const [createTest] = useMutation(CREATE_TEST);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteTest] = useMutation(DELETE_TEST);
  const history = useHistory();
  const [all, setAll] = React.useState(false);

  const columns = [
    {
      Header: "Wykonany?",
      accessor: "status",
      type: "switch",
      width: "8%",
      inputProps: {
        disabled: props.audit.status === 19,
      },
    },
    {
      Header: "Zamknięty?",
      accessor: "verified",
      type: "switch",
      width: "8%",
      inputProps: {
        disabled: props.audit.status === 19,
      },
    },
    {
      Header: "nr",
      accessor: "num",
      type: "text",
      width: "4%",
    },
    {
      Header: "Nazwa",
      accessor: "value",
      width: "24%",
      type: "text",
      inputProps: {
        subtext: "from",
      },
    },
    {
      Header: "Audytor",
      accessor: "auditor.id",
      type: "user",
      width: "8%",
      inputProps: {
        users: [props.audit.leader, ...props.audit.team],
      },
    },
    {
      Header: "Opis",
      accessor: "desc",
      type: "longText",
      width: "10%",
    },
    {
      Header: "Do zrobienia",
      accessor: "todo",
      type: "longText",
      width: "10%",
    },
    {
      Header: "Il. godz.",
      accessor: "hours",
      type: "number",
      width: "8%",
    },
    {
      Header: "Próbka",
      accessor: "sample",
      type: "text",
      width: "8%",
    },
    // {
    //   Header: 'Rozpoczęcie',
    //   accessor: 'start',
    //   type: 'date',
    //   width: "13%"
    // options: {
    //   clearable: true,
    //   disablePast: true
    // }
    // }
  ];

  React.useEffect(() => {
    setIds(props.ids);
  }, [props.ids]);

  if (t.loading) return <Loading />;
  let tests = t.data.tests.testFeed;

  if (!all)
    tests = tests.filter((aa) => aa.auditor && aa.auditor.id === user.id);

  const setData = (_1, _2, _3) => {
    console.log("setData", _1, _2, _3);
  };
  const _updateTest = async (idx, name, value) => {
    let row = tests[idx];
    const refetchQueries = [
      { query: TESTS },
      { query: TESTS, variables: { audit: props.audit.id } },
    ];

    if (row.status === 1) {
      if (
        !(
          (name === "verified" && row.verifier.id === user.id) ||
          (name === "status" && row.auditor.id === user.id)
        )
      ) {
        enqueueSnackbar(`${getName("Test")} jest zamknięty`, {
          variant: "error",
        });
        return false;
      }
    }

    // console.log(idx, name, value, row);
    if (name === "sample") {
      try {
        value = parseInt(value);
      } catch (err) {
        value = 0;
      }
    }
    let input = {};
    if (name === "status") {
      input = { status: value, verifier: value ? props.audit.leader.id : null };
    } else if (name === "verifier" || name === "verified") {
      input = { verified: parseInt(value) === 1 ? 1 : null };
    } else input = { [name]: value };

    // console.log(input);

    const { data } = await updateTest({
      variables: { id: row.id, input },
      refetchQueries,
    });

    enqueueSnackbar(
      `${getName("Test")} ${data.updateTest.value} został zaktualizowany`,
      { variant: "success" }
    );
  };

  // const skipPageReset = (_1,_2,_3) => {
  //   console.log(skipPageReset, _1,_2,_3)
  // }

  const showAuditPlan = () => setRightbarData(true);

  const onDeleteTests = async (ps) => {
    if (ps.length === 0) return false;

    let snackText = [
      ps.length > 1 ? getName("Test", 1) : getName("Test"),
      "",
      ps.length > 1 ? "zostały usunięte" : "został usunięty",
    ];
    let names = [];

    for (let i = ps.length - 1; i > 0; i--) {
      await deleteTest({ variables: { id: ps[i].id } });
      names.push(ps[i].value);
    }
    await deleteTest({
      variables: { id: ps[0].id },
      refetchQueries: [
        { query: TESTS },
        { query: TESTS, variables: { audit: props.audit.id } },
      ],
    });
    names.push(ps[0].value);
    snackText[1] = names.join(", ");

    enqueueSnackbar(snackText.join(" "), { variant: "success" });
  };

  const onSubmitTestForm = async ({ isTest, ..._input }) => {
    let input = {};

    Object.keys(_input).map((i) => (input[i] = _input[i]));
    if (input["hours"]) input["hours"] = parseInt(input["hours"]);
    input["audit"] = props.audit.id;
    input["from"] = input.from
      ? "Plan audytowy" + "→" + input.from + "→" + input.value
      : null;
    input["auditor"] = user.id;

    // console.log(input, _input)
    const { data } = await createTest({
      variables: { input },
      refetchQueries: [
        { query: TESTS },
        { query: TESTS, variables: { audit: props.audit.id } },
      ],
    });
    enqueueSnackbar(
      `${getName("Test")} ${data.createTest.value} został dodany`,
      { variant: "success" }
    );
  };
  function goToTest(i) {
    const test = tests[i];
    const tabs = settings.tabs ? [...settings.tabs] : [];

    if (_.findIndex(tabs, { id: test.id }) < 0) {
      setSettings({
        tabs: [
          {
            id: test.id,
            title:
              test.value.length > 20
                ? test.value.slice(0, 20) + "..."
                : test.value,
            full: test.value,
            parent: test.audit.id,
            href: "/test/" + test.id,
          },
          ...tabs,
        ],
      });
    }
    history.push("/test/" + test.id);
  }

  const handleShowAll = (event, checked) => setAll(checked);

  return (
    <>
      <Table
        typename="Test"
        columns={columns}
        setData={setData}
        updateMyData={_updateTest}
        skipPageReset={true}
        onAdd={props.audit.status !== 19 ? onSubmitTestForm : null}
        onDelete={onDeleteTests}
        data={tests}
        handleShowAll={handleShowAll}
        rowActions={[{ icon: <KeyboardArrowRightIcon />, onClick: goToTest }]}
        toolbarButtons={[
          {
            label: getName("AuditPlan", 1),
            icon: <FolderOpenIcon />,
            typename: "AuditPlan",
            onClick: showAuditPlan,
          },
        ]}
        {...props}
      />
      <RightBar open={rightbarData} onClose={() => setRightbarData(false)}>
        <AuditPlan addTest={onSubmitTestForm} />
      </RightBar>
    </>
  );
};
