import React, { useState } from "react";
import moment from "moment";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useQuery } from "@apollo/client";
import { TESTSC } from "graphql/queries";
// import Button from "@material-ui/core/Button";
import { Tests, Templates, Actions, Action, Data } from "./components";
import { useSnackbar } from "notistack";
import {
  // BrowserRouter as Router,
  // Switch,
  // Route,
  useLocation,
  Link,
  useParams,
} from "react-router-dom";
// import { getName } from "tools";
import { Panel, RightBar, Loading } from "elements";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    height: "100%",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    width: "20%",
  },
  tabsContent: {
    width: "80%",
  },
}));

function ContinuousAudits({ data, num, setData }) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(num);
  const [rightbarData, setRightbarData] = React.useState(false);

  const showData = (idx) => {
    setRightbarData({ __typename: "Data" });
  };

  const showAction = (idx) => {
    setRightbarData({ idx, ...data.alerts[idx] });
  };

  let location = useLocation();
  React.useEffect(() => {
    try {
      setValue(
        parseInt(location.pathname.match(/\/\d+\/?$/)[0].replace(/\//, ""))
      );
    } catch (err) {
      setValue(0);
    }
  }, [location]);

  const handleChange = (_, newValue) => {
    let p = window.location.pathname.replace(/\/\d+\/?$/, "") + "/" + newValue;
    window.history.pushState({}, null, p);
    // setPath(p);
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const addToAlert = (what, values) => {
    let alerts = [...data.alerts];
    try {
      alerts[rightbarData.idx][what].list.push(values);
    } catch (err) {
      alerts[rightbarData.idx][what].list = [values];
    }

    console.log(alerts);

    setData({ ...data, alerts });
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        orientation="vertical"
        variant="scrollable"
        className={classes.tabs}
      >
        <Tab label="Testy ciągłe" {...a11yProps(0)} />
        <Tab label="Schematy" {...a11yProps(1)} />
      </Tabs>

      <TabPanel
        value={value}
        index={0}
        dir={theme.direction}
        className={classes.tabsContent}
      >
        <Panel subheader={"Lista testów ciągłych"} title={"Testy ciągłe"}>
          <Tests data={data} />
        </Panel>
      </TabPanel>
      <TabPanel
        value={value}
        index={1}
        dir={theme.direction}
        className={classes.tabsContent}
      >
        <Panel
          subheader={"Lista zdefiniowanych schatów audytowych"}
          title={"Schematy audytów ciągłych"}
        >
          <Templates />
        </Panel>
      </TabPanel>
      {/* <TabPanel value={value} index={2} dir={theme.direction}>
        <Panel >
          <Actions data={data.actions} showDetails={showAction} />
        </Panel>
      </TabPanel> */}
      {/* <TabPanel value={value} index={3} dir={theme.direction}>
        <Panel >
          <Data data={[]} showDetails={showData}/>
        </Panel>
      </TabPanel> */}

      {/* <RightBar
        open={rightbarData !== false}
        onClose={() => setRightbarData(false)}
      >
        {rightbarData.__typename === "Action" && (
          <Action alert={rightbarData} />
        )}

        {rightbarData.__typename === "Data" && (
          <div>DATA SOURCE</div>
        )}
      </RightBar> */}
    </div>
  );
}

export default (props) => {
  let { num } = useParams();

  const { loading, data } = useQuery(TESTSC);
  if (loading) return <Loading />;

  return <ContinuousAudits num={num} data={data.testcs.testcFeed} />;

  // return <ContinuousAudit refetch={refetch} data={data.audit} num={num ? parseInt(num) : 0} />;
};
