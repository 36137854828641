import React from 'react'
import { SwatchesPicker } from 'react-color';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import  Button  from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        height: '100%',
        '& .swatches-picker>div>div':{
            boxShadow: 'none!important'
        }
    },
    label: {
        fontSize: 11,
        paddingBottom: 3
    },
    colorBlock:{
        width: '100%',
        cursor: 'pointer'
    },
    dialog:{
        '& .swatches-picker>div>div':{
            boxShadow: 'none!important'
        }
    }
}));


export default ({value, register, name, label, onChange}) => {
    const color = React.useRef(value)
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();

    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };


    const handleChange = (c) => {
        setOpen(false)
        color.current = c.hex
        console.log(c.hex, color)
        onChange && onChange()
    }

    return (
        <div className={classes.root}>
            <div className={classes.label}>{label}</div>
            <div className={classes.colorBlock} style={{ backgroundColor: color.current}} onClick={handleClickOpen}>&nbsp;</div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="Wybierz kolor">
                <DialogTitle id="Wybierz kolor">Wybierz kolor...</DialogTitle>
                <DialogContent className={classes.dialog}>
                    <SwatchesPicker onChange={handleChange }/>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Zamknij
                </Button>
                </DialogActions>
            </Dialog>  
            <input type="hidden" name={name} ref={register} value={color.current} />
        </div>
        
    )
}
