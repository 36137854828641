import React, { Component } from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Chart } from "react-chartjs-2";
import { ThemeProvider } from "@material-ui/styles";
import validate from "validate.js";
import AuthDataProvider from "context/Context";
import { chartjs } from "./helpers";
import theme from "./theme";
import "react-perfect-scrollbar/dist/css/styles.css";
// import 'react-sortable-tree/style.css';
import "./assets/scss/index.scss";
import validators from "./common/validators";
import Routes from "./Routes";
import { SnackbarProvider } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ConfirmProvider } from "material-ui-confirm";
// import AuthDataProvider from "authProvider";

const browserHistory = createBrowserHistory();

window.alert = function (text) {
  console.log("tried to alert: " + text);
  return true;
};
alert(new Date());

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw,
});

validate.validators = {
  ...validate.validators,
  ...validators,
};

export default function App() {
  return (
    <AuthDataProvider>
      <ThemeProvider theme={theme}>
        <ConfirmProvider
          defaultOptions={{
            title: "Potwierdzenie",
            cancellationText: "Anuluj",
          }}
        >
          <SnackbarProvider
            maxSnack={2}
            iconVariant={{ default: <CircularProgress size={24} /> }}
          >
            <Router history={browserHistory}>
              <Routes />
            </Router>
          </SnackbarProvider>
        </ConfirmProvider>
      </ThemeProvider>
    </AuthDataProvider>
  );
}
