import React from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Avatar, Typography } from "@material-ui/core";
// import SettingsSharpIcon from "@material-ui/icons/SettingsSharp";
import AccountCircleSharpIcon from "@material-ui/icons/AccountCircleSharp";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // minHeight: "fit-content",
    marginBottom: 10,
  },
  avatar: {
    width: 50,
    height: 50,
  },
  name: {
    marginTop: theme.spacing(1),
  },
  subheader: {
    marginBottom: 5,
  },
  icon: {
    position: "absolute",
    left: 30,
    height: 50,
    // color: "#999",
    fontSize: 18,
  },
}));

const Profile = ({ user }) => {
  const classes = useStyles();

  return (
    <Typography className={classes.root} component={RouterLink} to="/settings">
      <Avatar
        alt="Person"
        style={{
          backgroundColor: user.color,
          fontSize: 12,
          height: 24,
          width: 24,
          position: "absolute",
          left: 10,
          bottom: 30,
        }}
        component={RouterLink}
        to="/settings"
        variant="square"
      >
        {" "}
        {user.avatar}{" "}
      </Avatar>
      <Typography className={classes.name} variant="h5">
        {user.value}
      </Typography>
      <Typography variant="body2">{user.position}</Typography>
    </Typography>
  );
};

Profile.propTypes = {
  className: PropTypes.string,
};

export default Profile;
