import React, { useState } from "react";
import { Tree, Table, RightBar } from "elements";
import { useQuery, useMutation, useSubscription } from "@apollo/client";
import { MultiSelect } from "elements";
import { AUDIT, VARIANTS } from "graphql/queries";
import { UPDATE_VARIANT, UPDATE_AUDIT } from "graphql/mutations";
import { useSnackbar } from "notistack";
import { VariantDetails, VariantEdit } from "../../Risk/components";
import { isEmpty } from "underscore";
import { makeStyles } from "@material-ui/styles";
import { useForm } from "react-hook-form";
import { getName } from "tools";
import { useHistory } from "react-router";
import BarChartIcon from "@material-ui/icons/BarChart";
import { useAuthDataContext } from "context/Context";

const useStyles = makeStyles((theme) => ({
  tab: {
    paddingRight: 45,
  },
}));

export default ({ audit }) => {
  const [updateVariant] = useMutation(UPDATE_VARIANT);
  const [updateAudit] = useMutation(UPDATE_AUDIT);
  const v = useQuery(VARIANTS);
  const { enqueueSnackbar } = useSnackbar();
  const { systemSettings } = useAuthDataContext();
  const history = useHistory();

  if (audit.status === 0) {
    enqueueSnackbar(`Audyt nie został rozpoczęty`, { variant: "info" });
    return false;
  }

  if (v.loading) return false;
  let variantsR = {};
  v.data.variants.variantFeed.map((v) => (variantsR[v.id] = v.risk));

  const update = async (idx, _, value) => {
    data[idx].newRisk = value;

    await updateAudit({
      variables: { id: audit.id, input: { riskChange: JSON.stringify(data) } },
      refetchQueries: [{ query: AUDIT, variables: { id: audit.id } }],
    });

    await updateVariant({
      variables: { id: data[idx].id, input: { risk: value } },
      refetchQueries: [{ query: VARIANTS }],
    });

    enqueueSnackbar(`Ryzyko zostało zaktualizowane`, { variant: "success" });
  };

  let data = JSON.parse(audit.riskChange);
  data.map((d) => (d.risk = variantsR[d.id]));
  // console.log(data, audit,9000)

  const _updateAudit = async (idx, name, value) => {
    const refetchQueries = [{ query: AUDIT, variables: { id: audit.id } }];

    const { data } = await updateAudit({
      variables: { id: audit.id, input: { [name]: value } },
      refetchQueries,
    });

    enqueueSnackbar(
      `${getName("Audit")} ${data.updateAudit.value} został zaktualizowany`,
      { variant: "success" }
    );
  };

  let labels = {};
  if (systemSettings.riskLabel)
    systemSettings.riskLabel.map((r, i) => (labels[i * 2] = r));
  else labels = { 0: "0", 2: "2", 4: "4", 6: "6", 8: "8", 10: "10" };

  return (
    <Table
      columns={[
        {
          Header: "Nazwa",
          accessor: "value",
          type: "label",
          width: "40%",
        },
        {
          Header: "Aktualne ryzyko",
          accessor: "risk",
          type: "label",
          inputProps: {
            labels,
          },
          width: "16%",
        },
        {
          Header: "Ryzyko przed audytem",
          accessor: "oldRisk",
          type: "label",
          inputProps: {
            labels,
          },
          width: "16%",
        },
        {
          Header: "Ryzyko po audycie",
          accessor: "newRisk",
          type: "slider",
          inputProps: {
            min: 0,
            max: 10,
            step: 2,
            labels,
            disabled: audit.status === 19,
          },
          width: "26%",
        },
      ]}
      updateMyData={update}
      skipPageReset={false}
      // toolbarActions={[
      //   { label: getName("Instance",2), typename: 'Instance', width: "25%" , onSubmit: onSubmitInstanceForm }
      // ]}
      data={data}
    />
  );
};
