import React from 'react'
import { Avatar } from 'elements';
import { makeStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';


const useStyles = makeStyles((theme) => ({
    avatar: {
      width: 18,
      height: 18,
      fontSize: 10,
      fontFamily: "bold"
  },
  num1:{
    fontSize: 13,
    color: '#fff',
    display: 'block',
  },
num2:{
    display: 'block',
    fontSize: 9,
    color: '#fff'
},
ul:{
    listStyleType: 'none', textAlign: 'center'
}
}));

export default (props) => {
    const {value} = props
    const classes = useStyles();
    
    let color = null
    // let nValue = parseInt(100 - (value) / props.data.variants.length)
    if(value<20){ 
        color = grey[300]
    }else if(value<30){ 
        color = grey[400]
    }else if(value<40){ 
        color = grey[500]
    }else if(value<50){ 
        color = red[300]
    }else if(value<60){ 
        color = red[400]
    }else if(value<80){ 
        color = red[500]
    }else if(value<110){ 
        color = red[600]
    }else if(value<150){ 
        color = red[700]
    }else{ 
        color = red[900]
    }

    return (<Avatar className={classes.avatar} color={color}>
                <ul className={classes.ul}>
                <li className={classes.num1}>{value ? parseFloat(value/10): "-"}</li>
                {/* <li className={classes.num2}>{value}</li> */}
            </ul>
    </Avatar> )
}