import React from "react";
import { Avatar, Table, Panel } from "elements";
import { FILE_URL } from "vars";
import { makeStyles } from "@material-ui/styles";
import { useQuery, useMutation, useSubscription } from "@apollo/client";
import { REPORTS } from "graphql/queries";
import { REPORT } from "graphql/subscriptions";
import {
  CREATE_REPORT,
  UPDATE_REPORT,
  DELETE_REPORT,
  GENERATE_REPORT,
} from "graphql/mutations";

// import SendIcon from '@material-ui/icons/Send';
// import LockIcon from '@material-ui/icons/Lock';
// import LockOpenIcon from '@material-ui/icons/LockOpen';
// import NoteAddIcon from "@material-ui/icons/NoteAdd";
import { useSnackbar } from "notistack";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useForm } from "react-hook-form";
import RefreshIcon from "@material-ui/icons/Refresh";
import GetAppIcon from "@material-ui/icons/GetApp";
import { getName } from "tools";
import { useAuthDataContext } from "context/Context";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  button: {
    marginLeft: 10,
  },
}));

export default ({ audit }) => {
  const { user } = useAuthDataContext();
  const classes = useStyles();
  const { data, loading, refetch } = useQuery(REPORTS, {
    variables: { audits: null },
  });
  //empty audits is for testc
  const [createReport] = useMutation(CREATE_REPORT);
  const [updateReport] = useMutation(UPDATE_REPORT);
  const [deleteReport] = useMutation(DELETE_REPORT);
  const [generateReport] = useMutation(GENERATE_REPORT);
  const { enqueueSnackbar } = useSnackbar();
  const [link, setLink] = React.useState(false);
  const onSubscriptionData = ({ subscriptionData }) => {
    enqueueSnackbar("Raport wygenerowny", { variant: "success" });
    setLink(true);
    refetch();
  };

  useSubscription(REPORT, {
    onSubscriptionData: onSubscriptionData,
  });

  if (loading) return null;

  const downloadReport = (idx) => {
    let row = data.reports[idx];
    const link = document.createElement("a");
    link.href = `${FILE_URL}/report${row.id}.${row.type}/${row.value}.${row.type}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const genReport = async (idx) => {
    let row = data.reports[idx];
    await generateReport({ variables: { id: row.id } });
  };
  // const skipPageReset = (_1,_2,_3) => {
  //   console.log(skipPageReset, _1,_2,_3)
  // }

  const onDeleteReports = async (fs) => {
    if (fs.length === 0) return false;
    let snackText = [
      fs.length > 1 ? "Raporty" : "Raport",
      "",
      fs.length > 1 ? "zostały usunięte" : "został usunięty",
    ];
    let names = [];
    for (let i = fs.length - 1; i > 0; i--) {
      await deleteReport({ variables: { id: fs[i].id } });
      names.push(fs[i].value);
    }
    await deleteReport({
      variables: { id: fs[0].id },
      refetchQueries: [{ query: REPORTS, variables: { audits: null } }],
    });
    names.push(fs[0].value);
    snackText[1] = names.join(", ");
    enqueueSnackbar(snackText.join(" "), { variant: "success" });
  };
  const onSubmitReportForm = async ({ value, type }) => {
    const { data } = await createReport({
      variables: { input: { value, type } },
      refetchQueries: [{ query: REPORTS, variables: { audits: null } }],
    });
    enqueueSnackbar(`Raport ${data.createReport.value} został utworzony`, {
      variant: "success",
    });
  };
  const _updateReport = async (idx, name, value) => {
    let row = data.reports[idx];
    // console.log(idx,name,value, row)
    const refetchQueries = [{ query: REPORTS, variables: { audits: null } }];

    // console.log(name, value)

    const r = await updateReport({
      variables: { id: row.id, input: { [name]: value } },
      refetchQueries,
    });

    enqueueSnackbar(
      `Raport ${r.data.updateReport.value} został zaktualizowany`,
      { variant: "success" }
    );
  };
  const setData = async () => {};

  //   const isDisabled = () =>
  //     !user.permissions?.audit?.audit_editAll && audit.status === 19;

  // let rowActions = [
  //   {  icon:<Refresh />,  onClick:genReport }
  // ]

  return (
    <Panel subheader={"Lista raportów"} title={"Raporty"}>
      <Table
        typename="Report"
        columns={[
          {
            Header: "Nazwa",
            accessor: "value",
            type: "text",
            width: "34%",
          },

          {
            Header: "Type",
            accessor: "type",
            type: "select",
            inputProps: {
              data: [
                { id: "xlsx", value: "xlsx" },
                { id: "docx", value: "docx" },
                { id: "pdf", value: "pdf" },
              ],
            },
            width: "8%",
          },
          {
            Header: "Opis",
            accessor: "desc",
            type: "longText",
            width: "12%",
          },
          {
            Header: "Zawartość",
            accessor: "testcFields",
            type: "extension",
            width: "10%",
            inputProps: {
              singelColumn: true,
              fields: [
                {
                  label: "Raport wszystkich ryzyk",
                  key: "r1",
                  type: "checkbox",
                },
                {
                  label: "Raport wszystkich procesów",
                  key: "r2",
                  type: "checkbox",
                },
                {
                  label: "Raport wszystkich kontroli",
                  key: "r3",
                  type: "checkbox",
                },
                {
                  label: "Raport aktywnych działań naprawczych",
                  key: "r4",
                  type: "checkbox",
                },
                {
                  label: "Raport zakończonych działań naprawczych",
                  key: "r5",
                  type: "checkbox",
                },
              ],
            },
          },
        ]}
        setData={setData}
        updateMyData={_updateReport}
        skipPageReset={false}
        onAdd={onSubmitReportForm}
        onDelete={onDeleteReports}
        rowActions={[
          { icon: <RefreshIcon />, onClick: genReport },
          {
            icon: <GetAppIcon />,
            onClick: downloadReport,
            conditionVar: "generated",
          },
        ]}
        // toolbarActions={[
        //   { label: getName("Instance",2), typename: 'Instance', width: "25%" , onSubmit: onSubmitInstanceForm }
        // ]}
        data={data.reports}
      />
    </Panel>
  );
};
