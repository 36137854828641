import gql from "graphql-tag";

export const USER = gql`
  query user {
    user {
      id
      value
      email
      settings
      systemSettings
      avatar
      color
      position
      permissions
      holidays
      createdAt
      updatedAt
    }
  }
`;
export const USERS = gql`
  query users {
    users {
      id
      email
      value
      avatar
      color
      position
      permissions
      holidays
    }
  }
`;

// export const USERSDATES = gql`
//   query usersDates($from: String, $to: String) {
//     usersDates(from: $from, to: $to) {
//       id
//       email
//       value
//       avatar
//       color
//       position
//       permissions
//       holidays
//       avaliable
//     }
//   }
// `;

export const PERSPECTIVES = gql`
  query perspectives {
    perspectives {
      perspectiveFeed {
        id
        value
        weight
        order
        variants {
          id
          value
        }
      }
      pageInfo {
        nextPageCursor
        hasNextPage
      }
    }
  }
`;

export const VARIANTS = gql`
  query variants {
    variants {
      variantFeed {
        id
        value
        risk
        perspective {
          id
        }
        prevRisks {
          time
          risk
        }
      }
      pageInfo {
        nextPageCursor
        hasNextPage
      }
    }
  }
`;

export const VARIANT = gql`
  query variant($id: ID!) {
    variant(id: $id) {
      id
      value
      risk
      prevRisks {
        time
        risk
      }
    }
  }
`;

export const PERSPECTIVE = gql`
  query perspective($id: ID!) {
    perspective(id: $id) {
      id
      value
      weight
      prevWeight {
        weight
        time
      }
      variants {
        value
        risk
      }
    }
  }
`;

export const SURVEY = gql`
  query survey {
    survey
  }
`;

export const INSTANCE = gql`
  query instance($id: ID!) {
    instance(id: $id) {
      id
      value
      risk
      rank
      prevRisks {
        time
        risk
      }
      lastAudited
      lastScale
      variants {
        value
      }
    }
  }
`;

export const INSTANCES = gql`
  query instances {
    instances {
      instanceFeed {
        id
        value
        risk
        rank
        prevRisks {
          time
          risk
        }
        lastAudited
        lastScale
        updatedAt
        variants {
          value
        }
      }
      pageInfo {
        nextPageCursor
        hasNextPage
      }
    }
  }
`;

export const AUDITTEMPLATES = gql`
  query auditTemplates {
    auditTemplates {
      auditTemplateFeed {
        id
        value
        fields
      }
      pageInfo {
        nextPageCursor
        hasNextPage
      }
    }
  }
`;

export const TESTTEMPLATES = gql`
  query testTemplates {
    testTemplates {
      testTemplateFeed {
        id
        value
        fields
      }
      pageInfo {
        nextPageCursor
        hasNextPage
      }
    }
  }
`;

export const FINDINGTEMPLATES = gql`
  query findingTemplates {
    findingTemplates(limit: 10) {
      findingTemplateFeed {
        id
        value
        fields
      }
      pageInfo {
        nextPageCursor
        hasNextPage
      }
    }
  }
`;

export const AUDITGROUPS = gql`
  query auditsGroups {
    auditGroups {
      id
      value
      audits {
        id
      }
    }
  }
`;

export const SELECTEDAUDITGROUPS = gql`
  query auditsGroups($ids: [ID!]) {
    auditGroups(ids: $ids) {
      id
      value
      audits {
        id
        value
        dates
        riskChange
        fields
        status
        scale
        checklist
        instances {
          value
          risk
        }
        auditGroup {
          id
          value
        }
        leader {
          id
          value
        }
        team {
          id
          email
          value
          avatar
          color
          position
        }
      }
    }
  }
`;

export const AUDITGROUP = gql`
  query auditGroup($id: ID!) {
    auditGroup(id: $id) {
      id
      value

      audits {
        ids
      }
    }
  }
`;

export const AUDITS = gql`
  query audits {
    audits {
      auditFeed {
        id
        value
        dates
        scale
        riskChange
        checklist
        fields
        auditGroup {
          id
          value
        }
        leader {
          id
          value
        }
        team {
          id
          email
          value
          avatar
          color
          position
        }
        status
      }
      pageInfo {
        nextPageCursor
        hasNextPage
      }
    }
  }
`;

export const AUDIT = gql`
  query audit($id: ID!) {
    audit(id: $id) {
      id
      value
      fields
      scale
      checklist
      riskChange
      auditGroup {
        id
      }
      leader {
        id
        value
        avatar
        color
        position
      }
      team {
        id
        email
        value
        avatar
        color
        position
      }
      dates
      status
      instances {
        id
        value
        lastAudited
      }
    }
  }
`;

export const TESTS = gql`
  query tests(
    $audit: String
    $all: Boolean
    $my: Boolean
    $audits: [String]
    $open: Boolean
  ) {
    tests(audit: $audit, all: $all, my: $my, audits: $audits, open: $open) {
      testFeed {
        id
        value
        verifier {
          id
        }
        auditor {
          id
          value
          avatar
          color
          position
        }
        audit {
          id
        }
        hours
        start
        todo
        status
        verified
        done
        desc
        riskDesc
        num
        from
        sample
        controlCovered
        riskCovered
      }
    }
  }
`;

export const TEST = gql`
  query test($id: ID!) {
    test(id: $id) {
      id
      value
      auditor {
        id
        value
        avatar
        color
        position
      }
      hours
      start
      todo
      status
      verified
      done
      desc
      riskDesc
      num
      from
      sample
      controlCovered
      riskCovered
      audit {
        id
        value
        status
        leader {
          id
        }
      }
    }
  }
`;

export const FILES = gql`
  query files($test: String, $audit: String, $finding: String) {
    files(test: $test, audit: $audit, finding: $finding) {
      id
      filename
      versions
    }
  }
`;
export const FILE = gql`
  query file {
    file {
      filename
    }
  }
`;

export const AUDITPLANS = gql`
  query auditPlans {
    auditPlans {
      id
      value
      tree
    }
  }
`;

export const REPORTS = gql`
  query reports($audits: [String]) {
    reports(audits: $audits) {
      id
      desc
      value
      auditFields
      testFields
      testcFields
      findingFields
      generated
      type
      author {
        value
        color
        avatar
      }
    }
  }
`;

export const FINDING = gql`
  query finding($id: ID!) {
    finding(id: $id) {
      id
      value
      desc
      riskSelect0
      riskSelect1
      auditor {
        id
        value
      }
      test {
        id
        value
      }
      audit {
        id
        value
      }
      financialImpactA
      # financialImpactP
      remark
      risk
      recomendation
      solution
      lastMessage
      responsible
      responsibleEmail
      responsibleUnread
      auditorUread
      messages
      date
      status
    }
  }
`;

export const FINDINGS = gql`
  query findings($audits: [String], $tests: [String], $all: Boolean) {
    findings(audits: $audits, tests: $tests, all: $all) {
      id
      value
      desc
      riskSelect0
      riskSelect1
      auditor {
        id
        value
      }
      test {
        id
        value
      }
      audit {
        id
        value
      }
      financialImpactA
      # financialImpactP
      remark
      risk
      recomendation
      lastMessage
      solution
      responsible
      responsibleEmail
      responsibleUnread
      auditorUread
      messages
      date
      status
    }
  }
`;

export const REVIEW_NOTES = gql`
  query reviewNotes(
    $audits: [String]
    $tests: [String]
    $auditors: [String]
    $all: Boolean
    $open: Boolean
  ) {
    reviewNotes(
      audits: $audits
      tests: $tests
      auditors: $auditors
      all: $all
      open: $open
    ) {
      id
      value
      messages
      lastAuditor {
        id
        value
        avatar
        color
      }
      owner {
        id
        value
        avatar
        color
      }
      auditors {
        id
        value
        avatar
        color
      }
      test {
        id
        value
      }
      audit {
        id
        value
      }
      status
      updatedAt
    }
  }
`;

export const REVIEW_NOTE = gql`
  query reviewNote($id: ID!) {
    reviewNote(id: $id) {
      id
      value
      messages
      lastAuditor {
        id
        value
        avatar
        color
      }
      owner {
        id
        value
        avatar
        color
      }
      auditors {
        id
        value
        avatar
        color
      }
      test {
        id
        value
      }
      audit {
        id
        value
      }
      status
      updatedAt
    }
  }
`;

export const INCIDENTS = gql`
  query incidents {
    incidents {
      incidentFeed {
        id
        value
        desc
        done
        employeeName
        employeeEmail
        status
      }
    }
  }
`;

export const INCIDENT = gql`
  query incident($id: ID!) {
    incident(id: $id) {
      id
      value
      desc
      employeeName
      employeeEmail
      status
    }
  }
`;

export const LOGS = gql`
  query logs(
    $user: String
    $test: String
    $audit: String
    $incident: String
    $finding: String
    $task: String
  ) {
    logs(
      user: $user
      test: $test
      audit: $audit
      incident: $incident
      finding: $finding
      task: $task
    ) {
      logFeed {
        id
        title
        description
        type
        test {
          id
        }
        audit {
          id
        }
        user {
          id
          value
        }
        incident {
          id
        }
        finding {
          id
        }
        createdAt
        updatedAt
      }
      pageInfo {
        nextPageCursor
        hasNextPage
      }
    }
  }
`;

export const RISK = gql`
  query risk($id: ID!) {
    risk(id: $id) {
      id
      value
      description
      mitigate

      testcs {
        id
        value
      }
      createdAt
      updatedAt
    }
  }
`;
export const PROCES = gql`
  query proces($id: ID!) {
    proces(id: $id) {
      id
      value
      description
      risks {
        id
        value
        description
      }
      controls {
        id
        value
        description
      }
      createdAt
      updatedAt
    }
  }
`;

export const CONTROL = gql`
  query control($id: ID!) {
    control(id: $id) {
      id
      value
      description
      process {
        id
        value
      }
      createdAt
      updatedAt
    }
  }
`;

export const RISKS = gql`
  query risks {
    risks {
      riskFeed {
        id
        value
        description
        testcs {
          id
          value
        }
        process {
          id
          value
        }
        createdAt
        updatedAt
      }
      pageInfo {
        nextPageCursor
        hasNextPage
      }
    }
  }
`;

export const PROCESS = gql`
  query process {
    process {
      procesFeed {
        id
        value
        description
        controls {
          id
          value
          description
        }
        risks {
          id
          value
          description
        }
        createdAt
        updatedAt
      }
      pageInfo {
        nextPageCursor
        hasNextPage
      }
    }
  }
`;

export const CONTROLS = gql`
  query controls {
    controls {
      controlFeed {
        id
        value
        description
        process {
          id
          value
        }
        createdAt
        updatedAt
      }
      pageInfo {
        nextPageCursor
        hasNextPage
      }
    }
  }
`;

export const TESTCTEMPLATES = gql`
  query testcTemplates {
    testcTemplates {
      testcTemplateFeed {
        id
        value
        description
        inputs
        sources
        outputs
        rules
        prediction_code
        code
      }
      pageInfo {
        nextPageCursor
        hasNextPage
      }
    }
  }
`;

export const TESTCTEMPLATE = gql`
  query testcTemplate($id: ID!) {
    testcTemplate(id: $id) {
      id
      value
      description
      sources
      inputs
      outputs
      rules
      prediction_code
      code
    }
  }
`;

export const SOURCES = gql`
  query sources($inner: Boolean, $out: Boolean) {
    sources(inner: $inner, out: $out) {
      sourceFeed {
        id
        value
        inout
        type
        frequency
        host
        username
        password
        protocol
        path
        file
        limit
        values {
          value
        }
        createdAt
        updatedAt
      }
      pageInfo {
        nextPageCursor
        hasNextPage
      }
    }
  }
`;

export const TESTSC = gql`
  query testcs {
    testcs {
      testcFeed {
        id
        value
        description
        inputs
        outputs
        template {
          id
          value
        }
        last
        checklist
        description
        status
        day
        hour
        minute
        inweek
        results
        risks {
          id
          value
        }
        sources {
          id
          value
          name
          column
          timeColumn
          source {
            id
          }
        }
        frequency
      }
      pageInfo {
        nextPageCursor
        hasNextPage
      }
    }
  }
`;

export const TESTC = gql`
  query testc($id: ID!) {
    testc(id: $id) {
      id
      value
      description
      inputs
      outputs
      last
      checklist
      description
      status
      results
      day
      inweek
      hour
      minute
      template {
        id
        value
        sources
      }
      risks {
        id
        value
      }
      sources {
        id
        value
        name
        column
        timeColumn
        source {
          id
        }
      }
      frequency
    }
  }
`;

export const ACTION = gql`
  query action($id: ID!) {
    action(id: $id) {
      id
      value
      rules

      messages {
        id
        value
        receiver {
          id
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export const ACTIONS = gql`
  query actions($testc: String) {
    actions(testc: $testc) {
      actionFeed {
        id
        value
        rules
        testc {
          id
        }
        messages {
          id
          value
          receiver {
            id
          }
        }
        createdAt
        updatedAt
      }
      pageInfo {
        nextPageCursor
        hasNextPage
      }
    }
  }
`;

export const RECEIVERS = gql`
  query receivers {
    receivers {
      receiverFeed {
        id
        value
        description
        emails
        createdAt
        updatedAt
      }
      pageInfo {
        nextPageCursor
        hasNextPage
      }
    }
  }
`;

export const RECEIVER = gql`
  query receiver($id: ID!) {
    receiver(id: $id) {
      id
      value
      description
      emails
      createdAt
      updatedAt
    }
  }
`;

export const MESSAGES = gql`
  query messages($receiver: String, $action: String) {
    messages(receiver: $receiver, action: $action) {
      messageFeed {
        id
        value
        receiver {
          id
        }
        action {
          id
          value
          testc {
            id
            value
            risks {
              id
              value
            }
          }
        }
        createdAt
        updatedAt
      }
      pageInfo {
        nextPageCursor
        hasNextPage
      }
    }
  }
`;

export const TASK = gql`
  query task($id: ID!) {
    task(id: $id) {
      id
      value
      description
      messages
      solution
      status
      date
      when
      action {
        id
        value
        messages {
          value
          receiver {
            value
            description
          }
        }
      }
      testc {
        id
        value
        description
        risks {
          value
          process {
            value
            controls {
              value
            }
          }
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export const TASKS = gql`
  query tasks($status: String) {
    tasks(status: $status) {
      taskFeed {
        id
        value
        description
        status
        when
        action {
          id
          messages {
            id
            receiver {
              id
              emails
            }
          }
        }
        testc {
          id
          value
          description
          risks {
            value
          }
          sources {
            value
          }
        }

        messages
        date
        createdAt
        updatedAt
      }
      pageInfo {
        nextPageCursor
        hasNextPage
      }
    }
  }
`;

export const TESTCSOURCES = gql`
  query testcSources {
    testcSources {
      testcSourceFeed {
        id
        value
        column
        timeColumn
        source {
          id
        }
      }
      pageInfo {
        nextPageCursor
        hasNextPage
      }
    }
  }
`;

export const TESTCSOURCE = gql`
  query testcSource($id: ID!) {
    testcSource(id: $id) {
      id
      value
      column
      timeColumn
      source {
        id
      }
    }
  }
`;
