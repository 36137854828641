import React from 'react'
import Button from "@material-ui/core/Button";
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
// import { Input } from 'antd';

const useStyles = makeStyles(theme => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
    alignItems: 'center',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  
}));

export default ({variant, status, label, disabled, ...rest}) => {
    const classes = useStyles();
  
    return (
    <div className={classes.wrapper}>
        <Button
          variant={variant || "outlined" }
          disabled={status==='loading' || disabled}
          {...rest}
        >
          {label}
          {status==='loading' && <CircularProgress size={24} className={classes.buttonProgress} />}
        </Button>
      </div>
    )
}