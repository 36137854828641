import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import moment from "moment";
import { Date } from "elements";
import Grid from "@material-ui/core/Grid";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1, 1, 0, 0),
  },
}));

export default (props) => {
  const [value, setValue] = React.useState("1");
  const [selectedDate0, handleDateChange0] = React.useState(null);
  const [selectedDate1, handleDateChange1] = React.useState(null);
  var currentDate = moment();
  const dates = React.useRef([0, 0]);

  const getDates = (num, fullMonth = false) => {
    var futureDate = moment(currentDate).add(num, "M");

    return fullMonth
      ? [
          futureDate.startOf("month").valueOf(),
          futureDate.endOf("month").valueOf(),
        ]
      : [currentDate.valueOf(), futureDate.valueOf()];
  };

  React.useEffect(() => {
    let _d = getDates(0, true);
    props.onChange(_d[0], _d[1]);
    setValue("1");
  }, []);

  const data = [
    { label: "ten miesiąc", value: getDates(0, true), id: 1 },
    { label: "przyszły miesiąc", value: getDates(1, true), id: 1 },
    { label: "pół roku", value: getDates(6), id: 2 },
    { label: "rok", value: getDates(12), id: 2 },
  ];

  const handleChange = (event) => {
    setValue(event.target.value);

    const idx = parseInt(event.target.value);
    if (idx > 0) {
      const v = data[idx - 1].value;

      handleDateChange0(v[0]);
      handleDateChange1(v[1]);
      props.onChange &&
        selectedDate0 &&
        selectedDate1 &&
        props.onChange(v[0], v[1]);
    } else {
      handleDateChange0(null);
      handleDateChange1(null);
    }
  };

  const onChange = (idx) => (val) => {
    val = val.valueOf();
    if (idx === 0) {
      props.onChange && props.onChange(val, null);
      handleDateChange0(val);
      handleDateChange1(null);
    } else {
      props.onChange && props.onChange(selectedDate0, val);
      handleDateChange1(val);
    }
  };

  return (
    <>
      <FormControl component="fieldset" style={{ width: "100%" }}>
        <RadioGroup row name="dates" value={value} onChange={handleChange}>
          {data.map((d, idx) => (
            <FormControlLabel
              key={idx}
              value={`${idx + 1}`}
              control={<Radio color="primary" />}
              label={d.label}
            />
          ))}
          <FormControlLabel
            value="0"
            control={<Radio color="primary" />}
            label="wybierz daty"
          />

          {value === "0" && (
            <Grid
              container
              spacing={1}
              style={{ marginLeft: 30, width: "35%" }}
            >
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  onChange={onChange(0)}
                  variant="inline"
                  style={{ width: "125px" }}
                  format="YYYY-MM-DD"
                  label="Od"
                  value={selectedDate0}
                />

                <DatePicker
                  onChange={onChange(1)}
                  variant="inline"
                  style={{ marginLeft: 20, width: "125px" }}
                  format="YYYY-MM-DD"
                  label="Do"
                  value={selectedDate1}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          )}
        </RadioGroup>
      </FormControl>
    </>
  );
};
