import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import CommentIcon from "@material-ui/icons/Comment";
import { Input } from "elements";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",

    backgroundColor: theme.palette.background.paper,
  },
}));

export default (props) => {
  const classes = useStyles();
  const [checked, setChecked] = React.useState([...props.selected]);
  const [data, setData] = React.useState([...props.data]);
  const [search, setSearch] = React.useState("");
  let currentData = React.useRef([...props.data]);

  React.useEffect(() => {
    props.ex && Object.keys(props.ex).map((e) => setIt(e, props.ex[e]));
  }, []);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    props.onChange(newChecked);
  };

  const searchIt = (value) => {
    let newData = [...currentData.current];
    let v2 = value.toLowerCase();
    if (value && value.length)
      newData = newData.filter((d) => d.value.toLowerCase().indexOf(v2) >= 0);

    setData([...newData]);
    setSearch(value);
  };

  const changeFilter = (event) => {
    const { value } = event.target;
    if (value == "sel") {
      currentData.current = props.data.filter(
        (d) => checked.indexOf(d.id) >= 0
      );
    } else if (value == "not") {
      currentData.current = props.data.filter((d) => checked.indexOf(d.id) < 0);
    } else currentData.current = props.data;

    searchIt(search);
  };

  const setIt = (did, value) => {
    props.onChangeEx(did, value);
  };

  return (
    <div>
      <Input type="text" inputProps={{ onChange: searchIt }} />

      <RadioGroup
        row
        aria-label="position"
        name="position"
        defaultValue="all"
        onChange={changeFilter}
        style={{ marginLeft: 15 }}
      >
        <FormControlLabel
          value="all"
          control={<Radio color="primary" />}
          label="Wszystkie"
        />
        <FormControlLabel
          value="sel"
          control={<Radio color="primary" />}
          label="Wybrane"
        />
        <FormControlLabel
          value="not"
          control={<Radio color="primary" />}
          label="Niewybrane"
        />
      </RadioGroup>

      <List className={classes.root}>
        {data.map((d, idx) => {
          const labelId = `checkbox-list-label-${d.id}`;

          return (
            <ListItem fullWidth key={d.id} role={undefined} dense button>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={checked.indexOf(d.id) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                  onClick={handleToggle(d.id)}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={d.value}
                secondary={
                  props.ex &&
                  checked.indexOf(d.id) !== -1 && (
                    <div style={{ width: "100%" }}>
                      <br />
                      <Input
                        type="text"
                        multiline="true"
                        rows="2"
                        inputProps={{
                          defaultValue: props.ex[d.id],
                          onChange: (value) => setIt(d.id, value),
                        }}
                      />{" "}
                    </div>
                  )
                }
              />

              {}
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};
