import React from "react";
import { RightBar } from "elements";
import { Avatar, Panel, Status, Input } from "elements";
import { makeStyles } from "@material-ui/styles";
import {
  TextField,
  Divider,
  Card,
  Button,
  CardActions,
  CardContent,
  Switch,
  Grid,
} from "@material-ui/core";
import { useQuery, useMutation } from "@apollo/client";
import { REVIEW_NOTE, REVIEW_NOTES } from "graphql/queries";
import { ADD_MESSAGE, UPDATE_REVIEWNOTE } from "graphql/mutations";
import SendIcon from "@material-ui/icons/Send";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { useSnackbar } from "notistack";
import moment from "moment";
import { useAuthDataContext } from "context/Context";
import { getName } from "tools";

const useStyles = makeStyles((theme) => ({
  tab: {
    paddingRight: 45,
  },
  avatarLeft: {
    float: "left",
    marginLeft: 10,
    marginTop: 10,
  },
  avatarRight: {
    float: "right",
    marginRight: 10,
    marginTop: 10,
  },
  messageLeft: {
    width: 400,
    marginLeft: 50,
    minHeight: 100,
    padding: 10,
    marginBottom: 10,
  },
  messageRight: {
    width: 400,
    marginLeft: 150,
    minHeight: 100,
    padding: 10,
    marginBottom: 10,
  },
  messageBoard: {
    height: "75%",
    position: "fixed",
    top: 86,
    overflow: "scroll",
    scrollY: "auto",
    scrollX: "none",
    backgroundColor: "#f4f6f8",
    paddingBottom: 10,
    paddingTop: 10,
    width: 600,
  },
  messageNew: {
    position: "fixed",
    bottom: 0,
    height: 160,
    width: 600,
    backgroundColor: "#ffffff",
    padding: "5px 20px",
  },
  message: {
    fontSize: 14,
  },
  time: {
    textAlign: "right",
    fontSize: 11,
    width: "100%",
  },
}));

export default ({ open, id, setOpen }) => {
  const classes = useStyles();
  const el = React.useRef(null);
  const [message, setMessage] = React.useState();
  const { data, loading } = useQuery(REVIEW_NOTE, { variables: { id } });
  const [addMessage] = useMutation(ADD_MESSAGE);
  const [updateReviewNote] = useMutation(UPDATE_REVIEWNOTE);
  const { enqueueSnackbar } = useSnackbar();
  const { systemSettings, user } = useAuthDataContext();
  const dateFromat = systemSettings.dateFormat || "YYYY-MM-DD";

  if (loading) return false;
  // if(!reviewNote) return false
  // const [open, setOpen] = React.useState(initialState)
  const { reviewNote } = data;
  const messages = JSON.parse(reviewNote.messages);
  const users = {};

  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  reviewNote.auditors.map((a) => (users[a.id] = a));
  setTimeout(() => {
    try {
      el.current.scrollIntoView({ block: "end" });
    } catch (e) {}
  }, 200);

  const getStatus = () => {};

  const handleClick = async () => {
    const { data } = await addMessage({
      variables: { id: reviewNote.id, message },
      refetchQueries: [
        { query: REVIEW_NOTE, variables: { id: reviewNote.id } },
      ],
    });
    enqueueSnackbar(`Wiadomość została wysłana`, { variant: "success" });
    console.log(data);
  };
  // const handleClose = async() => {
  //     const {data} = await updateReviewNote({
  //         variables: { id: reviewNote.id, input: {status: 20} },
  //         refetchQueries: [{query: REVIEW_NOTE, variables: {id: reviewNote.id} } ]
  //     })
  //     enqueueSnackbar(`${getName("ReviewNote")} została zamknięta`, { variant: 'success' });

  // }

  // const handleOpen = async() => {
  //     const {data} = await updateReviewNote({
  //         variables: { id: reviewNote.id, input: {status: 10} },
  //         refetchQueries: [{query: REVIEW_NOTE, variables: {id: reviewNote.id} } ]
  //     })
  //     enqueueSnackbar(`${getName("ReviewNote")} została ponowanie otwarta`, { variant: 'success' });

  // }

  const switchIt = async ({ target }) => {
    let refetchQueries = [
      { query: REVIEW_NOTE, variables: { id: reviewNote.id } },
    ];
    if (reviewNote.test)
      refetchQueries.push({
        query: REVIEW_NOTES,
        variables: { tests: [reviewNote.test.id] },
      });
    if (reviewNote.audit)
      refetchQueries.push({
        query: REVIEW_NOTES,
        variables: { audits: [reviewNote.audit.id] },
      });

    await updateReviewNote({
      variables: {
        id: reviewNote.id,
        input: { status: target.checked ? 1 : 0 },
      },
      refetchQueries,
    });
    enqueueSnackbar(
      `${getName("ReviewNote")} została ${
        target.checked ? "zamknięta" : "otwarta"
      }`,
      { variant: "success" }
    );
  };

  return (
    <RightBar open={open} onClose={() => setOpen(false)}>
      <Panel
        subheader={
          <div className="subheader">
            status:{" "}
            <Status
              value={reviewNote.status}
              labels={{
                0: { label: "Nowa", icon: <LockOpenIcon /> },
                5: { label: "Odpowiedziana", icon: <LockOpenIcon /> },
                10: { label: "Wysłana", icon: <LockOpenIcon /> },
                20: { label: "Zamknięta", icon: <LockIcon /> },
              }}
            />
          </div>
        }
        title={reviewNote.value}
      >
        <div
          id="mb"
          className={classes.messageBoard}
          style={{ height: window.innerHeight - 246 }}
        >
          {messages.reverse().map((m, i) => (
            <>
              <div
                className={
                  m.id !== user.id ? classes.avatarLeft : classes.avatarRight
                }
              >
                <Avatar key={"avatar" + i} {...users[m.id]}>
                  {users[m.id].avatar}
                </Avatar>
              </div>
              <Card
                className={
                  m.id !== user.id ? classes.messageLeft : classes.messageRight
                }
              >
                <CardContent className={classes.message}>
                  {m.message}
                </CardContent>
                <CardActions>
                  <div className={classes.time}>
                    {moment.unix(m.time).format(dateFromat + " HH:mm")}
                  </div>
                </CardActions>
              </Card>
            </>
          ))}
          <div id={"el"} ref={el}></div>
        </div>
        <Divider />
        <div className={classes.messageNew}>
          <TextField
            disabled={reviewNote.status === 20}
            label="Nowa wiadomość"
            multiline
            rows={5}
            value={message}
            onChange={handleChange}
            fullWidth
          />
          <Grid component="label" container alignItems="center" spacing={1}>
            {/* <Grid xs={1} item style={{ fontSize: 14 }}>
              Otwarta
            </Grid>
            <Grid xs={2} item style={{ textAlign: "center" }}>
              <Switch
                checked={reviewNote.status}
                onChange={switchIt}
                name="status"
              />
            </Grid>
            <Grid xs={1} item style={{ fontSize: 14 }}>
              Zamknięta
            </Grid> */}

            <Grid item xs={8}>
              <Button
                disabled={reviewNote.status}
                className={classes.button}
                style={{ float: "right" }}
                startIcon={<SendIcon />}
                onClick={handleClick}
              >
                Wyślij
              </Button>
              <Button
                className={classes.button}
                style={{ float: "right" }}
                onClick={() => setOpen(false)}
              >
                Wyjdź
              </Button>
            </Grid>
          </Grid>

          {/* {user.id == reviewNote.owner.id && reviewNote.status===20 && <Button className={classes.button}  startIcon={<LockOpenIcon />} onClick={handleOpen}>Otwórz ponownie</Button>}
            {user.id == reviewNote.owner.id && reviewNote.status!==20 && <Button className={classes.button}  startIcon={<LockIcon />} onClick={handleClose}>Zamknij</Button>} */}
        </div>
      </Panel>
    </RightBar>
  );
};
