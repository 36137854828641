import React from "react";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import AllInboxTwoToneIcon from "@material-ui/icons/AllInboxTwoTone";

const useStyles = makeStyles(() => ({
  root: {
    textAlign: "center",
    opacity: 0.5,
    marginTop: 20,
  },
}));
export default ({ type, size }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AllInboxTwoToneIcon />
      <div>Brak danych</div>
    </div>
  );
};
