import React from "react";
import { useAuthDataContext } from "context/Context";
import { Panel } from "elements";
import { useQuery } from "@apollo/client";
import {
  // AUDITS,
  AUDITGROUPS,
  USERS,
  // SELECTEDAUDITGROUPS,
} from "graphql/queries";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
// import { useSnackbar } from 'notistack';
import { ToDo, MyReviewNotes, MyFindings } from "./components";
// import { isEmpty } from "underscore";
import { makeStyles } from "@material-ui/styles";
// import { useApolloClient } from '@apollo/react-hooks';
import { getName } from "tools";
import {
  // BrowserRouter as Router,
  // Switch,
  // Route,
  // Link,
  useLocation,
  useParams,
} from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  tab: {
    // paddingRight: 45
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default () => {
  let { num } = useParams();
  const {
    settings: { auditGroupFilter },
    setSettings,
  } = useAuthDataContext();
  // const [ids, setIds] = React.useState(auditGroupFilter || []);
  const ag = useQuery(AUDITGROUPS);
  const u = useQuery(USERS);
  const classes = useStyles();
  const [value, setValue] = React.useState(parseInt(num) || 0);

  // const [rightbarData, setRightbarData] = React.useState({});

  let location = useLocation();
  React.useEffect(() => {
    try {
      // console.log(location.pathname.match(/\d+\/?$/)[0].replace(/\/$/, ""), 2);
      setValue(
        parseInt(location.pathname.match(/\d+\/?$/)[0].replace(/\/$/, ""))
      );
    } catch (err) {
      setValue(0);
    }
  }, [location]);

  const handleChange = (_, newValue) => {
    let p = window.location.pathname.replace(/\/?\d+\/?$/, "") + "/" + newValue;
    window.history.pushState({}, null, p);
    console.log(p, 1);
    // setPath(p);
    setValue(newValue);
  };

  // const setSelected = (_1, _2, _3) => {
  //   setRightbarData(_1);
  // };

  // const changeAuditGroup = (_ids) => {
  //   setIds(_ids);
  //   setSettings({ auditGroupFilter: _ids });
  // };

  // const getRightBar = () => {
  //   if (isEmpty(rightbarData.current)) return null;
  //   return null;
  // };

  if (ag.loading || u.loading) return false;

  return (
    <div>
      <AppBar position="static" color="transparent">
        <Tabs value={value} onChange={handleChange}>
          <Tab label={getName("Test", 1)} {...a11yProps(0)} />
          <Tab label={getName("ReviewNote", 1)} {...a11yProps(1)} />
          <Tab label={getName("Finding", 1)} {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} className={classes.tab}>
        <Panel
          title={getName("Test", 1)}
          subheader="Lista twoich testów audytowych"
        >
          <ToDo />
        </Panel>
      </TabPanel>

      <TabPanel
        className="treeWithSelection"
        value={value}
        index={1}
        className={classes.tab}
      >
        <Panel
          title={getName("ReviewNote", 1)}
          subheader={"Lista twoich " + getName("ReviewNote", 1)}
        >
          <MyReviewNotes />
        </Panel>
      </TabPanel>
      <TabPanel
        className="treeWithSelection"
        value={value}
        index={2}
        className={classes.tab}
      >
        <Panel
          title={getName("Finding", 1)}
          {...a11yProps(2)}
          subheader={"Lista twoich rekomedacji audytowych"}
        >
          <MyFindings />
        </Panel>
      </TabPanel>
    </div>
  );
};
