import React from "react";

import { useAuthDataContext } from "context/Context";
import { RightBar, DateSelect, Panel, Loading } from "elements";
import { makeStyles } from "@material-ui/styles";
import _ from "lodash";
import { AppBar, Tabs, Tab, Typography, Box } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { Time } from "./components";

const useStyles = makeStyles((theme) => ({}));

function TabPanel(props) {
  const { children, value, index, style, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={style}
      {...other}
    >
      {value === index && <Box style={style}>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default () => {
  let { num } = useParams();
  const [pids, setPids] = React.useState([]);
  const {
    settings: { datesFilter },
    setSettings,
  } = useAuthDataContext();
  const [dates, setDates] = React.useState([]);

  const classes = useStyles();
  const [value, setValue] = React.useState(parseInt(num) || 0);
  const [rightbarData, setRightbarData] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const changeDates = (d1, d2) => {
    setDates([d1, d2]);
  };

  return (
    <div style={{ height: "100%" }}>
      <AppBar position="static" color="transparent">
        <Tabs value={value} onChange={handleChange} aria-label="">
          <Tab label="Zespół" {...a11yProps(0)} />
        </Tabs>
      </AppBar>
      <TabPanel
        className="treeWithSelection"
        value={value}
        index={0}
        className={classes.tab}
      >
        <Panel
          subheader={
            <DateSelect label="Okres" onChange={changeDates} noBorder={true} />
          }
          title="Zespół"
        >
          <Time dates={dates} />
        </Panel>
      </TabPanel>

      <RightBar
        open={rightbarData}
        onClose={() => setRightbarData(false)}
      ></RightBar>
    </div>
  );
};
