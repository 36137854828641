import React, { forwardRef } from 'react'
import {Tree, Table, RightBar} from 'elements'
import { useQuery, useMutation, useSubscription } from '@apollo/client';
import { PERSPECTIVES, INSTANCES} from 'graphql/queries'
import {
  UPDATE_VARIANT,
  UPDATE_PERSPECTIVE,
  DELETE_PERSPECTIVE,
  DELETE_VARIANT,
  CREATE_INSTANCE,
  CREATE_VARIANT,
  CREATE_PERSPECTIVE,
  DELETE_INSTANCE,
  CREATE_AUDIT,
  RANK_INSTANCES
} from 'graphql/mutations'
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useSnackbar } from 'notistack';
import { VariantDetails, VariantEdit, PerspectiveEdit } from '../../Risk/components'
import {isEmpty} from  'underscore'
import { makeStyles } from '@material-ui/styles';
import { useForm } from "react-hook-form";
import {getName} from 'tools'
import BarChartIcon from '@material-ui/icons/BarChart';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({ }));

export default (props) => {
  const [updatePerspective] = useMutation(UPDATE_PERSPECTIVE);
  const [createPerspective] = useMutation(CREATE_PERSPECTIVE);
  const { enqueueSnackbar } = useSnackbar();
  const [deletePerspective]= useMutation(DELETE_PERSPECTIVE);


  const setData = (_1,_2,_3) => {
    console.log('setData', _1,_2,_3)
  }
  const _updatePerspective = async (idx,name,value) => {
    let row = props.data[idx]

    if(name==='weight' && (value<0 || value>100)){
      enqueueSnackbar(`Zmiana nie została zapisana - waga poza zakresem. Dozwolony zakres (0-100).`, { variant: 'error' });
      return false 
    }

    const {data} = await updatePerspective({ variables: { id: row.id, input: { [name]: value } } })
    enqueueSnackbar(`${getName("Perspective")} ${data.updatePerspective.value} została zaktualizowana`, { variant: 'success' });
  }
   
  
  const up = async (idx) => {
    if(idx===0) return false
    let row = props.data[idx]
    let row2 = props.data[idx-1]


    await updatePerspective({ 
      variables: { id: row.id, input: { order: row.order-1 } } 
    })
    await updatePerspective({ 
      variables: { id: row2.id, input: { order: row2.order+1 } } ,
      refetchQueries: [{query: PERSPECTIVES}, {query: INSTANCES}] 
    })
    enqueueSnackbar(`Kolejność została zaktualizowana`, { variant: 'success' });
 
  }

  const down = async (idx) => {
    if(idx===props.data.length-1) return false
    let row = props.data[idx]
    let row2 = props.data[idx+1]

    console.log(row.order,row2.order)
    console.log(row.order+1,row2.order-1)
    await updatePerspective({ 
      variables: { id: row.id, input: { order: row.order+1 } } 
    })
    await updatePerspective({ 
      variables: { id: row2.id, input: { order: row2.order-1 } } ,
      refetchQueries: [{query: PERSPECTIVES}, {query: INSTANCES}] 
    })
    enqueueSnackbar(`Kolejność została zaktualizowana`, { variant: 'success' });
  }

  const onDeletePerspectives = async(ps) => {
    if(ps.length===0) return false
    let snackText = [ps.length>1 ? getName("Perspective",1): getName("Perspective"),"",ps.length>1 ? "zostały usunięte": "została usunięta"]
    let names = []
    for (let i = ps.length-1; i>0; i--){
      await deletePerspective({ variables: { id: ps[i].id }})
      names.push(ps[i].value)
    }
    await deletePerspective({ variables: { id: ps[0].id },
      refetchQueries: [{query: PERSPECTIVES }]  
    })
    names.push(ps[0].value)
    snackText[1] = names.join(", ")
    enqueueSnackbar(snackText.join(" "), { variant: 'success' });
  }

  const onSubmitPerspectiveForm = async({value, weight}) => {
    const {data} = await createPerspective({ 
      variables: { input: { value, weight: parseInt(weight) } },
      refetchQueries: [{query: PERSPECTIVES}] 
    })
    enqueueSnackbar(`${getName("Perspective")} ${data.createPerspective.value} została dodana`, { variant: 'success' });
    
  }
  // let data = props.data.map(d=>({...d, variantsCount: d.variants.length}))
  let all =0 
  props.data.map(d=>all+=d.variants.length)


  
  return (
    <Table
        typename='Perspective'
        columns={[{
          Header: 'Nazwa',
          accessor: 'value',
          type: 'text',
          width: '50%'
        },
        {
          Header: 'Waga',
          accessor: 'weight',
          type: 'number',
          width: '10%',
          inputProps: { 
            inputProps: {
              min: 0,
              max:99
            }
          }
        },{
          Header: getName('Variant',1),
          accessor: 'variants',
          type: 'info',
          width: '10%',
          inputProps: { 
            all,
            columns:[{name: "Nazwa", value:"value", width: "100%"}]
          }
        }]}
        setData={setData}
        updateMyData={_updatePerspective}
        // skipPageReset={false}
        onAdd={onSubmitPerspectiveForm}
        onDelete={onDeletePerspectives}
        rowActions={[
          {  icon:<ExpandLessIcon />,  onClick:up },
          {  icon:<ExpandMoreIcon />,  onClick:down },
          {  icon:<BarChartIcon />,  onClick:props.showDetails }
        
        ]}
        {...props}
      />
  )
}